import { Feature } from 'ol'
import Polygon from 'ol/geom/Polygon'
import VectorLayer from 'ol/layer/Vector'
import ClusterSource from 'ol/source/Cluster'
import olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import { parseExtentString } from '../../shared/utils'
import { StyleService } from '../services/style.service'
import { fromLonLat } from 'ol/proj'
import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style, Text } from 'ol/style'
import { Injector } from '@angular/core'
import { Geometry } from 'ol/geom'
import { Zoom } from '@sde-ild/ssd-soillib-lib'
import { Store } from '@ngxs/store'
import { FilterStateSelectors } from '../../store/filter/filter.selectors'
import { MapStateSelectors } from '../../store/map/map.selectors'

export class JobsitesLayer {
  jobsiteExtentSource: VectorSource
  jobsiteCenterSource: VectorSource

  private styleCache = {}
  private styleService: StyleService
  private store: Store

  constructor(private map: olMap, private injector: Injector) {
    this.styleService = this.injector.get(StyleService)
    this.store = this.injector.get(Store)
    this.jobsiteExtentSource = new VectorSource()
    this.jobsiteCenterSource = new VectorSource()
    this.map.addLayer(this.getJobsiteExtentLayer())
    this.map.addLayer(this.getJobsiteCenterLayer())
  }

  private getJobsiteExtentLayer(): VectorLayer {
    const jobsiteExtentLayer = new VectorLayer({
      source: this.jobsiteExtentSource,
      style: this.styleService.getJobsiteExtentStyle(),
      zIndex: 1,
    })
    jobsiteExtentLayer.set('name', 'JOBSITE-EXTENT')
    return jobsiteExtentLayer
  }

  private getJobsiteCenterLayer(): VectorLayer {
    const jobsiteCenterLayer = new VectorLayer({
      source: new ClusterSource({
        distance: 10,
        source: this.jobsiteCenterSource,
      }),
      style: (feature) => {
        const size = feature.get('features').length
        let featureStyle
        const zoom = this.map.getView().getZoom()
        if (size > 1 && zoom && zoom < Zoom.MAX) {
          featureStyle = this.styleCache[size]
          if (!featureStyle) {
            featureStyle = new Style({
              image: new CircleStyle({
                radius: this.getRadius(size.toString()),
                fill: new Fill({
                  color: [0, 0, 255, 0.8],
                }),
              }),
              text: new Text({
                text: size.toString(),
                font: 'bold 15px sans-serif',
                fill: new Fill({
                  color: '#fff',
                }),
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 0.6)',
                  width: 3,
                }),
              }),
            })
            this.styleCache[size] = featureStyle
          }
        } else {
          featureStyle = feature.get('features')[0].getStyle()
        }
        return featureStyle
      },
      zIndex: 1,
    })
    jobsiteCenterLayer.set('name', 'JOBSITE-CENTER')
    return jobsiteCenterLayer
  }

  private getRadius(size: string) {
    switch (size?.length) {
      case 1:
        return 12
      case 2:
        return 15
      case 3:
        return 18
      case 4:
        return 20
      default:
        return 15
    }
  }

  drawJobsiteExtents() {
    const jobsites = this.store.selectSnapshot(MapStateSelectors.slices.jobsites) || []
    const preventJobsiteExtentsDrawing = this.store.selectSnapshot(
      FilterStateSelectors.slices.preventJobsiteExtentsDrawing,
    )
    this.jobsiteExtentSource.clear()
    this.jobsiteCenterSource.clear()
    if (!preventJobsiteExtentsDrawing) {
      this.jobsiteExtentSource.addFeatures(
        jobsites
          .filter(({ extent }) => !!extent)
          .map(({ extent, id }) => {
            const parsedPolygon = extent ? parseExtentString(extent) : undefined
            const geometry = parsedPolygon ? new Polygon([parsedPolygon]) : undefined
            const feature = new Feature<Geometry>({ geometry })
            feature.set('extentId', id)
            feature.setId(id || undefined)
            return feature
          }),
      )
      this.jobsiteCenterSource.addFeatures(
        jobsites
          .filter(({ lat_coord, lon_coord }) => lat_coord != null && lon_coord != null)
          .map(({ id, lat_coord, lon_coord }) => ({
            id,
            lat_coord: lat_coord as number,
            lon_coord: lon_coord as number,
          }))
          .map(({ id, lat_coord, lon_coord }) => {
            const feature = new Feature<Geometry>({
              geometry: new Point(fromLonLat([lon_coord, lat_coord])),
            })
            feature.set('centerId', id)
            feature.setId(id || undefined)
            feature.setStyle(this.styleService.getJobsiteCenterStyle())
            return feature
          }),
      )
    }
  }
}
