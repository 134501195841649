import { Injectable } from '@angular/core'
import Handsontable from 'handsontable'
import { CellsToColorize } from '../models'
import { Param } from '../models/CellsToColorize.model'
import { isParam } from '../models/CellsToColorize.model.type.guard'
import { isString, nonNullable } from '../utils'
import { CellProperties } from 'handsontable/settings'
import * as Sentry from '@sentry/angular'

@Injectable()
export class DataTableService {
  private colorIndexes: { cutting: Param[]; survey: Param[] } = {
    survey: [
      'depth',
      'em',
      'f',
      'pf',
      'pl',
      'qc',
      'rf',
      'geo_soil',
      'calculated_soil',
      'pl_star',
      'n',
      'n_1_60',
      'rqd',
      'scr',
      'tcr',
      'u2',
      'uo',
      'i',
      'fs',
      'qt',
      'qn',
      'qe',
      'soilfr',
      'soilbq',
      'cu_insitu',
      'ic',
      'icRolling',
      'geotechnical_engineer_description',
      'calculated_compactness',
      'gamma',
      'spt_su_coefficient',
    ],
    cutting: [
      'toit',
      'base',
      'em',
      'f',
      'pf',
      'pl',
      'qc',
      'rf',
      'geo_soil',
      'calculated_soil',
      'pl_star',
      'n',
      'n_1_60',
      'rqd',
      'scr',
      'tcr',
      'u2',
      'uo',
      'i',
      'fs',
      'qt',
      'qn',
      'qe',
      'soilfr',
      'soilbq',
      'cu_insitu',
      'ic',
      'icRolling',
      'geotechnical_engineer_description',
      'calculated_compactness',
    ],
  }

  private noColor(code) {
    const colorCodeArr = Array.from(code)
    return colorCodeArr.filter((item) => item === '0').length === colorCodeArr.length
  }

  private constructSerializeColors(
    tableRef: Handsontable,
    rowIndex: number,
    colProp: Param,
    color: string,
    currentTableColor: { depth: number | null; color: string } | null,
    surveyType: 'survey' | 'cutting',
  ): { depth: number | null; color: string } | null {
    let tableColor = currentTableColor
    if (!tableColor) {
      tableColor = {
        color: Array(this.colorIndexes[surveyType].length).fill('0').join(''),
        depth: null,
      }
    }
    const toIndex = this.mapParamToIndex(surveyType, colProp)
    const toDigit = this.mapColorToDigit(color)
    if (toIndex !== undefined && toDigit !== undefined) {
      tableColor.color = this.replaceAt(tableColor.color, toIndex, toDigit.toString())
    }
    if (tableColor.depth == null) {
      if (surveyType === 'survey') {
        tableColor.depth = tableRef.getDataAtCell(rowIndex, tableRef.propToCol('depth'))
      } else if (surveyType === 'cutting') {
        tableColor.depth = tableRef.getDataAtCell(rowIndex, tableRef.propToCol('toit'))
      }
    }

    if (this.noColor(tableColor.color)) {
      tableColor = null
    }
    return tableColor
  }

  getCurrentTableColors(tableRef: Handsontable, type: 'survey' | 'cutting'): { depth: number | null; color: string }[] {
    let newTableColor: { depth: number | null; color: string }[] = []
    const coloredCells = this.getCellsColors(tableRef)
    if (coloredCells) {
      coloredCells.forEach(({ className, prop, visualRow }) => {
        if (isParam(prop) && isString(className)) {
          const newColor = this.constructSerializeColors(
            tableRef,
            visualRow,
            prop,
            className,
            newTableColor[visualRow],
            type,
          )
          if (newColor) {
            newTableColor[visualRow] = newColor
          }
        }
      })
      newTableColor = newTableColor.filter((item) => item)
    }
    return newTableColor
  }

  deSerializeColors(surveyType: 'survey' | 'cutting', colors: (string | null)[]): CellsToColorize {
    const cellsToColorize: CellsToColorize = {
      none: [],
      red: [],
      orange: [],
      yellow: [],
    }
    colors.forEach((rowColorCode, rowIndex) => {
      if (rowColorCode != null) {
        Array.from(rowColorCode).forEach((colorCode, colorCodeIndex) => {
          const color = this.mapDigitToColor(+colorCode)
          const toParam = this.mapIndexToParam(surveyType, +colorCodeIndex)
          if (toParam !== undefined) {
            cellsToColorize[color].push([rowIndex, toParam])
          }
        })
      }
    })
    return cellsToColorize
  }

  private mapParamToIndex = (surveyType: 'survey' | 'cutting', param: Param): number | undefined =>
    this.colorIndexes[surveyType].indexOf(param)

  private mapIndexToParam = (surveyType: 'survey' | 'cutting', index: number): Param | undefined =>
    this.colorIndexes[surveyType][index]

  private mapDigitToColor(digit: number): 'yellow' | 'orange' | 'red' | 'none' {
    switch (digit) {
      case 0:
        return 'none'
      case 1:
        return 'yellow'
      case 2:
        return 'orange'
      case 3:
        return 'red'
      default:
        return 'none'
    }
  }

  private mapColorToDigit(color: string): number | undefined {
    if (color.includes('none')) {
      return 0
    } else if (color.includes('yellow')) {
      return 1
    } else if (color.includes('orange')) {
      return 2
    } else if (color.includes('red')) {
      return 3
    }
  }

  private replaceAt(originString: string, index: number, replacement: string): string {
    return originString.substr(0, index) + replacement + originString.substr(index + replacement.length)
  }

  private getCellsColors(tableRef: Handsontable): CellProperties[] | null {
    try {
      return (
        tableRef
          .getCellsMeta()
          ?.filter(nonNullable)
          ?.filter(
            ({ className }) =>
              className && (className.includes('red') || className.includes('orange') || className.includes('yellow')),
          ) || null
      )
    } catch (e) {
      Sentry.captureException(e)
      return null
    }
  }
}
