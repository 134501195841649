<ng-container
  *ngIf="{
    complexChartsOpened: complexChartOpened$ | async,
    disableDrawOrEditGraph: disableDrawOrEditGraph$() | async
  } as componentState"
>
  <div
    [@slideInOutFromLeft]="componentState.complexChartsOpened"
    [style.margin-left]="componentState.complexChartsOpened ? '45vw' : ''"
    class="container"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="handleComplexChartsToggle(!componentState.complexChartsOpened)"
  >
    <div fxLayout="row">
      <span>{{ 'COMPARISON.COMPLEX_CHARTS' | translate }}</span>
      <img
        *ngIf="!componentState.complexChartsOpened"
        fxFlexAlign="end"
        id="img-right-arrow"
        src="assets/ico-arrow-right.png"
      />
    </div>
  </div>

  <div
    [@slideInOutFromLeft]="componentState.complexChartsOpened"
    class="sidenav"
    *ngIf="componentState.complexChartsOpened"
    fxLayout="column"
  >
    <form [formGroup]="complexChartForm">
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">
        <mat-form-field>
          <input [placeholder]="'COMPARISON.CHART_TITLE' | translate" matInput formControlName="title" type="text" />
          <mat-error *ngIf="complexChartForm.get('title')?.hasError('required')">
            * <strong>{{ 'GENERAL.REQUIRED' | translate }}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-select
            [placeholder]="'COMPARISON.COMMON_BH_TYPE' | translate"
            [formControl]="commonBhTypeFormControl"
            *ngIf="allBoreholeTypes$ | async as allBoreholeTypes"
          >
            <mat-option [value]="type" *ngFor="let type of allBoreholeTypes">
              <span [innerHtml]="showBoreholeTypeName(type)"></span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="commonBhTypeFormControl.hasError('required')">
            * <strong>{{ 'GENERAL.REQUIRED' | translate }}</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-select
            [placeholder]="'COMPARISON.SET_COMMON_PARA' | translate"
            [formControl]="commonParametersFormControl"
            *ngIf="commonParameters$ | async as commonParameters"
          >
            <mat-option [value]="para" *ngFor="let para of commonParameters">
              <span [innerHtml]="getParamName(para)"></span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="commonParametersFormControl.hasError('required')">
            * <strong>{{ 'GENERAL.REQUIRED' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <p *ngIf="complexChartForm.controls.surveyNames.controls.length === 0" class="info-tips">
        <mat-icon>info</mat-icon>
        {{ 'COMPARISON.INFO.ADD_BOREHOLES' | translate }}
      </p>
      <div
        fxLayout="row"
        fxLayoutGap="5px"
        fxLayoutAlign="space-between"
        *ngIf="availableParams$ | async as availableParams"
      >
        <div fxFlex="15" formArrayName="surveyNames" fxLayout="column" fxLayoutGap="5px">
          <mat-form-field
            class="column-cell"
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngFor="let surveyName of complexChartForm.controls.surveyNames.controls; let i = index"
          >
            <input matInput [placeholder]="'SURVEY.NAME' | translate" formControlName="{{ i }}" readonly type="text" />
          </mat-form-field>
        </div>
        <div fxFlex="20" formArrayName="surveyTypes" fxLayout="column" fxLayoutGap="5px">
          <mat-form-field
            class="column-cell"
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngFor="let surveyType of complexChartForm.controls.surveyTypes.controls; let i = index"
          >
            <input matInput placeholder="Type" formControlName="{{ i }}" readonly type="text" class="hidden" />
            <input
              *ngIf="showBoreholeTypeName(surveyType.value) as bhTypeName"
              [value]="bhTypeName || ''"
              matInput
              matTooltip="{{ bhTypeName }}"
              placeholder="Type"
              readonly
              type="text"
            />
          </mat-form-field>
        </div>
        <div fxFlex="15" formArrayName="selectedParams" fxLayout="column" fxLayoutGap="5px">
          <mat-form-field
            class="column-cell"
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngFor="let selectedParam of complexChartForm.controls.selectedParams.controls; let i = index"
          >
            <mat-select formControlName="{{ i }}" [placeholder]="'COMPARISON.PARAMETERS' | translate">
              <mat-option *ngFor="let availableParam of availableParams[i]" [value]="availableParam">
                <span [innerHtml]="getParamName(availableParam)"></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="10" formArrayName="selectedColors" fxLayout="column" fxLayoutGap="5px">
          <div
            class="column-cell"
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngFor="let color of complexChartForm.controls.selectedColors.controls; let i = index"
          >
            <input class="pointer" formControlName="{{ i }}" type="color" />
          </div>
        </div>
        <div fxFlex="5" fxFlex.lt-xl="5" fxLayout="column" fxLayoutGap="5px">
          <div
            class="column-cell pointer"
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngFor="let availableParam of availableParams; let i = index"
          >
            <i (click)="deleteSurvey(i)" class="material-icons">delete</i>
          </div>
        </div>
        <div
          fxFlex="20"
          fxFlexAlign="start"
          fxLayout="column"
          *ngIf="availableParams.length"
          class="actions-buttons"
          fxLayoutGap="10px"
        >
          <mat-icon
            *ngIf="componentState.disableDrawOrEditGraph"
            style="color: orange"
            matTooltip="{{ 'COMPARISON.COMPLEX_CHART_FORM_WARNING' | translate }}"
            >warning
          </mat-icon>
          <ng-container *ngIf="editedComplexChart$ | async; else drawButton">
            <button
              type="button"
              mat-raised-button
              color="primary"
              [ngClass]="{ disabled: componentState.disableDrawOrEditGraph }"
              [disabled]="!!componentState.disableDrawOrEditGraph"
              (click)="complexChartForm.valid && editComplexGraph()"
            >
              {{ 'GENERAL.EDIT' | translate }}
            </button>
            <button mat-raised-button color="primary" type="button" (click)="handleComplexChartsToggle(false)">
              {{ 'GENERAL.CANCEL' | translate }}
            </button>
            <button mat-raised-button color="warn" type="button" (click)="deleteComplexGraph()">
              {{ 'COMPARISON.DELETE_CHART' | translate }}
            </button>
            <button mat-raised-button class="copy-button" type="button" (click)="copyComplexGraph()">
              {{ 'COMPARISON.COPY_CHART' | translate }}
            </button>
          </ng-container>
          <ng-template #drawButton>
            <button
              type="button"
              mat-raised-button
              color="primary"
              [ngClass]="{ disabled: componentState.disableDrawOrEditGraph }"
              [disabled]="!!componentState.disableDrawOrEditGraph"
              (click)="complexChartForm.valid && drawComplexGraph()"
            >
              {{ 'COMPARISON.DRAW_THE_CHART' | translate }}
            </button>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
</ng-container>
