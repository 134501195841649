<div class="import-container">
  <h3 *ngIf="level === 'borehole'">{{ 'FILE_IMPORT.IMPORT_CSV_BH' | translate }}</h3>
  <div *ngIf="level === 'jobsite'" fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
    <h3>{{ 'FILE_IMPORT.IMPORT_CSV_JOBSITE' | translate }}</h3>

    <mat-icon
      style="margin-left: 20px"
      matTooltipClass="line-break-tooltip"
      [matTooltip]="'FILE_IMPORT.CSV_CONFIG_HELP' | translate : { example: shortTemplateExample }"
      >help</mat-icon
    >
    <button
      tabindex="-1"
      mat-icon-button
      [matTooltip]="'FILE_IMPORT.CSV_CONFIG_COPY.HELP' | translate"
      [cdkCopyToClipboard]="templateExample"
      (click)="showCopySuccess()"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <soillib-import-select-files
      fxFlex="10"
      (filesSelectedEvent)="handleFilesSelected($event)"
      (delimiterSelectedEvent)="delimiterSelected($event)"
    ></soillib-import-select-files>
    <div fxFlex="90" *ngIf="selectedFiles">
      <soillib-import-csv-config
        [parameters_dispo]="availableParameters$ | async"
        [csvMetaHeaders]="metaHeaders"
        [csvParamHeaders]="paraHeaders"
        [level]="level"
        (sendConfigHeadersEvent)="sendConfigHeaders($event)"
      ></soillib-import-csv-config>
      <div fxLayout="row" style="margin-top: 15px">
        <soillib-import-configuration
          fxFlex="20"
          [level]="level"
          [detectedProjections]="detectedProjections"
          [detectedJobsiteNames]="detectedJobsiteNames"
          [detectedType]="detectedType"
          [customProjInfoText]="customProjInfoText"
          [customProjErrorText]="customProjErrorText"
          [selectedJobsiteName]="selectedJobsiteName"
          (projectionSelectEvent)="onProjSelectionChange($event)"
          (projectionCodeChangeEvent)="applyProjection($event)"
          (jobsiteNameSelectEvent)="setJobsiteName($event)"
          (boreholeTypeSelectEvent)="onBoreholeTypeChange($event)"
        ></soillib-import-configuration>
        <soillib-import-boreholes-preview
          fxFlex="80"
          [level]="level"
          [selectedFiles]="selectedFiles"
          [importedBoreholes]="allImportedBoreholes"
          [selectedJobsiteName]="selectedJobsiteName"
          [loadingText]="loadingText"
          [datumDisplayName]="datumDisplayName"
          (updateSurveysToMapEvent)="updateSurveysToMap($event)"
          (finishAgsImportEvent)="finishAgsImport($event)"
        ></soillib-import-boreholes-preview>
      </div>
    </div>
  </div>
</div>
