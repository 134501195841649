import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SurveysCorrelationDataComponent } from './surveys-correlation-data.component'
import { CorrelationDataChartComponent } from './correlation-data-chart/correlation-data-chart.component'
import { NgxsModule } from '@ngxs/store'
import { CorrelationDataState } from './store/correlation-data.state'
import { MaterialModule } from '../material/material.module'
import { TranslateModule } from '@ngx-translate/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { CorrelationDataService } from '../shared/remote-services'
import { CorrelationParametersDialog } from './correlation-parameters-dialog/correlation-parameters-dialog.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [SurveysCorrelationDataComponent, CorrelationDataChartComponent, CorrelationParametersDialog],
  imports: [
    CommonModule,
    NgxsModule.forFeature([CorrelationDataState]),
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [SurveysCorrelationDataComponent],
  providers: [CorrelationDataService],
})
export class SurveysCorrelationDataModule {}
