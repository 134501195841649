import { Injectable } from '@angular/core'
import Feature from 'ol/Feature'
import Polygon, { fromExtent as FROMEXTENT } from 'ol/geom/Polygon'
import LineString from 'ol/geom/LineString'
import VectorSource from 'ol/source/Vector'
import { getCenter as GETEXTENTCENTER, getHeight as GETEXTENTHEIGHT, getWidth as GETEXTENTWIDTH } from 'ol/extent'
import { fromLonLat as FROMLONLAT, toLonLat as TOLONLAT } from 'ol/proj'
import { BehaviorSubject } from 'rxjs'
import { StyleService } from '../../main-map/services/style.service'
import { lineString } from '@turf/helpers'
import lineIntersect from '@turf/line-intersect'
import { Geometry } from 'ol/geom'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Coordinate } from 'ol/coordinate'
import { isString } from '../utils'
import { Store } from '@ngxs/store'
import { CrossSectionSetProjectedBhInfos } from '../../soil-cuttings-edition/store/cross-section.actions'

@Injectable()
export class CrossSectionService {
  constructor(private styleService: StyleService, private store: Store) {}

  // TODO move fields to cross section state
  private lineFeature: Feature
  private boxFeature: Feature
  private angle: number
  private centerPoint: Coordinate
  private parallelX: boolean
  private boxGeomOriginal: Polygon
  private lineGeomOriginal: LineString
  private originalK: number
  private lineScale = 1

  private originalHeight: number

  private _canCrossJobsite: BehaviorSubject<boolean> = new BehaviorSubject(true)
  canCrossJobsite$ = this._canCrossJobsite.asObservable()

  get canCrossJobsite() {
    return this._canCrossJobsite.value
  }

  set canCrossJobsite(cj: boolean) {
    this._canCrossJobsite.next(cj)
  }

  showSelectBox(surveys: SoilSurvey[], source: VectorSource, showBox = true) {
    if (!surveys || surveys.length !== 2) {
      console.error('Original boreholes number must be two !')
    } else {
      if (!source.getFeatures() || source.getFeatures().length === 0) {
        // add centerline
        const firstPoint = FROMLONLAT([Number(surveys[0].lon_coord), Number(surveys[0].lat_coord)])
        const secondPoint = FROMLONLAT([Number(surveys[1].lon_coord), Number(surveys[1].lat_coord)])
        const twoCoord: [{ x: number; y: number }, { x: number; y: number }] = [
          {
            x: firstPoint[0],
            y: firstPoint[1],
          },
          {
            x: secondPoint[0],
            y: secondPoint[1],
          },
        ]
        this.lineGeomOriginal = new LineString([
          [twoCoord[0].x, twoCoord[0].y],
          [twoCoord[1].x, twoCoord[1].y],
        ])
        this.originalHeight = this.lineGeomOriginal.getLength()
        const centerlineGeom = this.lineGeomOriginal.clone() as LineString
        const lineExtent = centerlineGeom.getExtent()
        this.centerPoint = GETEXTENTCENTER(lineExtent)
        this.angle = this.getCenterLineAngle(twoCoord)
        let boxGeomScaleX: number
        let boxGeomScaleY: number
        // the inital width of select box is set to 70 !
        if (this.parallelX) {
          boxGeomScaleY = 70 / GETEXTENTHEIGHT(lineExtent)
          boxGeomScaleX = centerlineGeom.getLength() / GETEXTENTWIDTH(lineExtent)
        } else {
          boxGeomScaleX = 70 / GETEXTENTWIDTH(lineExtent)
          boxGeomScaleY = centerlineGeom.getLength() / GETEXTENTHEIGHT(lineExtent)
        }
        this.boxGeomOriginal = FROMEXTENT(lineExtent)
        this.boxGeomOriginal.scale(boxGeomScaleX, boxGeomScaleY)
        const boxGeom = this.boxGeomOriginal.clone()
        boxGeom.rotate(this.angle, this.centerPoint)
        this.lineFeature = new Feature<Geometry>({
          geometry: centerlineGeom,
        })
        this.lineFeature.setStyle(this.styleService.getCrossSectionCenterLineStyle())
        this.lineFeature.setId('line')
        this.boxFeature = new Feature<Geometry>({
          geometry: boxGeom,
        })
        this.boxFeature.setId('box')
        if (showBox) {
          source.addFeatures([this.lineFeature, this.boxFeature])
        } else {
          source.addFeatures([this.lineFeature])
        }
      }
    }
  }

  getOriginalHeight() {
    if (this.originalHeight !== undefined) {
      return this.originalHeight
    } else {
      return 1
    }
  }
  resizeSelectBox(parallel: number, vertical: number, source: VectorSource) {
    let sx: number
    let sy: number
    if (this.parallelX) {
      sx = parallel
      sy = vertical
    } else {
      sx = vertical
      sy = parallel
    }
    source.clear()
    const boxGeom = this.boxGeomOriginal.clone()
    boxGeom.scale(sx, sy)
    boxGeom.rotate(this.angle, this.centerPoint)
    this.boxFeature = new Feature<Geometry>({
      geometry: boxGeom,
    })
    this.boxFeature.setId('box')
    const lineGeom = this.lineGeomOriginal.clone()
    this.lineScale = parallel
    lineGeom.scale(parallel)
    this.lineFeature = new Feature<Geometry>({
      geometry: lineGeom,
    })
    this.lineFeature.setId('line')
    this.lineFeature.setStyle(this.styleService.getCrossSectionCenterLineStyle())
    source.addFeatures([this.lineFeature, this.boxFeature])
  }

  getBoreholesInside(selectedJobsiteId: string | null | undefined, surveys: SoilSurvey[]) {
    const projectedBoreholes: SoilSurvey[] = []
    const boxGeom = this.boxFeature.getGeometry()
    surveys.forEach((item: SoilSurvey) => {
      const coord = FROMLONLAT([Number(item.lon_coord), Number(item.lat_coord)])
      const canPush = this.canCrossJobsite
        ? boxGeom?.intersectsCoordinate(coord)
        : boxGeom?.intersectsCoordinate(coord) && item.jobsite_id === selectedJobsiteId
      if (canPush) {
        projectedBoreholes.push(item)
      }
    })
    return projectedBoreholes
  }

  showVerticalLines(projectedBoreholes: SoilSurvey[], source: VectorSource) {
    const projectedBhInfos = {}
    const lineGeom = this.lineGeomOriginal.clone() as LineString
    lineGeom.scale(this.lineScale)
    const line1 = lineString([TOLONLAT(lineGeom.getFirstCoordinate()), TOLONLAT(lineGeom.getLastCoordinate())])
    if (this.originalK !== 0) {
      const verticalK = -1 / this.originalK
      const verticalFeatures: Feature[] = []
      projectedBoreholes
        .filter((item) => !!item.id)
        .forEach((item) => {
          const itemCoord = FROMLONLAT([Number(item.lon_coord), Number(item.lat_coord)])
          let deltax = -2000
          let deltay = deltax * verticalK
          let secondCoord = [itemCoord[0] + deltax, itemCoord[1] + deltay]
          let line2 = lineString([
            [Number(item.lon_coord), Number(item.lat_coord)],
            TOLONLAT([secondCoord[0], secondCoord[1]]),
          ])

          let intersects = lineIntersect(line1, line2)
          if (intersects.features.length === 0) {
            deltax = 2000
            deltay = deltax * verticalK
            secondCoord = [itemCoord[0] + deltax, itemCoord[1] + deltay]
            line2 = lineString([
              [Number(item.lon_coord), Number(item.lat_coord)],
              TOLONLAT([secondCoord[0], secondCoord[1]]),
            ])
            intersects = lineIntersect(line1, line2)
          }
          const projectedPoint = intersects.features.find(() => true)?.geometry?.coordinates
          const projectedPointCoord = projectedPoint ? FROMLONLAT([projectedPoint[0], projectedPoint[1]]) : null
          const verticalLineGeom = projectedPointCoord ? new LineString([itemCoord, projectedPointCoord]) : null

          if (isString(item.id) && projectedPoint && verticalLineGeom) {
            projectedBhInfos[item.id] = {
              projectedPointCoord: projectedPoint,
              distance: verticalLineGeom.getLength(),
            }
          }

          const vfeature = new Feature<Geometry>({
            geometry: verticalLineGeom,
          })
          verticalFeatures.push(vfeature)
        })
      source.addFeatures(verticalFeatures)
    }
    this.store.dispatch(new CrossSectionSetProjectedBhInfos(projectedBhInfos))
  }

  getDistanceOfTwoPoints(coordsOne: Coordinate, coordsTwo: Coordinate): number {
    const pointOne = FROMLONLAT(coordsOne)
    const pointTwo = FROMLONLAT(coordsTwo)
    const line = new LineString([pointOne, pointTwo])
    return line.getLength()
  }

  private getCenterLineAngle(twoCoord: [{ x: number; y: number }, { x: number; y: number }]): number {
    const deltax = Math.abs(twoCoord[0].x - twoCoord[1].x)
    const deltay = Math.abs(twoCoord[0].y - twoCoord[1].y)
    this.originalK = (twoCoord[0].y - twoCoord[1].y) / (twoCoord[0].x - twoCoord[1].x)
    let k: number
    if (deltax > deltay) {
      k = (twoCoord[0].y - twoCoord[1].y) / (twoCoord[0].x - twoCoord[1].x)
      this.parallelX = true
      return Math.tanh(k)
    } else {
      k = (twoCoord[0].x - twoCoord[1].x) / (twoCoord[0].y - twoCoord[1].y)
      this.parallelX = false
      return Math.PI - Math.tanh(k)
    }
  }
}
