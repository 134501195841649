<mat-toolbar id="header" fxFlexAlign="row no-wrap" fxLayoutAlign="start center">
  <span fxFlex="10"></span>
  <span fxFlex="20">
    <img id="logo" src="assets/logo-soletanche.png" />
    <img id="logoSoillib" src="assets/Soillib.png" />
    <span id="main-title">
      <span class="red-vertical-bar">&#x0007C;</span>
      {{ 'HEADER.TITLE' | translate }}
    </span>
  </span>
  <span fxFlex="45"></span>
  <span fxFlex="5">
    <button
      [disabled]="(selectedJobsite$ | async) === null"
      mat-icon-button
      color="primary"
      id="message-logo"
      (click)="displayMessagesList = !displayMessagesList"
    >
      <mat-icon
        matTooltip="{{ 'GENERAL.POPOVER' | translate }}"
        matTooltipPosition="below"
        [matTooltipDisabled]="(selectedJobsite$ | async) !== null"
        >message</mat-icon
      >
    </button>
    <soillib-messages [selectedJobsite]="selectedJobsite$ | async" *ngIf="displayMessagesList"></soillib-messages>
  </span>

  <div id="profile">
    <span class="vertical-bar-right">&#x0007C;</span>
    <span
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="profileMenu"
      (click)="menuTrigger.openMenu()"
      class="profile"
    >
      <div id="profile-text">
        <p id="profile-name">
          {{ user?.name }}
        </p>
      </div>
      <img id="profile-logo" src="assets/ico-user-header.png" />
    </span>
  </div>
  <div style="height: 100%; margin-left: 20px; cursor: pointer" matTooltip="{{ 'GENERAL.TUTOS' | translate }}">
    <img style="height: 100%" src="assets/tutorials.png" (click)="openTuto()" />
  </div>
  <mat-menu #profileMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
    <button mat-menu-item (click)="openAbout()">
      <mat-icon><i class="material-icons">information</i></mat-icon>
      <span>{{ 'GENERAL.ABOUT' | translate }}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="languageMenu">
      <mat-icon><i class="material-icons">language</i></mat-icon>
      <span>{{ 'HEADER.LANGUAGES' | translate }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon><i class="material-icons">power_settings_new</i></mat-icon>
      <span>{{ 'HEADER.LOGOUT' | translate }}</span>
    </button>
  </mat-menu>
  <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    <ng-container *ngIf="currentLanguage$ | async as currentLanguage">
      <button mat-menu-item (click)="setCurrentLang('en')" [class.selected]="currentLanguage === 'en'">English</button>
      <button mat-menu-item (click)="setCurrentLang('fr')" [class.selected]="currentLanguage === 'fr'">Français</button>
    </ng-container>
  </mat-menu>
</mat-toolbar>

<div class="overlay" *ngIf="!hideOverlay">
  <div class="arrow">
    <img class="icon" height="50" src="assets/arrow.png" />
    <div class="text">{{ 'GENERAL.HERE_TUTOS' | translate }}</div>
    <mat-checkbox class="checkbox" (change)="onCheck($event.checked)">{{
      'GENERAL.NOT_REMIND' | translate
    }}</mat-checkbox>
    <br />
    <button mat-raised-button class="button" (click)="hide()">OK</button>
  </div>
</div>
