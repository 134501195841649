import { createPickSelector, createPropertySelectors, createSelector, Selector } from '@ngxs/store'
import { UserConfigState, UserConfigStateModel } from './user-config.state'
import { Permission } from '../../shared/models'

export class UserConfigStateSelectors {
  static slices = createPropertySelectors<UserConfigStateModel>(UserConfigState)

  static fullUserConfigState = createSelector([UserConfigState], (state: UserConfigStateModel) => state)

  static zoomAndCenter = createPickSelector(UserConfigStateSelectors.fullUserConfigState, ['zoom', 'center'])

  @Selector([UserConfigStateSelectors.slices.language])
  static userLanguage(language: string | undefined): string {
    return language || 'en'
  }

  @Selector([UserConfigStateSelectors.slices.permission])
  static canWrite(permission: Permission | null): boolean {
    return permission?.canWrite || false
  }

  @Selector([UserConfigStateSelectors.slices.permission])
  static canCreate(permission: Permission | null): boolean {
    return permission?.canCreate || false
  }

  @Selector([UserConfigStateSelectors.slices.permission])
  static canDelete(permission: Permission | null): boolean {
    return permission?.canDelete || false
  }
}
