<div class="cross-section" fxLayout="row">
  <div fxFlex="20" fxLayout="column" fxLayoutAlign="top" fxLayoutGap="5px" class="select-part">
    <soillib-creation-configuration
      *ngIf="!selectedCrossSectionInfos"
      [originalBoreholes]="originalBoreholes"
      [projectedBoreholes]="projectedBoreholes"
      (resizeSelectBoxEvent)="resizeSelectBox($event)"
    ></soillib-creation-configuration>

    <div *ngIf="selectedCrossSectionInfos">
      <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{ selectedCrossSection?.cr_name }}</span>
        <mat-icon (click)="cancelSelection()">close</mat-icon>
      </div>
      <p>{{ 'CROSS_SECTION.ORIGINAL_BOREHOLES' | translate }}</p>
      <ul fxLayout="row warp" fxLayoutGap="30px">
        <li fxFlex *ngFor="let ob of selectedCrossSectionInfos?.originalBhs">{{ ob.name }}</li>
      </ul>
      <p>{{ 'CROSS_SECTION.PROJECTED_BOREHOLES' | translate }}</p>
      <ul fxLayout="row warp" fxLayoutGap="30px">
        <li fxFlex *ngFor="let ob of selectedCrossSectionInfos?.projectedBhs">{{ ob.name }}</li>
        <li class="deleted-bh" fxFlex *ngFor="let dp of deletedProjectedBhs">
          {{ dp.survey_name }} ({{ dp.survey_type }})
        </li>
      </ul>
    </div>

    <div class="button-container" fxLayout="row" fxLayoutAlign="space-between center">
      <button
        *ngIf="!selectedCrossSectionInfos"
        mat-raised-button
        color="warn"
        [disabled]="!canSave"
        (click)="saveCrossSection()"
      >
        {{ 'CROSS_SECTION.SAVE' | translate }}
      </button>
      <button
        *ngIf="selectedCrossSectionInfos"
        mat-raised-button
        color="warn"
        [disabled]="!currentSelectedparameters"
        (click)="updateCrossSection()"
      >
        {{ 'CROSS_SECTION.UPDATE' | translate }}
      </button>
      <button
        *ngIf="!selectedCrossSectionInfos"
        mat-raised-button
        color="warn"
        (click)="showStratigraphy()"
        [ngClass]="{ disabled: originalBoreholes?.length !== 2 }"
        [disabled]="originalBoreholes?.length !== 2"
      >
        {{ 'CROSS_SECTION.STRATI' | translate }}
      </button>
    </div>
  </div>

  <div fxFlex="80" fxLayout="column" fxLayoutAlign="top center" class="stratigraphy-part">
    <ssd-soillib-lib-stratigraphy-rendering-chart
      class="stratigraphyChart"
      (highlightBoreholeEvent)="highlightBorehole($event)"
      (setSelectedTestParametersEvent)="setSelectedTestParameters($event)"
      [crossSectionStratigraphyData]="crossSectionStratigraphyData"
      [chartFrom]="'cross-section'"
      [labTestDataArray]="labTestDataArray"
      [selectedCrossSectionParameters]="selectedCrossSectionParameters"
      [datumDisplayName]="(datumDisplayName$ | async) || 'NGF'"
    ></ssd-soillib-lib-stratigraphy-rendering-chart>
  </div>
</div>
