import { Action, State, StateContext } from '@ngxs/store'
import { Injectable } from '@angular/core'
import produce from 'immer'
import {
  FilterSetDisplayedSurveys,
  FilterToggleFilteringOpened,
  FilterSetPreventCuttingsDrawing,
  FilterSetPreventJobsiteExtentsDrawing,
  FilterSetRadiusSearch,
  FilterSetShowMapLabels,
} from './filter.actions'
import { DisplayedSurveys, RadiusSearch } from '../../shared/models'

export interface FilterStateModel {
  filteringOpened: boolean
  preventJobsiteExtentsDrawing: boolean
  preventCuttingsDrawing: boolean
  displayedSurveys: DisplayedSurveys
  showMapLabels: boolean
  radiusSearch: RadiusSearch
}

@State<FilterStateModel>({
  name: 'filter',
  defaults: {
    filteringOpened: false,
    preventJobsiteExtentsDrawing: false,
    preventCuttingsDrawing: false,
    displayedSurveys: {
      displaySurveys: true,
      filters: {},
    },
    showMapLabels: false,
    radiusSearch: { active: false, radius: '0.5' },
  },
})
@Injectable()
export class FilterState {
  @Action(FilterToggleFilteringOpened)
  public setFilteringOpened(ctx: StateContext<FilterStateModel>) {
    const filteringOpened = ctx.getState().filteringOpened
    ctx.setState(
      produce((draft) => {
        draft.filteringOpened = !filteringOpened
      }),
    )
  }
  @Action(FilterSetPreventJobsiteExtentsDrawing)
  public setPreventJobsiteExtentsDrawing(
    ctx: StateContext<FilterStateModel>,
    { preventJobsiteExtentsDrawing }: FilterSetPreventJobsiteExtentsDrawing,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.preventJobsiteExtentsDrawing = preventJobsiteExtentsDrawing
      }),
    )
  }

  @Action(FilterSetPreventCuttingsDrawing)
  public setPreventCuttingsDrawing(
    ctx: StateContext<FilterStateModel>,
    { preventCuttingsDrawing }: FilterSetPreventCuttingsDrawing,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.preventCuttingsDrawing = preventCuttingsDrawing
      }),
    )
  }

  @Action(FilterSetDisplayedSurveys)
  public setDisplayedSurveys(ctx: StateContext<FilterStateModel>, { displayedSurveys }: FilterSetDisplayedSurveys) {
    ctx.setState(
      produce((draft) => {
        draft.displayedSurveys = displayedSurveys
      }),
    )
  }

  @Action(FilterSetShowMapLabels)
  public setShowMapLabels(ctx: StateContext<FilterStateModel>, { showMapLabels }: FilterSetShowMapLabels) {
    ctx.setState(
      produce((draft) => {
        draft.showMapLabels = showMapLabels
      }),
    )
  }

  @Action(FilterSetRadiusSearch)
  public setRadiusSearch(ctx: StateContext<FilterStateModel>, { radiusSearch }: FilterSetRadiusSearch) {
    ctx.setState(
      produce((draft) => {
        draft.radiusSearch = radiusSearch
      }),
    )
  }
}
