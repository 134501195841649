import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { HotTableRegisterer } from '@handsontable/angular'
import Handsontable from 'handsontable'
import { combineLatest, EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs'
import Swal from 'sweetalert2'
import { getAutoFillZone, getSourceData, integerValidator, isColumnEdit, isNumber, isString } from '../../shared/utils'
import { MatDialog } from '@angular/material/dialog'
import { AddRowsDialog } from '../../shared/components/add-rows-dialog/add-rows-dialog.component'
import { DataTableService, TableAutoSaveService } from '../../shared/services'
import { MessagesService, SoilSurveyService, ZoneService } from '../../shared/remote-services'
import {
  SelectColumnsDialog,
  SelectColumnsDialogData,
} from '../../shared/components/select-columns-dialog/select-columns-dialog'
import { StatsTableDialog } from '../../shared/components/stats-table-dialog/stats-table-dialog'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { Zone, ZoneData } from '../../shared/models'
import { TranslateService } from '@ngx-translate/core'
import { FormControl, Validators } from '@angular/forms'
import {
  DataEntity,
  deSerializeColumnNames,
  getTechniqueAvailableParameters,
  getTechniqueColumnTitle,
  getSelectableColumns,
  getTechniqueDefaultColumns,
  SoilSurvey,
  SoilTypeUtilsService,
  StratigraphyDataModel,
  SurveyData,
} from '@sde-ild/ssd-soillib-lib'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { CellChange, CellValue, ChangeSource } from 'handsontable/common'
import { cloneDeep } from 'lodash'
import { Store } from '@ngxs/store'
import { AppFetchSoilSurveyAvailableParameters } from '../../store/app/app.actions'
import { colorizeCells, colorizeCellsByKey } from '../../shared/utils/handsontable.utils'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { environment } from '../../../environments/environment'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'
import { safeSetLSItem } from '../../shared/utils/local-storage.utils'

@Component({
  selector: 'soillib-soil-cuttings-data-table',
  templateUrl: './soil-cuttings-data-table.component.html',
  styleUrls: ['./soil-cuttings-data-table.component.scss'],
})
export class SoilCuttingsDataTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() jobsiteId: string | null | undefined
  @Input() selectedZone: Zone
  @Input() isSBT = false

  @Output() setTableChangeStatusEvent: EventEmitter<boolean> = new EventEmitter()

  private stepSubscription: Subscription

  table = 'hotInstance'
  tableRef: Handsontable
  tableSourceData: ZoneData[]
  retrievedData: ZoneData[] | null
  autoFillData: Record<string, unknown>[] | null
  tableColors: (string | null)[] | null = []
  stratigraphyData: StratigraphyDataModel

  surveysInside: SoilSurvey[] = []
  surveysInsideData: SurveyData[][] | null

  checked = false
  indeterminate = false
  isAutoFillData: boolean
  disabled = false

  stepFormControl: FormControl<number | null>
  autoFillStep = 1

  tableSettings: Handsontable.GridSettings
  selectedColumns: Handsontable.ColumnSettings[]
  isValidating = false

  private tableValid = true
  private observer: MutationObserver

  constructor(
    private cdRef: ChangeDetectorRef,
    private zoneService: ZoneService,
    private soilSurveyService: SoilSurveyService,
    private _hotRegisterer: HotTableRegisterer,
    private dataTableService: DataTableService,
    private messagesService: MessagesService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private tableAutoSaveService: TableAutoSaveService,
    private soilTypeUtilsService: SoilTypeUtilsService,
    private store: Store,
  ) {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event) {
    this.resizeTable()
  }

  private resizeTable() {
    this.tableRef = this._hotRegisterer.getInstance(this.table)
    if (this.tableRef) {
      this.tableRef.render()
    }
  }

  ngOnInit() {
    const targetNode = document.documentElement
    const config = { attributes: true }
    this.observer = new MutationObserver(() => {
      if (getComputedStyle(targetNode).getPropertyValue('--bottom-nav-height') !== '5px') {
        this.resizeTable()
      }
    })
    this.observer.observe(targetNode, config)

    this.stepFormControl = new FormControl(
      { value: this.autoFillStep, disabled: !this.store.selectSnapshot(UserConfigStateSelectors.canWrite) },
      { validators: [Validators.required, Validators.min(0.000001)], updateOn: 'blur' },
    )
    this.stepSubscription = this.stepFormControl.valueChanges.subscribe((value) => {
      if (this.stepFormControl.valid && value !== null) {
        this.autoFillStep = value
        this.getAutoFillData()
      }
    })
    this.store.dispatch(AppFetchSoilSurveyAvailableParameters)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedZone && this.selectedZone) {
      this.checked = false
      this.indeterminate = false
      this.autoFillData = null
      this.retrievedData = null
      this.surveysInsideData = null
      const { columns, settings } = this.getTableSettings('SOIL_CUTTING')
      this.tableSettings = settings
      this.selectedColumns = columns

      this.soilSurveyService.searchVisibleSoilSurveys(this.selectedZone.extent).subscribe((surveys) => {
        this.surveysInside = surveys
        this.disabled = !(surveys && surveys.length > 0)
      })
      this.getDataFromDB()
    }
  }

  getDataFromDB() {
    const selectedZoneId = this.selectedZone.id
    if (selectedZoneId) {
      combineLatest(
        this.zoneService.getZoneData(selectedZoneId),
        this.tableAutoSaveService.getAutoSaveTableData$<ZoneData[]>(selectedZoneId),
      )
        .pipe(
          map(([fromDB, fromLS]) => fromLS || fromDB),
          tap((data) => {
            this.tableRef = this._hotRegisterer.getInstance(this.table)
            this.retrievedData = this.parseData(data)
            this.showData(this.retrievedData.map((value) => ({ ...value })))
            this.tableRef.updateSettings({
              ...this.tableSettings,
              columns: this.selectedColumns,
              data: this.retrievedData,
            })
            this.tableColors = this.retrievedData?.map((row) => row.colors) || null
            if (this.tableColors) {
              colorizeCells(this.tableRef, this.dataTableService.deSerializeColors('cutting', this.tableColors))
            }
            this.tableRef.validateCells()
            this.setTableChangeStatusEvent.emit(true)
          }),
          catchError(() => {
            this.tableRef = this._hotRegisterer.getInstance(this.table)
            this.tableRef.updateSettings({ ...this.tableSettings, data: undefined })
            return EMPTY
          }),
        )
        .subscribe()
    }
  }

  private showData(tableData: Record<string, unknown>[]) {
    const data = [...tableData]
    this.tableColors = data.map((d) => ('colors' in d ? String(d.colors) : null)).filter((color) => !!color)
    colorizeCells(this.tableRef, this.dataTableService.deSerializeColors('cutting', this.tableColors))
    this.tableRef.updateSettings({
      ...this.tableSettings,
      columns: this.selectedColumns,
      data,
    })
    this.tableRef.loadData(data)
  }

  getAutoFillData() {
    if (this.surveysInside && this.surveysInside.length > 0) {
      const soilCuttingParams = getTechniqueAvailableParameters('SOIL_CUTTING').concat(['colors', 'geo_soil'])
      if (this.surveysInsideData) {
        this.autoFillData = this.parseData(
          getAutoFillZone(this.surveysInsideData, this.autoFillStep, soilCuttingParams),
        )
        this.showData(this.autoFillData)
      } else {
        const observableBatch = this.surveysInside.map(({ id }) =>
          id ? this.soilSurveyService.getSurveyData(id) : of([]),
        )
        forkJoin(observableBatch).subscribe((surveysData: SurveyData[][]) => {
          this.surveysInsideData = cloneDeep(surveysData)
          this.autoFillData = this.parseData(getAutoFillZone(surveysData, this.autoFillStep, soilCuttingParams))
          this.showData(this.autoFillData)
        })
      }
    }
  }

  onChange(e: MatCheckboxChange) {
    if (e.checked) {
      if (this.autoFillData) {
        this.showData(this.autoFillData)
      } else {
        this.getAutoFillData()
      }
      this.isAutoFillData = true
    } else {
      if (this.retrievedData) {
        this.showData(this.retrievedData.map((value) => ({ ...value })))
      } else {
        this.getDataFromDB()
      }
      this.isAutoFillData = false
    }
    this.indeterminate = false
  }

  onAfterValid = (invalid: boolean) => {
    this.tableValid = invalid
  }

  cuttingCellValidator(value: CellValue, callback: (valid: boolean) => void) {
    let previousValue: CellValue
    // @ts-ignore
    const { instance, row, col, prop } = this
    if (prop === 'base') {
      previousValue = instance.getDataAtCell(row, col - 1)
    } else if (prop === 'toit') {
      previousValue = instance.getDataAtCell(row - 1, col + 1)
    }
    if (value === '' || value === null) {
      const rowArr = instance.getDataAtRow(row).filter((item) => item !== '' && item !== null && item !== undefined)
      if (rowArr.length > 0) {
        callback(false)
      } else {
        callback(true)
      }
    } else if ((previousValue && Number(value) < previousValue) || Number(value) < 0 || isNaN(value)) {
      callback(false)
    } else {
      callback(true)
    }
  }

  handleColumns(column: Handsontable.ColumnSettings): Handsontable.ColumnSettings {
    if (column.data === 'calculated_soil' || column.data === 'calculated_compactness') {
      return {
        ...column,
        className: 'readOnlyColumn',
        readOnly: true,
      }
    } else if (column.data === 'geo_soil' || column.data === 'geotechnical_engineer_description') {
      return {
        ...column,
        type: 'text',
        allowInvalid: true,
        invalidCellClassName: 'highlight--error',
      }
    } else if (column.data === 'toit' || column.data === 'base') {
      return {
        ...column,
        validator: this.cuttingCellValidator,
        allowInvalid: true,
        invalidCellClassName: 'toit-base--error',
        type: 'numeric',
        numericFormat: {
          pattern: '0.000',
        },
      }
    } else if (column.data === 'n' || column.data === 'n_1_60') {
      return {
        ...column,
        type: 'numeric',
        validator: integerValidator,
        allowInvalid: true,
        invalidCellClassName: 'integer--error',
        numericFormat: {
          pattern: '0',
        },
      }
    } else {
      return {
        ...column,
        type: 'numeric',
        allowInvalid: true,
        invalidCellClassName: 'highlight--error',
        numericFormat: {
          pattern: '0.000',
        },
      }
    }
  }

  private getFormattedData(): ZoneData[] | null {
    if (!this.tableRef) {
      this.tableRef = this._hotRegisterer.getInstance(this.table)
    }
    const sourceData = getSourceData(this.tableRef)
    if (sourceData) {
      let formattedData = cloneDeep(sourceData) as ZoneData[]
      const zoneId = this.selectedZone.id
      formattedData = formattedData.filter((item) => item.toit != null && item.base != null)
      if (zoneId) {
        formattedData = formattedData.map((item) => ({ ...item, zone_id: zoneId }))
      }
      return formattedData
    } else {
      return null
    }
  }

  private checkTableValidation() {
    this.isValidating = true
    this.cdRef.detectChanges()
    setTimeout(() => {
      this.tableRef.validateCells((valid) => {
        this.tableValid = valid
        this.isValidating = false
        this.cdRef.detectChanges()
      })
    }, 600)
  }

  handleColumnMove = () => {}

  handleCellChanges = (changes: CellChange[] | null, source: ChangeSource) => {
    if (this._hotRegisterer.getInstance(this.table)) {
      if (!this.tableRef) {
        this.tableRef = this._hotRegisterer.getInstance(this.table)
      }
      this.tableSourceData = this.getFormattedData() || []
      if (this.isAutoFillData) {
        this.indeterminate = true
        this.isAutoFillData = false
      }
      if (source) {
        if (isColumnEdit(changes, 'geo_soil').changed) {
          const typeChangedInfo = isColumnEdit(changes, 'geo_soil').infos
          typeChangedInfo.forEach(({ newValue, rowNumber }) => {
            if (isNumber(rowNumber)) {
              this.tableRef.setDataAtCell(
                rowNumber,
                this.tableRef.propToCol('calculated_soil'),
                this.soilTypeUtilsService.parseSoilType(String(newValue), this.isSBT),
              )
            }
          })
        }
        if (isColumnEdit(changes, 'geotechnical_engineer_description').changed) {
          const typeChangedInfo = isColumnEdit(changes, 'geotechnical_engineer_description').infos
          typeChangedInfo.forEach(({ newValue, rowNumber }) => {
            if (isNumber(rowNumber)) {
              this.tableRef.setDataAtCell(
                rowNumber,
                this.tableRef.propToCol('calculated_compactness'),
                this.soilTypeUtilsService.parseSoilCompactness(String(newValue)),
              )
            }
          })
        }
      }

      if (
        source === 'loadData' ||
        isColumnEdit(changes, 'geo_soil').changed ||
        isColumnEdit(changes, 'toit').changed ||
        isColumnEdit(changes, 'base').changed
      ) {
        this.getStratigraphyData(this.tableSourceData)
      }
      if (source === 'loadData' && this.tableSourceData.length > 0) {
        this.checkTableValidation()
      }

      if (source !== 'loadData') {
        this.setTableChangeStatusEvent.emit(true)
        const selectedZoneId = this.selectedZone.id
        if (selectedZoneId) {
          this.tableAutoSaveService.autosaveTableData(selectedZoneId, this.tableSourceData)
        }
      }
    }
  }

  private getStratigraphyData(tableSourceData: ZoneData[]) {
    const soilTypeData = tableSourceData.map((item) => {
      const data: (string | number)[] = [item.toit, item.base]
      if (item.calculated_soil) {
        data.push(item.calculated_soil)
      }
      return data
    })
    this.stratigraphyData = {
      name: this.selectedZone.name,
      data: soilTypeData,
    }
    this.cdRef.detectChanges()
  }

  saveTableData() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    if (this.tableValid) {
      const formattedData: ZoneData[] | null = this.getFormattedData()
      const currentTableColors = this.dataTableService.getCurrentTableColors(this.tableRef, 'cutting')
      if (formattedData) {
        formattedData.forEach((row, i) => {
          formattedData[i].colors = currentTableColors.find(({ depth }) => row.toit === depth)?.color || ''
        })
      }
      const { name: selectedZoneName, id: selectedZoneId } = this.selectedZone
      if (selectedZoneId && selectedZoneName && formattedData) {
        this.zoneService
          .saveZoneData(formattedData, selectedZoneId)
          .pipe(
            switchMap(() =>
              this.messagesService.saveResourceAndJobsiteMessage$(
                this.jobsiteId,
                selectedZoneName,
                'section',
                'added data to the',
              ),
            ),
            tap(() => {
              this.retrievedData = null
              this.tableAutoSaveService.deleteAutosaveTableData(selectedZoneId)
              this.setTableChangeStatusEvent.emit(false)
              return Swal.fire({
                title: this.translateService.instant('ALERT.SAVED'),
                text: this.translateService.instant('ALERT.SAVE_SUCCESS'),
                icon: 'success',
                confirmButtonText: 'Ok',
              })
            }),
          )
          .subscribe()
      }
    } else {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.INVALID_MESS'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
  }

  parseData = <T extends DataEntity>(data: T[]): T[] => {
    let parsedData = this.soilTypeUtilsService.parseSoilTypes(data, this.isSBT)
    parsedData = this.soilTypeUtilsService.parseSoilCompactnesses(parsedData)
    return parsedData
  }

  addRow() {
    if (!this.tableSourceData) {
      this.tableSourceData = this.getFormattedData() || []
    }
    this.tableRef.alter('insert_row', this.tableSourceData.length, 1)
  }

  addRows() {
    const dialogRef = this.dialog.open(AddRowsDialog, {
      width: '500px',
    })
    dialogRef
      .afterClosed()
      .pipe(
        tap((data: { rows: number; step: number; start: number }) => {
          if (data) {
            const initialLength = this.tableSourceData?.length
            this.tableRef.alter('insert_row', initialLength, data.rows)
            const sourceData: ZoneData[] = []
            for (let i = 0; i < data.rows; i++) {
              const zoneId = this.selectedZone.id
              if (zoneId) {
                const toitValue = Number(data.start) + Number(i * data.step)
                const baseValue = Number(data.start) + Number((i + 1) * data.step)
                sourceData.push({
                  zone_id: zoneId,
                  toit: toitValue,
                  base: baseValue,
                  colors: '',
                })
              }
            }
            if (!this.tableSourceData) {
              this.tableSourceData = []
            }
            this.tableSourceData.push(...sourceData)
            this.tableRef.updateSettings({ data: this.tableSourceData })
            if (this.tableColors) {
              colorizeCells(this.tableRef, this.dataTableService.deSerializeColors('cutting', this.tableColors))
            }
            this.setTableChangeStatusEvent.emit(true)
          }
        }),
      )
      .subscribe()
  }

  showStats() {
    if (this.surveysInsideData) {
      this.showStatsDialog({ data: this.surveysInsideData, geo: this.surveysInside })
    } else if (this.surveysInside && this.surveysInside.length > 0) {
      const observableBatch: Observable<SurveyData[]>[] = this.surveysInside.map(({ id }) =>
        id ? this.soilSurveyService.getSurveyData(id) : of([]),
      )
      forkJoin(observableBatch).subscribe((surveysData: SurveyData[][]) => {
        this.surveysInsideData = surveysData
        this.showStatsDialog({ data: this.surveysInsideData, geo: this.surveysInside })
      })
    }
  }

  private showStatsDialog(stats: { data: SurveyData[][] | null; geo: SoilSurvey[] }) {
    return this.dialog.open(StatsTableDialog, {
      width: '70vw',
      data: stats,
    })
  }

  selectColumns() {
    const dialogRef = this.dialog.open<SelectColumnsDialog, SelectColumnsDialogData>(SelectColumnsDialog, {
      width: '500px',
      data: {
        selectedCols: this.selectedColumns,
        selectableCols: getSelectableColumns(
          'SOIL_CUTTING',
          this.store.selectSnapshot(AppStateSelectors.soilSurveyAvailableParameters),
        ),
      },
    })
    dialogRef
      .afterClosed()
      .pipe(
        tap((selectedColumns) => {
          if (selectedColumns) {
            selectedColumns = deSerializeColumnNames(Object.keys(selectedColumns).filter((key) => selectedColumns[key]))
            let columns = [
              { data: 'toit', title: getTechniqueColumnTitle('toit') },
              { data: 'base', title: getTechniqueColumnTitle('base') },
              { data: 'calculated_soil', title: getTechniqueColumnTitle('calculated_soil') },
              { data: 'geo_soil', title: getTechniqueColumnTitle('geo_soil') },
              ...selectedColumns,
            ]
            safeSetLSItem('SOIL_CUTTING-columns', JSON.stringify(columns.map((item) => item.data)))
            this.tableRef = this._hotRegisterer.getInstance(this.table)
            columns = columns.map((item) => this.handleColumns(item))
            this.tableRef.updateSettings({ columns })
            this.selectedColumns = columns
            if (this.tableColors) {
              colorizeCells(this.tableRef, this.dataTableService.deSerializeColors('cutting', this.tableColors))
            }
          }
        }),
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.stepSubscription.unsubscribe()
    this.observer.disconnect()
  }

  private getTableSettings(surveyType: string): {
    settings: Handsontable.GridSettings
    columns: Handsontable.ColumnSettings[]
  } {
    const columns = getTechniqueDefaultColumns(surveyType)
      .filter(
        (c) =>
          environment.features.soilCompactness ||
          !isString(c.data) ||
          !['geotechnical_engineer_description', 'calculated_compactness'].includes(c.data),
      )
      .map((e) => this.handleColumns(e))
    return {
      settings: {
        stretchH: 'all',
        className: 'htLeft smallFont',
        minRows: 10,
        rowHeaders: true,
        minCols: columns.length,
        columns,
        afterValidate: this.onAfterValid,
        columnSorting: false,
        contextMenu: {
          callback: (key) => {
            const table = this._hotRegisterer.getInstance(this.table)
            colorizeCellsByKey(table, key)
            this.setTableChangeStatusEvent.emit(true)
            table.render()
          },
          items: {
            red: { name: this.translateService.instant('SURVEY.SET_RED') },
            orange: { name: this.translateService.instant('SURVEY.SET_ORANGE') },
            yellow: { name: this.translateService.instant('SURVEY.SET_YELLOW') },
            none: { name: this.translateService.instant('SURVEY.REMOVE_COLOR') },
            '---------': {},
            row_above: { name: this.translateService.instant('SURVEY.INSERT_ROW_ABOVE') },
            row_below: { name: this.translateService.instant('SURVEY.INSERT_ROW_BELOW') },
            remove_row: { name: this.translateService.instant('SURVEY.REMOVE_ROW') },
            undo: { name: this.translateService.instant('SURVEY.UNDO') },
          },
        },
        manualRowMove: false,
        manualColumnMove: true,
        manualColumnResize: true,
        fillHandle: 'vertical',
        height: '100%',
      },
      columns,
    }
  }
}
