export class CrossSectionSetSearch {
  public static readonly type = '[CrossSection] SetSearch'

  constructor(readonly search: string) {}
}
export class CrossSectionFetchCrossSections {
  public static readonly type = '[CrossSection] FetchCrossSections'
}
export class CrossSectionFetchSelectedCrossSection {
  public static readonly type = '[CrossSection] FetchSelectedCrossSection'

  constructor(readonly crId: string) {}
}
export class CrossSectionClearSelectedCrossSection {
  public static readonly type = '[CrossSection] ClearSelectedCrossSection'
}
export class CrossSectionDeleteCrossSection {
  public static readonly type = '[CrossSection] DeleteCrossSection'

  constructor(readonly crId: string) {}
}
export class CrossSectionSetProjectedBhInfos {
  public static readonly type = '[CrossSection] SetProjectedBhInfos'

  constructor(readonly projectedBhInfos: Record<string, { projectedPointCoord: number[]; distance: number }>) {}
}
