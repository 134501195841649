<ng-container
  *ngIf="{
    selectedJobsite: selectedJobsite$ | async,
    selectedZone: selectedZone$ | async,
    soilCuttingEditionOpened: soilCuttingEditionOpened$ | async,
    isModifyingZone: isModifyingZone$ | async,
    validModifZone: validModifZone$ | async
  } as state"
>
  <div
    matTooltip="{{ 'GENERAL.POPOVER' | translate }}"
    matTooltipPosition="above"
    [matTooltipDisabled]="state.selectedJobsite !== null"
  >
    <div
      [@slideInOut]="state.soilCuttingEditionOpened"
      [ngClass]="{
        'edit-button-state0': !state.selectedJobsite?.extent,
        'edit-button-state1': !!state.selectedJobsite?.extent && !state.soilCuttingEditionOpened,
        'edit-button-state2': !!state.selectedJobsite?.extent && state.soilCuttingEditionOpened
      }"
      class="container"
      fxLayout="column"
      fxLayoutAlign="center center"
      (click)="
        handleSoilCuttingsEditionToggle(!state.soilCuttingEditionOpened, state.selectedJobsite, state.selectedZone)
      "
    >
      <img *ngIf="!state.soilCuttingEditionOpened" id="img-up-arrow" src="assets/ico-arrow-up.png" />
      <div fxLayout="row">
        <img id="img-soil-cuttings-edition" src="assets/ico-coupesedit.png" />
        <div fxLayout="column">
          <span id="text-edition">{{ 'GENERAL.EDIT' | translate }}</span>
          <span id="text-soil-cuttings">{{ 'CUTTING.CUTTING' | translate }}</span>
        </div>
        <mat-icon
          fxFlex="20"
          *ngIf="tableDataChanged && state.selectedZone && state.soilCuttingEditionOpened"
          style="color: red; font-size: 15px"
          >fiber_manual_record
        </mat-icon>
      </div>
    </div>
  </div>

  <div
    [@slideInOutFromLeft]="state.soilCuttingEditionOpened && state.selectedZone && state.selectedZone.id"
    id="info-box"
    *ngIf="state.soilCuttingEditionOpened && state.selectedZone && state.selectedZone.id"
  >
    <div soillibDraggable id="draggable-div" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <span>
        {{ state.selectedZone.name }}
      </span>
      <div class="zoneButtons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <button
          mat-raised-button
          color="warn"
          (click)="deleteZone(state.selectedZone.id, state.selectedZone.name, state.selectedJobsite)"
          *ngIf="!state.isModifyingZone"
        >
          {{ 'CUTTING.DELETE' | translate }}
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="modifyZone(state.selectedZone)"
          *ngIf="!state.isModifyingZone"
        >
          {{ 'GENERAL.EDIT' | translate }}
        </button>
        <button
          mat-raised-button
          color="warn"
          (click)="saveModifiedZone(state.selectedZone)"
          *ngIf="state.isModifyingZone"
          [ngClass]="{ disabled: !state.validModifZone }"
          [disabled]="!state.validModifZone"
        >
          {{ 'ALERT.SAVE' | translate }}
        </button>
        <button mat-raised-button (click)="cancelModifiedZone(state.selectedZone)" *ngIf="state.isModifyingZone">
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    [@slideInOut]="state.soilCuttingEditionOpened"
    id="soil-cuttings-edition-bottomNav"
    class="bottomNav"
    *ngIf="state.soilCuttingEditionOpened"
    fxLayout="column"
  >
    <soillib-map-table-resize-button class="resize-button"></soillib-map-table-resize-button>

    <div *ngIf="!state.selectedZone?.id" class="bottom-container">
      <mat-tab-group (selectedIndexChange)="tabChanged($event)">
        <mat-tab label="{{ 'CUTTING.ADD_CUTTING' | translate }}">
          <div fxLayout="column" fxLayoutAlign="top center">
            <h3>{{ 'CUTTING.ADD_CUTTING' | translate }}</h3>
            <form [formGroup]="zoneForm">
              <mat-form-field>
                <input matInput formControlName="name" type="text" />
              </mat-form-field>
            </form>
            <button
              mat-raised-button
              color="primary"
              [ngClass]="{ disabled: zoneForm.invalid || !state.selectedZone }"
              [disabled]="zoneForm.invalid || !state.selectedZone"
              (click)="state.selectedZone && zoneForm.valid && saveZone(state.selectedZone, state.selectedJobsite)"
            >
              {{ 'CUTTING.SAVE_CUTTING' | translate }}
            </button>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'CROSS_SECTION.TITLE' | translate }}">
          <ng-container
            *ngIf="
              state.selectedJobsite?.id && {
                selectedCrossSection: selectedCrossSection$ | async
              } as selectedCrossSectionWrapper
            "
          >
            <soillib-cross-section-creation
              [jobsiteId]="state.selectedJobsite.id"
              [originalBoreholes]="originalBoreholes"
              [projectedBoreholes]="projectedBoreholes"
              [selectedCrossSectionInfos]="selectedCrossSectionWrapper.selectedCrossSection?.infos"
              [selectedCrossSection]="selectedCrossSectionWrapper.selectedCrossSection?.dto"
              (resizeSelectBoxEvent)="resizeSelectBox($event)"
              (setBoreholeHoverStyleEvent)="setBoreholeHoverStyle($event)"
              (cancelSelectionEvent)="cancelSelection()"
            ></soillib-cross-section-creation>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>

    <ng-container *ngIf="state.selectedJobsite?.id">
      <soillib-cross-section-list id="cross-section-list" *ngIf="crossSectionMode"></soillib-cross-section-list>
    </ng-container>

    <div *ngIf="state.selectedZone && state.selectedZone.id" class="bottom-container">
      <soillib-soil-cuttings-data-table
        [jobsiteId]="state.selectedJobsite?.id"
        [selectedZone]="state.selectedZone"
        [isSBT]="isSBT()"
        (setTableChangeStatusEvent)="setTableChangeStatus($event)"
      ></soillib-soil-cuttings-data-table>
    </div>
  </div>
</ng-container>
