<ng-container
  *ngIf="{
    surveysComparisonOpened: surveysComparisonOpened$ | async
  } as panelStateWrapper"
>
  <div
    matTooltip="{{ 'GENERAL.POPOVER' | translate }}"
    matTooltipPosition="above"
    [matTooltipDisabled]="jobsite !== null"
  >
    <div
      [@slideInOut]="panelStateWrapper.surveysComparisonOpened"
      [ngClass]="{
        'edit-button-state0': !jobsite?.extent,
        'edit-button-state1': !!jobsite?.extent && !panelStateWrapper.surveysComparisonOpened,
        'edit-button-state2': !!jobsite?.extent && panelStateWrapper.surveysComparisonOpened
      }"
      class="container"
      fxLayout="column"
      fxLayoutAlign="center center"
      (click)="handleSurveyComparisonToggle(!panelStateWrapper.surveysComparisonOpened)"
    >
      <img *ngIf="!panelStateWrapper.surveysComparisonOpened" id="img-up-arrow" src="assets/ico-arrow-up.png" />
      <div fxLayout="row">
        <img class="img-survey-comparison" src="assets/ico-sondageedit.png" />
        <img class="img-survey-comparison" src="assets/ico-sondageedit.png" />
        <div fxLayout="column">
          <span id="text-edition">{{ 'COMPARISON.COMPARISON' | translate }}</span>
          <span id="text-surveys">{{ 'COMPARISON.SURVEYS' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div id="complex-charts" *ngIf="panelStateWrapper.surveysComparisonOpened">
    <soillib-surveys-comparison-complex-charts [jobsiteId]="jobsite?.id"></soillib-surveys-comparison-complex-charts>
  </div>

  <div
    [@slideInOut]="panelStateWrapper.surveysComparisonOpened"
    id="surveys-comparison-bottomNav"
    class="bottomNav"
    *ngIf="panelStateWrapper.surveysComparisonOpened"
    fxLayout="column"
  >
    <soillib-map-table-resize-button
      *ngIf="panelStateWrapper.surveysComparisonOpened"
      class="resize-button"
    ></soillib-map-table-resize-button>
    <div fxLayout="column" fxLayoutAlign="top" class="bottom-container">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="options-container">
        <mat-slide-toggle fxFlex="50" color="warn" [(ngModel)]="checked">{{
          'SURVEY.ELEVATION' | translate : { name: datumDisplayName$ | async }
        }}</mat-slide-toggle>
        <div fxLayout="row" fxFlex="50">
          <button
            matTooltip="{{ 'COMPARISON.DRAG_DROP_MODE' | translate }}"
            mat-raised-button
            [color]="dragDrop ? 'warn' : ''"
            (click)="toggleDragDrop()"
          >
            {{ 'COMPARISON.DRAG_DROP' | translate }}
          </button>
          <div *ngIf="dragDrop" style="font-size: 15px" matTooltip="{{ 'COMPARISON.DRAG_DROP_ZOOM' | translate }}">
            <mat-icon>info</mat-icon>
          </div>
        </div>
      </div>
      <div class="charts-container">
        <span *ngIf="!(complexCharts$ | async)?.length">
          <p><mat-icon>info</mat-icon> {{ 'COMPARISON.INFO.OPEN_SIDEMENU' | translate }}</p>
        </span>
        <div
          fxLayout="row wrap"
          fxLayoutAlign="space-around"
          fxLayoutGap="10px"
          [dragula]="simpleChartDragDrop"
          *ngIf="selectedSurveys$ | async as selectedSurveys"
        >
          <soillib-surveys-comparison-chart
            class="chart"
            *ngFor="let survey of selectedSurveys; let index = index"
            [dragDrop]="simpleChartDragDrop"
            [elevationMode]="checked"
            [chartIndex]="index"
            [survey]="survey"
          >
          </soillib-surveys-comparison-chart>
        </div>
        <div
          *ngIf="complexCharts$ | async as complexCharts"
          fxLayout="row wrap"
          fxLayoutAlign="space-around"
          fxLayoutGap="10px"
          [dragula]="dragDrop"
          [dragulaModel]="complexCharts || undefined"
          (dragulaModelChange)="onComplexChartDragged($event)"
        >
          <soillib-surveys-comparison-complex-chart
            class="chart"
            *ngFor="let complexChart of complexCharts; trackBy: trackByChart; let index = index"
            [dragDrop]="dragDrop"
            [chartContainerId]="'complex-' + (complexChart.chartId || index)"
            [elevationMode]="checked"
            [complexChart]="complexChart"
          >
          </soillib-surveys-comparison-complex-chart>
        </div>
      </div>
    </div>
  </div>
</ng-container>
