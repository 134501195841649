import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { OperatorType, SBTnParametersModel } from '../../models/SurveySBTnResults.model'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { SbtService } from '../../services'

@Component({
  selector: 'soillib-sbtn-parameters-dialog',
  templateUrl: './sbtn-parameters-dialog.component.html',
  styles: [],
})
export class SbtnParametersDialog implements OnInit {
  sbtnParamsFormGroup: FormGroup<{
    a: FormControl<number | null>
    dic: FormControl<number | null>
    calculationTimes: FormControl<number | null>
    waterLevel: FormControl<number | null>
    gamma: FormControl<number | null>
    smoothingInterval: FormControl<number | null>
    smoothingOperator: FormControl<OperatorType | null>
  }>

  operatorList = Object.values(OperatorType)

  constructor(
    public dialogRef: MatDialogRef<SbtnParametersDialog>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public survey: SoilSurvey,
    private sbtService: SbtService,
  ) {
    const { water_level, elevation, id } = survey
    const defaultSbtParameters = this.sbtService.getDefaultSbtParameters(elevation, water_level)
    this.sbtnParamsFormGroup = this.fb.group({
      a: this.fb.control(defaultSbtParameters.a, Validators.required),
      dic: this.fb.control(defaultSbtParameters.dic, Validators.required),
      calculationTimes: this.fb.control(defaultSbtParameters.calculationTimes, Validators.required),
      waterLevel: this.fb.control<number | null>(defaultSbtParameters.waterLevel),
      gamma: this.fb.control(defaultSbtParameters.gamma),
      smoothingInterval: this.fb.control<number | null>(defaultSbtParameters.smoothingInterval),
      smoothingOperator: this.fb.control(defaultSbtParameters.smoothingOperator),
    })
    const waterLevelInput = this.sbtnParamsFormGroup.get('waterLevel')
    if (waterLevelInput) {
      if (elevation != null && water_level != null) {
        waterLevelInput.setValue(elevation - water_level)
        waterLevelInput.clearValidators()
      } else {
        waterLevelInput.addValidators(Validators.required)
      }
    }
    if (id) {
      const item = this.sbtService.getLocalSbtItem(id)
      if (item) {
        this.sbtnParamsFormGroup.setValue({
          ...this.sbtnParamsFormGroup.value,
          ...item,
        })
      }
      this.sbtnParamsFormGroup.valueChanges.subscribe((value: SBTnParametersModel) => {
        this.sbtService.setLocalSbtItem(id, value)
      })
    }
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close()
  }
}
