import { Feature } from 'ol'
import Circle from 'ol/geom/Circle'
import VectorLayer from 'ol/layer/Vector'
import olMap from 'ol/Map'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import { LatLonLocation } from '../../shared/models'

export class CircleDrawingLayer {
  circleDrawingSource: VectorSource
  circleDrawingLayer: VectorLayer

  private circleStyle: Style

  constructor(private map: olMap) {
    this.circleDrawingSource = new VectorSource({})
    this.circleDrawingLayer = new VectorLayer({ source: this.circleDrawingSource, visible: false })
    this.circleStyle = new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 2,
      }),
      fill: new Fill({
        color: [0, 0, 255, 0.05],
      }),
    })
    this.map.addLayer(this.circleDrawingLayer)
  }

  setVisible(visible: boolean) {
    this.circleDrawingLayer.setVisible(visible)
  }

  drawCircle(radius: number, selectedLocation: LatLonLocation) {
    this.circleDrawingSource.clear()
    const circle = new Circle(fromLonLat([selectedLocation.longitude, selectedLocation.latitude]), radius + 50)
    const CircleFeature = new Feature(circle)
    CircleFeature.setStyle(this.circleStyle)
    this.circleDrawingSource.addFeatures([CircleFeature])
  }
}
