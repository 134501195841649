import { Axis, AxisSetExtremesEventObject } from 'highcharts'

export const dynamicTickIntervalsWhenZooming = (baseTickInterval: number) => {
  return function (this: Axis, evt: AxisSetExtremesEventObject) {
    let axisOptions: { tickInterval: number }
    if (evt.trigger !== 'zoom' || evt.min === undefined || evt.max === undefined) {
      axisOptions = {
        tickInterval: baseTickInterval,
      }
    } else {
      const interval = Math.round(((evt.max - evt.min) / 4) * 100) / 100
      axisOptions = {
        tickInterval: interval,
      }
    }
    this.update(axisOptions, false)
  }
}
