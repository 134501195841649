import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

declare let gtag: any

@Injectable()
export class GoogleAnalyticsService {
  event(eventName: string, params: Record<string, string | null | undefined>) {
    if (environment.gaEnabled) {
      gtag('event', eventName, params)
    } else {
      // eslint-disable-next-line no-console
      console.log(`[Google Analytics] Send ${eventName} with params`, params)
    }
  }

  setUserId(id: string) {
    if (environment.gaEnabled) {
      gtag('config', environment.gaIdMesure, {
        user_id: id,
      })
      gtag('config', environment.uaId, {
        user_id: id,
      })
    } else {
      // eslint-disable-next-line no-console
      console.log(`[Google Analytics] Send user_id with params ${id}`)
    }
  }

  init() {
    if (environment.gaEnabled) {
      try {
        const script1 = document.createElement('script')
        script1.async = true
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.uaId
        document.head.appendChild(script1)

        const script2 = document.createElement('script')
        script2.innerHTML =
          `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '` +
          environment.uaId +
          `', {'send_page_view': false});
            gtag('config', '` +
          environment.gaIdMesure +
          `', {'send_page_view': false});
          `
        document.head.appendChild(script2)
      } catch (ex) {
        console.error('Error appending google analytics')
        console.error(ex as Error)
      }
    }
  }
}
