import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { getAverage } from '../../utils'
import {
  getTechniqueColumnTitle,
  getTechniqueAvailableParameters,
  SurveyData,
  SoilSurvey,
} from '@sde-ild/ssd-soillib-lib'

@Component({
  selector: 'soillib-stats-table-dialog',
  templateUrl: 'stats-table-dialog.html',
})
export class StatsTableDialog implements AfterViewInit {
  constructor(
    private dialogRef: MatDialogRef<StatsTableDialog>,
    @Inject(MAT_DIALOG_DATA) public sourceData: { data: SurveyData[][] | null; geo: SoilSurvey[] },
  ) {
    this.initData()
    const averageSurveys = {}
    sourceData.data?.forEach((element) => {
      if (element && element[0] && element[0].soilsurvey_id) {
        const objectData = {}
        this.availableZoneParas.forEach((para) => {
          objectData[para] = StatsTableDialog.getDecimal(getAverage(para, element))
        })
        averageSurveys[element[0].soilsurvey_id] = objectData
      }
    })
    sourceData.geo.forEach((element) => {
      const surveyId = element.id
      if (surveyId != null) {
        if (averageSurveys[surveyId] === undefined) {
          averageSurveys[surveyId] = {}
        }
        averageSurveys[surveyId].name = element.name
        averageSurveys[surveyId].type = element.type
        const availableParas = element.type ? getTechniqueAvailableParameters(element.type) : []
        Object.keys(averageSurveys[surveyId]).forEach((key) => {
          if (key !== 'name' && key !== 'type' && !availableParas.includes(key)) {
            averageSurveys[surveyId][key] = null
          }
        })
      }
    })
    Object.keys(averageSurveys).forEach((key) => {
      this.statsTableData.push(averageSurveys[key])
    })
    this.dataSource = new MatTableDataSource(this.statsTableData)
  }
  statsTableData: any = []
  displayedColumns: string[]
  dataSource: MatTableDataSource<any>
  availableZoneParas: string[]

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  private static getDecimal(val) {
    if (val !== null) {
      if (isNaN(Number(val))) {
        return val
      } else {
        return parseFloat(val).toFixed(3)
      }
    } else {
      return null
    }
  }

  private initData() {
    this.availableZoneParas = getTechniqueAvailableParameters('SOIL_CUTTING').filter(
      (para) => para !== 'toit' && para !== 'base',
    )
    this.displayedColumns = ['name', 'type'].concat(this.availableZoneParas)
  }

  getTechniqueColumnTitle(name) {
    return getTechniqueColumnTitle(name)
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator
  }

  close(): void {
    this.dialogRef.close()
  }
}
