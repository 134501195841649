import { RouterModule, Routes } from '@angular/router'
import { MainMapComponent } from './main-map/main-map.component'

const appRoutes: Routes = [
  {
    path: '',
    component: MainMapComponent,
  },
  {
    path: ':jobsiteid',
    component: MainMapComponent,
  },
]

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
