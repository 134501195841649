import { NgModule } from '@angular/core'
import * as Highcharts from 'highcharts'
import HighchartsBoost from 'highcharts/modules/boost'

@NgModule({})
export class HighchartsModule {
  constructor() {
    HighchartsBoost(Highcharts)
  }
}
