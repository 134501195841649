import { SurveyData } from '@sde-ild/ssd-soillib-lib'
import * as TypeGuards from './SurveySBTnResults.model.type.guard'
/** @see {isSurveySBTnResultsModel} ts-auto-guard:type-guard */
export interface SurveySBTnResultsModel {
  survey_id: string
  depth: number
  a: number
  dic: number
  ic: number
  icRolling: number
  n: number
  qt: number
  rf: number
  u: number
  u2: number
}

export enum OperatorType {
  AVG = 'AVG',
  MAX = 'MAX',
  MIN = 'MIN',
}

/** @see {isSBTnParametersModel} ts-auto-guard:type-guard */
export interface SBTnParametersModel {
  a: number
  dic: number
  calculationTimes: number
  waterLevel: number
  gamma: number
  smoothingInterval: number
  smoothingOperator: OperatorType
}

export type TNullablePartial<T> = { [P in keyof T]?: T[P] | null }
export type SurveysTableDataType = TNullablePartial<SurveyData> & TNullablePartial<SurveySBTnResultsModel>

export { TypeGuards }
