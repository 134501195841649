import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { SeriesOptionsType } from 'highcharts'
import { CorrelationDataModel } from '../shared/models'
import { Store } from '@ngxs/store'
import { map, switchMap } from 'rxjs/operators'
import { CorrelationDataStateSelectors } from './store/correlation-data.selectors'
import { MatDialog } from '@angular/material/dialog'
import { CorrelationParametersDialog } from './correlation-parameters-dialog/correlation-parameters-dialog.component'
import {
  CorrelationDataDownloadExportFile,
  CorrelationDataFetchCorrelationParameters,
  CorrelationDataSaveCorrelationParameters,
  CorrelationDataUpdateCorrelationData,
} from './store/correlation-data.actions'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { EMPTY, iif, of } from 'rxjs'
import { SbtService } from '../shared/services'
import { nonNullable, scale } from '../shared/utils'

@Component({
  selector: 'soillib-surveys-correlation-data',
  templateUrl: './surveys-correlation-data.component.html',
  styleUrls: ['./surveys-correlation-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveysCorrelationDataComponent {
  @Input()
  elevationParameters: {
    activeElevation: boolean
    label: string
    depthFormatter: (depth: number) => number
  }
  @Input()
  selectedSurvey: SoilSurvey
  @Input()
  correlationData: CorrelationDataModel[]
  @Input()
  insituData: Record<string, [number, number][]> | null

  cptGammaReference =
    'Robertson (2015) (P.K. Robertson and K.L. Cabal: Guide to Cone Penetration Testing for Geotechnical Engineering - Gregg Drilling & Testing, Inc., 6th Edition, 2015)'
  cptSuReference =
    'Robertson (2010) (P.K. Robertson : Estimating in-situ state parameter and friction angle in sandy soils from CPT - Gregg Drilling & Testing, Inc., Mai 2010)'
  cptOcrReference =
    'Robertson (2010) (P.K. Robertson : Estimating in-situ state parameter and friction angle in sandy soils from CPT - Gregg Drilling & Testing, Inc., Mai 2010)'
  cptDrReference = 'Jamiolkowski et al. (2001) (Rocscience Inc. : CPT Data Interpretation Theory Manual – 2021)'
  cptPhiPrimeReference =
    'Kulhawy and Mayne (1990) (P.K. Robertson and K.L. Cabal: Guide to Cone Penetration Testing for Geotechnical Engineering - Gregg Drilling & Testing, Inc., 6th Edition, 2015)'
  cptKReference =
    'Robertson (2010) (P.K. Robertson : Estimating in-situ state parameter and friction angle in sandy soils from CPT - Gregg Drilling & Testing, Inc., Mai 2010)'
  sptSuReference =
    'Stroud Proc ESPT 1975 ; White (2019) (F.White : An update of the SPT-Cu relationship proposed by M.Stroud in 1974 – ECSMGE, 2019)'
  sptPhiPrimeReference = 'Chen (1984)'
  pressiomPhiPrimeReference =
    'Ménard (M.CASSAN : Les essais in situ en mécanique des sols, 1. Réalisation et Interprétation - Deuxième Edition, 1988 - Chapitre V.4.3 page 241)'
  pressiomSuReference =
    'Cassan. (2005) (M.CASSAN : Les essais in situ en mécanique des sols, 1. Réalisation et Interprétation - Deuxième Edition, 1988 - Chapitre XII.2 p. 520)'
  cptSptN60Reference =
    'Robertson (2012) (P.K. Robertson and K.L. Cabal: Guide to Cone Penetration Testing for Geotechnical Engineering - Gregg Drilling & Testing, Inc., 6th Edition, 2015)'
  cptPressiomQcReference = 'Corrélations SBI : Corrélations entre les divers essais in situ'

  get chartElevationParameters(): {
    activeElevation: boolean
    label: string
  } {
    return {
      activeElevation: this.elevationParameters.activeElevation,
      label: this.elevationParameters.label,
    }
  }

  get hasCPT(): boolean {
    return this.correlationData.some(
      (data) =>
        data.cptGamma != null ||
        data.cptSu != null ||
        data.cptOcr != null ||
        data.cptDr != null ||
        data.cptPhiPrime != null ||
        data.cptK != null,
    )
  }

  get hasSPT(): boolean {
    return this.correlationData.some(
      (data) =>
        data.sptSu != null ||
        data.sptPhiPrimeGravel != null ||
        data.sptPhiPrimeCoarseSand != null ||
        data.sptPhiPrimeMediumSand != null ||
        data.sptPhiPrimeFineSand != null ||
        data.sptPhiPrimeSiltySand != null,
    )
  }

  get hasPressiom(): boolean {
    return this.correlationData.some((data) => data.pressiomPhiPrime != null || data.pressiomSu != null)
  }

  get hasTestCorrelation(): boolean {
    return this.correlationData.some(
      (data) =>
        data.cptSptN60 != null ||
        data.cptPressiomQcClays != null ||
        data.cptPressiomQcSilts != null ||
        data.cptPressiomQcSands != null,
    )
  }

  get cptQtData(): SeriesOptionsType[] | null {
    if (this.insituData && 'qt' in this.insituData) {
      return this.toChartSeries(
        'cpt_qt',
        'qt',
        this.insituData.qt,
        ([depth]) => depth,
        ([, value]) => scale(value),
        'line',
      )
    } else {
      return null
    }
  }

  get cptGammaData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_gamma', 'ϒ', (data) => data.cptGamma)
  }

  get cptSuData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_su', 'Su', (data) => data.cptSu)
  }

  get cptOcrData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_ocr', 'OCR', (data) => data.cptOcr)
  }

  get cptDrData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_dr', 'Dr', (data) => data.cptDr)
  }

  get cptPhiPrimeData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_phi_prime', 'φ’', (data) => data.cptPhiPrime)
  }

  get cptKData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_k', 'k', (data) => data.cptK)
  }

  get sptNData(): SeriesOptionsType[] | null {
    if (this.insituData && 'n' in this.insituData) {
      return this.toChartSeries(
        'spt_n',
        'N',
        this.insituData.n,
        ([depth]) => depth,
        ([, value]) => scale(value),
        'scatter',
      )
    } else {
      return null
    }
  }

  get sptSuData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('spt_su', 'Su', (data) => data.sptSu, 'scatter')
  }

  get sptPhiPrimeData(): SeriesOptionsType[] | null {
    const series: SeriesOptionsType[] = [
      this.getSeriesOptionsTypeFromProperty((model) => model.sptPhiPrimeGravel, 'spt_phi_prime_gravel', 'φ’ (gravel)'),
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.sptPhiPrimeCoarseSand,
        'spt_phi_prime_coarse_sand',
        'φ’ (coarse sand)',
      ),
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.sptPhiPrimeMediumSand,
        'spt_phi_prime_medium_sand',
        'φ’ (medium sand)',
      ),
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.sptPhiPrimeFineSand,
        'spt_phi_prime_fine_sand',
        'φ’ (fine sand)',
      ),
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.sptPhiPrimeSiltySand,
        'spt_phi_prime_silty_sand',
        'φ’ (silty sand)',
      ),
    ].filter(nonNullable)

    return series.length ? series : null
  }

  get pressiomPlStarData(): SeriesOptionsType[] | null {
    if (this.insituData && 'pl_star' in this.insituData) {
      return this.toChartSeries(
        'pressiom_pl_star',
        'pl*',
        this.insituData.pl_star,
        ([depth]) => depth,
        ([, value]) => scale(value),
        'scatter',
      )
    } else {
      return null
    }
  }

  get pressiomPhiPrimeData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('pressiom_phi_prime', 'φ’', (data) => data.pressiomPhiPrime, 'scatter')
  }

  get pressiomSuData(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('pressiom_su', 'Su', (data) => data.pressiomSu, 'scatter')
  }

  get cptSptN60Data(): SeriesOptionsType[] | null {
    return this.correlationDataToChartSeries('cpt_spt_n60', 'N60', (data) => data.cptSptN60)
  }

  get cptPressiomQcData(): SeriesOptionsType[] | null {
    const series: SeriesOptionsType[] = [
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.cptPressiomQcClays,
        'cpt_pressiom_qc_clays',
        'qc (Argiles)',
      ),
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.cptPressiomQcSilts,
        'cpt_pressiom_qc_silts',
        'qc (Limons)',
      ),
      this.getSeriesOptionsTypeFromProperty(
        (model) => model.cptPressiomQcSands,
        'cpt_pressiom_qc_sands',
        'qc (Sables)',
      ),
    ].filter(nonNullable)

    return series.length ? series : null
  }

  constructor(private store: Store, private dialog: MatDialog, private sbtService: SbtService) {}

  openParametersDialog() {
    const soilSurveyId = this.selectedSurvey.id
    if (soilSurveyId) {
      this.store
        .dispatch(new CorrelationDataFetchCorrelationParameters(soilSurveyId))
        .pipe(
          map(() => this.store.selectSnapshot(CorrelationDataStateSelectors.slices.correlationParameters)),
          switchMap((parameters) =>
            this.dialog
              .open(CorrelationParametersDialog, {
                width: '500px',
                data: {
                  correlationParameter: parameters,
                  hasCPT: this.hasCPT,
                  hasPressiom: this.hasPressiom,
                },
              })
              .afterClosed(),
          ),
          switchMap((parameters) =>
            iif(
              () => parameters != null,
              of([
                new CorrelationDataSaveCorrelationParameters(soilSurveyId, parameters),
                new CorrelationDataUpdateCorrelationData(
                  soilSurveyId,
                  this.sbtService.getLocalSurveySbtParameters(this.selectedSurvey),
                ),
              ]).pipe(switchMap((actions) => this.store.dispatch(actions))),
              EMPTY,
            ),
          ),
        )
        .subscribe()
    }
  }

  refreshCorrelations() {
    const soilSurveyId = this.selectedSurvey.id
    if (soilSurveyId) {
      this.store.dispatch(
        new CorrelationDataUpdateCorrelationData(
          soilSurveyId,
          this.sbtService.getLocalSurveySbtParameters(this.selectedSurvey),
        ),
      )
    }
  }

  download() {
    const soilSurveyId = this.selectedSurvey.id
    if (soilSurveyId) {
      this.store
        .dispatch(
          new CorrelationDataDownloadExportFile(
            soilSurveyId,
            this.sbtService.getLocalSurveySbtParameters(this.selectedSurvey),
          ),
        )
        .subscribe()
    }
  }

  private correlationDataToChartSeries = (
    id: string,
    name: string,
    dataExtractor: (data: CorrelationDataModel) => number | null | undefined,
    type: 'line' | 'scatter' = 'line',
  ): SeriesOptionsType[] | null =>
    this.toChartSeries(id, name, this.correlationData, (data) => data.depth, dataExtractor, type)

  private toChartSeries<T>(
    id: string,
    name: string,
    models: T[],
    depthExtractor: (data: T) => number,
    dataExtractor: (data: T) => number | null | undefined,
    type: 'line' | 'scatter',
  ): SeriesOptionsType[] | null {
    const data = models.map(
      (res) =>
        [this.elevationParameters.depthFormatter(depthExtractor(res)), dataExtractor(res)] as [
          number,
          number | null | undefined,
        ],
    )
    if (!data.every(([_, d]) => d == null)) {
      return [
        {
          id,
          type,
          name,
          data: data.sort(([aDepth], [bDepth]) => aDepth - bDepth),
        } as SeriesOptionsType,
      ]
    } else {
      return null
    }
  }

  private getSeriesOptionsTypeFromProperty(
    accessor: (CorrelationDataModel) => number | null | undefined,
    serieId: string,
    serieName: string,
    type: string = 'scatter',
  ): SeriesOptionsType | undefined {
    let serie: SeriesOptionsType | undefined
    const data = this.correlationData
      ?.map(
        (model) =>
          [this.elevationParameters.depthFormatter(model.depth), accessor(model)] as [
            number,
            number | null | undefined,
          ],
      )
      ?.sort(([aDepth], [bDepth]) => aDepth - bDepth)
    if (!data?.every(([_, d]) => d == null)) {
      serie = {
        id: serieId,
        type,
        name: serieName,
        data,
      } as SeriesOptionsType
    }
    return serie
  }
}
