import { Injectable } from '@angular/core'
import { Coordinate } from 'ol/coordinate'
import { applyTransform as APPLYTRANSFORM, containsCoordinate as CONTAINSCOORDINATE, Extent } from 'ol/extent'
import { getTransform as GETTRANSFORM, get as GETPROJ, toLonLat as TOLONLAT } from 'ol/proj'
import { register as REGISTER } from 'ol/proj/proj4'
import * as proj4x from 'proj4'
import { from, Observable } from 'rxjs'
import { EpsgProjModel, KnowProjModel } from '../models'
import Projection from 'ol/proj/Projection'

const proj4 = (proj4x as any).default

@Injectable()
export class EpsgProjectionService {
  Known_Projs: KnowProjModel = {
    'RGF93 / CC49': {
      code: '3949',
      name: 'RGF93 / CC49',
      proj4def:
        '+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      bbox: [50.0, -4.87, 48.0, 8.23],
    },
    OSGB: {
      code: '7405',
      name: 'OSGB 1936 / British National Grid + ODN height',
      proj4def:
        '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +vunits=m +no_defs',
      bbox: [58.71, -7.06, 49.93, 1.8],
    },
    SVY21: {
      code: '3414',
      name: 'SVY21 / Singapore TM',
      proj4def:
        '+proj=tmerc +lat_0=1.366666666666667 +lon_0=103.8333333333333 +k=1 +x_0=28001.642 +y_0=38744.572 +ellps=WGS84 +units=m +no_defs',
      bbox: [1.47, 103.59, 1.13, 104.07],
    },
    HongKong: {
      code: '2326',
      name: 'Hong Kong 1980 Grid System',
      proj4def:
        '+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs',
      bbox: [22.58, 113.76, 22.13, 114.51],
    },
    NZTM2000: {
      code: '2193',
      name: 'NZGD2000 / New Zealand Transverse Mercator 2000',
      proj4def:
        '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      bbox: [-34.1, 166.37, -47.33, 178.63],
    },
    Merchich: {
      code: '26191',
      name: 'Merchich / Nord Maroc',
      proj4def:
        '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs',
      bbox: [35.97, -9.85, 31.49, -1.01],
    },
    PolandZone5: {
      code: '2176',
      name: 'ETRS89 / Poland CS2000 zone 5',
      proj4def:
        '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      bbox: [55.35, 14.14, 50.26, 16.5],
    },
    PolandZone6: {
      code: '2177',
      name: 'ETRS89 / Poland CS2000 zone 6',
      proj4def:
        '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      bbox: [55.93, 16.5, 49.39, 19.5],
    },
    PolandZone7: {
      code: '2178',
      name: 'ETRS89 / Poland CS2000 zone 7',
      proj4def:
        '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      bbox: [54.55, 19.5, 49.09, 22.5],
    },
    PolandZone8: {
      code: '2179',
      name: 'ETRS89 / Poland CS2000 zone 8',
      proj4def:
        '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
      bbox: [54.41, 22.5, 49.0, 24.15],
    },
  }

  getCodeByKey(key: string | null | undefined): string | null {
    if (key && this.Known_Projs[key]) {
      return this.Known_Projs[key].code
    }
    return null
  }

  searchEpsg(query: string): Observable<EpsgProjModel | null> {
    return from(
      fetch('https://epsg.io/?format=json&q=' + query)
        .then((response) => {
          return response.json()
        })
        .then(
          (json: {
            results:
              | (
                  | {
                      code: string | null | undefined
                      name: string
                      proj4: string | null | undefined
                      bbox: Extent | null | undefined
                    }
                  | null
                  | undefined
                )[]
              | null
              | undefined
          }) => {
            const results = json.results
            if (results && results.length > 0) {
              for (const result of results) {
                if (result) {
                  const code = result.code
                  const name = result.name
                  const proj4def = result.proj4
                  const bbox = result.bbox
                  if (code && proj4def && bbox && bbox?.length === 4) {
                    return {
                      code,
                      name,
                      proj4def,
                      bbox,
                    }
                  }
                }
              }
            }
            return null
          },
        ),
    )
  }

  private isPointInsideProjExtent(newProj: Projection, bbox: Extent, local_coords: Coordinate) {
    const fromLonLat = GETTRANSFORM('EPSG:4326', newProj)
    const extent = APPLYTRANSFORM([bbox[1], bbox[2], bbox[3], bbox[0]], fromLonLat)
    return CONTAINSCOORDINATE(extent, local_coords)
  }

  toLonLat(agsProj: EpsgProjModel, coordinates: Coordinate): undefined | Coordinate {
    if (agsProj?.code && agsProj?.proj4def && agsProj?.bbox) {
      proj4.defs(agsProj.code, agsProj.proj4def)
      REGISTER(proj4)
      const AGSProj = GETPROJ(agsProj.code)
      if (this.isPointInsideProjExtent(AGSProj, agsProj.bbox, coordinates)) {
        return TOLONLAT(coordinates, AGSProj)
      }
    }
    return undefined
  }
}
