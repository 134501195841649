import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FileModel, Jobsite, Permission, User } from '../shared/models'
import {
  FilesService,
  JobsiteService,
  MessagesService,
  SoilSurveyService,
  ZoneService,
} from '../shared/remote-services'
import { defaultIfEmpty, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators'
import { FormControl, Validators } from '@angular/forms'
import { firstValueFrom, forkJoin, from, map, noop, Observable, of, tap, withLatestFrom } from 'rxjs'
import {
  AGSImportDialog,
  AGSImportDialogInput,
} from '../shared/components/ags-import-dialog/ags-import-dialog.component'
import {
  CSVImportDialog,
  CSVImportDialogInput,
} from '../shared/components/csv-import-dialog/csv-import-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { KeycloakService } from '../keycloak/keycloak.service'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import { GoogleAnalyticsService } from '../shared/google-analytics/google-analytics.service'
import { MapFileModel } from '../shared/models/file.model'
import { UploadFileModel } from '../shared/models/upload-file.model'
import { Subscription } from 'rxjs/internal/Subscription'
import { nonNullable, withNonNullableFields } from '../shared/utils'
import { AppUpdateSelectedJobsite } from '../store/app/app.actions'
import { Select, Store } from '@ngxs/store'
import { AppStateSelectors } from '../store/app/app.selectors'
import { UserConfigStateSelectors } from '../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-jobsite-box',
  templateUrl: './jobsite-box.component.html',
  styleUrls: ['./jobsite-box.component.scss'],
})
export class JobsiteBoxComponent implements OnInit, OnDestroy {
  @Select(UserConfigStateSelectors.slices.permission) permission$: Observable<Permission | null>
  @Select(AppStateSelectors.slices.selectedJobsite) selectedJobsite$: Observable<Jobsite | null>

  @Output()
  fitToVisibleMapEvent: EventEmitter<Jobsite | null> = new EventEmitter()
  @Output()
  toggleJobsiteEditPopupEvent: EventEmitter<Jobsite | null> = new EventEmitter()
  @Output()
  resetMapViewEvent: EventEmitter<void> = new EventEmitter()
  @Output()
  goToNewJobsiteEvent: EventEmitter<Jobsite> = new EventEmitter()
  @Output()
  updateVisibleUploadWidgetEvent: EventEmitter<boolean> = new EventEmitter()

  ngfFormControl: FormControl<number | null>
  datumFormControl: FormControl<string | null>

  showReportList = false
  attachedFiles: FileModel[]

  user: User
  mapFiles: MapFileModel[]

  uploadFiles: UploadFileModel[] = []
  showUploadWidget = false
  nbWaitingFiles: number

  private subscription = new Subscription()

  constructor(
    private jobsiteService: JobsiteService,
    private messagesService: MessagesService,
    private filesService: FilesService,
    private keycloakService: KeycloakService,
    private soilSurveyService: SoilSurveyService,
    private zoneService: ZoneService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store,
  ) {
    this.user = this.keycloakService.getCurrentUser()
  }

  ngOnInit() {
    this.dragManage()
    this.ngfFormControl = new FormControl<number | null>(
      { value: null, disabled: true },
      { validators: Validators.required, updateOn: 'blur' },
    )
    this.datumFormControl = new FormControl<string | null>(
      { value: 'NGF', disabled: true },
      { validators: Validators.required, updateOn: 'blur' },
    )

    this.subscription.add(
      this.selectedJobsite$
        .pipe(
          map((jobsite) => jobsite?.ngf),
          filter(nonNullable),
          distinctUntilChanged(),
          tap((ngf) => this.ngfFormControl.setValue(ngf, { emitEvent: false })),
        )
        .subscribe(),
    )
    this.subscription.add(
      this.permission$
        .pipe(
          filter(nonNullable),
          tap(({ canWrite }) => {
            if (canWrite) {
              this.ngfFormControl.enable()
              this.datumFormControl.enable()
            } else {
              this.ngfFormControl.disable()
              this.datumFormControl.disable()
            }
          }),
        )
        .subscribe(),
    )
    this.subscription.add(
      this.selectedJobsite$
        .pipe(
          map((jobsite) => jobsite?.datumDisplayName),
          filter(nonNullable),
          distinctUntilChanged(),
          tap((datumDisplayName) => this.datumFormControl.setValue(datumDisplayName, { emitEvent: false })),
        )
        .subscribe(),
    )
    this.subscription.add(
      this.ngfFormControl.valueChanges
        .pipe(
          withLatestFrom(this.selectedJobsite$),
          filter(([ngfValue, jobsite]) => !!jobsite && ngfValue != null && Number(ngfValue) !== jobsite?.ngf),
          map(([ngf, jobsite]) => ({ ...jobsite, ngf })),
          switchMap((jobsite) =>
            this.store.dispatch(new AppUpdateSelectedJobsite(jobsite, 'changed the elevation value of the')),
          ),
        )
        .subscribe(),
    )
    this.subscription.add(
      this.datumFormControl.valueChanges
        .pipe(
          withLatestFrom(this.selectedJobsite$),
          filter(withNonNullableFields),
          filter(([datumValue, jobsite]) => datumValue !== '' && datumValue !== jobsite?.datumDisplayName),
          map(([datumValue, jobsite]) => ({
            ...jobsite,
            datumDisplayName: datumValue,
          })),
          switchMap((jobsite) =>
            this.store.dispatch(new AppUpdateSelectedJobsite(jobsite, 'changed the datum value of the')),
          ),
        )
        .subscribe(),
    )

    firstValueFrom(
      this.selectedJobsite$.pipe(
        map((j) => j?.id),
        filter(nonNullable),
        switchMap((jobsiteId) =>
          this.filesService.getReportsInfo(jobsiteId).pipe(
            map((hdfsFiles) =>
              hdfsFiles.map((item) => ({
                ...item,
                jobsiteId,
              })),
            ),
          ),
        ),
        tap((files) => (this.attachedFiles = files)),
      ),
    ).then(noop)

    firstValueFrom(
      this.selectedJobsite$.pipe(
        map((j) => j?.id),
        filter(nonNullable),
        switchMap((jobsiteId: string) => this.filesService.getMapFileList(jobsiteId)),
        tap((mapFiles) => (this.mapFiles = mapFiles)),
      ),
    ).then(noop)

    this.subscription.add(
      this.filesService.uploadFiles$
        .pipe(
          withLatestFrom(this.selectedJobsite$),
          map(([filesToUpload, selectedJobsite]) => ({ filesToUpload, jobsiteId: selectedJobsite?.id })),
          filter(({ jobsiteId }) => !!jobsiteId),
          tap(({ filesToUpload }) => (this.uploadFiles = filesToUpload || [])),
          map(({ jobsiteId, filesToUpload }) => ({
            jobsiteId,
            nbWaitingFiles: filesToUpload?.filter((file) => !file.loaded && !file.inError).length || 0,
          })),
          filter(({ nbWaitingFiles }) => nbWaitingFiles === 0),
          switchMap(({ jobsiteId }) =>
            jobsiteId
              ? this.filesService.getReportsInfo(jobsiteId).pipe(map((files) => ({ jobsiteId, files })))
              : of(null),
          ),
          tap(
            (info) =>
              (this.attachedFiles = info?.files?.map((item) => ({ ...item, jobsiteId: info?.jobsiteId })) || []),
          ),
        )
        .subscribe(),
    )
  }

  private dragManage() {
    const dropzoneId = 'file-drop-zone'

    window.addEventListener(
      'dragenter',
      (e: DragEvent) => {
        if ((e.target as HTMLElement).id !== dropzoneId) {
          e.preventDefault()
          if (e.dataTransfer) {
            e.dataTransfer.effectAllowed = 'none'
            e.dataTransfer.dropEffect = 'none'
          }
        }
      },
      false,
    )

    window.addEventListener('dragover', (e: DragEvent) => {
      if ((e.target as HTMLElement).id !== dropzoneId) {
        e.preventDefault()
        if (e.dataTransfer) {
          e.dataTransfer.effectAllowed = 'none'
          e.dataTransfer.dropEffect = 'none'
        }
      }
    })

    window.addEventListener('drop', (e: DragEvent) => {
      if ((e.target as HTMLElement).id !== dropzoneId) {
        e.preventDefault()
        if (e.dataTransfer) {
          e.dataTransfer.effectAllowed = 'none'
          e.dataTransfer.dropEffect = 'none'
        }
      }
    })
  }

  fitToVisibleMap() {
    this.fitToVisibleMapEvent.emit(this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite))
  }

  toggleJobsiteEditPopup() {
    this.toggleJobsiteEditPopupEvent.emit(this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite))
  }

  quitJobsite() {
    this.resetMapViewEvent.emit()
  }

  showTooltipDelete() {
    if (this.store.selectSnapshot(UserConfigStateSelectors.canDelete)) {
      return this.translateService.instant('MAINMAP.DELETE_JOBSITE')
    } else {
      return this.translateService.instant('MAINMAP.NO_DELETE_JOBSITE')
    }
  }

  deleteJobsite() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canDelete)) {
      return
    }
    const jobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    const id = jobsite?.id
    const nickname = jobsite?.nickname
    if (id) {
      from(
        Swal.fire({
          title: this.translateService.instant('GENERAL.SURE'),
          text: this.translateService.instant('GENERAL.NO_REVERT'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
          confirmButtonText: this.translateService.instant('GENERAL.DELETE'),
        }),
      )
        .pipe(
          take(1),
          filter((result) => result.value),
          switchMap(() =>
            forkJoin([this.soilSurveyService.getJobsiteSurveys(id), this.zoneService.getJobsiteZones(id)]),
          ),
          switchMap(([surveys, zones]) =>
            forkJoin([
              ...surveys.map((survey) =>
                survey.id ? this.soilSurveyService.deleteTheSoilSurvey(id, survey.id) : of(null),
              ),
              ...zones.map((zone) => (zone.id ? this.zoneService.deleteZone(zone.id) : null)),
            ]).pipe(defaultIfEmpty(null)),
          ),
          switchMap(() => this.jobsiteService.deleteJobsite(id)),
          switchMap(() => this.messagesService.saveJobsiteMessage$(id, nickname, 'deleted')),
        )
        .subscribe(() => {
          this.quitJobsite()
        })
    }
  }

  openAGSImportDialog() {
    const currentJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    const dialogRef = this.dialog.open<AGSImportDialog, AGSImportDialogInput, null>(AGSImportDialog, {
      width: '98vw',
      height: 'calc(100vh - 80px)',
      position: {
        top: '75px',
      },
      data: {
        currentJobsite,
        isSBT: false,
      },
    })

    const sub = dialogRef.componentInstance.onFinishAgsImport.subscribe((newJobsite: Jobsite) => {
      if (newJobsite) {
        this.googleAnalyticsService.event('import_ags_jobsite', {})
        this.goToNewJobsiteEvent.emit(newJobsite)
      }
      sub.unsubscribe()
    })
  }

  openCSVImportDialog() {
    const currentJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    const dialogRef = this.dialog.open<CSVImportDialog, CSVImportDialogInput, null>(CSVImportDialog, {
      width: '98vw',
      height: 'calc(100vh - 80px)',
      position: {
        top: '75px',
      },
      data: {
        jobsiteId: currentJobsite?.id,
        currentJobsite,
        isSBT: false,
      },
    })

    const sub = dialogRef.componentInstance.onFinishAgsImport.subscribe((newJobsite: Jobsite) => {
      if (newJobsite) {
        this.googleAnalyticsService.event('import_csv_jobsite', {})
        this.goToNewJobsiteEvent.emit(newJobsite)
      }
      sub.unsubscribe()
    })
  }

  changeJobsiteStatus(status: string | null) {
    firstValueFrom(
      this.selectedJobsite$.pipe(
        filter(nonNullable),
        map((jobsite: Jobsite) => ({ ...jobsite, status })),
        //!\ switchMap causes an infinite loop
        tap((jobsite: Jobsite) =>
          this.store.dispatch(new AppUpdateSelectedJobsite(jobsite, 'modified the status of the')),
        ),
      ),
    ).then(noop)
  }

  onFileChange(files: File[]) {
    if (files?.length > 0) {
      this.showUploadWidget = true
      const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
      if (selectedJobsiteId) {
        this.filesService.handleFilesToUpload(files, selectedJobsiteId)
      }
    }
  }

  onFileInputClick(event) {
    event.target.value = null
  }

  allowDrop(ev) {
    ev.preventDefault()
  }

  dropFiles(ev) {
    const files = ev.dataTransfer.files
    ev.preventDefault()
    this.onFileChange(files)
  }

  hideUploadWidgetEvent() {
    this.showUploadWidget = false
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
