import { Action, State, StateContext } from '@ngxs/store'
import { Injectable } from '@angular/core'
import produce from 'immer'
import {
  DataTableClearSurveyData,
  DataTableSetSelectedColumns,
  DataTableAddSurveyData,
  DataTableSetTableColors,
} from './data-table.actions'
import Handsontable from 'handsontable'
import { cloneDeep } from 'lodash'
import { map, of, tap } from 'rxjs'

export interface DataTableStateModel {
  tableColors: (string | null)[]
  selectedColumns: Handsontable.ColumnSettings[]
  surveyData: Record<string, [number, number][]> | null
}

@State<DataTableStateModel>({
  name: 'dataTable',
  defaults: {
    tableColors: [],
    selectedColumns: [],
    surveyData: null,
  },
})
@Injectable()
export class DataTableState {
  @Action(DataTableSetTableColors)
  public setTableColors(ctx: StateContext<DataTableStateModel>, { tableColors }: DataTableSetTableColors) {
    ctx.setState(
      produce((draft) => {
        draft.tableColors = tableColors
      }),
    )
  }

  @Action(DataTableSetSelectedColumns)
  public setSelectedColumns(ctx: StateContext<DataTableStateModel>, { selectedColumns }: DataTableSetSelectedColumns) {
    ctx.setState({
      ...ctx.getState(),
      selectedColumns: cloneDeep(selectedColumns),
    })
  }

  @Action(DataTableAddSurveyData)
  public addSurveyData(ctx: StateContext<DataTableStateModel>, payload: DataTableAddSurveyData) {
    return of(ctx.getState()).pipe(
      map(({ surveyData }) => ({
        ...cloneDeep(surveyData || {}),
        ...cloneDeep(payload.surveyData),
      })),
      tap((surveyData) => {
        ctx.setState(
          produce((draft) => {
            draft.surveyData = surveyData
          }),
        )
      }),
    )
  }

  @Action(DataTableClearSurveyData)
  public clearSurveyData(ctx: StateContext<DataTableStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.surveyData = null
      }),
    )
  }
}
