import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'soillib-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() public message

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  timestampToDatestring(timestamp) {
    const date = new Date(timestamp)
    return date.toLocaleString(this.translate.currentLang ? this.translate.currentLang : 'en')
  }
}
