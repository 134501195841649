import { Injectable } from '@angular/core'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import { defer, from, iif, map, Observable, of, switchMap } from 'rxjs'
import { removeLSItem, safeSetLSItem } from '../utils/local-storage.utils'

@Injectable()
export class TableAutoSaveService {
  constructor(private translateService: TranslateService) {}

  autosaveTableData<T>(id: string, data: T, secondId: string = 'table') {
    if (this.nonEmptyObject(data)) {
      safeSetLSItem(this.getItemId(id, secondId), JSON.stringify(data))
    }
  }

  getAutoSaveTableData$ = <T>(id: string, secondId: string = 'table'): Observable<T | null> =>
    of(this.getItemId(id, secondId)).pipe(
      map((itemId) => removeLSItem(itemId)),
      switchMap((item) =>
        iif(
          () => !!item,
          defer(() =>
            from(
              Swal.fire({
                title: this.translateService.instant('TABLE.AUTOSAVE.TITLE'),
                text: this.translateService.instant('TABLE.AUTOSAVE.TEXT'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
              }),
            ),
          ).pipe(map((result) => (result.value ? JSON.parse(item as string) : null))),
          of(null),
        ),
      ),
    )

  deleteAutosaveTableData(id: string, secondId: string = 'table') {
    const itemId = this.getItemId(id, secondId)
    removeLSItem(itemId)
  }

  nonEmptyObject = <T>(obj?: T): boolean =>
    !!obj &&
    (typeof obj === 'string' ||
      typeof obj === 'number' ||
      typeof obj === 'boolean' ||
      typeof obj === 'bigint' ||
      !!Object.values(obj).filter((value) => this.nonEmptyObject(value)).length)

  private getItemId = (id: string, secondId: string) => `${id}-${secondId}`
}
