import { Injector } from '@angular/core'
import VectorLayer from 'ol/layer/Vector'
import olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import { StyleService } from '../services/style.service'

export class CrossSectionLayer {
  crossSectionSource: VectorSource
  crossSectionLayer: VectorLayer

  private styleService: StyleService

  constructor(private map: olMap, private injector: Injector) {
    this.styleService = this.injector.get(StyleService)
    this.crossSectionSource = new VectorSource({})
    this.crossSectionLayer = new VectorLayer({
      source: this.crossSectionSource,
      style: this.styleService.getCrossSectionStyle(),
    })
    this.map.addLayer(this.crossSectionLayer)
  }
}
