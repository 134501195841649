<div id="search-address" soillibDraggable>
  <h1 mat-dialog-title>{{ 'GENERAL.SEARCH_ADDRESS' | translate }}</h1>
  <div mat-dialog-content>
    <mat-tab-group [selectedIndex]="selectedTabIndex">
      <mat-tab label="{{ 'MAINMAP.BY_TEXT' | translate }}">
        <div class="tabcontent">
          <mat-form-field>
            <input
              matInput
              placeholder="{{ 'GENERAL.SEARCH_ADDRESS' | translate }}"
              (input)="search$.next($event.target.value)"
              [matAutocomplete]="searchBoxDialog"
            />
          </mat-form-field>
          <mat-autocomplete
            #searchBoxDialog
            [displayWith]="displaySearch"
            (optionSelected)="handleSearchSelection($event.option.value)"
          >
            <mat-option *ngFor="let searchResult of searchResults" [value]="searchResult">
              {{ searchResult.displayedName }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'MAINMAP.BY_COOR' | translate }}">
        <div class="tabcontent">
          <form fxLayout="row" fxLayoutGap="20px" [formGroup]="coordinateForm">
            <div>
              <mat-form-field>
                <input
                  matInput
                  (click)="$event.target.select()"
                  matTooltip="Longitude example: 2.34567 (range -180 to 180) or 2°47'18.586&quot;E (end with E or O, degree value must be integer and less than 180, minute value must be integer and less than 60, seconde value must be less than 60)"
                  placeholder="Longitude"
                  formControlName="lon"
                />
              </mat-form-field>
              <mat-error *ngIf="coordinateForm.controls['lon'].hasError('longitudeError') && coordinateForm.dirty"
                >{{ 'GENERAL.INVALID' | translate }} !</mat-error
              >
            </div>
            <div>
              <mat-form-field>
                <input
                  matInput
                  (click)="$event.target.select()"
                  matTooltip="Latitude example: 48.788524 (range -90 to 90) or 48°47'18.586&quot;N (end with N or S, degree value must be integer and less than 90, minute value must be integer and less than 60, seconde value must be less than 60)"
                  placeholder="Latitude"
                  formControlName="lat"
                />
              </mat-form-field>
              <mat-error *ngIf="coordinateForm.controls['lat'].hasError('latitudeError') && coordinateForm.dirty"
                >{{ 'GENERAL.INVALID' | translate }} !</mat-error
              >
            </div>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="close()">{{ 'GENERAL.CANCEL' | translate }}</button>
    <button
      mat-raised-button
      color="warn"
      (click)="saveAddress()"
      [ngClass]="{ disabled: coordinateForm.invalid }"
      [disabled]="coordinateForm.invalid"
    >
      {{ 'GENERAL.SAVE_ADDRESS' | translate }}
    </button>
  </div>
</div>
