<div id="new-jobsite" soillibDraggable>
  <h3>{{ 'MAINMAP.JOBSITE_CREATION' | translate }}</h3>
  <form [formGroup]="newJobsiteForm" fxLayout="column">
    <div>
      <mat-form-field>
        <input matInput placeholder="{{ 'MAINMAP.JOBSITE_NAME' | translate }}" formControlName="jobsiteName" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <div>
        <mat-form-field>
          <input
            matInput
            (click)="$event.target.select()"
            matTooltip="Longitude example: 2.34567 (range -180 to 180) or 2°47'18.586&quot;E (end with E or O, degree value must be integer and less than 180, minute value must be integer and less than 60, seconde value must be less than 60)"
            placeholder="Longitude"
            formControlName="lon"
          />
        </mat-form-field>
        <mat-error *ngIf="newJobsiteForm.controls['lon'].hasError('longitudeError')"
          >{{ 'GENERAL.INVALID' | translate }} !</mat-error
        >
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            (click)="$event.target.select()"
            matTooltip="Latitude example: 48.788524 (range -90 to 90) or 48°47'18.586&quot;N (end with N or S, degree value must be integer and less than 90, minute value must be integer and less than 60, seconde value must be less than 60)"
            placeholder="Latitude"
            formControlName="lat"
          />
        </mat-form-field>
        <mat-error *ngIf="newJobsiteForm.controls['lat'].hasError('latitudeError')"
          >{{ 'GENERAL.INVALID' | translate }} !</mat-error
        >
      </div>
    </div>
  </form>
  <div fxLayout="row" fxLayoutGap="20px">
    <button mat-raised-button (click)="close()">{{ 'GENERAL.CANCEL' | translate }}</button>
    <button
      mat-raised-button
      color="warn"
      [ngClass]="{ disabled: newJobsiteForm.invalid }"
      [disabled]="newJobsiteForm.invalid"
      (click)="createJobsite()"
    >
      {{ 'MAINMAP.SAVE_JOBSITE' | translate }}
    </button>
  </div>
</div>
