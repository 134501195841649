import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { JobsiteMessage, RessourceMessage } from '../models'
import { forkJoin, Observable, of } from 'rxjs'
import { Store } from '@ngxs/store'
import { Resource } from '../../store/app/app.state'
import { AppStateSelectors } from '../../store/app/app.selectors'

@Injectable()
export class MessagesService {
  private pageSize = 30

  constructor(private http: HttpClient, private keycloakService: KeycloakService, private store: Store) {}

  getLastMessages(
    jobsiteId: string | null,
    pageState: string | null,
    selectedResource: Resource | null,
  ): Observable<{ entities: (JobsiteMessage | RessourceMessage)[]; pageState: string } | null> {
    let params: Record<string, string> = {
      pageSize: this.pageSize.toString(),
    }
    if (pageState) {
      params = {
        ...params,
        pageState,
      }
    }
    if (selectedResource != null) {
      const resourceId = selectedResource.id || this.keycloakService.getCurrentUser().id
      return this.http.get<{ entities: RessourceMessage[]; pageState: string }>(
        `api/messages/ressource/${resourceId}`,
        { params },
      )
    } else if (jobsiteId) {
      return this.http.get<{ entities: JobsiteMessage[]; pageState: string }>(`api/messages/jobsite/${jobsiteId}`, {
        params,
      })
    }
    return of(null)
  }

  saveComment(
    jobsiteId: string | null,
    comment: JobsiteMessage,
    selectedResource: Resource | null,
  ): Observable<RessourceMessage | JobsiteMessage | null> {
    const message = {
      ...comment,
      author: this.keycloakService.getCurrentUser().name,
    }
    if (selectedResource != null) {
      message.type = selectedResource.type
      const resourceId = selectedResource?.id || this.keycloakService.getCurrentUser().id
      return this.http.post<RessourceMessage>(`api/messages/ressource/${resourceId}`, message)
    } else if (jobsiteId) {
      return this.saveJobsiteMessage(jobsiteId, message)
    }
    return of(null)
  }

  saveJobsiteMessage$(
    jobsiteId: string | null | undefined,
    modifiedResourceName: string | null | undefined,
    action: string,
  ): Observable<JobsiteMessage | null> {
    return modifiedResourceName && jobsiteId
      ? this.saveJobsiteMessage(jobsiteId, this.buildMessage(modifiedResourceName, 'jobsite', action))
      : of(null)
  }

  saveResourceAndJobsiteMessage$(
    jobsiteId: string | null | undefined,
    modifiedResourceName: string | null | undefined,
    modifiedResourceType: 'borehole' | 'section',
    action: string,
  ): Observable<[JobsiteMessage | null, RessourceMessage | null] | null> {
    return modifiedResourceName
      ? this.saveResourceAndJobsiteMessage(
          jobsiteId,
          this.buildMessage(modifiedResourceName, modifiedResourceType, action),
          this.store.selectSnapshot(AppStateSelectors.slices.selectedResource),
        )
      : of(null)
  }

  saveJobsiteMessageByJobsiteId$(
    jobsiteId: string,
    modifiedResourceName: string | null | undefined,
    modifiedResourceType: 'jobsite' | 'boreholes',
    action: string,
  ): Observable<JobsiteMessage | null> {
    return modifiedResourceName
      ? this.saveJobsiteMessage(jobsiteId, this.buildMessage(modifiedResourceName, modifiedResourceType, action))
      : of(null)
  }

  private saveJobsiteMessage(jobsiteId: string, message: JobsiteMessage): Observable<JobsiteMessage> {
    return this.http.post<JobsiteMessage>(`api/messages/jobsite/${jobsiteId}`, message)
  }

  private saveResourceAndJobsiteMessage(
    jobsiteId: string | null | undefined,
    message: JobsiteMessage | RessourceMessage,
    selectedResource: Resource | null,
  ): Observable<[JobsiteMessage | null, RessourceMessage | null]> {
    const jobsiteMessageObservable = jobsiteId ? this.saveJobsiteMessage(jobsiteId, message) : of(null)
    const resourceMessageObservable = selectedResource?.id
      ? this.http.post<RessourceMessage>(`api/messages/ressource/${selectedResource.id}`, message)
      : of(null)

    return forkJoin([jobsiteMessageObservable, resourceMessageObservable])
  }

  private buildMessage(
    modifiedResourceName: string,
    modifiedResourceType: 'jobsite' | 'borehole' | 'boreholes' | 'section',
    action: string,
  ): JobsiteMessage | RessourceMessage {
    return {
      content: `${
        this.keycloakService.getCurrentUser().name
      } ${action} ${modifiedResourceType} ${modifiedResourceName}`,
      author: 'logger',
      type: modifiedResourceType,
    }
  }
}
