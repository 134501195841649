<table mat-table [dataSource]="bhData" style="width: 100%">
  <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
    <th mat-header-cell *matHeaderCellDef>{{ getColTitle(col) }}</th>
    <td mat-cell *matCellDef="let element">{{ element[col] }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div *ngIf="bhData?.length === 0">{{ 'FILE_IMPORT.NO_DATA' | translate }}</div>
