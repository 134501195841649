<div id="popup" class="ol-popup limitSize">
  <div (click)="closeZonesPopup()" class="ol-popup-closer"></div>
  <h4 class="popup-title">{{ 'MAINMAP.AVAILABLE_ZONES' | translate }}</h4>
  <ul class="popup-items">
    <li
      class="popup-item"
      *ngFor="let zone of zonesAtPixel"
      (click)="selectThisZone(zone)"
      (mouseenter)="onHovering(zone)"
      (mouseleave)="onUnhovering(zone)"
      [ngClass]="{ selected: zone && zone.id === (selectedZoneId$ | async) }"
    >
      {{ zone.name }}
    </li>
  </ul>
</div>
<div id="info-popup" class="ol-popup limitSize">
  <h4 class="popup-title">{{ 'GENERAL.' + popupType | translate }}</h4>
  <ul class="popup-items" *ngIf="basicInfo$ | async as basicInfo">
    <li *ngFor="let info of basicInfo">
      {{ info }}
    </li>
  </ul>
</div>
<div id="help-tooltip" class="tooltip">{{ helpMsg }}</div>
<div id="clickable-popup" class="ol-popup limitSize">
  <div (click)="closeJobsitePopup()" class="ol-popup-closer"></div>
  <h4 class="popup-title">{{ 'GENERAL.' + popupType | translate }} ({{ elementsAtPixel?.length }})</h4>
  <ul class="popup-items">
    <li *ngFor="let element of elementsAtPixel" (click)="select(element)" class="clickable-element">
      {{ element?.nickname || element?.name }}
    </li>
  </ul>
</div>
