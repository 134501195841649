import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Jobsite, JobsiteSearch } from '../models'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'

@Injectable()
export class JobsiteService {
  constructor(private http: HttpClient) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }

  searchJobsites(search: string): Observable<JobsiteSearch[]> {
    search = search.replace(/'/g, ' ') // temporary fix for ' which dont pass lucene queries
    return this.http.post<Jobsite[]>('api/jobsites/search', { search }, this.httpOptions)
  }

  searchVisibleJobsites(polygon: string): Observable<Jobsite[]> {
    return this.http.get<Jobsite[]>(`api/jobsites/geoloc?polygon=${polygon}`)
  }

  createJobsite(jobsite): Observable<string> {
    return this.http.post<string>('api/jobsites', jobsite)
  }

  updateJobsite(jobsiteId: string, jobsite: Jobsite): Observable<string> {
    return this.http.put<string>(`api/jobsites/${jobsiteId}`, jobsite)
  }

  getJobsiteById(jobsiteId: string): Observable<Jobsite> {
    return this.http.get<Jobsite>(`api/jobsites/${jobsiteId}`)
  }

  getJobsiteSurveys(jobsiteId: string): Observable<SoilSurvey[]> {
    return this.http.get<SoilSurvey[]>(`api/jobsites/${jobsiteId}/soil_surveys`)
  }

  deleteJobsite(jobsiteId: string): Observable<any> {
    return this.http.delete(`api/jobsites/${jobsiteId}`)
  }
}
