import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { FileModel } from '../../models'
import { MapFileModel } from '../../models/file.model'
import { Permission } from '../../models/Keycloak.permission.model'
import { FilesService } from '../../remote-services/files.services'
import { UserConfigStateSelectors } from '../../../store/user-config/user-config.selectors'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'

@Component({
  selector: 'soillib-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit {
  @Input()
  attachedFiles: FileModel[]

  @Input()
  mapFiles: MapFileModel[]

  @Select(UserConfigStateSelectors.slices.permission) permission$: Observable<Permission | null>

  @Output()
  closeEvent: EventEmitter<void> = new EventEmitter()

  constructor(private filesService: FilesService, private translate: TranslateService, private store: Store) {}

  ngOnInit() {}

  downloadFile(file: FileModel) {
    if (file.jobsiteId) {
      this.filesService.downloadFile(file.jobsiteId, file.name)
    }
  }

  showDateTime(modified: string) {
    return new Date(modified).toLocaleString(this.translate.currentLang)
  }

  deleteFile(file: FileModel, i: number) {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canDelete)) {
      return
    }
    if (file.jobsiteId) {
      this.filesService.deleteFile(file.jobsiteId, file.name).subscribe(() => {
        this.attachedFiles.splice(i, 1)
      })
    }
  }

  downloadMapFile(path: string) {
    window.open(path)
  }

  close() {
    this.closeEvent.emit()
  }
}
