<div class="import-container">
  <h3 *ngIf="level === 'borehole'">{{ 'FILE_IMPORT.IMPORT_AGS_BH' | translate }}</h3>
  <h3 *ngIf="level === 'jobsite'">{{ 'FILE_IMPORT.IMPORT_AGS_JOBSITE' | translate }}</h3>
  <div fxLayout="row" fxLayoutGap="20px">
    <soillib-import-select-files
      fxFlex="10"
      [isAGS]="true"
      (filesSelectedEvent)="handleFilesSelected($event)"
    ></soillib-import-select-files>
    <!-- <div fxFlex="10" class="select-ags" fxLayout="column" fxLayoutAlign="start center">
      <label>
        {{ 'FILE_IMPORT.SELECT_FILES' | translate }}
        <input type="file" multiple accept=".ags" class="hidden" (change)="handleFilesSelected($event)" />
      </label>
      <ul *ngIf="selectedFileNames" style="font-size: 13px; margin-left: -30px">
        <li *ngFor="let filename of selectedFileNames">{{ filename }}</li>
      </ul>
    </div> -->
    <div fxFlex="15" *ngIf="selectedFiles">
      <soillib-import-configuration
        [level]="level"
        [detectedProjections]="detectedProjections"
        [detectedJobsiteNames]="detectedJobsiteNames"
        [customProjInfoText]="customProjInfoText"
        [customProjErrorText]="customProjErrorText"
        [selectedJobsiteName]="selectedJobsiteName"
        (projectionSelectEvent)="onProjSelectionChange($event)"
        (projectionCodeChangeEvent)="applyProjection($event)"
        (jobsiteNameSelectEvent)="setJobsiteName($event)"
        (boreholeTypeSelectEvent)="onBoreholeTypeChange($event)"
      ></soillib-import-configuration>
    </div>
    <div fxFlex="75">
      <soillib-import-boreholes-preview
        [level]="level"
        [selectedFiles]="selectedFiles"
        [importedBoreholes]="allImportedBoreholes"
        [selectedJobsiteName]="selectedJobsiteName"
        [loadingText]="loadingText"
        [datumDisplayName]="currentJobsite?.datumDisplayName ?? 'NGF'"
        (updateSurveysToMapEvent)="updateSurveysToMap($event)"
        (finishAgsImportEvent)="finishAgsImport($event)"
      ></soillib-import-boreholes-preview>
    </div>
  </div>
</div>
