import { Directive, ElementRef, HostListener, OnInit } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { map, mergeMap, takeUntil, debounceTime, tap } from 'rxjs/operators'
import { getLSItem, safeSetLSItem } from '../utils/local-storage.utils'

@Directive({
  selector: '[soillibDraggable]',
})
export class DraggableDirective implements OnInit {
  mouseup = new Subject<MouseEvent>()
  mousedown = new Subject<MouseEvent>()
  mousemove = new Subject<MouseEvent>()
  mousedrag: Observable<{ top: number; left: number }>
  containerRect: DOMRect

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event: MouseEvent) {
    this.mouseup.next(event)
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent) {
    this.mousedown.next(event)
    return false
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    this.mousemove.next(event)
  }

  constructor(public element: ElementRef<HTMLElement>) {
    element.nativeElement.style.position = 'relative'
    element.nativeElement.style.cursor = 'move'

    this.mousedrag = this.mousedown.pipe(
      map((event: MouseEvent) => {
        return {
          top:
            event.clientY -
            element.nativeElement.getBoundingClientRect().top +
            (element.nativeElement.offsetParent as HTMLElement).offsetTop,
          left:
            event.clientX -
            element.nativeElement.getBoundingClientRect().left +
            (element.nativeElement.offsetParent as HTMLElement).offsetLeft,
        }
      }),
      mergeMap((imageOffset) =>
        this.mousemove.pipe(
          map((pos: MouseEvent) => ({
            top: pos.clientY - imageOffset.top,
            left: pos.clientX - imageOffset.left,
          })),
          takeUntil(this.mouseup),
        ),
      ),
    )
  }

  ngOnInit() {
    const localStorageItem = getLSItem(this.element.nativeElement.id)
    if (localStorageItem) {
      const parsedPosition: { top: string; left: string } = JSON.parse(localStorageItem)
      this.element.nativeElement.style.top = parsedPosition.top
      this.element.nativeElement.style.left = parsedPosition.left
    } else if (this.element.nativeElement.id === 'search-address' || this.element.nativeElement.id === 'new-jobsite') {
      this.element.nativeElement.style.left = '700px'
    } else if (this.element.nativeElement.id === 'visible-zone-box-draggable') {
      this.element.nativeElement.style.left = '550px'
    } else if (this.element.nativeElement.id === 'drag-jobsite-edit') {
      this.element.nativeElement.style.top = '380px'
    }

    this.mousedrag.subscribe((pos) => {
      // set a bound for this draggable element
      const container = document.getElementById('container')
      if (container) {
        this.containerRect = container.getBoundingClientRect()
        const containerHeight = window.innerHeight - this.containerRect.top
        const containerWidth = window.innerWidth
        // very important :
        // the parent element of draggable div is defined as fixed position with bottom and left
        // this initial position will be the origin of the drag coordinate system
        // thus, if we want to add another draggable div, should also define its parent element as fixed position with bottom and left
        const initialLeft = this.element.nativeElement.parentElement?.offsetLeft || 0
        const initialBottom =
          window.innerHeight -
          (this.element.nativeElement.parentElement?.offsetTop || 0) -
          (this.element.nativeElement.parentElement?.offsetHeight || 0)

        if (this.containerRect.left !== null) {
          if (pos.left < this.containerRect.left - initialLeft) {
            pos.left = this.containerRect.left - initialLeft
          }
          if (pos.left > containerWidth - this.element.nativeElement.getBoundingClientRect().width - initialLeft) {
            pos.left = containerWidth - this.element.nativeElement.getBoundingClientRect().width - initialLeft
          }
        }
        if (this.containerRect.top !== null) {
          if (pos.top > initialBottom) {
            pos.top = initialBottom
          }
          if (
            pos.top < -(containerHeight - this.element.nativeElement.getBoundingClientRect().height - initialBottom)
          ) {
            pos.top = -(containerHeight - this.element.nativeElement.getBoundingClientRect().height - initialBottom)
          }
        }
        /////////////////////////////////////////
        this.element.nativeElement.style.top = pos.top + 'px'
        this.element.nativeElement.style.left = pos.left + 'px'
      }
    })

    this.mousedrag
      .pipe(
        debounceTime(1000),
        tap(() => this.saveBoxPosition()),
      )
      .subscribe()
  }

  saveBoxPosition() {
    safeSetLSItem(
      this.element.nativeElement.id,
      JSON.stringify({
        top: this.element.nativeElement.style.top,
        left: this.element.nativeElement.style.left,
      }),
    )
  }
}
