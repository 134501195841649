import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { LatLonLocation, Jobsite, ParsedSurvey, User, CorrelationDataModel } from '../shared/models'
import { TranslateService } from '@ngx-translate/core'
import { ddToDmsLat, ddToDmsLng } from '../shared/utils'
import { KeycloakService } from '../keycloak/keycloak.service'
import { BottomNavHeight, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Select, Store } from '@ngxs/store'
import { AppClearResource, AppCloseBottomTab, AppLeaveSurvey, AppOpenBottomTab } from '../store/app/app.actions'
import { CorrelationDataClearCorrelationData } from '../surveys-correlation-data/store/correlation-data.actions'
import { DataTableClearSurveyData } from '../store/data-table/data-table.actions'
import { AppStateSelectors } from '../store/app/app.selectors'
import { map, Observable, Subscription } from 'rxjs'
import { DataTableStateSelectors } from '../store/data-table/data-table.selectors'
import { CorrelationDataStateSelectors } from '../surveys-correlation-data/store/correlation-data.selectors'

@Component({
  selector: 'soillib-surveys-edition',
  templateUrl: './surveys-edition.component.html',
  styleUrls: ['./surveys-edition.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-bottom': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-bottom': 'var(--bottom-nav-height)' })),
      ]),
      transition('true => false', [
        style({ 'margin-bottom': 'var(--bottom-nav-height)' }),
        animate('0.5s ease-in-out', style({ 'margin-bottom': '0' })),
      ]),
    ]),
    trigger('slideInOutFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class SurveysEditionComponent implements OnInit, OnDestroy {
  @Output() addSurveyEvent: EventEmitter<SoilSurvey> = new EventEmitter()
  @Output() updateSurveysEvent: EventEmitter<{
    deleteIds: string[]
    newSurveys: SoilSurvey[]
  }> = new EventEmitter()
  @Output() deleteSurveyEvent: EventEmitter<string> = new EventEmitter()
  @Output() markLocationEvent: EventEmitter<LatLonLocation | null> = new EventEmitter()
  @Output() updateSurveyEvent: EventEmitter<SoilSurvey> = new EventEmitter()
  @Output() setMoveSurveyModeEvent: EventEmitter<boolean> = new EventEmitter()
  @Output() saveMovedSurveyEvent: EventEmitter<boolean> = new EventEmitter()
  @Output() setSurveyTableChangeStatusEvent: EventEmitter<boolean> = new EventEmitter()

  @Select(AppStateSelectors.slices.selectedJobsite) selectedJobsite$: Observable<Jobsite | null>
  @Select(AppStateSelectors.datumDisplayName) datumDisplayName$: Observable<string>
  @Select(AppStateSelectors.boreholeEditionOpened) boreholeEditionOpened$: Observable<boolean>
  @Select(DataTableStateSelectors.slices.surveyData) surveyData$: Observable<Record<string, [number, number][]> | null>
  @Select(CorrelationDataStateSelectors.slices.correlationData) correlationData$: Observable<
    CorrelationDataModel[] | null
  >
  @Select(AppStateSelectors.slices.selectedSurvey) selectedSurvey$: Observable<SoilSurvey | null>

  user: User

  parsedSurvey: ParsedSurvey[] = []

  observer: MutationObserver
  showInfoBox = true

  tabIndex = 0

  elevationMode = false

  private subscription = new Subscription()

  get isSBT$(): Observable<boolean> {
    return this.selectedSurvey$.pipe(map((selectedSurvey) => selectedSurvey?.type?.includes('CPT') === true))
  }

  constructor(
    private translateService: TranslateService,
    private keycloakService: KeycloakService,
    private store: Store,
  ) {
    this.user = this.keycloakService.getCurrentUser()
  }

  ngOnInit() {
    this.customResize()
    this.subscription.add(
      this.selectedSurvey$.subscribe((selectedSurvey) => {
        this.tabIndex = 0
        if (selectedSurvey) {
          this.parsedSurvey = this.getParsedSurvey(selectedSurvey)
        }
      }),
    )
  }

  getElevationParameters$(): Observable<{
    activeElevation: boolean
    label: string
    depthFormatter: (depth: number) => number
  }> {
    return this.selectedSurvey$.pipe(
      map((selectedSurvey) => selectedSurvey?.elevation),
      map((elevation) => {
        const activeElevation = this.elevationMode && elevation != null
        return {
          activeElevation,
          label: activeElevation
            ? this.translateService.instant('SURVEY.ELEVATION', {
                name: this.store.selectSnapshot(AppStateSelectors.datumDisplayName),
              })
            : this.translateService.instant('GENERAL.DEPTH'),
          depthFormatter: (depth: number) => {
            const newDepth = activeElevation ? (elevation || 0) - depth : depth
            return parseFloat(newDepth.toFixed(2))
          },
        }
      }),
    )
  }

  private getParsedSurvey(selectedSurvey: SoilSurvey) {
    const datumDisplayName = this.store.selectSnapshot(AppStateSelectors.datumDisplayName)
    return [
      {
        fieldName: this.translateService.instant('SURVEY.NAME'),
        fieldValue: selectedSurvey.name || '',
        active: false,
      },
      {
        fieldName: 'Type',
        fieldValue: selectedSurvey.type ? selectedSurvey.type.split(',') : undefined,
        active: false,
      },
      {
        fieldName: this.translateService.instant('SURVEY.ELEVATION', {
          name: datumDisplayName,
        }),
        fieldValue: selectedSurvey.elevation ?? undefined,
        active: false,
      },
      {
        fieldName: this.translateService.instant('SURVEY.WATER_LEVEL', {
          name: datumDisplayName,
        }),
        fieldValue: selectedSurvey.water_level ?? undefined,
        active: false,
      },
      {
        fieldName: 'Latitude',
        fieldValue: selectedSurvey.lat_coord ? ddToDmsLat(selectedSurvey.lat_coord) : undefined,
        active: false,
      },
      {
        fieldName: 'Longitude',
        fieldValue: selectedSurvey.lon_coord ? ddToDmsLng(selectedSurvey.lon_coord) : undefined,
        active: false,
      },
      {
        fieldName: this.translateService.instant('SURVEY.COORD_X'),
        fieldValue: selectedSurvey.local_x ?? undefined,
        active: false,
      },
      {
        fieldName: this.translateService.instant('SURVEY.COORD_Y'),
        fieldValue: selectedSurvey.local_y ?? undefined,
        active: false,
      },
      {
        fieldName: this.translateService.instant('SURVEY.PROJ_REF'),
        fieldValue: selectedSurvey.projection_ref ?? undefined,
        active: false,
      },
    ]
  }

  toggleSurveyEdition(openSurveyEdition: boolean) {
    if (this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)?.extent) {
      if (openSurveyEdition) {
        this.store.dispatch(new AppOpenBottomTab('borehole-edition'))
      } else {
        this.store.dispatch([
          AppLeaveSurvey,
          AppClearResource,
          CorrelationDataClearCorrelationData,
          DataTableClearSurveyData,
          AppCloseBottomTab,
        ])
      }
    }
  }

  tabChanged(index: number) {
    this.tabIndex = index
  }

  ngOnDestroy() {
    this.observer.disconnect()
    this.subscription.unsubscribe()
  }

  private customResize() {
    const targetNode = document.documentElement
    const config = { attributes: true }
    this.observer = new MutationObserver(() => {
      this.showInfoBox = getComputedStyle(targetNode).getPropertyValue('--bottom-nav-height') === BottomNavHeight.MIDDLE
    })
    this.observer.observe(targetNode, config)
  }
}
