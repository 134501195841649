<mat-expansion-panel *ngIf="isVisible" [expanded]="isExpanded" [hideToggle]="true" class="report-upload-process">
  <mat-expansion-panel-header>
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
      <span *ngIf="nbWaitingFiles">{{ 'MAINMAP.UPDLOADING' | translate }}</span>
      <span *ngIf="!nbWaitingFiles">{{ 'MAINMAP.UPLOAD_FINISH' | translate }}</span>
      <mat-progress-spinner *ngIf="nbWaitingFiles" [diameter]="20" color="primary" mode="indeterminate">
      </mat-progress-spinner>
      <div fxFlex></div>
      <mat-icon aria-label="Close" (click)="close()">close</mat-icon>
    </div>
  </mat-expansion-panel-header>

  <mat-list>
    <div *ngFor="let file of files; trackBy: trackByFile; let last = last">
      <mat-list-item>
        <div fxLayout="row" fxFlexFill fxLayoutAlign="start center">
          <div fxLayout="column" fxFlex="75">
            <div class="fileName" [title]="file.file.name">{{ file.file.name }}</div>
            <div class="progressbar" fxFlex="25">
              <div class="inner-progressbar" [style.width]="file.progress + '%'"></div>
            </div>
          </div>
          <div fxFlex></div>
          <div fxFlex="60px" fxLayoutAlign="end center">
            <mat-icon class="green-icon" *ngIf="file.loaded"> check_circle </mat-icon>
            <mat-icon
              color="warn"
              class="clickable"
              aria-label="Error"
              [matTooltip]="file.errorDesc + '. Click to retry !'"
              *ngIf="file.inError"
              (click)="retryFile(file)"
            >
              error
            </mat-icon>
            <mat-icon
              style="color: gray"
              class="clickable"
              aria-label="cancel"
              *ngIf="!file.loaded && !file.inError"
              (click)="cancelFile(file)"
            >
              cancel
            </mat-icon>
          </div>
        </div>
      </mat-list-item>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
  </mat-list>
</mat-expansion-panel>
