import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { getTechniqueColumnTitle, SurveyData } from '@sde-ild/ssd-soillib-lib'

@Component({
  selector: 'soillib-import-bh-data-table',
  templateUrl: './import-bh-data-table.component.html',
  styleUrls: ['./import-bh-data-table.component.scss'],
})
export class ImportBhDataTableComponent implements OnInit, OnChanges {
  @Input()
  bhData: SurveyData[]

  displayedColumns: string[]

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bhData) {
      if (this.bhData?.[0]) {
        this.displayedColumns = Object.keys(this.bhData[0])
      }
    }
  }

  ngOnInit(): void {}

  getColTitle(key: string) {
    return getTechniqueColumnTitle(key, true)
  }
}
