import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ImportedBorehole, Jobsite } from '../../models'
import { ddToDmsLat, ddToDmsLng, parseExtentString } from '../../utils'
import { boundingExtent as BOUNDINGEXTENT, getCenter as GETCENTER } from 'ol/extent'
import Polygon, { fromExtent as FROMEXTENT } from 'ol/geom/Polygon'
import { fromLonLat as FROMLONLAT } from 'ol/proj'
import { JobsiteService, MessagesService, SoilSurveyService } from '../../remote-services'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin, iif, of } from 'rxjs'
import Swal from 'sweetalert2'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { defaultIfEmpty, map, switchMap } from 'rxjs/operators'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { allBoreholeTypes, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../../store/app/app.selectors'
import { AppUpdateSelectedJobsite } from '../../../store/app/app.actions'
import { UserConfigStateSelectors } from '../../../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-import-boreholes-preview',
  templateUrl: './import-boreholes-preview.component.html',
  styleUrls: ['./import-boreholes-preview.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ImportBoreholesPreviewComponent implements OnInit, OnChanges {
  @Input()
  selectedFiles: File[]

  @Input()
  selectedFileNames: string[]

  @Input()
  importedBoreholes: ImportedBorehole[]

  @Input()
  selectedJobsiteName: string

  @Input()
  datumDisplayName: string

  @Input()
  level: string

  @Input()
  loadingText: string

  @Output()
  updateSurveysToMapEvent: EventEmitter<{ deleteIds: string[]; newSurveys: SoilSurvey[] }> = new EventEmitter()

  @Output()
  finishAgsImportEvent: EventEmitter<Jobsite> = new EventEmitter()

  boreholesTableData: SoilSurvey[]
  validBoreholes: ImportedBorehole[]
  displayedColumns: string[]

  expandedElement: SoilSurvey

  private newJobsite: Jobsite
  private newJobsiteExtent: string
  private epsgCode: string | undefined

  allchecked: boolean

  importMode: 'OVERWRITE' | 'UPDATE'

  private STATUS_CODE = {
    0: 'Valid',
    1: 'Local Projection invalid',
    2: 'Outside of jobsite extent',
    3: 'Duplicate local coordinates found in files',
    4: 'Duplicate local coordinates found in existing jobsites',
    5: 'Duplicate local coordinates found in current jobsite',
    6: 'Must have valid name value or type value',
  }

  constructor(
    private jobsiteService: JobsiteService,
    private soilSurveyService: SoilSurveyService,
    private translateService: TranslateService,
    private messagesService: MessagesService,
    private store: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importedBoreholes &&
      changes.importedBoreholes.currentValue &&
      changes.importedBoreholes.currentValue.length > 0
    ) {
      this.initData()
      this.setValidBoreholes()
    }
  }

  ngOnInit() {
    this.displayedColumns = [
      'name',
      'type',
      'elevation',
      'water_level',
      'local_x',
      'local_y',
      'local_proj',
      'lon_coord',
      'lat_coord',
      'status',
      'expand',
    ]
  }

  getComments(hole_basic): string {
    return this.STATUS_CODE[hole_basic.status] || ''
  }

  private initData() {
    this.validBoreholes = []
    this.importMode = 'UPDATE'
  }

  private saveBoreholes(jobsiteId: string) {
    let surveyIdsToDelete: string[]
    this.soilSurveyService
      .getJobsiteSurveys(jobsiteId)
      .pipe(
        switchMap((surveys: SoilSurvey[]) => {
          surveyIdsToDelete = surveys.filter(({ id }) => !!id).map(({ id }) => id as string)
          if (this.importMode === 'OVERWRITE') {
            return forkJoin(
              surveys
                .filter(({ id }) => !!id)
                .map(({ id }) => id as string)
                .map((id) => this.soilSurveyService.deleteTheSoilSurvey(jobsiteId, id)),
            ).pipe(defaultIfEmpty(null))
          } else {
            return of(null)
          }
        }),
        switchMap(() =>
          forkJoin(
            this.validBoreholes.map((borehole) =>
              this.soilSurveyService.createSoilSurvey(jobsiteId, borehole.survey_basic),
            ),
          ),
        ),
        switchMap((surveyIds: string[]) =>
          forkJoin(
            this.validBoreholes.map((borehole, index) =>
              this.soilSurveyService.saveSurveyData(jobsiteId, surveyIds[index], { surveyData: borehole.survey_data }),
            ),
          ).pipe(map(() => surveyIds)),
        ),
        switchMap((surveyIds: string[]) =>
          iif(
            () => this.level === 'borehole',
            forkJoin(surveyIds.map((id) => this.soilSurveyService.getSoilSurvey(id))),
            this.jobsiteService.getJobsiteById(jobsiteId),
          ),
        ),
        map((result: SoilSurvey[] | Jobsite) => {
          if (this.level === 'borehole') {
            const surveysArr = result as SoilSurvey[]
            const surveyNames = surveysArr.map((item) => item.name)
            this.updateSurveysToMapEvent.emit({
              deleteIds: this.importMode === 'OVERWRITE' ? surveyIdsToDelete : [],
              newSurveys: surveysArr,
            })
            return surveyNames.toString()
          } else {
            this.finishAgsImportEvent.emit(result as Jobsite)
            return (result as Jobsite).nickname
          }
        }),
        switchMap((message: string) =>
          iif(
            () => this.level === 'borehole',
            this.messagesService.saveJobsiteMessageByJobsiteId$(jobsiteId, message, 'boreholes', 'Imported from files'),
            this.messagesService.saveJobsiteMessageByJobsiteId$(
              jobsiteId,
              message,
              'jobsite',
              'imported a new jobsite via files',
            ),
          ),
        ),
      )
      .subscribe(() => {
        if (this.level === 'borehole') {
          Swal.fire({
            text: this.translateService.instant('FILE_IMPORT.SUCCESS_MESSAGE'),
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          })
        }
      })
  }

  private updateJobsiteEpsgCode(code: string | undefined) {
    const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    if (code && selectedJobsite && selectedJobsite.id && selectedJobsite?.epsg_code !== code) {
      const jobsite = {
        ...selectedJobsite,
        epsg_code: code,
      }
      this.store.dispatch(
        new AppUpdateSelectedJobsite(jobsite, `saved the projection as EPSG:${jobsite.epsg_code} for the`),
      )
    }
  }

  importBoreholes() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }

    if (this.level === 'borehole') {
      this.updateJobsiteEpsgCode(this.epsgCode)
      const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
      if (selectedJobsiteId) {
        if (this.importMode === 'OVERWRITE') {
          Swal.fire({
            title: this.translateService.instant('GENERAL.SURE'),
            text: 'Jobsite surveys will be overwritten',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
            confirmButtonText: this.translateService.instant('GENERAL.IMPORT'),
          }).then((result) => {
            if (result.value && this.validBoreholes.length > 0) {
              this.saveBoreholes(selectedJobsiteId)
            }
          })
        } else if (this.importMode === 'UPDATE') {
          if (this.validBoreholes?.length > 0) {
            this.saveBoreholes(selectedJobsiteId)
          }
        }
      }
    } else if (this.level === 'jobsite' && this.newJobsite && this.selectedJobsiteName) {
      this.jobsiteService.createJobsite(this.newJobsite).subscribe((jobsiteId) => {
        this.validBoreholes = this.validBoreholes.map((ele) => {
          ele.survey_basic.jobsite_id = jobsiteId
          return ele
        })
        this.saveBoreholes(jobsiteId)
      })
    }
  }

  ddToDmsLng(v) {
    if (v) {
      return ddToDmsLng(v)
    }
    return null
  }

  ddToDmsLat(v) {
    if (v) {
      return ddToDmsLat(v)
    }
    return null
  }

  private setValidBoreholes() {
    this.setBoreholeStatus()
    this.boreholesTableData = this.importedBoreholes.map((item) => item.survey_basic)
    this.validBoreholes = this.importedBoreholes.filter((hole) => hole.survey_basic.status === 0)
    this.checkBoreholesStatus()
  }

  private setBoreholeStatus() {
    this.importedBoreholes = this.importedBoreholes.map((hole, index, array) => {
      if (!hole.survey_basic.name || !hole.survey_basic.type || !allBoreholeTypes().includes(hole.survey_basic.type)) {
        hole.survey_basic.status = 6
      } else if (!this.isBoreholeHasCoords(hole.survey_basic)) {
        hole.survey_basic.status = 1
      } else if (!this.isPointInsideExtent([hole.survey_basic.lon_coord, hole.survey_basic.lat_coord])) {
        hole.survey_basic.status = 2
      } else if (this.isPointDuplicated(array, index, hole.survey_basic)) {
        hole.survey_basic.status = 3
      } else {
        hole.survey_basic.status = 0
      }
      return hole
    })
  }

  private isPointInsideExtent(coord) {
    const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    if (selectedJobsite?.extent) {
      const extentString = parseExtentString(selectedJobsite.extent)
      if (!extentString) {
        return false
      }
      const extentGeometry = new Polygon([extentString])
      return extentGeometry.intersectsCoordinate(FROMLONLAT(coord))
    }
    return true
  }

  private checkBoreholesStatus() {
    this.epsgCode = this.validBoreholes?.[0]?.survey_basic?.projection_ref ?? undefined
    if (this.level === 'jobsite' && this.validBoreholes.length > 0) {
      const boreholeCoordinates: [number, number][] = this.validBoreholes.map(
        (ele) => [ele.survey_basic.lon_coord, ele.survey_basic.lat_coord] as [number, number],
      )
      if (boreholeCoordinates.length === 1) {
        boreholeCoordinates.push([boreholeCoordinates[0][0] + 0.001, boreholeCoordinates[0][1] + 0.001])
      }
      const extent = BOUNDINGEXTENT(boreholeCoordinates)
      const polygon = FROMEXTENT(extent)
      polygon.scale(1.2)
      const extentCoords = polygon.getCoordinates()[0]
      const center = GETCENTER(extent)
      this.newJobsiteExtent =
        'POLYGON((' + extentCoords.map((a) => `${a[0]} ${a[1]}`).reduce((acc, curr) => `${acc}, ${curr}`) + '))'

      this.newJobsite = {
        nickname: this.selectedJobsiteName,
        lon_coord: center[0],
        lat_coord: center[1],
        epsg_code: this.epsgCode,
        extent:
          'POLYGON((' + extentCoords.map((a) => `${a[0]} ${a[1]}`).reduce((acc, curr) => `${acc}, ${curr}`) + '))',
      }
      if (this.validBoreholes.length > 0) {
        this.soilSurveyService.searchVisibleSoilSurveys(this.newJobsiteExtent).subscribe((surveys: SoilSurvey[]) => {
          this.importedBoreholes = this.importedBoreholes.map((hole) => {
            if (hole.survey_basic.status === 0) {
              for (const survey of surveys) {
                if (
                  Number(hole.survey_basic.local_x) === Number(survey.local_x) &&
                  Number(hole.survey_basic.local_y) === Number(survey.local_y)
                ) {
                  hole.survey_basic.status = 4
                  break
                }
              }
            }
            return hole
          })
          this.validBoreholes = this.importedBoreholes.filter((hole) => hole.survey_basic.status === 0)
          this.allchecked = true
        })
      } else {
        this.allchecked = true
      }
    } else {
      const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
      if (this.level === 'borehole' && selectedJobsite && selectedJobsite.extent) {
        const jobsiteId = selectedJobsite.id
        if (this.validBoreholes.length > 0 && jobsiteId) {
          this.soilSurveyService.getJobsiteSurveys(jobsiteId).subscribe((surveys: SoilSurvey[]) => {
            this.importedBoreholes = this.importedBoreholes.map((hole) => {
              if (hole.survey_basic.status === 0) {
                for (const survey of surveys) {
                  if (
                    Number(hole.survey_basic.local_x) === Number(survey.local_x) &&
                    Number(hole.survey_basic.local_y) === Number(survey.local_y)
                  ) {
                    hole.survey_basic.status = 5
                    break
                  }
                }
              }
              return hole
            })
            this.validBoreholes = this.importedBoreholes.filter((hole) => hole.survey_basic.status === 0)
            this.allchecked = true
          })
        } else {
          this.allchecked = true
        }
      }
    }
  }

  OnImportModeChange(event: MatButtonToggleChange) {
    this.importMode = event.value
    if (this.importMode === 'OVERWRITE') {
      this.validBoreholes = this.importedBoreholes.filter(
        (hole) => hole.survey_basic.status === 0 || hole.survey_basic.status === 5,
      )
    } else if (this.importMode === 'UPDATE') {
      this.validBoreholes = this.importedBoreholes.filter((hole) => hole.survey_basic.status === 0)
    }
  }

  setGray(status: number) {
    if (this.importMode === 'OVERWRITE' && status === 5) {
      return false
    }
    return status
  }

  private isBoreholeHasCoords(hole_basic) {
    return hole_basic && hole_basic.lon_coord !== null && hole_basic.lat_coord !== null
  }

  private isPointDuplicated(array: ImportedBorehole[], index, basic_survey: SoilSurvey) {
    for (let i = 0; i < index; i++) {
      if (
        array[i].survey_basic.local_x === basic_survey.local_x &&
        array[i].survey_basic.local_y === basic_survey.local_y
      ) {
        return true
      }
    }
    return false
  }
}
