import { Chart } from 'highcharts'

export function getUnifiedMarginTop(chartRef: Chart): number {
  const validAxes = chartRef.yAxis
    .map(({ series }) => series)
    .filter((series) => !!series?.length && !!series?.find((d) => !!d.data?.length))
  const yAxisHeight = 30 * validAxes.length
  const nbLegendItem = chartRef.legend?.allItems?.length || 0
  const legendHeight = nbLegendItem !== 0 ? 90 + 20 * Math.floor(nbLegendItem / 6) : 0
  return yAxisHeight + legendHeight
}
