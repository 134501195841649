<div
  class="content"
  *ngIf="{
    isModifyingSurvey: isModifyingSurvey$ | async,
    validModifSurvey: validModifSurvey$ | async
  } as state"
>
  <ul fxLayout="row wrap" fxLayoutGap="10px">
    <li
      *ngFor="let field of parsedSurvey; let index = index"
      fxFlex="calc(50%-10px)"
      (click)="parsedSurvey[1]['active'] = false; parsedSurvey[index]['active'] = true"
    >
      <div fxLayout="row wrap">
        <div class="name" fxFlex="50">{{ field.fieldName }}:</div>
        <div fxFlex="50" *ngIf="!field.active" class="editable-field" title="Cliquer pour modifier">
          {{ showFieldValue(field) }}
          <span class="overlay-icon"><mat-icon>edit</mat-icon></span>
        </div>
        <div fxFlex *ngIf="field.active && field.fieldName !== 'Type'" class="value">
          <input
            #editedField
            focus-input
            type="text"
            [value]="field.fieldValue || ''"
            (keyup.enter)="savedEditField(index, editedField.value); handleBlurInput(index)"
            (blur)="handleBlurInput(index)"
          />
          <div class="save-options" tabindex="1">
            <button type="submit" (click)="savedEditField(index, editedField.value)">
              <i class="material-icons">done</i>
            </button>
            <button type="cancel">
              <i class="material-icons">cancel</i>
            </button>
          </div>
        </div>
        <div fxFlex *ngIf="field.active && field.fieldName === 'Type'" class="value">
          <mat-form-field style="width: 130px; margin-top: -30px">
            <mat-select
              #editedField
              [value]="field.fieldValue"
              (keyup.enter)="savedEditField(index, editedField.value); handleBlurInput(index)"
              multiple
            >
              <mat-option *ngFor="let type of allBoreholeTypes" [value]="type">{{
                showBoreholeTypeName(type)
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="save-options" tabindex="1" style="margin-top: -18px">
            <button type="submit" (click)="savedEditField(index, editedField.value); handleBlurInput(index)">
              <i class="material-icons">done</i>
            </button>
            <button type="cancel" (click)="handleBlurInput(index)">
              <i class="material-icons">cancel</i>
            </button>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div fxLayout="row" fxLayoutGap="5px">
    <button
      class="delete-button"
      mat-raised-button
      color="warn"
      (click)="deleteSurvey()"
      *ngIf="!state.isModifyingSurvey"
    >
      {{ 'SURVEY.DELETE' | translate }}
    </button>
    <button
      class="modify-button"
      mat-raised-button
      color="accent"
      (click)="moveSurvey()"
      *ngIf="!state.isModifyingSurvey"
    >
      {{ 'SURVEY.MOVE' | translate }}
    </button>
    <button
      mat-raised-button
      color="warn"
      (click)="saveMovedSurvey()"
      *ngIf="state.isModifyingSurvey"
      [ngClass]="{ disabled: !state.validModifSurvey }"
      [disabled]="!state.validModifSurvey"
    >
      {{ 'SURVEY.SAVE_COORDS' | translate }}
    </button>
    <button mat-raised-button (click)="cancelMovedSurvey()" *ngIf="state.isModifyingSurvey">
      {{ 'GENERAL.CANCEL' | translate }}
    </button>
  </div>
</div>
