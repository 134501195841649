import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { ComplexChart, ComplexChartDTO } from '../models'
import { map } from 'rxjs/operators'

@Injectable()
export class UserChartsService {
  constructor(private http: HttpClient, private keycloakService: KeycloakService) {}
  getUserCharts(jobsiteId: string): Observable<ComplexChartDTO[]> {
    return this.http.get<ComplexChartDTO[]>(`api/charts/${this.keycloakService.getCurrentUser().id}/${jobsiteId}`)
  }

  saveUserCharts(jobsiteId: string, charts: ComplexChart[] | null): Observable<ComplexChartDTO[] | null> {
    if (charts?.length) {
      const chartsToSave = charts.map((chart, index) => ({
        user_id: this.keycloakService.getCurrentUser().id,
        jobsite_id: jobsiteId,
        chart_index: index,
        chart_name: chart.chartName,
        data: chart.items.map((data) => {
          return {
            survey_name: data.surveyName,
            param: data.param,
            color: data.color,
            survey_id: data?.rawData?.[0]?.soilsurvey_id || data?.labRawData?.[0]?.soilsurvey_id,
          }
        }),
      }))
      return this.http.post<ComplexChartDTO[]>('api/charts', chartsToSave)
    }
    if (charts?.length === 0) {
      return this.http
        .delete(`api/charts/${this.keycloakService.getCurrentUser().id}/${jobsiteId}`)
        .pipe(map(() => null))
    }
    return of(null)
  }

  deleteChart(jobsiteId: string, chartId: string) {
    return this.http.delete(`api/charts/${this.keycloakService.getCurrentUser().id}/${jobsiteId}/${chartId}`)
  }
}
