export function getAutoFillZone<T extends { depth?: number | null }>(
  surveysData: T[][] | null,
  step: number,
  availableParams: string[],
): Record<string, unknown>[] {
  if (!surveysData) {
    return []
  }
  const depthMaxArray: number[] = surveysData.map(
    (surveyData) =>
      Math.max.apply(
        null,
        surveyData.map(({ depth }) => depth),
      ) as number,
  )
  const depthMax: number = Math.max.apply(null, depthMaxArray)
  const estimatedData: Record<string, unknown>[] = []
  for (let i = 0; i < depthMax; i += step) {
    estimatedData.push(getEstimatedValue<T>(i, i + step, surveysData, availableParams))
  }
  return estimatedData
}

function getEstimatedValue<T extends { depth?: number | null }>(
  toit: number,
  base: number,
  surveysData: T[][] | null,
  availableParams: string[],
): Record<string, number | null> {
  const averageObject: Record<string, number | null> = {}
  availableParams.forEach((para) => {
    if (para === 'toit') {
      averageObject[para] = toit
    } else if (para === 'base') {
      averageObject[para] = base
    } else if (para === 'colors') {
      averageObject[para] = null
    } else {
      const eleArray = surveysData
        ?.map((data) => data.filter((ele) => ele.depth != null && ele.depth > toit && ele.depth <= base))
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue))
      averageObject[para] = eleArray ? getAverage<T>(para, eleArray) : null
    }
  })
  return averageObject
}

export function getAverage<T>(parameter: string, arr: T[]): number | null {
  const valueArr = arr.map((val) => val[parameter]).filter((v) => v !== null)
  if (valueArr.length > 0) {
    let sum = 0
    for (const i in valueArr) {
      if (!isNaN(Number(valueArr[i]))) {
        sum += Number(valueArr[i])
      } else if (
        parameter === 'geo_soil' ||
        parameter === 'calculated_soil' ||
        parameter === 'geotechnical_engineer_description' ||
        parameter === 'calculated_compactness'
      ) {
        return valueArr[i]
      }
    }
    return sum / valueArr.length
  }
  return null
}
