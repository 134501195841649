import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { EpsgProjectionService } from '../../services'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { KnowProjModel } from '../../models'
import { allBoreholeTypes, getBoreholeTypeName } from '@sde-ild/ssd-soillib-lib'
import { UserConfigStateSelectors } from '../../../store/user-config/user-config.selectors'
import { Store } from '@ngxs/store'

@Component({
  selector: 'soillib-import-configuration',
  templateUrl: './import-configuration.component.html',
  styleUrls: ['./import-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  level: string

  @Input()
  detectedProjections: string[]

  @Input()
  detectedJobsiteNames: string[]

  @Input()
  detectedType: string

  @Input()
  customProjInfoText: string

  @Input()
  customProjErrorText: string

  @Input()
  selectedJobsiteName: string

  @Output()
  projectionSelectEvent: EventEmitter<string> = new EventEmitter()

  @Output()
  projectionCodeChangeEvent: EventEmitter<string> = new EventEmitter()

  @Output()
  jobsiteNameSelectEvent: EventEmitter<string> = new EventEmitter()

  @Output()
  boreholeTypeSelectEvent: EventEmitter<string> = new EventEmitter()

  configurationForm: FormGroup<{
    jobsiteName: FormControl<string | null>
    selectedJobsiteName: FormControl<string | null>
    selectedProj: FormControl<string | null>
    customProj: FormControl<string | null>
    selectedType: FormControl<string | null>
  }>

  AGS_Proj: KnowProjModel
  keys_proj: string[]
  boreholeTypes: string[]

  private subs: Subscription = new Subscription()

  constructor(
    private fb: FormBuilder,
    private epsgProjectionService: EpsgProjectionService,
    private translateService: TranslateService,
    private store: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.detectedProjections) {
      this.configurationForm?.patchValue({ selectedProj: this.getSelectedProjectionFromDetectedProjections() })
    }
    if (changes.detectedJobsiteNames && this.detectedJobsiteNames.length === 1) {
      this.configurationForm?.patchValue({ jobsiteName: this.detectedJobsiteNames[0] })
    }
    if (changes.detectedType) {
      this.configurationForm?.patchValue({ selectedType: this.detectedType })
    }
  }

  ngOnInit() {
    this.AGS_Proj = this.epsgProjectionService.Known_Projs
    this.keys_proj = Object.keys(this.AGS_Proj)
    this.boreholeTypes = allBoreholeTypes()

    this.configurationForm = this.fb.group({
      jobsiteName: this.fb.control(
        { value: this.selectedJobsiteName, disabled: !this.store.selectSnapshot(UserConfigStateSelectors.canWrite) },
        { updateOn: 'blur' },
      ),
      selectedJobsiteName: this.fb.control(this.selectedJobsiteName),
      selectedProj: this.fb.control(this.getSelectedProjectionFromDetectedProjections() ?? null),
      customProj: this.fb.control<string | null>(
        { value: null, disabled: !this.store.selectSnapshot(UserConfigStateSelectors.canWrite) },
        Validators.pattern('^([0-9]{4,5})$'),
      ),
      selectedType: this.fb.control(''),
    })
    ;[
      this.configurationForm.get('jobsiteName')?.valueChanges?.subscribe((value) => {
        if (value !== null) {
          this.onJobsiteNameChange(value)
        }
      }),
      this.configurationForm.get('selectedJobsiteName')?.valueChanges?.subscribe((value) => {
        if (value !== null) {
          this.onJobsiteNameSelectionChange(value)
        }
      }),
      this.configurationForm.get('selectedProj')?.valueChanges?.subscribe((value) => {
        if (value !== null) {
          this.onProjSelectionChange(value)
        }
      }),
      this.configurationForm.get('selectedType')?.valueChanges?.subscribe((value) => {
        if (value !== null) {
          this.onBoreholeTypeChange(value)
        }
      }),
    ]
      .filter((s) => !!s)
      .forEach((s) => this.subs.add(s))
  }

  getBoreholeTypeName(type) {
    return getBoreholeTypeName(type, this.translateService)
  }

  applyProjection() {
    const customProj = this.configurationForm.get('customProj')
    if (customProj && customProj.valid && customProj.value) {
      this.projectionCodeChangeEvent.emit(customProj.value)
      this.configurationForm.patchValue({ selectedProj: null }, { emitEvent: false })
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  private onJobsiteNameChange(selectedJobsiteName: string) {
    this.jobsiteNameSelectEvent.emit(selectedJobsiteName)
    this.configurationForm.patchValue({ selectedJobsiteName }, { emitEvent: false })
  }

  private onJobsiteNameSelectionChange(jobsiteName: string) {
    this.jobsiteNameSelectEvent.emit(jobsiteName)
    this.configurationForm.patchValue({ jobsiteName }, { emitEvent: false })
  }

  private onProjSelectionChange(projSelection: string) {
    this.projectionSelectEvent.emit(projSelection)
    this.configurationForm.patchValue({ customProj: null })
  }

  private onBoreholeTypeChange(selectedType: string) {
    this.boreholeTypeSelectEvent.emit(selectedType)
  }

  private getSelectedProjectionFromDetectedProjections(): string | undefined {
    return this.detectedProjections?.find((p) => this.keys_proj.includes(p))
  }
}
