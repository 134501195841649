import { Injector } from '@angular/core'
import VectorLayer from 'ol/layer/Vector'
import olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import { StyleService } from '../services/style.service'

export class SelectionLayer {
  sourceSelectionVecteur: VectorSource
  selectionVecteur: VectorLayer
  private styleService: StyleService

  constructor(private map: olMap, private injector: Injector) {
    this.styleService = this.injector.get(StyleService)
    this.sourceSelectionVecteur = new VectorSource({})
    this.selectionVecteur = new VectorLayer({
      source: this.sourceSelectionVecteur,
      style: this.styleService.getMarkLocationStyle(),
    })
    this.map.addLayer(this.selectionVecteur)
  }
}
