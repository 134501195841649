<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="panel-title">
        <mat-icon>settings</mat-icon>
        {{ 'FILE_IMPORT.CSV_CONFIG' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="row" fxLayoutGap="5px">
      <div fxFlex="30">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span
            ><b>{{ 'FILE_IMPORT.METADATA' | translate }}</b></span
          ><mat-icon matTooltipClass="line-break-tooltip" [matTooltip]="metaHelpText">help</mat-icon>
        </div>
        <form fxLayoutGap="10px" fxLayout="row wrap" fxLayoutAlign="center" [formGroup]="metaForm">
          <mat-form-field *ngFor="let key of metaKeys">
            <mat-label>{{ metaName(key) }}</mat-label>
            <mat-select [formControlName]="key">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let header of csvMetaHeaders" [value]="header">
                {{ header }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div fxFlex="65">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span
            ><b>{{ 'FILE_IMPORT.PARA_DATA' | translate }}</b></span
          ><mat-icon matTooltipClass="line-break-tooltip" [matTooltip]="paraHelpText">help</mat-icon>
        </div>
        <form fxLayoutGap="10px" fxLayout="row wrap" fxLayoutAlign="center" [formGroup]="paraForm">
          <mat-form-field *ngFor="let para of paraFormKeys">
            <mat-label>{{ paraName(para) }}</mat-label>
            <mat-select [formControlName]="para">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let header of csvParamHeaders" [value]="header">
                {{ header }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div fxFlex style="text-align: center">
        <button mat-raised-button color="warn" (click)="finishConfig()">OK</button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
