import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'soillib-edit-jobsite-draggable',
  templateUrl: './edit-jobsite-draggable.component.html',
  styleUrls: ['./edit-jobsite-draggable.component.scss'],
})
export class EditJobsiteDraggableComponent {
  @Output()
  closeJobsiteEditEvent = new EventEmitter<void>()

  @Output()
  handleJobsiteEditOptionChangeEvent = new EventEmitter<'extent' | 'coordinate'>()

  @Output()
  updateJobsiteExtentCoordEvent = new EventEmitter<'extent' | 'coordinate' | null>()

  value: 'extent' | 'coordinate' | null = null
}
