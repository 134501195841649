import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { CrossSectionService } from '../../../shared/services'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'

@Component({
  selector: 'soillib-creation-configuration',
  templateUrl: './creation-configuration.component.html',
  styleUrls: ['./creation-configuration.component.scss'],
})
export class CreationConfigurationComponent implements OnInit {
  @Input()
  originalBoreholes: SoilSurvey[]

  @Input()
  projectedBoreholes: SoilSurvey[]

  @Output()
  resizeSelectBoxEvent: EventEmitter<{ vertical: number; parallel: number }> = new EventEmitter()

  widthScaleValue = 1
  heightScaleValue = 1
  private subscription: Subscription = new Subscription()
  private _debounceSubject = new Subject()

  checked: boolean

  constructor(private crossSectionService: CrossSectionService) {}

  ngOnInit(): void {
    this.checked = this.crossSectionService.canCrossJobsite
    this.subscription.add(
      this._debounceSubject
        .pipe(debounceTime(800))
        .subscribe((scale: { vertical: number; parallel: number }) => this.resizeSelectBoxEvent.emit(scale)),
    )
  }

  toggleCrossJobsite(event: MatSlideToggleChange) {
    this.crossSectionService.canCrossJobsite = event.checked
  }

  handleWidthScale(value) {
    this.widthScaleValue = value
    this._debounceSubject.next({ vertical: this.widthScaleValue, parallel: this.heightScaleValue })
  }

  handleHeightScale(value) {
    this.heightScaleValue = value
    this._debounceSubject.next({ vertical: this.widthScaleValue, parallel: this.heightScaleValue })
  }

  increaseWidth() {
    // step = 1
    this.widthScaleValue++
    this._debounceSubject.next({ vertical: this.widthScaleValue, parallel: this.heightScaleValue })
  }
  reduceWidth() {
    // step = 0.02
    this.widthScaleValue -= 0.02
    this._debounceSubject.next({ vertical: this.widthScaleValue, parallel: this.heightScaleValue })
  }

  increaseHeight() {
    this.heightScaleValue++
    this._debounceSubject.next({ vertical: this.widthScaleValue, parallel: this.heightScaleValue })
  }

  reduceHeight() {
    this.heightScaleValue -= 0.02
    this._debounceSubject.next({ vertical: this.widthScaleValue, parallel: this.heightScaleValue })
  }

  formatWLabel(value: number): string {
    return (value * 70).toFixed(0)
  }

  formatHLabel(value: number): string {
    return (value * this.crossSectionService.getOriginalHeight()).toFixed(0)
  }
}
