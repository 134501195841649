import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core'
import { MaterialModule } from './material/material.module'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MainMapComponent } from './main-map/main-map.component'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { ScrollToDirective } from './shared/directives/ScrollToDirective'
import { FocusInputDirective } from './shared/directives/FocusInputDirective'
import { DraggableDirective } from './shared/directives/DraggableDirective'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { routing } from './app.routing'
import {
  MapService,
  DataTableService,
  CrossSectionService,
  FileParseService,
  EpsgProjectionService,
  TableAutoSaveService,
  SbtService,
} from './shared/services'
import {
  JobsiteService,
  SoilSurveyService,
  ZoneService,
  UserChartsService,
  MessagesService,
  FilesService,
  CrossSectionGraphService,
  SoilStatsService,
} from './shared/remote-services'
import { FlexLayoutModule } from '@angular/flex-layout'
import { HotTableModule } from '@handsontable/angular'
import { DragulaModule } from 'ng2-dragula'
import { AddRowsDialog } from './shared/components/add-rows-dialog/add-rows-dialog.component'
import { SelectColumnsDialog } from './shared/components/select-columns-dialog/select-columns-dialog'
import { NavHeaderComponent } from './nav-header/nav-header.component'
import { FilterComponent } from './filter/filter.component'
import { SearchBoxComponent } from './search-box/search-box.component'
import { SurveysEditionComponent } from './surveys-edition/surveys-edition.component'
import { SurveysEditionDataTableComponent } from './surveys-edition/surveys-edition-data-table/surveys-edition-data-table.component'
import { SurveysEditionChartComponent } from './surveys-edition/surveys-edition-chart/surveys-edition-chart.component'
import { SoilCuttingsEditionComponent } from './soil-cuttings-edition/soil-cuttings-edition.component'
import { SoilCuttingsDataTableComponent } from './soil-cuttings-edition/soil-cuttings-data-table/soil-cuttings-data-table.component'
import { SurveysComparisonComponent } from './surveys-comparison/surveys-comparison.component'
import { LoadingInterceptor } from './shared/interceptors/http-loading.interceptor'
import { LoggerInterceptor } from './shared/interceptors/http-logger.interceptor'
import { KeycloakInterceptor } from './shared/interceptors/http-keycloak.interceptor'
import { LoaderComponent } from './shared/components/loader/loader.component'
import { SurveysComparisonChartComponent } from './surveys-comparison/surveys-comparison-chart/surveys-comparison-chart.component'
import { SurveysComparisonComplexChartsComponent } from './surveys-comparison/surveys-comparison-complex-charts/surveys-comparison-complex-charts.component'
import { SurveysComparisonComplexChartComponent } from './surveys-comparison/surveys-comparison-complex-charts/surveys-comparison-complex-chart/surveys-comparison-complex-chart.component'
import { KeycloakService } from './keycloak/keycloak.service'
import { MessagesListComponent } from './nav-header/messages/messages-list.component'
import { MessageComponent } from './nav-header/messages/message/message.component'
import { StatsTableDialog } from './shared/components/stats-table-dialog/stats-table-dialog'
import { SearchAddressDraggableComponent } from './shared/components/search-address-draggable/search-address-draggable.component'
import { NewJobsiteDraggableComponent } from './shared/components/new-jobsite-draggable/new-jobsite-draggable.component'
import { ReportListComponent } from './shared/components/report-list/report-list.component'
import { ReportUploadProgressComponent } from './shared/components/report-upload-progress/report-upload-progress.component'
import { SurveysImportAGSComponent } from './surveys-edition/surveys-import-ags/surveys-import-ags.component'
import { JobsiteBoxComponent } from './jobsite-box/jobsite-box.component'
import { AGSImportDialog } from './shared/components/ags-import-dialog/ags-import-dialog.component'
import { GlobalErrorHandler } from './shared/error-handling/global-error-handler'
import { SurveysImportCsvComponent } from './surveys-edition/surveys-import-csv/surveys-import-csv.component'
import { ImportConfigurationComponent } from './shared/components/import-configuration/import-configuration.component'
import { CSVImportDialog } from './shared/components/csv-import-dialog/csv-import-dialog.component'
import { ImportBoreholesPreviewComponent } from './shared/components/import-boreholes-preview/import-boreholes-preview.component'
import * as Sentry from '@sentry/angular'
import { MapTableResizeButtonComponent } from './shared/components/map-table-resize-button/map-table-resize-button.component'
import { SurveysLabtestDataTableComponent } from './surveys-edition/surveys-labtest-data-table/surveys-labtest-data-table.component'
import { CrossSectionCreationComponent } from './soil-cuttings-edition/cross-section-creation/cross-section-creation.component'
import { CrossSectionListComponent } from './soil-cuttings-edition/cross-section-list/cross-section-list.component'
import { GoogleAnalyticsService } from './shared/google-analytics/google-analytics.service'
import { CreationConfigurationComponent } from './soil-cuttings-edition/cross-section-creation/creation-configuration/creation-configuration.component'
import { OverlaysComponent } from './main-map/overlays/overlays.component'
import { VisibleInfoBoxComponent } from './main-map/visible-info-box/visible-info-box.component'
import { EditJobsiteDraggableComponent } from './shared/components/edit-jobsite-draggable/edit-jobsite-draggable.component'
import { StyleService } from './main-map/services/style.service'
import { JobsiteStatisticsComponent } from './jobsite-statistics/jobsite-statistics.component'
import { ImportCsvConfigComponent } from './shared/components/import-csv-config/import-csv-config.component'
import { ImportBhDataTableComponent } from './shared/components/import-bh-data-table/import-bh-data-table.component'
import { ImportSelectFilesComponent } from './shared/components/import-select-files/import-select-files.component'
import { SurveysEditionInfoBoxComponent } from './surveys-edition/surveys-edition-info-box/surveys-edition-info-box.component'
import { SurveyCreateFormComponent } from './surveys-edition/survey-create-form/survey-create-form.component'
import { SsdSoillibLibTranslateLoader, SsdSoillibLibModule } from '@sde-ild/ssd-soillib-lib'
import * as merge from 'deepmerge'
import { forkJoin, from, map, Observable } from 'rxjs'
import { SbtnParametersDialog } from './shared/components/sbtn-parameters-dialog/sbtn-parameters-dialog.component'
import { SbtnResultsChartsComponent } from './surveys-edition/sbtn-results-charts/sbtn-results-charts.component'
import { SbtnChartTemplateComponent } from './shared/components/sbtn-chart-template/sbtn-chart-template.component'
import { SbtnLegendDraggableComponent } from './shared/components/sbtn-legend-draggable/sbtn-legend-draggable.component'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { SbtnPorePressureChartComponent } from './shared/components/sbtn-pore-pressure-chart/sbtn-pore-pressure-chart.component'
import { HighchartsModule } from './highcharts/highcharts.module'
import { NgxsStoreModule } from './ngxs-store/ngxs-store.module'
import { SurveysCorrelationDataModule } from './surveys-correlation-data/surveys-correlation-data.module'
import { SurveysComparisonService } from './surveys-comparison/service/surveys-comparison.service'

class MultiTranslateLoader implements TranslateLoader {
  constructor(private ssdSoillibLibTranslateLoader: SsdSoillibLibTranslateLoader) {}

  public getTranslation(lang: string): Observable<any> {
    const requests = [
      from(import(`../assets/i18n/${lang}.json`)),
      this.ssdSoillibLibTranslateLoader.getTranslation(lang),
    ]
    return forkJoin(requests).pipe(
      map((response) => response.map((r: any) => ({ ...r }))),
      map((response) => merge.all(response)),
    )
  }
}

function createMultiTranslateFactory(ssdSoillibLibTranslateLoader: SsdSoillibLibTranslateLoader) {
  return new MultiTranslateLoader(ssdSoillibLibTranslateLoader)
}

@NgModule({
  declarations: [
    AppComponent,
    MainMapComponent,
    ScrollToDirective,
    FocusInputDirective,
    DraggableDirective,
    AddRowsDialog,
    NavHeaderComponent,
    FilterComponent,
    SearchBoxComponent,
    SurveysEditionComponent,
    SurveysEditionDataTableComponent,
    SurveysLabtestDataTableComponent,
    SurveysEditionChartComponent,
    SoilCuttingsEditionComponent,
    SoilCuttingsDataTableComponent,
    SurveysComparisonComponent,
    LoaderComponent,
    SurveysComparisonChartComponent,
    SurveysComparisonComplexChartsComponent,
    SurveysComparisonComplexChartComponent,
    SelectColumnsDialog,
    MessagesListComponent,
    MessageComponent,
    StatsTableDialog,
    SearchAddressDraggableComponent,
    NewJobsiteDraggableComponent,
    ReportListComponent,
    ReportUploadProgressComponent,
    SurveysImportAGSComponent,
    JobsiteBoxComponent,
    AGSImportDialog,
    CSVImportDialog,
    SurveysImportCsvComponent,
    ImportConfigurationComponent,
    ImportBoreholesPreviewComponent,
    MapTableResizeButtonComponent,
    CrossSectionCreationComponent,
    CrossSectionListComponent,
    CreationConfigurationComponent,
    OverlaysComponent,
    VisibleInfoBoxComponent,
    EditJobsiteDraggableComponent,
    JobsiteStatisticsComponent,
    ImportCsvConfigComponent,
    ImportBhDataTableComponent,
    ImportSelectFilesComponent,
    SurveysEditionInfoBoxComponent,
    SurveyCreateFormComponent,
    SbtnParametersDialog,
    SbtnResultsChartsComponent,
    SbtnChartTemplateComponent,
    SbtnLegendDraggableComponent,
    SbtnPorePressureChartComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SsdSoillibLibModule,
    routing,
    FlexLayoutModule,
    HotTableModule.forRoot(),
    DragulaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createMultiTranslateFactory,
        deps: [SsdSoillibLibTranslateLoader],
      },
    }),
    HighchartsModule,
    NgxsStoreModule,
    SurveysCorrelationDataModule,
  ],
  providers: [
    MapService,
    CrossSectionService,
    JobsiteService,
    SoilSurveyService,
    ZoneService,
    KeycloakService,
    UserChartsService,
    DataTableService,
    MessagesService,
    FilesService,
    FileParseService,
    EpsgProjectionService,
    TableAutoSaveService,
    CrossSectionGraphService,
    GoogleAnalyticsService,
    StyleService,
    SoilStatsService,
    SbtService,
    SurveysComparisonService,
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
