import { Injectable } from '@angular/core'
import { Fill, RegularShape, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { Color } from 'ol/color'
import { ColorLike } from 'ol/colorlike'
import { StyleLike } from 'ol/style/Style'

@Injectable()
export class StyleService {
  crossSectionThemeColor = 'magenta'

  getModifyStyle() {
    return [
      new Style({
        fill: new Fill({
          color: [255, 255, 255, 0.5],
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 1],
          width: 5,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: [0, 153, 255, 1],
          width: 3,
        }),
      }),
    ]
  }

  getSelectedZoneStyle() {
    const color = [255, 215, 0]
    const fillColor = [255, 215, 0, 0.1]
    return new Style({
      stroke: new Stroke({
        color,
        width: 3,
      }),
      fill: new Fill({ color: fillColor }),
    })
  }

  getCrossSectionStyle() {
    return new Style({
      stroke: new Stroke({
        color: this.crossSectionThemeColor,
        width: 3,
        lineDash: [5, 5], // [dashLength, dashSpace]
      }),
    })
  }

  getCrossSectionCenterLineStyle() {
    return new Style({
      stroke: new Stroke({
        color: this.crossSectionThemeColor,
        width: 5,
      }),
    })
  }

  getSurveyModifyStyle(type): StyleLike {
    return this.getStyle(type, [0, 153, 255, 0.8], 'white', 1)
  }

  getGreenSurveyStyle(type): StyleLike {
    return this.getStyle(type, '#7FFF00', 'black', 1)
  }

  getOriginalBoreholeStyle(type): StyleLike {
    return this.getStyle(type, this.crossSectionThemeColor, 'white', 2)
  }

  getProjectedBoreholeStyle(type): StyleLike {
    return this.getStyle(type, this.crossSectionThemeColor, 'black', 2)
  }

  getBoreholeHoverStyle(type): StyleLike {
    return this.getStyle(type, this.crossSectionThemeColor, 'yellow', 3)
  }

  getMarkLocationStyle() {
    return new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({
          color: 'red',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2,
          lineDash: [0.1, 5],
        }),
      }),
    })
  }

  getJobsiteCenterStyle() {
    return new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: [0, 0, 255, 0.8],
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2,
        }),
      }),
    })
  }

  getJobsiteCenterModifyStyle() {
    return [
      new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: 'blue',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3,
            lineDash: [0.1, 5],
          }),
        }),
      }),
    ]
  }

  getJobsiteExtentStyle() {
    return new Style({
      stroke: new Stroke({
        width: 3,
        color: 'white',
        lineDash: [0.1, 5],
      }),
      fill: new Fill({
        color: [255, 255, 255, 0.1],
      }),
    })
  }

  getProjectionLineStyle() {
    return new Style({
      stroke: new Stroke({
        width: 3,
        color: 'black',
        lineDash: [2, 5],
      }),
    })
  }

  getStyle(type: string, color: number[] | string, strokeColor: Color | ColorLike, strokeWidth: number): StyleLike {
    let style
    if (type.includes(',')) {
      // Style for composite borehole
      style = [
        new Style({
          image: new RegularShape({
            fill: new Fill({ color }),
            stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
            points: 6,
            radius: 12,
            radius2: 8,
            angle: 0,
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 6,
            stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
          }),
        }),
      ]
    } else {
      switch (type) {
        case 'CPT': // square
        case 'CPT-HK':
          style = [
            new Style({
              image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
                points: 4,
                radius: 8,
                angle: Math.PI / 4,
              }),
            }),
          ]
          break
        case 'PRESSIO': // star
          style = [
            new Style({
              image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
                points: 5,
                radius: 10,
                radius2: 4,
                angle: 0,
              }),
            }),
          ]
          break
        case 'CAROTTE': // triangle
          style = [
            new Style({
              image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
                points: 3,
                radius: 10,
                rotation: 0,
                angle: 0,
              }),
            }),
          ]
          break
        case 'DESTRUCTIF':
          style = [
            new Style({
              image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
                points: 4,
                radius: 10,
                radius2: 3,
                angle: 0,
              }),
            }),
          ]
          break
        case 'PIEZO':
          style = [
            new Style({
              image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
                points: 6,
                radius: 12,
                radius2: 4,
                angle: 0,
              }),
            }),
          ]
          break
        case 'PUITS':
          style = [
            new Style({
              image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
                points: 6,
                radius: 9,
                radius2: 8,
                angle: 0,
              }),
            }),
          ]
          break

        default:
          style = [
            new Style({
              image: new CircleStyle({
                radius: 8,
                fill: new Fill({ color: 'white' }),
                stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
              }),
            }),
          ]
          break
      }
    }

    return style
  }
}
