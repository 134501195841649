import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import {
  LabTestDataModel,
  SurveysTableDataType,
  SBTnParametersModel,
  SurveySBTnResultsModel,
  SoilSurveyDataResponseModel,
} from '../models'
import { SoilSurvey, SurveyData } from '@sde-ild/ssd-soillib-lib'
import { map } from 'rxjs/operators'
import { CorrelationDataService } from './correlation-data.service'

@Injectable()
export class SoilSurveyService {
  constructor(private http: HttpClient, private correlationDataService: CorrelationDataService) {}

  searchSoilSurveys(lat: number, lon: number, radius: number): Observable<SoilSurvey[]> {
    return this.http.get<SoilSurvey[]>(`api/soil_surveys/geoloc?lat=${lat}&lon=${lon}&radius=${radius}`)
  }

  searchVisibleSoilSurveys(polygon: string): Observable<SoilSurvey[]> {
    return this.http.get<SoilSurvey[]>(`api/soil_surveys/geoloc/visible?polygon=${polygon}`)
  }

  getJobsiteSurveys(jobsiteId: string): Observable<SoilSurvey[]> {
    return this.http.get<SoilSurvey[]>(`api/jobsites/${jobsiteId}/soil_surveys`)
  }

  getSoilSurvey(soil_surveyId: string): Observable<SoilSurvey> {
    return this.http.get<SoilSurvey>(`api/soil_surveys/${soil_surveyId}`)
  }

  createSoilSurvey(jobsiteId: string, soil_survey: SoilSurvey): Observable<string> {
    return this.http.post<string>(`api/soil_surveys/jobsite/${jobsiteId}`, soil_survey)
  }

  updateSoilSurvey(jobsiteId: string, soil_surveyId: string, soil_survey: SoilSurvey): Observable<unknown> {
    return this.http.put(`api/soil_surveys/jobsite/${jobsiteId}/${soil_surveyId}`, soil_survey)
  }

  deleteSoilSurvey(selectedSurvey: SoilSurvey): Observable<unknown | null> {
    const jobsiteId = selectedSurvey.jobsite_id
    const id = selectedSurvey.id
    return jobsiteId && id ? this.deleteTheSoilSurvey(jobsiteId, id) : of(null)
  }

  deleteTheSoilSurvey(jobsiteId: string, id: string): Observable<unknown> {
    return this.http.delete(`api/soil_surveys/jobsite/${jobsiteId}/${id}`)
  }

  saveSurveyData(
    jobsiteId: string,
    surveyId: string,
    surveyData: { surveyData: SurveysTableDataType[]; sbtnParameters?: SBTnParametersModel },
  ): Observable<SoilSurveyDataResponseModel> {
    return this.http
      .post<SoilSurveyDataResponseModel>(`api/soil_surveys/jobsite/${jobsiteId}/${surveyId}/data`, surveyData)
      .pipe(map((data) => this.scale(data)))
  }

  getSurveyData(surveyId: string): Observable<SurveyData[]> {
    return this.http.get<SurveyData[]>(`api/soil_surveys/${surveyId}/data`)
  }

  /****************lab test data***************/
  getLabTestDataBySurveyId(surveyId: string): Observable<LabTestDataModel[]> {
    return this.http.get<LabTestDataModel[]>(`api/soil_surveys/${surveyId}/labtestdata`)
  }

  saveLabTestDataToSurvey(surveyId: string, labtestdata: LabTestDataModel[]) {
    return this.http.post(`api/soil_surveys/${surveyId}/labtestdata`, labtestdata)
  }

  /****************SBTn results****************/
  getSBTnResults(surveyId: string): Observable<SurveySBTnResultsModel[]> {
    return this.http.get<SurveySBTnResultsModel[]>(`api/soil_surveys/${surveyId}/sbtn`)
  }

  refreshSBTnResults(surveyId: string, params: SBTnParametersModel): Observable<SoilSurveyDataResponseModel> {
    return this.http
      .post<SoilSurveyDataResponseModel>(`api/soil_surveys/${surveyId}/sbtn`, params)
      .pipe(map((data) => this.scale(data)))
  }

  private scale = (model: SoilSurveyDataResponseModel): SoilSurveyDataResponseModel => ({
    ...model,
    correlationData: model.correlationData.map((value) => this.correlationDataService.scale(value)),
  })
}
