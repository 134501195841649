export function getCSVGeoKeyWord(csvkey) {
  const jobsiteNameKeys = ['jobsite name']
  const nameKeys = ['test number', 'bh name', 'borehole name']
  const eastingKeys = ['e coordinate']
  const northingKeys = ['n coordinate']
  const elevationKeys = ['ground level', 'groundlevel', 'elevation']
  const waterlevelKeys = ['water level', 'waterlevel']
  if (nameKeys.includes(csvkey)) {
    return 'name'
  } else if (jobsiteNameKeys.includes(csvkey)) {
    return 'jobsite_name'
  } else if (eastingKeys.includes(csvkey)) {
    return 'local_x'
  } else if (northingKeys.includes(csvkey)) {
    return 'local_y'
  } else if (elevationKeys.includes(csvkey)) {
    return 'elevation'
  } else if (waterlevelKeys.includes(csvkey)) {
    return 'water_level'
  }
  return csvkey
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export const metaKeys = ['jobsite_name', 'name', 'type', 'projection', 'local_x', 'local_y', 'elevation', 'water_level']

export const metaKeysToTitle = {
  name: 'BH Name',
  type: 'Type',
  local_x: 'Easting',
  local_y: 'Northing',
  elevation: 'Elevation',
  water_level: 'Water Level',
  jobsite_name: 'Jobsite Name',
  projection: 'Local Projection',
}
