<div fxLayout="row" fxLayoutGap="20px">
  <div fxFlex *ngIf="selectedFiles && importedBoreholes?.length === 0">{{ loadingText }}</div>
  <div fxFlex *ngIf="selectedFiles && importedBoreholes?.length > 0" class="imported-boreholes">
    <table mat-table [dataSource]="boreholesTableData" class="mat-elevation-z8" multiTemplateDataRows>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'SURVEY.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>
      <ng-container matColumnDef="local_x">
        <th mat-header-cell *matHeaderCellDef>{{ 'SURVEY.COORD_X' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.local_x }}</td>
      </ng-container>
      <ng-container matColumnDef="local_y">
        <th mat-header-cell *matHeaderCellDef>{{ 'SURVEY.COORD_Y' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.local_y }}</td>
      </ng-container>
      <ng-container matColumnDef="local_proj">
        <th mat-header-cell *matHeaderCellDef>{{ 'SURVEY.PROJ_REF' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.local_proj }}</td>
      </ng-container>
      <ng-container matColumnDef="lon_coord">
        <th mat-header-cell *matHeaderCellDef>Longitude</th>
        <td mat-cell *matCellDef="let element">{{ ddToDmsLng(element.lon_coord) }}</td>
      </ng-container>
      <ng-container matColumnDef="lat_coord">
        <th mat-header-cell *matHeaderCellDef>Latitude</th>
        <td mat-cell *matCellDef="let element">{{ ddToDmsLat(element.lat_coord) }}</td>
      </ng-container>

      <ng-container matColumnDef="elevation">
        <th mat-header-cell *matHeaderCellDef>{{ 'SURVEY.ELEVATION' | translate : { name: datumDisplayName } }}</th>
        <td mat-cell *matCellDef="let element">{{ element.elevation }}</td>
      </ng-container>
      <ng-container matColumnDef="water_level">
        <th mat-header-cell *matHeaderCellDef>{{ 'SURVEY.WATER_LEVEL' | translate : { name: datumDisplayName } }}</th>
        <td mat-cell *matCellDef="let element">{{ element.water_level }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon style="color: orange" *ngIf="element.status" matTooltip="{{ getComments(element) }}">
            warning
          </mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element !== expandedElement">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="element === expandedElement">keyboard_arrow_up</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <div class="data-content" *ngIf="element === expandedElement">
              <soillib-import-bh-data-table [bhData]="importedBoreholes[i].survey_data"></soillib-import-bh-data-table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        [class.gray]="setGray(element.status)"
        [class.expanded-row]="expandedElement === element"
        class="element-row"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </div>
  <div fxFlex="15" *ngIf="selectedFiles" style="text-align: center">
    <div *ngIf="level === 'borehole'">
      <p>
        <b>{{ 'GENERAL.PLEASE_CHOSE' | translate }}: </b>
      </p>
      <mat-raised-button-toggle-group [value]="importMode" (change)="OnImportModeChange($event)">
        <mat-raised-button-toggle style="width: 50%" value="UPDATE">{{
          'FILE_IMPORT.UPDATE' | translate
        }}</mat-raised-button-toggle>
        <mat-raised-button-toggle style="width: 50%" value="OVERWRITE">{{
          'FILE_IMPORT.OVERWRITE' | translate
        }}</mat-raised-button-toggle>
      </mat-raised-button-toggle-group>
      <p class="warning-text" *ngIf="importMode === 'OVERWRITE'">
        <b>{{ validBoreholes?.length }}</b> / {{ importedBoreholes?.length }} {{ 'COMPARISON.SURVEYS' | translate }}.
        <br />
        {{ 'FILE_IMPORT.OVERWRITE_MESSAGE' | translate }}
      </p>
      <p class="warning-text" *ngIf="importMode === 'UPDATE'">
        <b>{{ validBoreholes?.length }}</b> / {{ importedBoreholes?.length }} {{ 'COMPARISON.SURVEYS' | translate }}.
        <br />
        {{ 'FILE_IMPORT.UPDATE_MESSAGE' | translate }}
      </p>
    </div>

    <button
      mat-raised-button
      color="warn"
      style="margin-top: 20px"
      [disabled]="validBoreholes?.length === 0 || !allchecked || (level === 'jobsite' && !this.selectedJobsiteName)"
      (click)="importBoreholes()"
    >
      {{ 'GENERAL.IMPORT' | translate }}
    </button>
    <p *ngIf="level === 'jobsite'">
      <b>{{ validBoreholes?.length }}</b> / {{ importedBoreholes?.length }} {{ 'COMPARISON.SURVEYS' | translate }}
    </p>
  </div>
</div>
