<div class="table-container">
  <div class="table">
    <hot-table
      licenseKey="non-commercial-and-evaluation"
      [afterChange]="handleCellChanges"
      [hotId]="tableHotId"
      [settings]="tableSettings"
    ></hot-table>
  </div>
</div>

<div class="button-container" fxLayout="row">
  <div fxFlex="75">
    <button mat-raised-button color="primary" (click)="addRow()">{{ 'GENERAL.ADD_ROW' | translate }}</button>
    <button mat-raised-button color="primary" (click)="addRows()">{{ 'GENERAL.ADD_ROWS' | translate }}</button>
    <button mat-raised-button color="warn" [disabled]="this.isValidating" (click)="saveTableData()">
      {{ 'GENERAL.SAVE' | translate }}
    </button>
  </div>
  <div class="alert-message" *ngIf="isValidating">
    {{ 'TABLE.VALIDATE_TABLE_CELLS' | translate }}
  </div>
</div>
