<div fxLayout="column" class="messages-container">
  <span class="triangle-top"></span>
  <span>
    <span fxLayout="row" fxLayoutAlign="start center" class="messages-header">
      <div>Messages</div>
    </span>
  </span>
  <div class="messages-list" (scroll)="onScroll($event.target)">
    <soillib-message *ngFor="let message of messages" [message]="message"></soillib-message>
  </div>
  <div class="comments-input">
    <form [formGroup]="commentsForm">
      <mat-form-field>
        <input
          matInput
          formControlName="content"
          [placeholder]="'GENERAL.ADD_COMMENT' | translate"
          (keyup.enter)="saveComments()"
        />
      </mat-form-field>
    </form>
    <button
      mat-icon-button
      color="primary"
      (click)="saveComments()"
      [disabled]="!selectedJobsite || commentsForm.invalid"
    >
      <mat-icon>add_comment</mat-icon>
    </button>
  </div>
</div>
