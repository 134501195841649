export const environment = {
  disableLogging: true,
  production: true,
  platform: 'DEV',
  keycloakConfig: {
    url: 'https://login.sde-ild.soletanche-bachy.org/auth',
    realm: 'Dev',
    clientId: 'SoilLib',
    'ssl-required': 'external',
    'public-client': true,
  },
  gaEnabled: true,
  gaIdMesure: 'G-MYMW0YLZY8',
  uaId: 'UA-147463668-2',
  googleSearchApiKey: 'AIzaSyCiGbSZZ8hZW12O-TrngTRUfrZT1WTmdAU',
  sentry: {
    enabled: true,
    dsn: 'https://eb397870ac654822b8790059af10ad8c@glitchtip.sde-ild.soletanche-bachy.org/5',
    tracesSampleRate: 1.0,
  },
  documentation: {
    agsSoilTypes: 'https://support-ild.atlassian.net/wiki/spaces/ZHELP/pages/4063242/AGS+Soil+types',
  },
  features: {
    soilCompactness: false,
  },
}
