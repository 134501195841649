<div class="title-container">
  <div class="title">{{ 'CROSS_SECTION.CREATE' | translate }}</div>
  <mat-slide-toggle [checked]="checked" (change)="toggleCrossJobsite($event)" color="warn">{{
    'CROSS_SECTION.CROSS_JOBISTE' | translate
  }}</mat-slide-toggle>
</div>

<p>{{ 'CROSS_SECTION.SELECT_BOREHOLES' | translate }}</p>
<ul fxLayout="row warp" fxLayoutGap="30px">
  <li fxFlex *ngFor="let ob of originalBoreholes" class="original-borehole">{{ ob.name }}</li>
</ul>
<p>{{ 'CROSS_SECTION.ADJUST_SIZE' | translate }}</p>
<div class="sliderBar">
  <div>
    <b>{{ 'CROSS_SECTION.WIDTH' | translate }}</b>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="sliderBtn" fxFlex="20px">
      <button
        [ngClass]="{ disabled: originalBoreholes?.length !== 2 && widthScaleValue > 0 }"
        [disabled]="originalBoreholes?.length !== 2 && widthScaleValue > 0"
        mat-mini-fab
        color="warn"
        (click)="reduceWidth()"
      >
        <mat-icon>remove</mat-icon>
      </button>
    </div>
    <mat-slider
      fxFlex
      thumbLabel
      [displayWith]="formatWLabel.bind(this)"
      [disabled]="originalBoreholes?.length !== 2"
      min="0"
      max="3"
      step="0.05"
      [value]="widthScaleValue"
      #wslider
      (input)="handleWidthScale(wslider.value)"
    >
    </mat-slider>
    <div class="sliderBtn" fxFlex="20px">
      <button
        [ngClass]="{ disabled: originalBoreholes?.length !== 2 }"
        [disabled]="originalBoreholes?.length !== 2"
        mat-mini-fab
        color="warn"
        (click)="increaseWidth()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<div>
  <div>
    <b>{{ 'CROSS_SECTION.HEIGHT' | translate }}</b>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="sliderBtn" fxFlex="20px">
      <button
        [ngClass]="{ disabled: originalBoreholes?.length !== 2 && heightScaleValue > 0 }"
        [disabled]="originalBoreholes?.length !== 2 && heightScaleValue > 0"
        mat-mini-fab
        color="warn"
        (click)="reduceHeight()"
      >
        <mat-icon>remove</mat-icon>
      </button>
    </div>
    <mat-slider
      fxFlex
      thumbLabel
      [displayWith]="formatHLabel.bind(this)"
      [disabled]="originalBoreholes?.length !== 2"
      min="0"
      max="3"
      step="0.05"
      [value]="heightScaleValue"
      #hslider
      (input)="handleHeightScale(hslider.value)"
    >
    </mat-slider>
    <div class="sliderBtn" fxFlex="20px">
      <button
        [ngClass]="{ disabled: originalBoreholes?.length !== 2 }"
        [disabled]="originalBoreholes?.length !== 2"
        mat-mini-fab
        color="warn"
        (click)="increaseHeight()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<p>{{ 'CROSS_SECTION.PROJECTED_BOREHOLES' | translate }}</p>
<ul fxLayout="row warp" fxLayoutGap="30px">
  <li *ngFor="let pb of projectedBoreholes" class="projected-borehole">
    {{ pb.name }}
  </li>
</ul>
