<div fxLayout.xs="row">
  <div fxFlex="80" class="table-container">
    <div id="dataTable">
      <hot-table
        licenseKey="non-commercial-and-evaluation"
        [afterChange]="handleCellChanges"
        [afterColumnMove]="handleColumnMove"
        [hotId]="table"
        [settings]="tableSettings"
      ></hot-table>
    </div>
    <div class="button-container" fxLayout="row">
      <div fxFlex="60">
        <button mat-raised-button color="primary" (click)="addRow()">{{ 'GENERAL.ADD_ROW' | translate }}</button>
        <button mat-raised-button color="primary" (click)="addRows()">{{ 'GENERAL.ADD_ROWS' | translate }}</button>
        <button mat-raised-button color="primary" (click)="selectColumns()">
          {{ 'GENERAL.SELECT_COLUMNS' | translate }}
        </button>
        <button mat-raised-button color="warn" [disabled]="this.isValidating" (click)="saveTableData()">
          {{ 'GENERAL.SAVE' | translate }}
        </button>
      </div>
      <button
        mat-raised-button
        fxFlex="5"
        color="accent"
        (click)="showStats()"
        [ngClass]="{ disabled: disabled }"
        [disabled]="disabled"
      >
        Stats
      </button>
      <div fxFlex="15" fxLayout="row wrap" fxLayoutAlign="end start" style="margin-right: 20px">
        <mat-checkbox
          fxFlex="50"
          (change)="onChange($event)"
          [(ngModel)]="checked"
          [(indeterminate)]="indeterminate"
          [labelPosition]="'after'"
          [disabled]="disabled"
        >
          Auto-Fill
        </mat-checkbox>
        <div fxFlex="30" *ngIf="checked" class="autofill-step">
          <form>
            <mat-form-field>
              <input
                matInput
                matTooltip="{{ stepFormControl.invalid ? 'The step value should be greater than 0' : '' }}"
                placeholder="Step"
                [formControl]="stepFormControl"
                type="number"
              />
              <span matSuffix>m</span>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div fxFlex class="alert-message" *ngIf="isValidating">
        {{ 'TABLE.VALIDATE_TABLE_CELLS' | translate }}
      </div>
    </div>
  </div>
  <ssd-soillib-lib-stratigraphy-rendering-chart
    fxFlex="20"
    [stratigraphyData]="stratigraphyData"
    [chartFrom]="'cuttingEdition'"
  ></ssd-soillib-lib-stratigraphy-rendering-chart>
</div>
