import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { KeycloakService } from './app/keycloak/keycloak.service'
import * as Sentry from '@sentry/angular'
import { BrowserTracing } from '@sentry/tracing'

if (environment.sentry.enabled) {
  Sentry.init({
    environment: environment.platform,
    dsn: environment.sentry.dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.sentry.tracesSampleRate,
  })
}

if (environment.production) {
  enableProdMode()
}

KeycloakService.init()
  .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
  .catch((e) => window.location.reload())
