export function getLSItem(key: string): string | null {
  return localStorage.getItem(key)
}
export function safeSetLSItem(key: string, value: string): void {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    // QuotaExceededError
    console.error(e)
  }
}
export function removeLSItem(key: string): string | null {
  const item = getLSItem(key)
  if (item) {
    localStorage.removeItem(key)
  }
  return item
}
