import { Injectable } from '@angular/core'
import { KeycloakToken, Permission, KeycloakTokenTypeGuards as TypeGuards, User } from '../shared/models'
import { environment } from '../../environments/environment'
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js'

export interface ParsedToken {
  jti: string
  nbf: number
  iss: string
  aud: string
  typ: string
  azp: string
  auth_time: number
  acr: string
  'allowed-origins': string[]
  scope: string
  unit_system: string
  country: string
  bu: string
  name: string
  company: string
  roCompany?: string
  preferred_username: string
  given_name: string
  family_name: string
  email: string
  apps: string[]
}

@Injectable()
export class KeycloakService {
  static auth: Keycloak

  static user: any = {}
  static permission: Permission = {
    canConsult: false,
    canWrite: false,
    canCreate: false,
    canDelete: false,
    userRoles: [],
  }

  static init(): Promise<ParsedToken & KeycloakTokenParsed> {
    const keycloakAuth = new Keycloak(environment.keycloakConfig)

    return new Promise((resolve, reject) => {
      keycloakAuth
        .init({ onLoad: 'login-required' })
        .then(() => {
          KeycloakService.auth = keycloakAuth

          const token = keycloakAuth.tokenParsed as ParsedToken & KeycloakTokenParsed
          KeycloakService.user = {
            id: token.sub,
            country: token.country,
            name: token.name || token.preferred_username,
          }
          if (token && token.resource_access && token.resource_access.SoilLib && token.resource_access.SoilLib.roles) {
            KeycloakService.permission.userRoles = token.resource_access.SoilLib.roles
            for (const role of KeycloakService.permission.userRoles) {
              if (role && role.toLowerCase() === 'engineer') {
                KeycloakService.permission.canConsult = true
                KeycloakService.permission.canWrite = true
              }
              if (role && role.toLowerCase() === 'admin') {
                KeycloakService.permission.canConsult = true
                KeycloakService.permission.canWrite = true
                KeycloakService.permission.canCreate = true
              }
              if (role && role.toLowerCase() === 'consulter') {
                KeycloakService.permission.canConsult = true
              }
              if (role && role.toLowerCase() === 'role_delete') {
                KeycloakService.permission.canDelete = true
              }
            }
          }
          resolve(token)
        })
        .catch(() => {
          reject('Failed to login')
        })
    })
  }

  getToken(): Promise<{ parsed: KeycloakToken | undefined; raw: string | undefined }> {
    return new Promise((resolve, reject) => {
      if (KeycloakService.auth.token) {
        KeycloakService.auth
          .updateToken(60)
          .then(() => {
            const tokenParsed = KeycloakService.auth.tokenParsed
            const keycloakToken: KeycloakToken | undefined = TypeGuards.isKeycloakToken(tokenParsed)
              ? tokenParsed
              : undefined
            resolve({ parsed: keycloakToken, raw: KeycloakService.auth.token })
          })
          .catch(() => {
            this.logout()
            reject('Failed to refresh token')
          })
      } else {
        reject('Not logged in')
      }
    })
  }

  getCurrentUser(): User {
    return KeycloakService.user ? KeycloakService.user : null
  }

  getUserPermission(): Permission {
    return KeycloakService.permission
  }

  logout() {
    KeycloakService.auth.logout()
  }
}
