import { createPropertySelectors, Selector } from '@ngxs/store'
import { FilterState, FilterStateModel } from './filter.state'
import { DisplayedSurveys, RadiusSearch } from '../../shared/models'

export class FilterStateSelectors {
  static slices = createPropertySelectors<FilterStateModel>(FilterState)

  @Selector([FilterStateSelectors.slices.displayedSurveys])
  static availableSurveyTypes(displayedSurveys: DisplayedSurveys): string[] {
    return Object.keys(displayedSurveys.filters)
  }

  @Selector([FilterStateSelectors.slices.radiusSearch])
  static radiusSearchInMeter(radiusSearch: RadiusSearch): number | null {
    return radiusSearch.active ? +radiusSearch.radius * 1000 : null
  }
}
