<div fxLayout="row" fxLayoutAlign="end center">
  <button
    *ngIf="hasCPT || hasSPT || hasPressiom || hasTestCorrelation"
    mat-icon-button
    color="secondary"
    (click)="download()"
  >
    <mat-icon>download</mat-icon>
  </button>
  <button *ngIf="hasCPT || hasPressiom" mat-icon-button color="secondary" (click)="openParametersDialog()">
    <mat-icon>settings</mat-icon>
  </button>
  <button mat-icon-button color="secondary" (click)="refreshCorrelations()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>
<ng-container *ngIf="hasCPT">
  <h2>{{ 'SURVEY.CORRELATION_DATA.CPT.TITLE' | translate }}</h2>
  <div class="charts">
    <ng-container *ngIf="cptQtData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_qt'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.QT.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.QT.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptGammaData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_gamma'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.GAMMA.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.GAMMA.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptGammaReference"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptSuData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_su'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.SU.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.SU.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptSuReference"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptOcrData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_ocr'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.OCR.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.OCR.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptOcrReference"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptDrData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_dr'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.DR.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.DR.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptDrReference"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptPhiPrimeData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_phi_prime'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.PHI_PRIME.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.PHI_PRIME.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptPhiPrimeReference"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptKData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_k'"
        [title]="'SURVEY.CORRELATION_DATA.CPT.K.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.CPT.K.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptKReference"
      ></soillib-correlation-data-chart>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="hasSPT">
  <h2>{{ 'SURVEY.CORRELATION_DATA.SPT.TITLE' | translate }}</h2>
  <div class="charts">
    <ng-container *ngIf="sptNData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'spt_n'"
        [title]="'SURVEY.CORRELATION_DATA.SPT.N.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.SPT.N.AXIS' | translate"
        [seriesData]="data"
        [chartMarginTop]="90"
        [elevationParameters]="chartElevationParameters"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="sptSuData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'spt_su'"
        [title]="'SURVEY.CORRELATION_DATA.SPT.SU.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.SPT.SU.AXIS' | translate"
        [seriesData]="data"
        [chartMarginTop]="90"
        [elevationParameters]="chartElevationParameters"
        [reference]="sptSuReference"
        [warningText]="'SURVEY.CORRELATION_DATA.SPT.SU.WARNING' | translate"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="sptPhiPrimeData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'spt_phi_prime'"
        [title]="'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME.AXIS' | translate"
        [seriesData]="data"
        [chartMarginTop]="90"
        [legend]="true"
        [elevationParameters]="chartElevationParameters"
        [reference]="sptPhiPrimeReference"
        [warningText]="'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME.WARNING' | translate"
      ></soillib-correlation-data-chart>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="hasPressiom">
  <h2>{{ 'SURVEY.CORRELATION_DATA.PRESSIOM.TITLE' | translate }}</h2>
  <div class="charts">
    <ng-container *ngIf="pressiomPlStarData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'pressiom_pl_star'"
        [title]="'SURVEY.CORRELATION_DATA.PRESSIOM.PL_STAR.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.PRESSIOM.PL_STAR.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="pressiomPhiPrimeData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'pressiom_phi_prime'"
        [title]="'SURVEY.CORRELATION_DATA.PRESSIOM.PHI_PRIME.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.PRESSIOM.PHI_PRIME.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="pressiomPhiPrimeReference"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="pressiomSuData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'pressiom_su'"
        [title]="'SURVEY.CORRELATION_DATA.PRESSIOM.SU.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.PRESSIOM.SU.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="pressiomSuReference"
      ></soillib-correlation-data-chart>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="hasTestCorrelation">
  <h2>{{ 'SURVEY.CORRELATION_DATA.TEST_CORRELATION.TITLE' | translate }}</h2>
  <div class="charts">
    <ng-container *ngIf="cptSptN60Data as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_spt_n60'"
        [title]="'SURVEY.CORRELATION_DATA.TEST_CORRELATION.N60.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.TEST_CORRELATION.N60.AXIS' | translate"
        [seriesData]="data"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptSptN60Reference"
        [warningText]="'SURVEY.CORRELATION_DATA.TEST_CORRELATION.N60.WARNING' | translate"
      ></soillib-correlation-data-chart>
    </ng-container>
    <ng-container *ngIf="cptPressiomQcData as data">
      <soillib-correlation-data-chart
        [chartContainerId]="'cpt_pressiom_qc'"
        [title]="'SURVEY.CORRELATION_DATA.TEST_CORRELATION.QC.TITLE' | translate"
        [yAxisTitle]="'SURVEY.CORRELATION_DATA.TEST_CORRELATION.QC.AXIS' | translate"
        [seriesData]="data"
        [legend]="true"
        [elevationParameters]="chartElevationParameters"
        [reference]="cptPressiomQcReference"
      ></soillib-correlation-data-chart>
    </ng-container>
  </div>
</ng-container>
