import Handsontable from 'handsontable'

export class DataTableSetTableColors {
  public static readonly type = '[DataTable] SetTableColors'

  constructor(readonly tableColors: (string | null)[]) {}
}
export class DataTableSetSelectedColumns {
  public static readonly type = '[DataTable] SetSelectedColumns'

  constructor(readonly selectedColumns: Handsontable.ColumnSettings[]) {}
}
export class DataTableAddSurveyData {
  public static readonly type = '[DataTable] AddSurveyData'

  constructor(readonly surveyData: Record<string, [number, number][]>) {}
}
export class DataTableClearSurveyData {
  public static readonly type = '[DataTable] ClearSurveyData'
}
