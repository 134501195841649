<div
  id="zone-box"
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="{ selectedJobsite: selectedJobsite$ | async, permission: permission$ | async } as state"
>
  <div
    fxFlex="80"
    fxLayout="column"
    fxLayoutGap="10px"
    class="zone-text"
    *ngIf="!state.selectedJobsite || !state.selectedJobsite.lon_coord"
  >
    <div>{{ 'MAINMAP.SEARCH' | translate }}</div>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
      <button mat-raised-button color="warn" (click)="openAGSImportDialog()">
        {{ 'FILE_IMPORT.IMPORT_AGS' | translate }}
      </button>
      <button mat-raised-button color="warn" (click)="openCSVImportDialog()">
        {{ 'FILE_IMPORT.IMPORT_CSV' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="state.selectedJobsite && state.selectedJobsite.lon_coord">
    <mat-icon matTooltip="Quit jobsite" class="quit-jobsite" (click)="quitJobsite()">exit_to_app</mat-icon>
    <span fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
      <span
        [matMenuTriggerFor]="statusMenu"
        class="circle"
        [ngClass]="state.selectedJobsite.status || 'not_started'"
      ></span>
      <mat-menu #statusMenu="matMenu">
        <button
          class="not_started"
          (click)="state.permission?.canWrite && changeJobsiteStatus('not_started')"
          mat-menu-item
        >
          {{ 'MAINMAP.NOTSTART' | translate }}
        </button>
        <button
          class="in_progress"
          (click)="state.permission?.canWrite && changeJobsiteStatus('in_progress')"
          mat-menu-item
        >
          {{ 'MAINMAP.INPROCESS' | translate }}
        </button>
        <button class="done" (click)="state.permission?.canWrite && changeJobsiteStatus('done')" mat-menu-item>
          {{ 'MAINMAP.END' | translate }}
        </button>
      </mat-menu>
    </span>
    <span fxFlex="30" fxLayout="column" fxLayoutAlign="center center">
      <form>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'MAINMAP.DATUM_PLACEHOLDER' | translate }}"
            [formControl]="datumFormControl"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Elevation" [formControl]="ngfFormControl" autocomplete="off" type="number" />
          <span matSuffix>m</span>
        </mat-form-field>
      </form>
    </span>
    <span fxLayout="column" fxLayoutAlign="center center">
      <span id="zone-title" (click)="fitToVisibleMap()" matTooltip="Fit to current jobsite">
        {{ state.selectedJobsite.nickname }}
      </span>
      <span class="zone-text">
        ({{ state.selectedJobsite.lon_coord.toString().slice(0, 7) }},
        {{ state.selectedJobsite.lat_coord.toString().slice(0, 7) }})<mat-icon
          matTooltip="Edit jobisite center or extent"
          (click)="toggleJobsiteEditPopup()"
          >edit</mat-icon
        >
      </span>
      <span matTooltip="Download reports" class="zone-download"
        ><b>{{ attachedFiles?.length + mapFiles?.length }}</b
        ><i class="material-icons" (click)="showReportList = !showReportList">attachment</i></span
      >
      <soillib-report-list
        *ngIf="showReportList"
        [attachedFiles]="attachedFiles"
        [mapFiles]="mapFiles"
        (closeEvent)="showReportList = false"
      ></soillib-report-list>
      <div class="upload-area">
        <label
          for="file"
          id="file-drop-zone"
          (dragover)="allowDrop($event)"
          (drop)="dropFiles($event)"
          class="label-file"
          *ngIf="state.permission?.canWrite"
          >{{ 'MAINMAP.UPLOAD' | translate }}</label
        >
        <input
          id="file"
          class="input-file"
          type="file"
          multiple="multiple"
          (change)="onFileChange($event.target.files)"
          (click)="onFileInputClick($event)"
        />
      </div>
    </span>
    <div fxFlex="5">
      <mat-icon
        [class.gray-color]="!state.permission?.canDelete"
        matTooltip="{{ showTooltipDelete() }}"
        class="delete-jobsite"
        (click)="deleteJobsite()"
        >delete_forever
      </mat-icon>
    </div>
  </div>
</div>

<soillib-report-upload-progress
  (hideUploadWidgetEvent)="hideUploadWidgetEvent()"
  [isVisible]="showUploadWidget"
  [files]="uploadFiles"
  [nbWaitingFiles]="nbWaitingFiles"
  class="upload-widget"
></soillib-report-upload-progress>
