import { Coordinate } from 'ol/coordinate'
import { platformModifierKeyOnly } from 'ol/events/condition'
import GeometryType from 'ol/geom/GeometryType'
import Draw, { DrawEvent } from 'ol/interaction/Draw'
import VectorLayer from 'ol/layer/Vector'
import olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import { isPolygonOutsideJobsite } from '../../shared/utils'
import { transform as PROJTRANSFORM } from 'ol/proj'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import { StyleService } from '../services/style.service'
import { Injector } from '@angular/core'
import { Store } from '@ngxs/store'
import { AppSetSelectedResource, AppSetSelectedZone } from '../../store/app/app.actions'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { SimpleGeometry } from 'ol/geom'
import { MapIsAddingJobsiteExtent, MapIsAddingZone, MapIsDrawingPolygon } from '../../store/map/map.actions'

export class DrawInteractionLayer {
  drawingSource: VectorSource
  drawingLayer: VectorLayer
  private drawInteraction: Draw | undefined

  private store: Store
  private styleService: StyleService
  private translateService: TranslateService

  constructor(private map: olMap, private injector: Injector) {
    this.store = this.injector.get(Store)
    this.styleService = this.injector.get(StyleService)
    this.translateService = this.injector.get(TranslateService)
    this.drawingSource = new VectorSource({})
    this.drawingLayer = new VectorLayer({ source: this.drawingSource })
    this.map.addLayer(this.drawingLayer)
  }

  clearDrawingLayer() {
    this.drawingSource.clear()
    this.store.dispatch(new MapIsAddingZone(false))
  }

  addDrawInteraction(): void {
    if (this.map && this.drawInteraction) {
      this.map.addInteraction(this.drawInteraction)
    }
  }

  removeDrawInteraction(): void {
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction)
    }
  }

  finishDrawing() {
    if (this.drawInteraction) {
      this.drawInteraction.finishDrawing()
    }
  }

  initializeDrawInteraction(saveJobsiteExtent: (coords: number[][]) => void) {
    this.drawInteraction = new Draw({
      source: this.drawingSource,
      type: GeometryType.POLYGON,
      freehand: false,
      freehandCondition: () => {
        return false
      },
      condition: (e) => {
        const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
        const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
        const zoneEditMode = this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode)
        if (
          (bottomTabOpened === 'soil-cutting-edition' && zoneEditMode === 'addzone') ||
          (selectedJobsite && !selectedJobsite.extent)
        ) {
          return platformModifierKeyOnly(e)
        } else {
          return false
        }
      },
    })

    this.drawInteraction.on('drawstart', () => {
      this.drawingSource.clear()
      this.store.dispatch([new MapIsAddingZone(true), new MapIsDrawingPolygon(true)])
    })

    this.drawInteraction.on('drawend', (e: DrawEvent) => {
      this.store.dispatch(new MapIsDrawingPolygon(false))
      let coordinates = (e.feature.getGeometry() as SimpleGeometry).getCoordinates()
      const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
      if (selectedJobsite && selectedJobsite.extent) {
        const zonestyle = this.styleService.getSelectedZoneStyle()
        e.feature.setStyle(zonestyle)
        const isInsideJobsite = !isPolygonOutsideJobsite(coordinates[0], selectedJobsite.extent)
        if (isInsideJobsite) {
          coordinates = coordinates[0].map((coord: Coordinate) => {
            return PROJTRANSFORM(coord, 'EPSG:3857', 'EPSG:4326')
          })
          if (selectedJobsite.id) {
            this.store.dispatch([
              new AppSetSelectedZone({
                temp: true,
                extent:
                  'POLYGON((' +
                  coordinates.map((a) => `${a[0]} ${a[1]}`).reduce((acc, curr) => `${acc}, ${curr}`) +
                  '))',
                jobsite_id: selectedJobsite.id,
              }),
              new AppSetSelectedResource({
                type: 'section',
                id: undefined,
              }),
            ])
          }
        } else {
          return Swal.fire({
            title: this.translateService.instant('ALERT.FORBIDDEN'),
            text: this.translateService.instant('ALERT.ZONE_INSIDE_SITE'),
            icon: 'error',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.clearDrawingLayer()
          })
        }
      } else {
        const jobsiteExtentStyle = this.styleService.getJobsiteExtentStyle()
        e.feature.setStyle(jobsiteExtentStyle)
        coordinates = coordinates[0].map((coord) => {
          return PROJTRANSFORM(coord, 'EPSG:3857', 'EPSG:4326')
        })
        Swal.fire({
          title: this.translateService.instant('ALERT.SITE_EXTENT'),
          text: 'Save this jobsite extent ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.value) {
            saveJobsiteExtent(coordinates)
            this.store.dispatch(new MapIsAddingJobsiteExtent(false))
          } else {
            this.drawingSource.clear()
          }
        })
      }
    })
  }
}
