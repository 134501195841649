import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Feature, MapBrowserEvent, Overlay } from 'ol'
import olMap from 'ol/Map'
import OverlayPositioning from 'ol/OverlayPositioning'
import { Jobsite, JobsiteTypeGuards as TypeGuards, Zone } from '../../shared/models'
import { ZonesLayer } from '../layers/zones-layer'
import { getBoreholeTypeName, SoilSurvey, Zoom } from '@sde-ild/ssd-soillib-lib'
import { FeatureLike } from 'ol/Feature'
import { BehaviorSubject, Observable } from 'rxjs'
import { Select, Store } from '@ngxs/store'
import { AppSetSelectedResource, AppSetSelectedZone } from '../../store/app/app.actions'
import { SurveysComparisonStateSelectors } from '../../surveys-comparison/store/surveys-comparison.selectors'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { MapStateSelectors } from '../../store/map/map.selectors'

@Component({
  selector: 'soillib-overlays',
  templateUrl: './overlays.component.html',
  styleUrls: ['./overlays.component.scss'],
})
export class OverlaysComponent implements AfterViewInit {
  private helptipOverlay: Overlay
  private zonesOverlay: Overlay
  private basicInfoOverlay: Overlay
  private clickableListOverlay: Overlay

  helpMsg: string | null
  zonesAtPixel: Zone[] = []
  elementsAtPixel: Jobsite[] | SoilSurvey[] = []
  basicInfoSubject = new BehaviorSubject<string[] | null>(null)
  popupType: 'JOBSITE' | 'SURVEY'

  hit: boolean | undefined

  @Input()
  zonesLayer: ZonesLayer

  @Select(AppStateSelectors.selectedZoneId) selectedZoneId$: Observable<string | null | undefined>

  @Output()
  goToJobsiteEvent = new EventEmitter<Jobsite>()
  @Output()
  goToSurveyEvent = new EventEmitter<SoilSurvey>()

  get basicInfo$(): Observable<string[] | null> {
    return this.basicInfoSubject.asObservable()
  }

  constructor(private translateService: TranslateService, private store: Store) {}

  ngAfterViewInit() {
    this.helptipOverlay = new Overlay({
      element: document.getElementById('help-tooltip') || undefined,
      offset: [15, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
    })
    this.zonesOverlay = new Overlay({
      element: document.getElementById('popup') || undefined,
    })
    this.basicInfoOverlay = new Overlay({
      element: document.getElementById('info-popup') || undefined,
    })
    this.clickableListOverlay = new Overlay({
      element: document.getElementById('clickable-popup') || undefined,
    })
  }

  closeZonesPopup() {
    this.zonesOverlay?.setPosition(undefined)
  }

  closeJobsitePopup() {
    this.clickableListOverlay?.setPosition(undefined)
  }

  select(item: Jobsite | SoilSurvey) {
    if (TypeGuards.isJobsite(item)) {
      this.goToJobsiteEvent.emit(item)
    } else {
      this.goToSurveyEvent.emit(item)
    }
    this.closeJobsitePopup()
  }

  selectThisZone(zone: Zone) {
    if (zone) {
      this.store.dispatch([
        new AppSetSelectedZone(zone),
        new AppSetSelectedResource({
          type: 'section',
          id: zone.id,
        }),
      ])
    }
  }

  onHovering(zone: Zone) {
    this.zonesLayer.onHovering(zone)
  }

  onUnhovering(zone: Zone) {
    this.zonesLayer.onUnhovering(zone)
  }

  initOverlays(map: olMap) {
    map.addOverlay(this.helptipOverlay)
    map.addOverlay(this.basicInfoOverlay)
    map.addOverlay(this.zonesOverlay)
    map.addOverlay(this.clickableListOverlay)

    map.on('pointermove', (e: MapBrowserEvent) => {
      if (e.dragging) {
        return
      }
      this.hit = undefined
      this.helpInfohandlerJOBSITE(e)
      this.helpInfohandlerSURVEYZONE(e)
      map.getTargetElement().style.cursor = this.hit ? 'pointer' : ''
    })

    map.on('singleclick', (e: MapBrowserEvent) => {
      if (this.zonesOverlay) {
        this.zonesOverlay.setPosition(undefined)
      }
      if (this.clickableListOverlay) {
        this.clickableListOverlay.setPosition(undefined)
      }
      e.map.forEachFeatureAtPixel(e.pixel, (feature: Feature) => {
        const jobsiteIds: string[] | undefined = feature
          .get('features')
          ?.filter((f) => f.get('centerId'))
          ?.map((f) => f.getId())
        const surveyIds: string[] | undefined = feature
          .get('features')
          ?.filter((f) => f.get('surveyId'))
          ?.map((f) => f.getId())

        const surveysEntities = this.store.selectSnapshot(MapStateSelectors.slices.surveysEntities)
        const surveys: SoilSurvey[] | undefined = surveysEntities
          ? surveyIds?.map((id) => surveysEntities[id])
          : undefined

        const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
        const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
        const jobsitesInVisu = this.store.selectSnapshot(MapStateSelectors.slices.jobsites)

        if (!bottomTabOpened && jobsiteIds && jobsiteIds.length > 1) {
          this.popupType = 'JOBSITE'
          this.elementsAtPixel =
            jobsitesInVisu?.filter((jobsite) => jobsite.id && jobsiteIds.includes(jobsite.id)) || []
          this.clickableListOverlay.setPosition(e.coordinate)
        }
        if (
          bottomTabOpened === 'borehole-edition' &&
          surveys &&
          surveys.length > 1 &&
          surveys.every((survey: SoilSurvey) => survey.jobsite_id === selectedJobsiteId)
        ) {
          this.popupType = 'SURVEY'
          this.elementsAtPixel = surveys
          this.clickableListOverlay.setPosition(e.coordinate)
        }
      })
    })

    map.on('dblclick', (e: MapBrowserEvent) => {
      const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
      const zoneEditMode = this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode)
      const zones = this.store.selectSnapshot(MapStateSelectors.slices.zones)
      if (bottomTabOpened === 'soil-cutting-edition' && zoneEditMode === 'addzone') {
        this.zonesAtPixel = []
        const featuresAtPixel = map.getFeaturesAtPixel(e.pixel)
        let zoneFeaturesAtPixel: FeatureLike[] | undefined
        if (featuresAtPixel) {
          zoneFeaturesAtPixel = featuresAtPixel.filter((f) => f.get('zoneId') != null)
        }
        if (zoneFeaturesAtPixel && zoneFeaturesAtPixel.length > 1) {
          if (zones) {
            zoneFeaturesAtPixel.forEach((item) => this.zonesAtPixel.push(zones[item.get('zoneId')]))
          }
          this.zonesOverlay.setPosition(e.coordinate)
        } else {
          this.zonesOverlay?.setPosition(undefined)
        }
      }
    })
  }

  private helpInfohandlerJOBSITE(e: MapBrowserEvent) {
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
    const zoneEditMode = this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode)
    const showNewJobsitePopup = this.store.selectSnapshot(AppStateSelectors.slices.showNewJobsitePopup)
    const showSearchAddressPopup = this.store.selectSnapshot(AppStateSelectors.slices.showSearchAddressPopup)
    const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)

    const isAddingZone = this.store.selectSnapshot(MapStateSelectors.slices.isAddingZone)
    const isAddingJobsiteExtent = this.store.selectSnapshot(MapStateSelectors.slices.isAddingJobsiteExtent)
    const isJobsiteExtentModifying = this.store.selectSnapshot(MapStateSelectors.slices.isJobsiteExtentModifying)
    const isJobsiteCoordsModifying = this.store.selectSnapshot(MapStateSelectors.slices.isJobsiteCoordsModifying)
    const isDrawingPolygon = this.store.selectSnapshot(MapStateSelectors.slices.isDrawingPolygon)
    const jobsitesInVisu = this.store.selectSnapshot(MapStateSelectors.slices.jobsites)

    this.helpMsg = null
    if (showNewJobsitePopup) {
      this.helpMsg = 'Click on map to define the center of new jobsite'
      this.helptipOverlay.setPosition(e.coordinate)
    } else if (!selectedJobsiteId) {
      this.helpMsg = 'Select a jobsite to start'
      this.helptipOverlay.setPosition(e.coordinate)
    } else if (showSearchAddressPopup) {
      this.helpMsg = 'Click on map to define an address'
      this.helptipOverlay.setPosition(e.coordinate)
    } else if (isAddingJobsiteExtent) {
      this.helpMsg = 'Ctrl/Cmd+click to start drawing jobsite extent'
      this.helptipOverlay.setPosition(e.coordinate)
    } else {
      this.helptipOverlay.setPosition(undefined)
    }
    if (!bottomTabOpened) {
      this.basicInfoOverlay?.setPosition(undefined)
      this.zonesOverlay?.setPosition(undefined)
    }
    e.map.forEachFeatureAtPixel(e.pixel, (feature: Feature) => {
      const jobsiteIds: string[] = feature
        .get('features')
        ?.filter((f) => f.get('centerId'))
        ?.map((f) => f.getId())

      const jobsiteName =
        jobsiteIds?.length === 1 ? jobsitesInVisu?.find((item) => item.id === jobsiteIds[0])?.nickname : undefined
      if (!bottomTabOpened && jobsiteName) {
        if (!selectedJobsiteId || (selectedJobsiteId && !jobsiteIds?.includes(selectedJobsiteId))) {
          this.basicInfoSubject.next([jobsiteName])
          this.popupType = 'JOBSITE'
          this.basicInfoOverlay.setPosition(e.coordinate)
          this.hit = true
        }
      } else if (bottomTabOpened === 'borehole-edition' && selectedJobsiteId === feature.get('extentId')) {
        this.helpMsg = 'Click inside jobsite extent to add a borehole'
        this.helptipOverlay.setPosition(e.coordinate)
      } else if (isJobsiteExtentModifying && selectedJobsiteId === feature.get('extentId')) {
        this.helpMsg = 'Click+drag polygon edge to modify; Alt+Click to delete a vertex'
        this.helptipOverlay.setPosition(e.coordinate)
      } else if (
        bottomTabOpened === 'soil-cutting-edition' &&
        zoneEditMode !== 'cross-section' &&
        selectedJobsiteId === feature.get('extentId')
      ) {
        this.helpMsg = 'Ctrl/Cmd+click to start drawing a zone'
        this.helptipOverlay.setPosition(e.coordinate)
        if (isDrawingPolygon) {
          this.helpMsg = 'Draw at least three point to finish the zone'
        } else if (!isDrawingPolygon && isAddingZone) {
          this.helpMsg = 'Name this zone or press ESC to delete'
        }
      } else if (isJobsiteCoordsModifying && feature.get('jobsitecenter')) {
        this.helpMsg = 'Click+drag to move jobsite center'
        this.helptipOverlay.setPosition(e.coordinate)
      }
    })
  }

  private helpInfohandlerSURVEYZONE(e: MapBrowserEvent) {
    const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
    const zoneEditMode = this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode)
    const selectedSurveyId = this.store.selectSnapshot(AppStateSelectors.selectedSurveyId)
    const isModifyingSurvey = this.store.selectSnapshot(AppStateSelectors.slices.isModifyingSurvey)
    const isModifyingZone = this.store.selectSnapshot(AppStateSelectors.slices.isModifyingZone)
    const complexChartsOpened = this.store.selectSnapshot(SurveysComparisonStateSelectors.slices.complexChartsOpened)

    const isAddingZone = this.store.selectSnapshot(MapStateSelectors.slices.isAddingZone)
    const surveysEntities = this.store.selectSnapshot(MapStateSelectors.slices.surveysEntities)
    const zones = this.store.selectSnapshot(MapStateSelectors.slices.zones)

    if (!isAddingZone && selectedJobsiteId) {
      this.hit = e.map.forEachFeatureAtPixel(e.pixel, (feature: Feature) => {
        const features = feature.get('features')
        if (features && (features.length === 1 || e.map.getView().getZoom() === Zoom.MAX)) {
          feature = features[0]
        }
        if (
          (feature.get('surveyId') && !(bottomTabOpened === 'soil-cutting-edition' && zoneEditMode === 'addzone')) ||
          (feature.get('zoneId') !== undefined &&
            bottomTabOpened === 'soil-cutting-edition' &&
            zoneEditMode === 'addzone')
        ) {
          if (feature.get('surveyId')) {
            const featureId = feature.getId()
            const survey = surveysEntities && featureId ? surveysEntities[featureId] : undefined
            if (survey && survey.type) {
              this.basicInfoSubject.next([
                survey.name + ' (' + getBoreholeTypeName(survey.type, this.translateService) + ')',
              ])
            }
            this.popupType = 'SURVEY'
            this.basicInfoOverlay.setPosition(e.coordinate)
            if (survey?.jobsite_id === selectedJobsiteId) {
              const surveyId = survey?.id
              if (bottomTabOpened === 'borehole-edition' && (selectedSurveyId !== surveyId || !selectedSurveyId)) {
                this.helpMsg = 'Click to consult the borehole'
                this.helptipOverlay.setPosition(e.coordinate)
              } else if (bottomTabOpened === 'borehole-edition' && isModifyingSurvey && selectedSurveyId === surveyId) {
                this.helpMsg = 'Click+drag to move the borehole'
                this.helptipOverlay.setPosition(e.coordinate)
              } else if (bottomTabOpened === 'borehole-comparison' && !complexChartsOpened) {
                this.helpMsg = 'Click to display/hide graph of the borehole'
                this.helptipOverlay.setPosition(e.coordinate)
              } else if (bottomTabOpened === 'borehole-comparison' && complexChartsOpened) {
                this.helpMsg = 'Click to add it to borehole compare form'
                this.helptipOverlay.setPosition(e.coordinate)
              } else if (!bottomTabOpened) {
                this.helpMsg = 'Double click to consult the borehole'
                this.helptipOverlay.setPosition(e.coordinate)
              }
            }
          }
          if (feature.get('zoneId') !== undefined) {
            const zone = zones?.[feature.get('zoneId')]
            if (zone?.jobsite_id === selectedJobsiteId) {
              if (isModifyingZone) {
                this.helpMsg = 'Click+drag polygon edge to modify; Alt+Click to delete a vertex'
                this.helptipOverlay.setPosition(e.coordinate)
              } else {
                this.helpMsg = 'Click/double click to consult the zone; Ctrl/Cmd+click to start drawing a zone'
                this.helptipOverlay.setPosition(e.coordinate)
              }
            }
          }
          return true
        } else if (bottomTabOpened && this.basicInfoOverlay) {
          this.basicInfoOverlay.setPosition(undefined)
        }
      })
    }
  }
}
