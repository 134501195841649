import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin, Observable, tap } from 'rxjs'
import Swal from 'sweetalert2'
import { LabTestDataModel, CrossSectionInfos, ParamInfo, BoreholeInfo } from '../../shared/models'
import { CrossSectionService } from '../../shared/services'
import { CrossSectionGraphService, SoilSurveyService } from '../../shared/remote-services'
import {
  labEntitiesColumnsToTitle,
  getTechniqueColumnTitle,
  getDataObject,
  getScaleInfos,
  SoilTypeUtilsService,
  StratigraphyDataModel,
  SoilSurvey,
  SurveyData,
  toCrossSectionDto,
  ParameterSelectorModel,
  CrossSectionDto,
} from '@sde-ild/ssd-soillib-lib'
import { Select, Store } from '@ngxs/store'
import { CrossSectionStateSelectors } from '../store/cross-section.selectors'
import { AppStateSelectors } from '../../store/app/app.selectors'

@Component({
  selector: 'soillib-cross-section-creation',
  templateUrl: './cross-section-creation.component.html',
  styleUrls: ['./cross-section-creation.component.scss'],
})
export class CrossSectionCreationComponent implements OnInit, OnChanges {
  @Input() jobsiteId: string

  @Input()
  originalBoreholes: SoilSurvey[] | null

  @Input()
  projectedBoreholes: SoilSurvey[] | null

  @Input()
  selectedCrossSectionInfos: CrossSectionInfos | null

  @Input()
  selectedCrossSection: CrossSectionDto | null

  @Select(AppStateSelectors.datumDisplayName) datumDisplayName$: Observable<string>

  @Output()
  resizeSelectBoxEvent: EventEmitter<{ vertical: number; parallel: number }> = new EventEmitter()

  @Output()
  setBoreholeHoverStyleEvent: EventEmitter<string> = new EventEmitter()

  @Output()
  cancelSelectionEvent: EventEmitter<void> = new EventEmitter()

  crossSectionStratigraphyData: StratigraphyDataModel[]
  labTestDataArray: LabTestDataModel[][]
  canSave: boolean
  selectedCrossSectionParameters: ParameterSelectorModel | null
  currentSelectedparameters: ParameterSelectorModel | null
  deletedProjectedBhs: BoreholeInfo[] = []

  constructor(
    private crossSectionService: CrossSectionService,
    private soilSurveyService: SoilSurveyService,
    private translateService: TranslateService,
    private crossSectionGraphService: CrossSectionGraphService,
    private soilTypeUtilsService: SoilTypeUtilsService,
    private store: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.originalBoreholes?.currentValue && !this.selectedCrossSectionInfos) {
      if (this.originalBoreholes?.length !== 2) {
        this.crossSectionStratigraphyData = []
        this.canSave = false
      }
    } else if (changes.projectedBoreholes?.currentValue) {
      this.canSave = false
    }

    if (changes.selectedCrossSectionInfos?.currentValue) {
      this.originalBoreholes = this.selectedCrossSectionInfos?.originalBhs || null
      this.projectedBoreholes = this.selectedCrossSectionInfos?.projectedBhs || null
      if (this.projectedBoreholes) {
        const pids = this.projectedBoreholes.map((p) => p.id)
        this.deletedProjectedBhs =
          this.selectedCrossSectionInfos?.boreholeInfo?.filter(
            (item) => !item.bh_original && !pids.includes(item.survey_id),
          ) || []
      }
      if (this.selectedCrossSectionInfos) {
        this.showStratigraphy(this.selectedCrossSectionInfos.paramInfos)
      }
    } else if (changes.selectedCrossSectionInfos && !changes.selectedCrossSectionInfos.firstChange) {
      this.init()
    }
  }

  ngOnInit(): void {
    this.init()
  }

  private init() {
    this.crossSectionStratigraphyData = []
    this.canSave = false
    this.selectedCrossSectionParameters = null
    this.currentSelectedparameters = null
  }

  resizeSelectBox(scale: { vertical: number; parallel: number }) {
    this.resizeSelectBoxEvent.emit(scale)
  }

  highlightBorehole(boreholeId) {
    this.setBoreholeHoverStyleEvent.emit(boreholeId)
  }

  showStratigraphy(paramInfos: Record<string, ParamInfo[]> | null | undefined = null) {
    if (this.originalBoreholes && this.originalBoreholes.length === 2) {
      const isSBT = this.isSBT(this.originalBoreholes)
      const observableBatch: Observable<SurveyData[]>[] = []
      const observableBatchLabTest: Observable<LabTestDataModel[]>[] = []
      let boreholes: SoilSurvey[] = []
      const projectedBhInfos = this.store.selectSnapshot(CrossSectionStateSelectors.slices.projectedBhInfos)
      if (this.projectedBoreholes) {
        boreholes = this.originalBoreholes.concat(this.projectedBoreholes)
      } else {
        boreholes = this.originalBoreholes
      }
      boreholes.sort((a, b) => {
        const x =
          projectedBhInfos && b.id && projectedBhInfos[b.id]
            ? projectedBhInfos[b.id].projectedPointCoord[1]
            : b.lat_coord
        const y =
          projectedBhInfos && a.id && projectedBhInfos[a.id]
            ? projectedBhInfos[a.id].projectedPointCoord[1]
            : a.lat_coord
        return (x || 0) - (y || 0)
      })
      boreholes
        .filter((survey) => !!survey.id)
        .map((survey) => survey.id as string)
        .forEach((survey) => {
          observableBatch.push(this.soilSurveyService.getSurveyData(survey))
          observableBatchLabTest.push(this.soilSurveyService.getLabTestDataBySurveyId(survey))
        })
      forkJoin(observableBatch)
        .pipe(
          tap((surveysData: SurveyData[][]) => {
            this.crossSectionStratigraphyData = []
            let firstPoint: number[] = []
            let xAxisValue: number
            for (const i of Object.keys(surveysData)) {
              if (i === '0') {
                const boreholeId = boreholes[i].id
                const hasProjectedPoint =
                  projectedBhInfos &&
                  boreholeId &&
                  projectedBhInfos[boreholeId] &&
                  projectedBhInfos[boreholeId].projectedPointCoord
                firstPoint = hasProjectedPoint
                  ? hasProjectedPoint
                  : [Number(boreholes[i].lon_coord), Number(boreholes[i].lat_coord)]
                xAxisValue = 0
              } else {
                const hasProjectedPoint =
                  projectedBhInfos &&
                  projectedBhInfos[boreholes[i].id] &&
                  projectedBhInfos[boreholes[i].id].projectedPointCoord
                const thePoint = hasProjectedPoint
                  ? hasProjectedPoint
                  : [Number(boreholes[i].lon_coord), Number(boreholes[i].lat_coord)]
                xAxisValue = this.crossSectionService.getDistanceOfTwoPoints(firstPoint, thePoint)
              }
              const item = surveysData[i]
              const typedata = item.map(({ depth, geo_soil }) => [
                depth,
                this.soilTypeUtilsService.parseSoilType(geo_soil, isSBT),
              ])
              const oneBoreholeData: StratigraphyDataModel = {
                id: boreholes[i].id,
                name: boreholes[i].name,
                type: boreholes[i].type,
                data: typedata,
                projectDis:
                  projectedBhInfos && projectedBhInfos[boreholes[i].id]
                    ? projectedBhInfos[boreholes[i].id].distance
                    : undefined,
                xValue: xAxisValue,
                elevation: boreholes[i].elevation,
                surveyData: item,
              }
              this.crossSectionStratigraphyData.push(oneBoreholeData)
            }
            if (this.crossSectionStratigraphyData?.length > 0) {
              this.canSave = true
            }
            forkJoin(observableBatchLabTest).subscribe((res: LabTestDataModel[][]) => {
              this.labTestDataArray = res
              this.selectedCrossSectionParameters = this.getParamSelections(paramInfos)
            })
          }),
        )
        .subscribe()
    }
  }

  private getParamSelections(paramInfos: Record<string, ParamInfo[]> | null): ParameterSelectorModel {
    const paramSelectionInfo: ParameterSelectorModel = {
      left: {
        type: 'in-situ',
        data: {},
        scale: {},
      },
      right: {
        type: 'in-situ',
        data: {},
        scale: {},
      },
    }
    if (paramInfos) {
      for (const bhid of Object.keys(paramInfos)) {
        const bhinsitudata = this.crossSectionStratigraphyData?.find((item) => item.id === bhid)
        const labtestdata = this.labTestDataArray?.find((item) => {
          return item?.[0]?.soilsurvey_id === bhid
        })
        for (const paramInfo of paramInfos[bhid]) {
          const elevation = bhinsitudata?.elevation
          if (getTechniqueColumnTitle(paramInfo.param)) {
            paramSelectionInfo[paramInfo.position].type = 'in-situ'
            if (!paramSelectionInfo[paramInfo.position].data[bhid]) {
              paramSelectionInfo[paramInfo.position].data[bhid] = {}
            }
            if (bhinsitudata && elevation != null) {
              paramSelectionInfo[paramInfo.position].data[bhid][paramInfo.param] = getDataObject(
                bhinsitudata.surveyData,
                paramInfo.param,
                elevation,
              )
            }
          } else if (labEntitiesColumnsToTitle[paramInfo.param]) {
            paramSelectionInfo[paramInfo.position].type = 'lab'
            if (!paramSelectionInfo[paramInfo.position].data[bhid]) {
              paramSelectionInfo[paramInfo.position].data[bhid] = {}
            }
            if (elevation != null) {
              paramSelectionInfo[paramInfo.position].data[bhid][paramInfo.param] = getDataObject(
                labtestdata,
                paramInfo.param,
                elevation,
              )
            }
          }
          if (paramInfo.position) {
            paramSelectionInfo[paramInfo.position].scale[paramInfo.param] = getScaleInfos(
              paramSelectionInfo[paramInfo.position].scale[paramInfo.param],
              paramSelectionInfo[paramInfo.position].data[bhid][paramInfo.param]?.data || [],
            )
          }
        }
      }
    }
    return paramSelectionInfo
  }

  setSelectedTestParameters(params: ParameterSelectorModel) {
    this.currentSelectedparameters = params
  }

  cancelSelection() {
    this.cancelSelectionEvent.emit()
    this.selectedCrossSectionParameters = null
    this.crossSectionStratigraphyData = []
    this.originalBoreholes = null
    this.projectedBoreholes = null
    this.canSave = false
  }

  updateCrossSection() {
    if (this.selectedCrossSection?.cr_name && this.currentSelectedparameters) {
      const crDto = toCrossSectionDto(
        this.selectedCrossSection.cr_name,
        this.currentSelectedparameters,
        this.originalBoreholes || [],
        this.projectedBoreholes || [],
      )
      crDto.cr_id = this.selectedCrossSection.cr_id
      crDto.created_at = this.selectedCrossSection.created_at
      crDto.created_by = this.selectedCrossSection.created_by
      this.crossSectionGraphService.updateCrossSection(this.jobsiteId, crDto).subscribe(() => {
        this.currentSelectedparameters = null
        return Swal.fire({
          title: this.translateService.instant('CROSS_SECTION.TITLE'),
          text: this.translateService.instant('ALERT.UPDATED'),
          icon: 'success',
          showConfirmButton: false,
          timer: 1000,
        })
      })
    }
  }

  async saveCrossSection() {
    const { value: name } = await Swal.fire({
      title: this.translateService.instant('CROSS_SECTION.NAME'),
      input: 'text',
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
      inputValidator: (value) => {
        if (!value) {
          return this.translateService.instant('CROSS_SECTION.NAME_REQUIRED')
        }
      },
    })
    if (name) {
      const crDto = toCrossSectionDto(
        name as string,
        this.currentSelectedparameters,
        this.originalBoreholes || [],
        this.projectedBoreholes || [],
      )
      this.crossSectionGraphService.saveCrossSection(this.jobsiteId, crDto).subscribe(() => {
        return Swal.fire({
          title: this.translateService.instant('CROSS_SECTION.TITLE'),
          text: this.translateService.instant('ALERT.SAVED'),
          icon: 'success',
          showConfirmButton: false,
          timer: 1000,
        })
      })
    }
  }

  private isSBT(boreholes: SoilSurvey[]): boolean {
    return boreholes?.some((borehole: SoilSurvey) => borehole?.type?.includes('CPT'))
  }
}
