import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'soillib-add-rows-dialog',
  templateUrl: 'add-rows-dialog.html',
})
export class AddRowsDialog {
  public data: { start: number; step: number; rows: number } = { rows: 10, step: 1, start: 0 }

  constructor(private dialogRef: MatDialogRef<AddRowsDialog>) {}

  close(): void {
    this.dialogRef.close()
  }
}
