<ng-container
  *ngIf="{
    visibleEntities: visibleEntities$ | async,
    isLoading: isLoading$ | async,
    bottomTabOpened: bottomTabOpened$ | async
  } as state"
>
  <div class="visible-zone-box" *ngIf="!state.isLoading && !state.bottomTabOpened">
    <div id="visible-zone-box-draggable" soillibDraggable fxLayout="column" fxLayoutAlign="start" fxLayoutGap="15px">
      <span id="visible" fxFlex="30" fxFlexAlign="center">
        <img id="eye-visible" src="assets/ico-infoszonevisible.png" />
        <span>{{ 'MAINMAP.BOXTITLE' | translate }}</span>
      </span>
      <span id="soil-cuts" fxFlex="30" fxFlexAlign="start">
        <img src="assets/ico-coupesol_bleu.png" />
        <span
          ><span class="text-important">{{ state.visibleEntities?.zones }}</span> {{ 'MAINMAP.ZONE' | translate }}</span
        >
        <img id="survey-img" src="assets/ico-sond-1.png" />
        <span class="red"
          ><span class="text-important">{{ state.visibleEntities?.surveys }}</span>
          {{ 'MAINMAP.SURVEY' | translate }}</span
        >
      </span>
      <span id="jobsites" fxFlex="30">
        <img src="assets/ico-zonechantier_noir.png" />
        <span
          ><span class="text-important">{{ state.visibleEntities?.jobsites }}</span>
          {{ 'MAINMAP.SITE' | translate }}</span
        >
      </span>
    </div>
  </div>
</ng-container>
