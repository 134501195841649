import { Component, EventEmitter, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Jobsite } from '../../models'

export interface AGSImportDialogInput {
  currentJobsite: Jobsite | null
  isSBT: boolean
}

@Component({
  selector: 'soillib-ags-import-dialog',
  templateUrl: 'ags-import-dialog.html',
})
export class AGSImportDialog {
  isSBT: boolean

  onFinishAgsImport = new EventEmitter<Jobsite>()

  constructor(
    public dialogRef: MatDialogRef<AGSImportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AGSImportDialogInput,
  ) {
    this.isSBT = data.isSBT
  }

  finishAgsImport(newJobsite: Jobsite) {
    this.onFinishAgsImport.emit(newJobsite)
    this.close()
  }

  close(): void {
    this.dialogRef.close()
  }
}
