import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { HotTableRegisterer } from '@handsontable/angular'
import { BehaviorSubject, combineLatest, EMPTY, map, noop, Observable, of, Subscription, throwError } from 'rxjs'
import Swal from 'sweetalert2'
import { MessagesService, SoilSurveyService } from '../../shared/remote-services'
import { MatDialog } from '@angular/material/dialog'
import { AddRowsDialog } from '../../shared/components/add-rows-dialog/add-rows-dialog.component'
import {
  SelectColumnsDialog,
  SelectColumnsDialogData,
} from '../../shared/components/select-columns-dialog/select-columns-dialog'
import { DataTableService, SbtService, TableAutoSaveService } from '../../shared/services'
import { catchError, filter, switchMap, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import {
  depthValidator,
  getSourceData,
  integerValidator,
  isColumnEdit,
  isNumber,
  isString,
  nonNullable,
} from '../../shared/utils'
import {
  SBTnParametersModel,
  SoilSurveyDataResponseModel,
  SurveySBTnResultsModel,
  SurveysTableDataType,
} from '../../shared/models'
import Handsontable from 'handsontable'
import { SbtnParametersDialog } from '../../shared/components/sbtn-parameters-dialog/sbtn-parameters-dialog.component'
import { cloneDeep, groupBy, isEqual } from 'lodash'
import {
  BottomNavHeight,
  deSerializeColumnNames,
  getSelectableColumns,
  getTechniqueColumnTitle,
  getTechniqueDefaultColumns,
  SoilSurvey,
  SoilTypeUtilsService,
  StratigraphyDataModel,
  SurveyData,
} from '@sde-ild/ssd-soillib-lib'
import { CellValue, RowObject } from 'handsontable/common'
import { Select, Store } from '@ngxs/store'
import { AppFetchSoilSurveyAvailableParameters } from '../../store/app/app.actions'
import { colorizeCells, colorizeCellsByKey, intervalValidator } from '../../shared/utils/handsontable.utils'
import {
  DataTableAddSurveyData,
  DataTableSetSelectedColumns,
  DataTableSetTableColors,
} from '../../store/data-table/data-table.actions'
import { deepDistinctUntilChanged, switchTap } from '../../shared/utils/observables.utils'
import { environment } from '../../../environments/environment'
import { CorrelationDataSetCorrelationData } from '../../surveys-correlation-data/store/correlation-data.actions'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { DataTableStateSelectors } from '../../store/data-table/data-table.selectors'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'
import { MenuConfig } from 'handsontable/plugins/contextMenu'
import { safeSetLSItem } from '../../shared/utils/local-storage.utils'

type TableCells = CellValue[][] | RowObject[]

type IndexedGamma = { index: number; gamma: number }

@Component({
  selector: 'soillib-surveys-edition-data-table',
  templateUrl: './surveys-edition-data-table.component.html',
  styleUrls: ['./surveys-edition-data-table.component.scss'],
})
export class SurveysEditionDataTableComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    private cdRef: ChangeDetectorRef,
    private soilSurveyService: SoilSurveyService,
    private hotRegisterer: HotTableRegisterer,
    private dataTableService: DataTableService,
    private messagesService: MessagesService,
    private translateService: TranslateService,
    private tableAutoSaveService: TableAutoSaveService,
    private soilTypeUtilsService: SoilTypeUtilsService,
    private dialog: MatDialog,
    private store: Store,
    private sbtService: SbtService,
  ) {}

  @Input() selectedSurvey: SoilSurvey
  @Input() elevationMode: boolean

  @Select(AppStateSelectors.datumDisplayName) datumDisplayName$: Observable<string>
  @Select(DataTableStateSelectors.slices.tableColors)
  tableColors$: Observable<(string | null)[] | null>
  @Select(DataTableStateSelectors.slices.selectedColumns)
  selectedColumns$: Observable<Handsontable.ColumnSettings[]>

  table = 'hotInstance'
  tableRef: Handsontable | null | undefined
  tableSourceData: SurveyData[] = []
  retrievedData: SurveysTableDataType[] | null

  tableSettings = new BehaviorSubject<Handsontable.GridSettings | null>(null)
  stratigraphyData: StratigraphyDataModel

  chartFrom = 'surveyEdition'
  depthMax: number
  position = 'after'

  isValidating = false

  unifiedMarginTop: number
  defaultUnifiedMarginTop = 60

  observer: MutationObserver

  hideTable = false
  showSBT = true
  showLegend = false
  reflowChartsSignal = false

  sbtNResults: SurveySBTnResultsModel[] = []
  private subscription: Subscription = new Subscription()
  private tableValid = true

  get tableSettings$(): Observable<Handsontable.GridSettings | null> {
    return this.tableSettings.asObservable()
  }

  private static handleColumns(column: Handsontable.ColumnSettings): Handsontable.ColumnSettings {
    switch (column.data) {
      case 'calculated_soil':
      case 'calculated_compactness':
        return {
          ...column,
          className: 'readOnlyColumn',
          readOnly: true,
          columnSorting: {
            indicator: false,
            headerAction: false,
            compareFunctionFactory: function compareFunctionFactory() {
              return function comparator() {
                return 0 // Don't sort.
              }
            },
          },
        }
      case 'geo_soil':
      case 'geotechnical_engineer_description':
        return {
          ...column,
          type: 'text',
          allowInvalid: true,
          columnSorting: {
            indicator: false,
            headerAction: false,
            compareFunctionFactory: function compareFunctionFactory() {
              return function comparator() {
                return 0 // Don't sort.
              }
            },
          },
        }
      case 'depth':
        return {
          ...column,
          type: 'numeric',
          renderer: Handsontable.renderers.NumericRenderer,
          validator: depthValidator,
          allowInvalid: true,
          invalidCellClassName: 'depth--error',
        }
      case 'n':
      case 'n_1_60':
        return {
          ...column,
          type: 'numeric',
          validator: integerValidator,
          allowInvalid: true,
          invalidCellClassName: 'integer--error',
          numericFormat: {
            pattern: '0',
          },
        }
      case 'spt_su_coefficient':
        return {
          ...column,
          type: 'numeric',
          validator: intervalValidator(3, 9),
          allowInvalid: true,
          invalidCellClassName: 'interval-3-9--error',
        }
      case 'ic':
        return {
          ...column,
          className: 'readOnlyColumn',
          readOnly: true,
        }
      default:
        return {
          ...column,
          type: 'numeric',
          renderer: Handsontable.renderers.NumericRenderer,
          allowInvalid: true,
          invalidCellClassName: 'highlight--error',
        }
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.resizeTable()
  }

  ngOnInit() {
    this.unifiedMarginTop = this.defaultUnifiedMarginTop

    const targetNode = document.documentElement
    const config = { attributes: true }
    this.observer = new MutationObserver(() => {
      if (
        getComputedStyle(targetNode).getPropertyValue('--bottom-nav-height') === BottomNavHeight.MIDDLE ||
        getComputedStyle(targetNode).getPropertyValue('--bottom-nav-height') === BottomNavHeight.TOP
      ) {
        this.resizeTable()
      }
    })
    this.observer.observe(targetNode, config)
    this.store.dispatch(AppFetchSoilSurveyAvailableParameters)

    this.subscription.add(
      combineLatest([this.tableSettings$, this.tableColors$])
        .pipe(
          deepDistinctUntilChanged(),
          tap(([tableSettings, colors]) => {
            if (tableSettings) {
              this.tableRef?.updateSettings(tableSettings)
            }
            if (colors) {
              colorizeCells(this.tableRef, this.dataTableService.deSerializeColors('survey', colors))
            }
          }),
        )
        .subscribe(),
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSurvey && this.selectedSurvey) {
      const { type: surveyType, id: surveyId } = this.selectedSurvey
      const { columns, settings } = this.getTableSettings(surveyType ?? undefined)
      this.tableSettings.next(settings)
      this.store.dispatch(new DataTableSetSelectedColumns(columns || []))

      this.hideTable = false
      this.showSBT = surveyType?.includes('CPT') === true
      this.sbtNResults = []

      if (surveyId) {
        this.soilSurveyService
          .getSurveyData(surveyId)
          .pipe(
            switchMap((data) => this.loadSBT$().pipe(map((sbtNResults) => ({ sbtNResults, data })))),
            switchMap(({ sbtNResults, data }) => {
              this.tableRef = this.hotRegisterer.getInstance(this.table)
              this.retrievedData = this.parseData(data)
              const tableColors = this.retrievedData.map((data) => data.colors ?? null)
              this.store.dispatch(new DataTableSetTableColors(tableColors))

              if (sbtNResults) {
                const data = this.getHotTableData(sbtNResults, this.retrievedData)
                this.tableRef.updateData(data)
              } else {
                this.tableRef.updateData(this.retrievedData)
              }
              this.tableSettings.next({ ...this.tableSettings.value })
              return this.checkLocalStorageTableData$(surveyId)
            }),
            catchError((err) => {
              this.tableRef = this.hotRegisterer.getInstance(this.table)
              this.tableSettings.next({ ...this.tableSettings.value, data: undefined })
              return throwError(err)
            }),
          )
          .subscribe()
      }
    }
  }

  onAfterValid = (invalid: boolean) => (this.tableValid = invalid)

  handleCellChanges: Handsontable.GridSettings['afterChange'] = (changes, source) => {
    const tableInstance = this.hotRegisterer.getInstance(this.table)
    if (tableInstance) {
      this.tableRef = tableInstance
      if (source) {
        if (isColumnEdit(changes, 'geo_soil').changed) {
          const typeChangedInfo = isColumnEdit(changes, 'geo_soil').infos
          typeChangedInfo.forEach(({ newValue, rowNumber }) => {
            if (isNumber(rowNumber)) {
              tableInstance.setDataAtCell(
                rowNumber,
                tableInstance.propToCol('calculated_soil'),
                this.soilTypeUtilsService.parseSoilType(String(newValue), this.isSBT()),
              )
            }
          })
        }
        if (isColumnEdit(changes, 'geotechnical_engineer_description').changed) {
          const typeChangedInfo = isColumnEdit(changes, 'geotechnical_engineer_description').infos
          typeChangedInfo.forEach(({ newValue, rowNumber }) => {
            if (isNumber(rowNumber)) {
              tableInstance.setDataAtCell(
                rowNumber,
                tableInstance.propToCol('calculated_compactness'),
                this.soilTypeUtilsService.parseSoilCompactness(String(newValue)),
              )
            }
          })
        }
      }

      this.tableSourceData = this.getFormattedData() || []

      this.depthMax = Math.max.apply(
        null,
        this.tableSourceData
          .filter((item) => Object.keys(item).filter((key) => item[key] !== null)?.length > 2) // depth and soilsurvey_id
          .map((item) => {
            return item.depth
          }),
      )
      const dataChanged = source === 'loadData' || (source as string) === 'updateData'
      if (dataChanged || isColumnEdit(changes, 'geo_soil').changed || isColumnEdit(changes, 'depth').changed) {
        this.getStratigraphyData(this.tableSourceData)
      }

      if (!dataChanged) {
        if (source === 'CopyPaste.paste') {
          this.tableSettings.next({
            ...this.tableSettings.value,
            columns: this.store.selectSnapshot(DataTableStateSelectors.slices.selectedColumns),
            data: getSourceData(this.tableRef),
          })
        }
        this.autoSaveTableData(this.tableRef)
      }
    }
  }

  saveTableData() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    if (this.tableValid) {
      const formattedData = this.tableRef ? this.getColorizedFormattedData(this.tableRef) : null
      const { jobsite_id: jobsiteId, id: surveyId, name: surveyName } = this.selectedSurvey
      if (jobsiteId && surveyId && surveyName && formattedData) {
        this.saveSurveyData$(formattedData)
          .pipe(
            switchMap(() =>
              this.messagesService.saveResourceAndJobsiteMessage$(
                jobsiteId,
                surveyName,
                'borehole',
                'added data to the',
              ),
            ),
            tap(() =>
              Swal.fire({
                title: this.translateService.instant('ALERT.SAVED'),
                text: this.translateService.instant('ALERT.SAVE_SUCCESS'),
                icon: 'success',
                confirmButtonText: 'Ok',
              }),
            ),
          )
          .subscribe()
      }
    } else {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.INVALID_MESS'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
  }

  addRow() {
    if (!this.tableSourceData) {
      this.tableSourceData = this.getFormattedData() || []
    }
    this.tableRef?.alter('insert_row', this.tableSourceData.length, 1)
  }

  addRows() {
    const dialogRef = this.dialog.open<AddRowsDialog, never, { start: number; step: number; rows: number }>(
      AddRowsDialog,
      {
        width: '500px',
      },
    )
    dialogRef
      .afterClosed()
      .pipe(
        tap((data) => {
          if (data && this.tableRef) {
            const initialLength = this.tableSourceData.length
            this.tableRef.alter('insert_row', initialLength, data.rows)
            for (let i = 0; i < data.rows; i++) {
              const depthValue = Number(data.start) + Number(i * data.step)
              this.tableSourceData.push({
                soilsurvey_id: this.selectedSurvey.id,
                depth: depthValue,
              })
            }
            this.tableRef.updateData(this.tableSourceData)
            this.autoSaveTableData(this.tableRef)
          }
        }),
      )
      .subscribe()
  }

  openSBTnParams() {
    const dialogRef = this.dialog.open<SbtnParametersDialog, SoilSurvey, SBTnParametersModel>(SbtnParametersDialog, {
      width: '500px',
      data: this.selectedSurvey,
    })
    dialogRef
      .afterClosed()
      .pipe(
        tap((value) => {
          if (value) {
            const surveyId = this.selectedSurvey.id
            if (surveyId) {
              this.soilSurveyService
                .refreshSBTnResults(surveyId, value)
                .pipe(
                  tap(({ sbtnResults }) => {
                    if (sbtnResults) {
                      this.setSBTnResults(sbtnResults)
                      if (sbtnResults.length === 0) {
                        Swal.fire({
                          title: this.translateService.instant('SBTN.NO_RESULTS'),
                          text: this.translateService.instant('SBTN.NO_RESULTS_MESS'),
                          icon: 'info',
                          confirmButtonText: 'Ok',
                        }).then(noop)
                      } else {
                        this.tableRef?.updateData(this.getHotTableData(sbtnResults, this.getFormattedData() || []))
                      }
                    }
                  }),
                  switchTap(({ correlationData }) =>
                    this.store.dispatch(
                      new CorrelationDataSetCorrelationData(correlationData.length ? correlationData : null),
                    ),
                  ),
                )
                .subscribe()
            }
          }
        }),
      )
      .subscribe()
  }

  selectColumns() {
    const surveyType = this.selectedSurvey.type
    if (surveyType) {
      const dialogRef = this.dialog.open<SelectColumnsDialog, SelectColumnsDialogData, Record<string, boolean>>(
        SelectColumnsDialog,
        {
          width: '500px',
          data: {
            selectedCols: this.store.selectSnapshot(DataTableStateSelectors.slices.selectedColumns),
            selectableCols: getSelectableColumns(
              surveyType as string,
              this.store.selectSnapshot(AppStateSelectors.soilSurveyAvailableParameters),
            ),
          },
        },
      )
      dialogRef
        .afterClosed()
        .pipe(
          tap((selected) => {
            if (selected) {
              const selectedColumns = deSerializeColumnNames(Object.keys(selected).filter((key) => selected[key]))
              let columns = [
                { data: 'depth', title: getTechniqueColumnTitle('depth') },
                { data: 'calculated_soil', title: getTechniqueColumnTitle('calculated_soil') },
                { data: 'geo_soil', title: getTechniqueColumnTitle('geo_soil') },
                ...selectedColumns,
              ]
              safeSetLSItem(surveyType + '-columns', JSON.stringify(columns.map((item) => item.data)))
              this.tableRef = this.hotRegisterer.getInstance(this.table)
              columns = columns.map((item) => SurveysEditionDataTableComponent.handleColumns(item))
              this.tableSettings.next({ columns })
              this.tableSourceData = this.getFormattedData() || []
              this.store.dispatch(new DataTableSetSelectedColumns(columns))
            }
          }),
        )
        .subscribe()
    }
  }

  openHelpDoc() {
    window.open(environment.documentation.agsSoilTypes)
  }

  toggleHideTable() {
    this.hideTable = !this.hideTable
    this.reflowChartsSignal = !this.reflowChartsSignal
  }

  toggleShowSBT() {
    this.showSBT = !this.showSBT
    this.loadSBT$().subscribe((sbtNResults: SurveySBTnResultsModel[]) => {
      const formattedData = this.getFormattedData()
      if (formattedData) {
        const data = this.getHotTableData(sbtNResults, formattedData)
        this.tableRef?.updateData(data)
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.observer.disconnect()
  }

  onChartMarginTopChanged(unifiedMarginTop: number) {
    this.unifiedMarginTop = Math.max(this.defaultUnifiedMarginTop, unifiedMarginTop)
  }

  // Visible for testing
  computeGammas(data: SurveysTableDataType[]): SurveysTableDataType[] {
    // Extract gammas per geosoils, if several values are found consider the gamma defined for the upper bound

    const maxBy = (array: IndexedGamma[], predicate: (item: IndexedGamma) => boolean): number | undefined => {
      let maxItem = array[0]
      let i = 0
      while (predicate(maxItem) && i < array.length) {
        maxItem = array[i++]
      }
      return maxItem.gamma
    }
    const getGamma = (indexedGamma: IndexedGamma[] | undefined, index: number): number | undefined =>
      indexedGamma ? maxBy(indexedGamma, (item) => item.index < index) : undefined

    const gammaByGeosoil = new Map<string | null, IndexedGamma[]>()
    for (let i = 0; i < data.length; i++) {
      const { gamma, geo_soil: geosoil } = data[i]
      if (gamma != null) {
        if (!gammaByGeosoil.has(geosoil ?? null)) {
          gammaByGeosoil.set(geosoil ?? null, [])
        }
        gammaByGeosoil.get(geosoil ?? null)?.push({ index: i, gamma })
      }
    }
    return data.map((item, index) => ({
      ...item,
      gamma: getGamma(gammaByGeosoil.get(item.geo_soil ?? null), index),
    }))
  }

  private resizeTable() {
    this.reflowChartsSignal = !this.reflowChartsSignal
    this.tableRef = this.hotRegisterer.getInstance(this.table)
    if (this.tableRef && !this.hideTable) {
      this.tableRef.render()
    }
  }

  private checkLocalStorageTableData$ = (surveyId: string): Observable<TableCells | null> =>
    this.tableAutoSaveService.getAutoSaveTableData$<TableCells>(surveyId).pipe(
      tap((data) => {
        if (data && this.retrievedData) {
          this.store.dispatch(new DataTableSetTableColors(this.retrievedData.map((row) => row.colors ?? null)))
          if (this.tableRef) {
            this.tableRef.updateData(data)
            this.tableRef.validateCells()
            this.autoSaveTableData(this.tableRef)
          }
        }
      }),
    )

  private getStratigraphyData(tableSourceData: SurveyData[]) {
    const soilTypeData: (string | number)[][] = tableSourceData
      .filter((item) => item.depth !== undefined && item.calculated_soil !== undefined)
      .map((item) => {
        return [item.depth as number, item.calculated_soil as string]
      })
    this.stratigraphyData = {
      name: this.selectedSurvey.name,
      data: soilTypeData,
      elevation: this.selectedSurvey.elevation,
    }
    this.cdRef.detectChanges()
  }

  private getFormattedData(): SurveysTableDataType[] | null {
    if (!this.tableRef) {
      this.tableRef = this.hotRegisterer.getInstance(this.table)
    }
    const sourceData = getSourceData(this.tableRef)
    if (sourceData) {
      const surveyId = this.selectedSurvey.id
      return (cloneDeep(sourceData) as SurveysTableDataType[])
        .filter((item) => item.depth != null)
        .map((item) => ({
          ...item,
          depth: item.depth as number,
        }))
        .sort((a, b) => a.depth - b.depth)
        .map((item) => ({ ...item, soilsurvey_id: surveyId }))
    } else {
      return null
    }
  }

  private autofillSoiltype() {
    const formattedData = this.getFormattedData()
    if (formattedData) {
      const geosoils = formattedData.map((item) => item.geo_soil ?? null)
      for (let i = geosoils.length - 2; i >= 0; i--) {
        if (geosoils[i + 1] && !geosoils[i]) {
          geosoils[i] = geosoils[i + 1]
        }
      }
      this.refreshGeosoils(geosoils, formattedData)
    }
  }

  private autofillSoiltypeFromIcRolling() {
    const currentLanguage = this.store.selectSnapshot(UserConfigStateSelectors.userLanguage)
    const formattedData = this.getFormattedData()
    const geosoils = formattedData
      ?.map((item) => item.icRolling)
      ?.map((icRolling) => (icRolling ? this.soilTypeUtilsService.getGeosoilFromIc(icRolling, currentLanguage) : null))
    if (geosoils && formattedData) {
      this.refreshGeosoils(geosoils, formattedData)
    }
  }

  private autofillSoilCompactness() {
    const formattedData = this.getFormattedData()
    const descriptions = formattedData?.map((item) => item.geotechnical_engineer_description ?? null)
    if (descriptions) {
      for (let i = descriptions.length - 2; i >= 0; i--) {
        if (descriptions[i + 1] && !descriptions[i]) {
          descriptions[i] = descriptions[i + 1]
        }
      }
      if (formattedData) {
        this.refreshCompactnesses(descriptions, formattedData)
      }
    }
  }

  private autoSaveTableData(tableRef: Handsontable) {
    const sourceData = getSourceData(tableRef)
    const { id: surveyId, jobsite_id: jobsiteId } = this.selectedSurvey
    if (surveyId && jobsiteId && sourceData && sourceData.length) {
      this.tableAutoSaveService.autosaveTableData<TableCells>(surveyId, sourceData)
      if (this.tableValid && this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
        const formattedData = this.tableRef ? this.getColorizedFormattedData(this.tableRef) : null
        if (formattedData) {
          this.saveSurveyData$(formattedData).subscribe()
        }
      }
    }
  }

  private parseData(data: SurveyData[]): SurveysTableDataType[] {
    let parsedData = this.soilTypeUtilsService.parseSoilTypes(data, this.isSBT())
    parsedData = this.soilTypeUtilsService.parseSoilCompactnesses(parsedData)
    return parsedData
  }

  private loadSBT$(): Observable<SurveySBTnResultsModel[] | null> {
    const surveyId = this.selectedSurvey.id
    if (this.showSBT && !!surveyId) {
      return of(surveyId).pipe(
        filter(nonNullable),
        switchMap((surveyId) => this.soilSurveyService.getSBTnResults(surveyId)),
        tap((sbtNResults: SurveySBTnResultsModel[]) => this.setSBTnResults(sbtNResults)),
      )
    } else {
      return of(null)
    }
  }

  private isSBT(): boolean {
    return this.selectedSurvey?.type?.includes('CPT') === true
  }

  private getHotTableData(results: SurveySBTnResultsModel[], models: SurveysTableDataType[]): SurveysTableDataType[] {
    const surveyDataModels = models
    const sbtnResultByDepth = groupBy(results, (v) => v.depth)
    return surveyDataModels?.map((v) => {
      const depth = v.depth
      const resultsCandidates = depth ? sbtnResultByDepth[depth] : null
      if (resultsCandidates?.length) {
        const scale = (n: number) => (n != null ? Math.round((n + Number.EPSILON) * 100) / 100 : n)
        const [{ ic, icRolling }] = resultsCandidates
        return {
          ...v,
          ic: scale(ic),
          icRolling: scale(icRolling),
        }
      } else {
        return v
      }
    })
  }

  private refreshGeosoils(geosoils: (string | null)[], formattedData: SurveyData[]) {
    if (
      this.tableRef &&
      !isEqual(
        geosoils,
        formattedData.map((item) => item.geo_soil),
      )
    ) {
      this.tableRef.updateData(
        this.soilTypeUtilsService.parseSoilTypes(
          formattedData.map((item, index) => ({
            ...item,
            geo_soil: geosoils[index] ?? undefined,
            calculated_soil: undefined,
          })),
          this.isSBT(),
        ),
      )
      this.autoSaveTableData(this.tableRef)
    }
  }

  private refreshCompactnesses(geotechnicalEngineerDescriptions: (string | null)[], formattedData: SurveyData[]) {
    if (
      this.tableRef &&
      !isEqual(
        geotechnicalEngineerDescriptions,
        formattedData.map((item) => item.geotechnical_engineer_description),
      )
    ) {
      this.tableRef.updateData(
        this.soilTypeUtilsService.parseSoilCompactnesses(
          formattedData.map((item, index) => ({
            ...item,
            geotechnical_engineer_description: geotechnicalEngineerDescriptions[index] ?? undefined,
            calculated_compactness: undefined,
          })),
        ),
      )
      this.autoSaveTableData(this.tableRef)
    }
  }

  private getColorizedFormattedData(tableRef: Handsontable): SurveyData[] | null {
    const formattedData = this.getFormattedData()
    if (formattedData && formattedData.length) {
      const currentTableColors = this.dataTableService.getCurrentTableColors(tableRef, 'survey')
      formattedData.forEach((row, i) => {
        formattedData[i].colors = undefined
        for (const { color, depth } of currentTableColors) {
          if (row.depth === depth) {
            formattedData[i].colors = color
            break
          }
        }
      })
    }
    return formattedData
  }

  private getTableSettings(surveyType: string | undefined): {
    settings: Handsontable.GridSettings
    columns: Handsontable.ColumnSettings[] | undefined
  } {
    const columns = surveyType
      ? getTechniqueDefaultColumns(surveyType)
          .filter(
            (c) =>
              environment.features.soilCompactness ||
              !isString(c.data) ||
              !['geotechnical_engineer_description', 'calculated_compactness'].includes(c.data),
          )
          .map((e) => SurveysEditionDataTableComponent.handleColumns(e))
      : undefined
    const items: MenuConfig = {
      autofill_soiltype: {
        name: () => `<b>${this.translateService.instant('SURVEY.AUTOFILL.SOILTYPE')}</b>`,
        hidden: this.isAutofillSoiltype(),
      },
      autofill_soiltype_from_ic_rolling: {
        name: () => `<b>${this.translateService.instant('SURVEY.AUTOFILL.SOILTYPE_BY_IC')}</b>`,
        hidden: this.isAutofillSoiltypeFromIcHidden(),
      },
      autofill_gamma: {
        name: () => `<b>${this.translateService.instant('SURVEY.AUTOFILL.GAMMA')}</b>`,
        hidden: this.isAutofillGammaHidden(),
      },
      autofill_soil_compactness: {
        name: () => `<b>${this.translateService.instant('SURVEY.AUTOFILL.COMPACTNESS')}</b>`,
        hidden: () => !environment.features.soilCompactness,
      },
      sp1: { name: '---------' },
      red: { name: this.translateService.instant('SURVEY.SET_RED') },
      orange: { name: this.translateService.instant('SURVEY.SET_ORANGE') },
      yellow: { name: this.translateService.instant('SURVEY.SET_YELLOW') },
      none: { name: this.translateService.instant('SURVEY.REMOVE_COLOR') },
      '---------': {},
      row_above: { name: this.translateService.instant('SURVEY.INSERT_ROW_ABOVE') },
      row_below: { name: this.translateService.instant('SURVEY.INSERT_ROW_BELOW') },
      remove_row: { name: this.translateService.instant('SURVEY.REMOVE_ROW') },
      undo: { name: this.translateService.instant('SURVEY.UNDO') },
    }
    return {
      settings: {
        stretchH: 'all',
        className: 'htLeft smallFont',
        minRows: 10,
        rowHeaders: true,
        minCols: columns?.length,
        columns,
        afterValidate: this.onAfterValid,
        columnSorting: true,
        contextMenu: {
          callback: (key) => {
            switch (key) {
              case 'autofill_soiltype':
                this.autofillSoiltype()
                break
              case 'autofill_soil_compactness':
                this.autofillSoilCompactness()
                break
              case 'autofill_soiltype_from_ic_rolling':
                this.autofillSoiltypeFromIcRolling()
                break
              case 'autofill_gamma':
                this.autofillGamma()
                break
              default: // key === 'red' || key === 'orange' || key === 'yellow' || key === 'none'
                if (this.tableRef) {
                  colorizeCellsByKey(this.tableRef, key)
                  this.autoSaveTableData(this.tableRef)
                  this.tableRef.render()
                }
                break
            }
          },
          items,
        },
        manualRowMove: true,
        manualColumnMove: true,
        manualColumnResize: true,
        fillHandle: 'vertical',
        height: '100%',
        afterGetColHeader: (col, TH) => {
          TH.className = 'htLeft'
        },
      },
      columns,
    }
  }

  private saveSurveyData$(surveyData: SurveysTableDataType[]): Observable<SoilSurveyDataResponseModel> {
    const jobsiteId = this.selectedSurvey.jobsite_id
    const surveyId = this.selectedSurvey.id
    if (jobsiteId && surveyId) {
      return this.soilSurveyService
        .saveSurveyData(jobsiteId, surveyId, {
          surveyData,
          sbtnParameters: this.sbtService.getLocalSurveySbtParameters(this.selectedSurvey),
        })
        .pipe(
          tap(({ sbtnResults }) => {
            if (sbtnResults) {
              this.setSBTnResults(sbtnResults)
              if (sbtnResults.length !== 0) {
                this.tableRef?.updateData(this.getHotTableData(sbtnResults, this.getFormattedData() || []))
              }
            }
            this.retrievedData = null
            this.tableAutoSaveService.deleteAutosaveTableData(surveyId)
          }),
          switchTap(({ correlationData }) =>
            this.store.dispatch([
              new CorrelationDataSetCorrelationData(correlationData.length ? correlationData : null),
              new DataTableSetTableColors(surveyData.map((item) => item.colors ?? null)),
            ]),
          ),
          catchError((err) => {
            this.tableAutoSaveService.autosaveTableData(surveyId, getSourceData(this.tableRef))
            return throwError(err)
          }),
        )
    } else {
      return EMPTY
    }
  }

  private setSBTnResults(sbtNResults: SurveySBTnResultsModel[]) {
    this.sbtNResults = sbtNResults
    if (sbtNResults.length) {
      const sbtnData: Record<string, [number, number][]> = {}
      for (const sbtNResult of sbtNResults) {
        for (const k in sbtNResult) {
          if (k !== 'depth' && k !== 'soilsurvey_id') {
            if (!(k in sbtnData)) {
              sbtnData[k] = []
            }
            sbtnData[k].push([sbtNResult.depth, sbtNResult[k]])
          }
        }
      }
      this.store.dispatch(new DataTableAddSurveyData(sbtnData))
    }
  }

  private isAutofillSoiltype = () => () => !this.selectionIsIncludingColumns(['geo_soil', 'calculated_soil'])

  private isAutofillSoiltypeFromIcHidden = () => () =>
    !(this.isSBT() && this.selectionIsIncludingColumns(['geo_soil', 'calculated_soil', 'ic', 'icRolling']))

  private isAutofillGammaHidden = () => () => !this.selectionIsIncludingColumns(['gamma'])

  private selectionIsIncludingColumns(columns: string[]): boolean {
    const titles: string[] = columns.map((c) => getTechniqueColumnTitle(c))
    const columnHeaders = this.tableRef?.getColHeader()
    const selectedColumns = this.tableRef
      ?.getSelected()
      ?.map(([, startCol, , endCol]) => Array.from({ length: endCol - startCol + 1 }, (v, k) => k + startCol))
      ?.reduce((acc, val) => acc.concat(val.filter((item) => acc.indexOf(item) < 0)), [])
      ?.map((col) => columnHeaders?.[col])
      ?.filter(isString)
    return !!selectedColumns?.find((e) => titles.includes(e))
  }

  private autofillGamma(): void {
    if (this.tableRef) {
      const formattedData = this.getFormattedData()
      if (formattedData) {
        const updatedData = this.computeGammas(formattedData)
        this.tableRef.updateData(updatedData)
        this.autoSaveTableData(this.tableRef)
      }
    }
  }
}
