<div
  [@slideInOut]="statisticsOpened"
  [ngStyle]="getstatisticsButtonStyle()"
  class="container"
  fxLayout="column"
  fxLayoutAlign="center center"
  (click)="handlestatisticsToggle()"
>
  <span>Statistics</span>
</div>

<div [@slideInOut]="statisticsOpened" *ngIf="statisticsOpened" class="statistics-content" fxLayout="column">
  <ssd-soillib-lib-jobsite-soil-stats [globalStats]="globalStats"></ssd-soillib-lib-jobsite-soil-stats>
</div>
