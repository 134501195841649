import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'
import { Jobsite, ComplexChart } from '../shared/models'
import { TableAutoSaveService } from '../shared/services'
import { KeycloakService } from '../keycloak/keycloak.service'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { AppClearResource, AppCloseBottomTab, AppOpenBottomTab, AppSetSelectedResource } from '../store/app/app.actions'
import { Select, Store } from '@ngxs/store'
import {
  SurveysComparisonCloseComparison,
  SurveysComparisonSaveComplexCharts,
  SurveysComparisonSetComplexCharts,
} from './store/surveys-comparison.actions'
import { Observable, tap, withLatestFrom, Subscription, of } from 'rxjs'
import { SurveysComparisonStateSelectors } from './store/surveys-comparison.selectors'
import { filter, switchMap } from 'rxjs/operators'
import { nonNullable } from '../shared/utils'
import { AppStateSelectors } from '../store/app/app.selectors'

@Component({
  selector: 'soillib-surveys-comparison',
  templateUrl: './surveys-comparison.component.html',
  styleUrls: ['./surveys-comparison.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-bottom': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-bottom': 'var(--bottom-nav-height)' })),
      ]),
      transition('true => false', [
        style({ 'margin-bottom': 'var(--bottom-nav-height)' }),
        animate('0.5s ease-in-out', style({ 'margin-bottom': '0' })),
      ]),
    ]),
  ],
})
export class SurveysComparisonComponent implements OnInit, OnDestroy {
  @Input() jobsite: Jobsite | null

  @Select(AppStateSelectors.datumDisplayName) datumDisplayName$: Observable<string>
  @Select(AppStateSelectors.surveysComparisonOpened) surveysComparisonOpened$: Observable<boolean>
  @Select(SurveysComparisonStateSelectors.slices.selectedSurveys) selectedSurveys$: Observable<SoilSurvey[] | null>
  @Select(SurveysComparisonStateSelectors.slices.complexCharts) complexCharts$: Observable<ComplexChart[] | null>
  @Select(SurveysComparisonStateSelectors.slices.saveError) saveError$: Observable<{
    jobsiteId: string
    error: boolean
  } | null>

  dragDrop: string
  simpleChartDragDrop: string

  checked: boolean

  private subscriptions = new Subscription()

  constructor(
    private tableAutoSaveService: TableAutoSaveService,
    private keycloakService: KeycloakService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.checked = false
    this.dragDrop = ''
    this.simpleChartDragDrop = ''

    this.subscriptions.add(
      this.saveError$
        .pipe(
          filter(nonNullable),
          filter(({ error }) => !!error),
          withLatestFrom(this.complexCharts$),
          tap(([{ jobsiteId }, complexCharts]) =>
            this.tableAutoSaveService.autosaveTableData(
              jobsiteId,
              complexCharts,
              this.keycloakService.getCurrentUser().id,
            ),
          ),
        )
        .subscribe(),
    )
  }

  handleSurveyComparisonToggle(surveysComparisonOpened: boolean) {
    const selectedJobsiteId = this.jobsite?.id
    if (this.jobsite?.extent && selectedJobsiteId) {
      of(
        surveysComparisonOpened
          ? [
              new AppOpenBottomTab('borehole-comparison'),
              new AppSetSelectedResource({
                type: 'chart',
                id: undefined,
              }),
            ]
          : [AppCloseBottomTab, SurveysComparisonCloseComparison, AppClearResource],
      )
        .pipe(
          switchMap((actions) => this.store.dispatch(actions)),
          tap(() => {
            if (!surveysComparisonOpened) {
              this.tableAutoSaveService.deleteAutosaveTableData(
                selectedJobsiteId,
                this.keycloakService.getCurrentUser().id,
              )
            }
          }),
        )
        .subscribe()
    }
  }

  toggleDragDrop() {
    if (this.dragDrop) {
      this.dragDrop = ''
      this.store.dispatch(SurveysComparisonSaveComplexCharts)
    } else {
      this.dragDrop = 'COMPLEX_CHART'
    }
    if (this.simpleChartDragDrop) {
      this.simpleChartDragDrop = ''
    } else {
      this.simpleChartDragDrop = 'SIMPLE_CHART'
    }
  }

  onComplexChartDragged(complexCharts: ComplexChart[]) {
    this.store.dispatch(
      new SurveysComparisonSetComplexCharts([...complexCharts].map((chart, index) => ({ ...chart, index }))),
    )
  }

  trackByChart(index, complexChart: ComplexChart) {
    return complexChart.chartId
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
