import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostListener,
  SimpleChanges,
  AfterViewInit,
  OnChanges,
} from '@angular/core'
import { SurveySBTnResultsModel } from '../../models'
import * as Highcharts from 'highcharts'
import { axisId, chartTitle, getTechniqueColumnTitle, yAxisTitle } from '@sde-ild/ssd-soillib-lib'
import { TranslateService } from '@ngx-translate/core'
import { dynamicTickIntervalsWhenZooming } from '../../utils'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../../store/app/app.selectors'

@Component({
  selector: 'soillib-sbtn-pore-pressure-chart',
  template: ` <div [id]="chartContainerId" style="height: 100%"></div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbtnPorePressureChartComponent implements AfterViewInit, OnChanges {
  chartContainerId = 'u'

  @Input()
  elevation: number | null | undefined

  @Input()
  elevationMode: boolean

  @Input()
  seriesData: SurveySBTnResultsModel[]

  @Input()
  reflowChartsSignal: boolean

  private chartRef: Highcharts.Chart | null
  private plotBands: Highcharts.YAxisPlotBandsOptions[]

  constructor(private translateService: TranslateService, private store: Store) {}

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.chartRef?.reflow()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reflowChartsSignal) {
      this.chartRef?.reflow()
    }
    if (changes.seriesData?.currentValue?.length >= 0 || changes.elevationMode) {
      this.drawChart()
    }
  }

  ngAfterViewInit() {
    this.drawChart()
  }

  private drawChart() {
    if (document.getElementById(this.chartContainerId)) {
      if (this.chartRef) {
        this.chartRef.destroy()
        this.chartRef = null
      }

      const activeElevation = this.activeElevation()
      const options: Highcharts.Options = {
        chart: {
          zoomType: 'xy',
          inverted: true,
          marginTop: 50,
          marginBottom: 70,
        },
        title: {
          text: chartTitle.u,
          style: {
            fontSize: '15px',
          },
        },
        xAxis: {
          title: {
            text: activeElevation
              ? this.translateService.instant('SURVEY.ELEVATION', {
                  name: this.store.selectSnapshot(AppStateSelectors.datumDisplayName),
                })
              : this.translateService.instant('GENERAL.DEPTH'),
          },
          reversed: !activeElevation,
          crosshair: true,
          endOnTick: true,
          startOnTick: true,
          gridLineWidth: 1,
          gridZIndex: 0,
          tickInterval: 5,
          events: {
            setExtremes: dynamicTickIntervalsWhenZooming(5),
          },
          min: activeElevation ? undefined : 0,
        },
        yAxis: {
          title: {
            text: axisId.PRESSURE_KPA,
          },
          endOnTick: true,
          startOnTick: true,
          plotBands: this.plotBands,
          lineWidth: 1,
          gridLineWidth: 1,
          gridZIndex: 0,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter() {
            return `<div>${this.x}</div><br><span style="color: ${this.color}">${this.series.name}</span>: <b> ${this.y} </b>`
          },
        },
        credits: {
          enabled: false,
        },
        series: this.lineSeriesData(),
      }
      this.chartRef = Highcharts.chart(this.chartContainerId, options)
    }
  }

  private lineSeriesData() {
    return [
      {
        id: 'u',
        type: 'line',
        name: yAxisTitle.u,
        data: this.getLineSeriesData((res) => res.u),
      } as Highcharts.SeriesOptionsType,
      {
        id: 'u2',
        type: 'line',
        lineWidth: 0.8,
        name: getTechniqueColumnTitle('u2'),
        data: this.getLineSeriesData((res) => res.u2),
      } as Highcharts.SeriesOptionsType,
    ]
  }

  private getLineSeriesData(toData: (res: SurveySBTnResultsModel) => number) {
    return this.seriesData?.map((res) => [this.getDepthOrElevation(res.depth), toData(res)]).sort((a, b) => a[0] - b[0])
  }

  private activeElevation() {
    return this.elevationMode && this.elevation != null
  }

  private getDepthOrElevation(depth: number) {
    if (this.activeElevation()) {
      return parseFloat(((this.elevation || 0) - depth).toFixed(2))
    } else {
      return parseFloat(depth.toFixed(2))
    }
  }
}
