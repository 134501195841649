<div fxLayout="column" class="report-container">
  <span class="report-header">
    <div style="float: left">
      <b>{{ 'MAINMAP.REPORT' | translate }}</b>
    </div>
    <div style="float: right" (click)="close()">X</div>
  </span>

  <div class="report-list">
    <div *ngFor="let file of attachedFiles; let i = index" fxLayout="row" fxLayoutAlign="start start">
      <span fxFlex="40" style="word-break: break-word">{{ file.name }}</span>
      <span fxFlex="40">{{ showDateTime(file.modified) }}</span>
      <span fxFlex="10" style="cursor: pointer" (click)="downloadFile(file)">
        <i class="material-icons">attachment</i>
      </span>
      <mat-icon fxFlex="10" (click)="deleteFile(file, i)" [class.gray-color]="(permission$ | async)?.canDelete !== true"
        >delete_forever</mat-icon
      >
    </div>
    <div *ngFor="let file of mapFiles; let i = index" fxLayout="row" fxLayoutAlign="start start">
      <span fxFlex="40" style="word-break: break-word">{{ file.file_name }}</span>
      <span fxFlex="40">{{ showDateTime(file.file_date) }}</span>
      <span fxFlex="10" style="cursor: pointer" (click)="downloadMapFile(file.file_path)">
        <mat-icon> attach_file</mat-icon>
      </span>
    </div>
  </div>
</div>
