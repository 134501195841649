<div class="chart-title" (click)="editChart(complexChart)" fxLayout="row" fxLayoutAlign="center center">
  <mat-icon *ngIf="dragDrop">drag_indicator</mat-icon>
  <span>{{ 'GENERAL.EDIT' | translate }}</span>
  <mat-icon>edit</mat-icon>
</div>
<div fxLayout="row">
  <div class="complex-chart" [id]="chartContainerId" *ngIf="hasParamChart"></div>
  <!--MAYBE to delete !!-->
  <ssd-soillib-lib-stratigraphy-rendering-chart
    class="stratigraphy-chart"
    *ngFor="let stratigraphyData of stratigraphyDataArray; let index = index"
    [stratigraphyData]="stratigraphyData"
    [depthMax]="depthMax"
    [chartIndexStr]="chartContainerId + '-' + index"
    [chartFrom]="'comparison'"
    [elevationMode]="elevationMode"
    [unifiedMarginTop]="(chartsMarginTop$ | async) || 0"
    [datumDisplayName]="(datumDisplayName$ | async) || 'NGF'"
  ></ssd-soillib-lib-stratigraphy-rendering-chart>
  <!-------------------->
</div>
