import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Zone, ZoneData } from '../models'
import { circular as CIRCULAR } from 'ol/geom/Polygon'

@Injectable()
export class ZoneService {
  constructor(private http: HttpClient) {}

  searchZones(lat: number, lon: number, radius: number): Observable<Zone[]> {
    const polygonCoord = CIRCULAR([lon, lat], radius, 12).getCoordinates()[0]
    const polygon =
      'POLYGON((' + polygonCoord.map((a) => `${a[0]} ${a[1]}`).reduce((acc, curr) => `${acc}, ${curr}`) + '))'
    return this.searchVisibleZones(polygon)
  }

  searchVisibleZones(polygon: string): Observable<Zone[]> {
    return this.http.get<Zone[]>(`api/zones/geoloc?polygon=${polygon}`)
  }

  getJobsiteZones(jobsiteId: string): Observable<Zone[]> {
    return this.http.get<Zone[]>(`api/jobsites/${jobsiteId}/zones`)
  }

  createZone(zone: Zone): Observable<string> {
    return this.http.post<string>('api/zones', zone)
  }

  getZone(zoneId: string): Observable<Zone> {
    return this.http.get<Zone>(`api/zones/${zoneId}`)
  }

  updateZone(zoneId: string, zone: Zone): Observable<{ string: string; zone: Zone }> {
    return this.http.put<{ string: string; zone: Zone }>(`api/zones/${zoneId}`, zone)
  }

  deleteZone(zoneId: string): Observable<unknown> {
    return this.http.delete(`api/zones/${zoneId}`)
  }

  saveZoneData(zoneData: ZoneData[], zoneId: string): Observable<string> {
    return this.http.post<string>(`api/zones/${zoneId}/data`, zoneData)
  }

  getZoneData(zoneId: string): Observable<ZoneData[]> {
    return this.http.get<ZoneData[]>(`api/zones/${zoneId}/data`)
  }
}
