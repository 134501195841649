import { Action, State, StateContext, Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import produce from 'immer'
import {
  CorrelationDataClearCorrelationData,
  CorrelationDataDownloadExportFile,
  CorrelationDataFetchCorrelationData,
  CorrelationDataFetchCorrelationParameters,
  CorrelationDataSaveCorrelationParameters,
  CorrelationDataSetCorrelationData,
  CorrelationDataUpdateCorrelationData,
} from './correlation-data.actions'
import { catchError, map, tap } from 'rxjs/operators'
import { iif, of } from 'rxjs'
import { CorrelationDataService, SoilSurveyService } from '../../shared/remote-services'
import { CorrelationDataModel, CorrelationParametersModel } from '../../shared/models'
import { HttpResponse } from '@angular/common/http'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'

export interface CorrelationDataStateModel {
  correlationData: CorrelationDataModel[] | null
  correlationParameters: CorrelationParametersModel | null
}

@State<CorrelationDataStateModel>({
  name: 'correlationData',
  defaults: {
    correlationData: null,
    correlationParameters: null,
  },
})
@Injectable()
export class CorrelationDataState {
  constructor(
    private store: Store,
    private soilSurveyService: SoilSurveyService,
    private correlationDataService: CorrelationDataService,
  ) {}

  @Action(CorrelationDataFetchCorrelationData)
  public fetchCorrelationData(
    ctx: StateContext<CorrelationDataStateModel>,
    { soilSurveyId }: CorrelationDataFetchCorrelationData,
  ) {
    return this.correlationDataService.getCorrelationData(soilSurveyId).pipe(
      map((correlationData) => (correlationData.length ? correlationData : null)),
      tap((correlationData) => {
        ctx.setState(
          produce((draft) => {
            draft.correlationData = correlationData
          }),
        )
      }),
    )
  }

  @Action(CorrelationDataUpdateCorrelationData)
  public updateCorrelationData(
    ctx: StateContext<CorrelationDataStateModel>,
    { soilSurveyId, sbtNParametersModel }: CorrelationDataUpdateCorrelationData,
  ) {
    return this.correlationDataService.updateCorrelationData(soilSurveyId, sbtNParametersModel).pipe(
      map((correlationData) => (correlationData.length ? correlationData : null)),
      tap((correlationData) => {
        ctx.setState(
          produce((draft) => {
            draft.correlationData = correlationData
          }),
        )
      }),
    )
  }

  @Action(CorrelationDataSetCorrelationData)
  public setCorrelationData(
    ctx: StateContext<CorrelationDataStateModel>,
    { correlationData }: CorrelationDataSetCorrelationData,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.correlationData = correlationData
      }),
    )
  }

  @Action(CorrelationDataClearCorrelationData)
  public clearCorrelationData(ctx: StateContext<CorrelationDataStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.correlationData = null
      }),
    )
  }

  @Action(CorrelationDataFetchCorrelationParameters)
  public fetchCorrelationParameters(
    ctx: StateContext<CorrelationDataStateModel>,
    { soilSurveyId }: CorrelationDataFetchCorrelationParameters,
  ) {
    return this.correlationDataService.getCorrelationParameters(soilSurveyId).pipe(
      catchError(() => of(null)),
      tap((correlationParameters) => {
        ctx.setState(
          produce((draft) => {
            draft.correlationParameters = correlationParameters
          }),
        )
      }),
    )
  }

  @Action(CorrelationDataSaveCorrelationParameters)
  public saveOrUpdateCorrelationDataParameters(
    ctx: StateContext<CorrelationDataStateModel>,
    { soilSurveyId, correlationParameters }: CorrelationDataSaveCorrelationParameters,
  ) {
    return iif(
      () => correlationParameters.soilSurveyId == null,
      this.correlationDataService.saveCorrelationParameters(soilSurveyId, correlationParameters),
      this.correlationDataService.updateCorrelationParameters(soilSurveyId, correlationParameters),
    ).pipe(
      tap((correlationParameters) => {
        ctx.setState(
          produce((draft) => {
            draft.correlationParameters = correlationParameters
          }),
        )
      }),
    )
  }

  @Action(CorrelationDataDownloadExportFile)
  public downloadExportFile(
    ctx: StateContext<CorrelationDataStateModel>,
    { soilSurveyId, sbtNParametersModel }: CorrelationDataDownloadExportFile,
  ) {
    const currentLanguage = this.store.selectSnapshot(UserConfigStateSelectors.userLanguage)
    return this.correlationDataService.exportData(soilSurveyId, sbtNParametersModel, currentLanguage).pipe(
      map((resp: HttpResponse<Blob>) => ({
        url: resp.body ? URL.createObjectURL(resp.body) : '',
        filename: this.getFileNameFromHttpResponse(resp) || 'correlation_data.xlsx',
      })),
      tap(({ url, filename }) => {
        const downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.setAttribute('download', filename)
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.parentNode?.removeChild(downloadLink)
      }),
    )
  }

  private getFileNameFromHttpResponse(httpResponse: HttpResponse<unknown>) {
    const contentDispositionHeader = httpResponse.headers.get('Content-Disposition')
    const result = contentDispositionHeader?.split(';')[1].trim().split('=')[1]
    return result?.replace(/"/g, '')
  }
}
