import { Colors, SelectStatus } from '@sde-ild/ssd-soillib-lib'

export function statusColor(status: SelectStatus) {
  switch (status) {
    case SelectStatus.SELECTED:
      return Colors.ORANGE
    case SelectStatus.UNSELECTED:
      return Colors.GREEN
    case SelectStatus.DEACTIVATED:
      return Colors.RED
    default:
      return Colors.GREEN
  }
}
