import { Component, OnInit } from '@angular/core'
import { GoogleAnalyticsService } from './shared/google-analytics/google-analytics.service'

@Component({
  selector: 'soillib-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    this.googleAnalyticsService.init()
  }
}
