import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CorrelationParametersModel } from '../../shared/models'

export interface CorrelationParametersDialogInput {
  correlationParameter: CorrelationParametersModel
  hasCPT: boolean
  hasPressiom: boolean
}

@Component({
  selector: 'soillib-correlation-parameters-dialog',
  templateUrl: './correlation-parameters-dialog.component.html',
  styles: [
    `
      .form-correlation-parameters mat-form-field.large {
        width: 280px;
      }
    `,
  ],
})
export class CorrelationParametersDialog implements OnInit {
  correlationParamsFormGroup: FormGroup<{
    cptBx: FormControl<number | null>
    pressiomPhiPrimeCorrectingAngle: FormControl<number | null>
  }>

  correlationParametersDialogInput: CorrelationParametersDialogInput

  private defaultCptBx = 0.675

  constructor(
    public dialogRef: MatDialogRef<CorrelationParametersDialog>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) correlationParametersDialogInput: CorrelationParametersDialogInput,
  ) {
    this.correlationParametersDialogInput = correlationParametersDialogInput
    this.correlationParamsFormGroup = this.fb.group({
      cptBx: this.fb.control(this.defaultCptBx, Validators.required),
      pressiomPhiPrimeCorrectingAngle: this.fb.control(0, Validators.required),
    })
  }

  ngOnInit(): void {
    this.correlationParamsFormGroup.setValue({
      cptBx: this.correlationParametersDialogInput.correlationParameter?.cptBx ?? this.defaultCptBx,
      pressiomPhiPrimeCorrectingAngle:
        this.correlationParametersDialogInput.correlationParameter?.pressiomPhiPrimeCorrectingAngle ?? 0,
    })
  }

  close(): void {
    this.dialogRef.close(null)
  }
}
