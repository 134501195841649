import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ParsedSurvey } from '../../shared/models'
import { MessagesService, SoilSurveyService } from '../../shared/remote-services'
import Swal from 'sweetalert2'
import { FormControl } from '@angular/forms'
import { getDD, getNumber } from '../../shared/utils/ddToDms'
import { switchMap, tap } from 'rxjs/operators'
import { dmsLatValidator, dmsLonValidator, isPointInsideJobsite } from '../../shared/utils'
import { allBoreholeTypes, getBoreholeTypeName, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import {
  AppClearResource,
  AppLeaveSurvey,
  AppSetSelectedResource,
  AppSetSelectedSurvey,
} from '../../store/app/app.actions'
import { Select, Store } from '@ngxs/store'
import {
  CorrelationDataClearCorrelationData,
  CorrelationDataFetchCorrelationData,
} from '../../surveys-correlation-data/store/correlation-data.actions'
import { DataTableClearSurveyData } from '../../store/data-table/data-table.actions'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { Observable } from 'rxjs'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-surveys-edition-info-box',
  templateUrl: './surveys-edition-info-box.component.html',
  styleUrls: ['./surveys-edition-info-box.component.scss'],
})
export class SurveysEditionInfoBoxComponent implements OnInit {
  @Input()
  parsedSurvey: ParsedSurvey[]

  @Select(AppStateSelectors.slices.isModifyingSurvey) isModifyingSurvey$: Observable<boolean>
  @Select(AppStateSelectors.slices.validModifSurvey) validModifSurvey$: Observable<boolean>

  @Output() setMoveSurveyModeEvent: EventEmitter<boolean> = new EventEmitter()
  @Output() saveMovedSurveyEvent: EventEmitter<boolean> = new EventEmitter()
  @Output() deleteSurveyEvent: EventEmitter<string> = new EventEmitter()
  @Output() updateSurveyEvent: EventEmitter<SoilSurvey> = new EventEmitter()

  allBoreholeTypes: string[]

  constructor(
    private soilSurveyService: SoilSurveyService,
    private messagesService: MessagesService,
    private translateService: TranslateService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.allBoreholeTypes = allBoreholeTypes()
  }

  handleBlurInput(index) {
    setTimeout(() => (this.parsedSurvey[index].active = false), 200)
  }

  savedEditField(index: string | number, editedValue: string) {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        showConfirmButton: false,
        timer: 1000,
      })
    }
    let formValide = false
    const jobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    if (this.parsedSurvey[index].fieldName === 'Longitude') {
      const lonField = this.parsedSurvey.find((e) => e.fieldName === 'Latitude')?.fieldValue
      if (
        editedValue &&
        !dmsLonValidator(new FormControl(editedValue)) &&
        lonField &&
        isPointInsideJobsite([getDD(editedValue), getDD(lonField)], jobsite?.extent, true)
      ) {
        this.parsedSurvey[index].fieldValue = editedValue
        formValide = true
      }
    } else if (this.parsedSurvey[index].fieldName === 'Latitude') {
      const latField = this.parsedSurvey.find((e) => e.fieldName === 'Longitude')?.fieldValue
      if (
        !dmsLatValidator(new FormControl(editedValue)) &&
        latField &&
        isPointInsideJobsite([getDD(latField), getDD(editedValue)], jobsite?.extent, true)
      ) {
        this.parsedSurvey[index].fieldValue = editedValue
        formValide = true
      }
    } else if (this.parsedSurvey[index].fieldName === 'Type') {
      if (editedValue && editedValue.length > 0) {
        this.parsedSurvey[index].fieldValue = editedValue
        formValide = true
      }
    } else {
      this.parsedSurvey[index].fieldValue = editedValue
      formValide = true
    }
    if (formValide) {
      const jobsiteId = jobsite?.id
      if (jobsiteId) {
        const lonField = this.parsedSurvey.find((e) => e.fieldName === 'Longitude')?.fieldValue
        const latField = this.parsedSurvey.find((e) => e.fieldName === 'Latitude')?.fieldValue
        const datumDisplayName = this.store.selectSnapshot(AppStateSelectors.datumDisplayName)
        const formattedSurvey: SoilSurvey = {
          jobsite_id: jobsiteId,
          name: this.parsedSurvey
            .find((e) => e.fieldName === this.translateService.instant('SURVEY.NAME'))
            ?.fieldValue?.toString(),
          type: this.parsedSurvey.find((e) => e.fieldName === 'Type')?.fieldValue
            ? this.parsedSurvey.find((e) => e.fieldName === 'Type')?.fieldValue?.toString()
            : undefined,
          elevation: getNumber(
            this.parsedSurvey.find(
              (e) =>
                e.fieldName ===
                this.translateService.instant('SURVEY.ELEVATION', {
                  name: datumDisplayName,
                }),
            )?.fieldValue,
          ),
          local_x: getNumber(
            this.parsedSurvey.find((e) => e.fieldName === this.translateService.instant('SURVEY.COORD_X'))?.fieldValue,
          ),
          local_y: getNumber(
            this.parsedSurvey.find((e) => e.fieldName === this.translateService.instant('SURVEY.COORD_Y'))?.fieldValue,
          ),
          lon_coord: lonField ? getDD(lonField) : undefined,
          lat_coord: latField ? getDD(latField) : undefined,
          projection_ref: this.parsedSurvey
            .find((e) => e.fieldName === this.translateService.instant('SURVEY.PROJ_REF'))
            ?.fieldValue?.toString(),
          water_level: getNumber(
            this.parsedSurvey.find(
              (e) =>
                e.fieldName ===
                this.translateService.instant('SURVEY.WATER_LEVEL', {
                  name: datumDisplayName,
                }),
            )?.fieldValue,
          ),
        }
        const selectedSurvey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)
        const soilSurveyId = selectedSurvey?.id
        if (soilSurveyId) {
          const surveyName = selectedSurvey?.name
          this.soilSurveyService
            .updateSoilSurvey(jobsiteId, soilSurveyId, formattedSurvey)
            .pipe(
              switchMap(() =>
                this.messagesService.saveResourceAndJobsiteMessage$(
                  jobsiteId,
                  surveyName,
                  'borehole',
                  'modified meta info of',
                ),
              ),
            )
            .subscribe({
              next: () => {
                const updatedSurvey = { ...formattedSurvey, id: soilSurveyId }
                this.updateSurveyEvent.emit(updatedSurvey)
                this.store.dispatch([
                  new AppSetSelectedSurvey(updatedSurvey),
                  new AppSetSelectedResource({
                    type: 'borehole',
                    id: soilSurveyId,
                  }),
                  new CorrelationDataFetchCorrelationData(soilSurveyId),
                ])
              },
              error: () => {
                return Swal.fire({
                  title: this.translateService.instant('ALERT.FAILED'),
                  text: this.translateService.instant('ALERT.SAVE_FAILED'),
                  icon: 'error',
                  confirmButtonText: 'Ok',
                })
              },
            })
        }
      }
    } else {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: 'The new value is not valid or it makes the borehole being outside of jobsite extent',
        icon: 'warning',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }

  deleteSurvey() {
    const selectedSurvey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        showConfirmButton: false,
        timer: 1000,
      })
    }
    Swal.fire({
      title: this.translateService.instant('GENERAL.SURE'),
      text: this.translateService.instant('GENERAL.NO_REVERT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
      confirmButtonText: this.translateService.instant('GENERAL.DELETE'),
    }).then((result) => {
      if (result.value && selectedSurvey) {
        this.soilSurveyService
          .deleteSoilSurvey(selectedSurvey)
          .pipe(
            switchMap(() =>
              this.messagesService.saveResourceAndJobsiteMessage$(
                this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId),
                selectedSurvey.name,
                'borehole',
                'deleted the',
              ),
            ),
            tap(() => this.deleteSurveyEvent.emit(selectedSurvey.id ?? undefined)),
            tap(() =>
              this.store.dispatch([
                AppLeaveSurvey,
                AppClearResource,
                CorrelationDataClearCorrelationData,
                DataTableClearSurveyData,
              ]),
            ),
          )
          .subscribe({
            next: () => {},
            error: () => {
              return Swal.fire({
                title: this.translateService.instant('ALERT.FAILED'),
                text: this.translateService.instant('ALERT.DELETE_FAILED'),
                icon: 'error',
                confirmButtonText: 'Ok',
              })
            },
          })
      }
    })
  }

  showFieldValue(field) {
    if (field.fieldName === 'Type') {
      return getBoreholeTypeName(field.fieldValue, this.translateService)
    }
    return field.fieldValue
  }

  showBoreholeTypeName(type) {
    return getBoreholeTypeName(type, this.translateService)
  }

  moveSurvey() {
    this.setMoveSurveyModeEvent.emit(true)
  }

  saveMovedSurvey() {
    this.saveMovedSurveyEvent.emit(true)
  }

  cancelMovedSurvey() {
    this.setMoveSurveyModeEvent.emit(false)
  }
}
