import { fromLonLat as FROMLONLAT } from 'ol/proj'
import { Coordinate } from 'ol/coordinate'

export function parseExtentString(extent: string): Coordinate[] | null {
  if (extent) {
    return extent
      .replace(/[^0-9 ,.-]/g, '')
      .split(',')
      .map((a) => a.trim().split(' '))
      .map((a) => [Number(a[0]), Number(a[1])])
      .map((coord: any) => {
        return FROMLONLAT(coord)
      })
  } else {
    return null
  }
}
