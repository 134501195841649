// This function returns the coordinate
// conversion string in DD to DMS.

export function ddToDmsLat(latitude: number | string): string {
  const lat = typeof latitude === 'string' ? parseFloat(latitude) : latitude
  const orien = lat >= 0 ? 'N' : 'S'
  return getDms(lat) + orien
}

export function ddToDmsLng(longitude: number | string): string {
  const lng = typeof longitude === 'string' ? parseFloat(longitude) : longitude
  const orien = lng >= 0 ? 'E' : 'W'
  return getDms(lng) + orien
}

export function dmsToDd(value: string): string | null {
  const str = value.trim()
  const matchPattern = new RegExp(/^(\d{1,3})[\u00b0](\d{1,2})[']([0-9]*[.]?[0-9]+)["][N|S|E|W]$/i)
  const matches = str.match(matchPattern)
  let sign = ''
  if (str[str.length - 1].toLowerCase() === 's' || str[str.length - 1].toLowerCase() === 'w') {
    sign = '-'
  }
  if (matches) {
    return sign + (parseInt(matches[1], 10) + parseInt(matches[2], 10) / 60 + parseFloat(matches[3]) / 3600)
  }
  return null
}

export function getDD<T extends { toString: () => string }>(val: T): number {
  const value = val.toString().trim()
  if (/^[+-]?([0-9]*[.])?[0-9]+$/.test(value)) {
    return Number(value)
  } else {
    return Number(dmsToDd(value))
  }
}

export const getNumber = (val: string | number | string[] | undefined) =>
  !isNaN(Number(val)) ? Number(val) : undefined

function getDms(val: number): string {
  let result: string

  val = Math.abs(val)

  const valDeg = Math.floor(val)
  result = valDeg + '°'

  const valMin = Math.floor((val - valDeg) * 60)
  result += valMin + "'"

  const valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000
  result += valSec + '"'

  return result
}
