import { Component, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core'
import * as Highcharts from 'highcharts'
import { SoilSurveyService } from '../../shared/remote-services'
import { TranslateService } from '@ngx-translate/core'
import { addSeriesAndYaxis, getTechniqueAvailableParameters, SoilSurvey, SurveyData } from '@sde-ild/ssd-soillib-lib'
import { dynamicTickIntervalsWhenZooming, isNumber } from '../../shared/utils'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../store/app/app.selectors'

@Component({
  selector: 'soillib-surveys-comparison-chart',
  template: `
    <mat-icon *ngIf="dragDrop" style="float:left;">drag_indicator</mat-icon>
    <div style="width: 25vw;" *ngIf="hasData" id="{{ chartIndex }}"></div>
    <div style="margin-top: 25vh" *ngIf="!hasData">{{ 'COMPARISON.NO_DATA' | translate }}{{ survey.name }}</div>
  `,
  styles: [],
})
export class SurveysComparisonChartComponent implements AfterViewInit, OnChanges {
  @Input() survey: SoilSurvey
  @Input() chartIndex: number
  @Input() dragDrop: string
  @Input() elevationMode: boolean

  chartRef: Highcharts.Chart | null
  hasData = true

  private surveyData: SurveyData[]

  constructor(
    private soilSurveyService: SoilSurveyService,
    private translateService: TranslateService,
    private store: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.elevationMode && !changes.elevationMode.firstChange) {
      this.initChart()
      this.drawGraph()
    }
  }

  ngAfterViewInit() {
    this.initChart()
    if (this.survey.id) {
      this.soilSurveyService.getSurveyData(this.survey.id).subscribe({
        next: (data) => {
          this.surveyData = data
          this.drawGraph()
        },
        error: () => {
          this.hasData = false
        },
      })
    }
  }

  private initChart() {
    if (this.chartRef) {
      this.chartRef.destroy()
      this.chartRef = null
    }
    const options: Highcharts.Options = {
      chart: {
        zoomType: 'xy',
        inverted: true,
      },
      title: {
        text: this.survey.name ?? undefined,
      },
      boost: {
        useGPUTranslations: true,
      },
      xAxis: {
        title: {
          text: this.activeElevation()
            ? this.translateService.instant('SURVEY.ELEVATION', {
                name: this.store.selectSnapshot(AppStateSelectors.datumDisplayName),
              })
            : this.translateService.instant('GENERAL.DEPTH'),
        },
        reversed: !this.activeElevation(),
        crosshair: true,
        tickInterval: 5,
        events: {
          setExtremes: dynamicTickIntervalsWhenZooming(5),
        },
        gridLineWidth: 1,
      },
      yAxis: [],
      plotOptions: {
        series: {
          connectNulls: true,
        },
      },
      tooltip: {
        formatter() {
          return `<div>${this.x}</div><br><span style="color: ${this.color}">${this.series.name}</span>: <b> ${this.y} </b>`
        },
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
      },
      credits: {
        enabled: false,
      },
    }
    this.chartRef = Highcharts.chart(this.chartIndex.toString(), options)
  }

  private activeElevation() {
    return this.elevationMode && this.survey.elevation !== null && this.survey.elevation !== undefined
  }

  private drawGraph() {
    if (!this.surveyData) {
      return
    } else if (this.surveyData.length === 0) {
      this.hasData = false
      return
    }
    const parasForType = this.survey.type
      ? getTechniqueAvailableParameters(this.survey.type, false).filter((para) => para !== 'depth')
      : null
    const depthData: number[] = []
    const colNames = Object.keys(this.surveyData[0]).filter((col) => parasForType?.includes(col))
    this.surveyData.forEach((row) => {
      if (isNumber(row.depth)) {
        const depth = this.activeElevation() && this.survey.elevation ? this.survey.elevation - row.depth : row.depth
        depthData.push(depth)
      }
    })
    colNames.forEach((colName) => {
      let colData: number[][] | string[] | null = []
      this.surveyData.forEach((row) => {
        colData?.push(row[colName])
      })
      colData = colData
        .map((colD, index) => (colD ? [+depthData[index], +colD] : null))
        .filter((el) => el != null)
        .map((el) => el as number[])
      if (!colData.find((el) => el !== null)) {
        colData = null
      } else {
        colData.sort((a, b) => (a ? a[0] : 0) - (b ? b[0] : 0))
        if (this.chartRef) {
          addSeriesAndYaxis(this.chartRef, colName, colData, undefined, undefined)
        }
      }
    })
    this.chartRef?.redraw()
  }
}
