import { Coordinate } from 'ol/coordinate'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Jobsite, LatLonLocation, Zone } from '../../shared/models'

export class MapSetSelectedLocation {
  public static readonly type = '[Map] set map selected location'

  constructor(readonly location: LatLonLocation) {}
}
export class MapClearSelectedLocation {
  public static readonly type = '[Map] clear map selected location'
}
export class MapSetVisibleEntities {
  public static readonly type = '[Map] set jobsites, zones and surveys'

  constructor(
    readonly payload: {
      jobsites: Jobsite[] | null
      surveys: SoilSurvey[] | null
      zones: Zone[] | null
    },
  ) {}
}
export class MapSetSurveys {
  public static readonly type = '[Map] set surveys'

  constructor(readonly surveys: SoilSurvey[] | null) {}
}
export class MapUpdateSurveyLocation {
  public static readonly type = '[Map] update location of a survey'

  constructor(
    readonly payload: {
      surveyId: string | undefined
      location: Coordinate
    },
  ) {}
}
export class MapUpdateSurvey {
  public static readonly type = '[Map] update a whole survey'

  constructor(readonly updatedSurvey: SoilSurvey) {}
}
export class MapSetSurveysOnMap {
  public static readonly type = '[Map] set surveys entities and surveys to draw'

  constructor(readonly surveysArray: SoilSurvey[]) {}
}
export class MapClearSurveysOnMap {
  public static readonly type = '[Map] clear surveys entities and surveys to draw'
}
export class MapSetZones {
  public static readonly type = '[Map] set zones'

  constructor(readonly zones: Zone[] | null) {}
}
export class MapUpdateZoneExtent {
  public static readonly type = '[Map] update extent of a zone'

  constructor(
    readonly payload: {
      zoneId: string | undefined
      extent: string
    },
  ) {}
}

export class MapIsAddingZone {
  public static readonly type = '[Map] IsAddingZone'

  constructor(readonly isAddingZone: boolean) {}
}

export class MapIsAddingJobsiteExtent {
  public static readonly type = '[Map] IsAddingJobsiteExtent'

  constructor(readonly isAddingJobsiteExtent: boolean) {}
}

export class MapIsModifyingJobsiteExtent {
  public static readonly type = '[Map] IsModifyingJobsiteExtent'

  constructor(readonly isJobsiteExtentModifying: boolean) {}
}

export class MapIsModifyingJobsiteCoords {
  public static readonly type = '[Map] IsModifyingJobsiteCoords'

  constructor(readonly isJobsiteCoordsModifying: boolean) {}
}

export class MapIsDrawingPolygon {
  public static readonly type = '[Map] IsDrawingPolygon'

  constructor(readonly isDrawingPolygon: boolean) {}
}
