import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { EpsgProjModel, ImportedBorehole, ImportedLocal, Jobsite } from '../../shared/models'
import { EpsgProjectionService, FileParseService } from '../../shared/services'
import { forkJoin, Observable } from 'rxjs'
import { cloneDeep } from 'lodash'
import { AGS4, GEOL, SCPP, SoilSurvey, SoilTypeUtilsService, SurveyData } from '@sde-ild/ssd-soillib-lib'
import { AppSetLoading } from '../../store/app/app.actions'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../store/app/app.selectors'

type GroupeAliases = 'ID' | 'TOP_STRATUM' | 'BOTTOM_STRATUM' | 'SOIL_TYPE'

@Component({
  selector: 'soillib-surveys-import-ags',
  templateUrl: './surveys-import-ags.component.html',
  styleUrls: ['./surveys-import-ags.component.scss'],
})
export class SurveysImportAGSComponent implements OnInit {
  @Input()
  level: string

  @Input()
  currentJobsite: Jobsite | null

  @Input()
  isSBT = false

  @Output()
  updateSurveysToMapEvent: EventEmitter<{ deleteIds: string[]; newSurveys: SoilSurvey[] }> = new EventEmitter()

  @Output()
  finishAgsImportEvent: EventEmitter<Jobsite> = new EventEmitter()

  private GEOLDataArray: (string | number)[][]
  private LOCADataArray: (string | number)[][]
  private PROJDataArray: (string | number)[][]
  private SCPPDataArray: (string | number)[][]
  detectedProjections: string[]
  detectedJobsiteNames: string[]
  selectedJobsiteName: string | null

  private LOCA_MAP: Record<string, ImportedLocal>
  private SOIL_TYPE_MAP: Record<string, Record<string, string | number>[]>

  allAGSBoreholes: ImportedBorehole[]
  allImportedBoreholes: ImportedBorehole[]

  loadingText: string
  customProjInfoText: string | null
  customProjErrorText: string | null

  AGS_Proj: object

  selectedFiles: File[]

  constructor(
    private fileParseService: FileParseService,
    private epsgProjectionService: EpsgProjectionService,
    private soilTypeUtilsService: SoilTypeUtilsService,
    private store: Store,
  ) {
    this.AGS_Proj = this.epsgProjectionService.Known_Projs
  }

  ngOnInit() {
    this.initData()
  }

  private initData() {
    this.GEOLDataArray = []
    this.LOCADataArray = []
    this.PROJDataArray = []
    this.SCPPDataArray = []
    this.detectedProjections = []
    this.detectedJobsiteNames = []
    this.allAGSBoreholes = []
    this.allImportedBoreholes = []
    this.LOCA_MAP = {}
    this.SOIL_TYPE_MAP = {}
    this.loadingText = 'LOADING...'
    this.customProjInfoText = null
    this.customProjErrorText = null
    this.selectedJobsiteName = null
  }

  handleFilesSelected(files: File[]) {
    this.selectedFiles = files
    this.initData()
    const observableParsedData: Observable<(string | number)[][]>[] = []
    this.selectedFiles.forEach((file) => {
      if (file) {
        observableParsedData.push(this.fileParseService.parseFile(file))
      }
    })
    this.store.dispatch(new AppSetLoading(true))
    forkJoin(observableParsedData).subscribe({
      next: (agsDataArr: (string | number)[][][]) => {
        if (agsDataArr && agsDataArr.length > 0) {
          agsDataArr.forEach((data) => {
            this.getGroupDataArray(data)
            this.parsePROJ()
            this.parseLOCA()
            if (this.GEOLDataArray?.length) {
              this.getInfosFromAGS(this.GEOLDataArray, GEOL)
            } else if (this.SCPPDataArray?.length) {
              this.getInfosFromAGS(this.SCPPDataArray, SCPP)
            }
          })
          this.selectedJobsiteName = this.detectedJobsiteNames[0] ? this.detectedJobsiteNames[0] : ''

          if (this.allAGSBoreholes.length > 0) {
            this.allImportedBoreholes = cloneDeep(this.allAGSBoreholes)
          }
        }
      },
      error: (error) => {
        console.error('AGS parse errors: ' + error)
      },
      complete: () => {
        this.store.dispatch(new AppSetLoading(false))
        if (this.LOCADataArray.length === 0) {
          this.loadingText = 'Please check your AGS version. For now, we support only AGS4 '
        } else if (this.allAGSBoreholes.length === 0) {
          this.loadingText = 'No boreholes found'
        }
      },
    })
  }

  setJobsiteName(name: string) {
    this.selectedJobsiteName = name
  }

  applyProjection(value: string) {
    if (this.allAGSBoreholes.length > 0) {
      this.customProjInfoText = null
      this.customProjErrorText = null
      this.epsgProjectionService.searchEpsg(value).subscribe((res: EpsgProjModel) => {
        if (res) {
          this.allAGSBoreholes.forEach((hole) => {
            if (hole.survey_basic) {
              const coords =
                hole.survey_basic.local_x != null && hole.survey_basic.local_y != null
                  ? this.epsgProjectionService.toLonLat(res, [hole.survey_basic.local_x, hole.survey_basic.local_y])
                  : null
              hole.survey_basic.local_proj = res.code
              hole.survey_basic.projection_ref = res.code
              hole.survey_basic.lon_coord = coords ? coords[0] : undefined
              hole.survey_basic.lat_coord = coords ? coords[1] : undefined
            }
          })

          this.allImportedBoreholes = cloneDeep(this.allAGSBoreholes)
          this.customProjInfoText = res.name
        } else {
          this.customProjErrorText = 'Projection not valid!'
        }
      })
    }
  }

  onBoreholeTypeChange(value: string) {
    this.allAGSBoreholes.forEach((hole) => {
      if (hole.survey_basic) {
        hole.survey_basic.type = value
      }
    })
    this.allImportedBoreholes = cloneDeep(this.allAGSBoreholes)
  }

  onProjSelectionChange(value: string) {
    const local_proj: EpsgProjModel = this.AGS_Proj[value]
    this.allAGSBoreholes.forEach((hole) => {
      if (hole.survey_basic) {
        const coords =
          hole.survey_basic.local_x != null && hole.survey_basic.local_y != null
            ? this.epsgProjectionService.toLonLat(local_proj, [hole.survey_basic.local_x, hole.survey_basic.local_y])
            : null
        hole.survey_basic.local_proj = value
        hole.survey_basic.projection_ref = this.AGS_Proj[value].code
        hole.survey_basic.lon_coord = coords ? coords[0] : undefined
        hole.survey_basic.lat_coord = coords ? coords[1] : undefined
      }
    })

    this.allImportedBoreholes = cloneDeep(this.allAGSBoreholes)
    this.customProjErrorText = null
    this.customProjInfoText = null
  }

  private getInfosFromAGS(dataArray: (string | number)[][], GROUP: Record<GroupeAliases, string>) {
    const jobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
    const Heading: string[] = dataArray.filter((ele) => ele[0] === AGS4.HEADING)[0].map((value) => String(value))
    if (Heading && Heading.indexOf(GROUP.ID)) {
      this.SOIL_TYPE_MAP = this.groupBy(
        dataArray.filter((ele) => ele[0] === AGS4.DATA),
        Heading.indexOf(GROUP.ID),
      ) // using LOCA_ID as group key
    }
    for (const key of Object.keys(this.LOCA_MAP)) {
      const lonLat = this.getLonLat(this.LOCA_MAP[key])
      const survey_basic: SoilSurvey = {
        jobsite_id: jobsiteId || '',
        name: key,
        type: 'CAROTTE',
        lat_coord: this.LOCA_MAP[key] && lonLat ? lonLat[1] : undefined,
        lon_coord: this.LOCA_MAP[key] && lonLat ? lonLat[0] : undefined,
        local_x: this.LOCA_MAP[key] ? this.LOCA_MAP[key].easting : undefined,
        local_y: this.LOCA_MAP[key] ? this.LOCA_MAP[key].northing : undefined,
        projection_ref: this.getProjectionCode(this.LOCA_MAP[key]),
        local_proj: this.LOCA_MAP[key] ? this.LOCA_MAP[key].projection : undefined,
        elevation: this.LOCA_MAP[key] ? this.LOCA_MAP[key].elevation : undefined,
      }
      const useTOP = this.SOIL_TYPE_MAP[key]?.every((item) => !item[Heading?.indexOf(GROUP.BOTTOM_STRATUM)])
      const survey_data: SurveyData[] = this.SOIL_TYPE_MAP[key]
        ? useTOP
          ? this.getSurveyDataViaTOP(key, Heading, GROUP.TOP_STRATUM, GROUP.SOIL_TYPE)
          : this.getSurveyData(key, Heading, GROUP.BOTTOM_STRATUM, GROUP.SOIL_TYPE)
        : []

      this.allAGSBoreholes.push({
        survey_basic,
        survey_data,
      })
    }
  }

  private getSurveyData(key: string, Heading: string[], depth_key: string, soil_type_key: string): SurveyData[] {
    return this.SOIL_TYPE_MAP[key]
      .map((item) => {
        const headingSoilTypeIndex = Heading?.indexOf(soil_type_key)
        const depth = Heading?.indexOf(depth_key) ? +item[Heading.indexOf(depth_key)] : undefined
        if (headingSoilTypeIndex) {
          const geoSoil = String(item[headingSoilTypeIndex])
          return {
            depth,
            geo_soil: geoSoil,
            calculated_soil: this.soilTypeUtilsService.parseSoilType(geoSoil, this.isSBT) ?? undefined,
          }
        } else {
          return { depth }
        }
      })
      .filter((item: SurveyData) => item.depth !== undefined)
      .sort((a: SurveyData, b: SurveyData) => (a.depth || 0) - (b.depth || 0))
  }

  private getSurveyDataViaTOP(key: string, Heading: string[], depth_key: string, soil_type_key: string) {
    const surveyData = this.getSurveyData(key, Heading, depth_key, soil_type_key)
    return surveyData
      .map((item, index) => {
        return {
          ...item,
          geo_soil: index > 0 ? surveyData[index - 1].geo_soil : item.geo_soil,
          calculated_soil: index > 0 ? surveyData[index - 1].calculated_soil : item.calculated_soil,
        }
      })
      .filter((item) => item.depth && item.depth > 0)
  }

  updateSurveysToMap(value: { deleteIds: string[]; newSurveys: SoilSurvey[] }) {
    this.initData()
    this.updateSurveysToMapEvent.emit(value)
  }

  finishAgsImport(value) {
    this.initData()
    this.finishAgsImportEvent.emit(value)
  }

  private getLonLat(AGSLocal: ImportedLocal): number[] | undefined {
    const localProjDefinition = this.epsgProjectionService.Known_Projs
    if (AGSLocal && localProjDefinition[AGSLocal.projection]) {
      if (AGSLocal.easting !== undefined && AGSLocal.northing !== undefined) {
        return this.epsgProjectionService.toLonLat(localProjDefinition[AGSLocal.projection], [
          AGSLocal.easting,
          AGSLocal.northing,
        ])
      }
    }
    return undefined
  }

  private getProjectionCode(AGSLocal: ImportedLocal): undefined | string {
    const localProjDefinition = this.epsgProjectionService.Known_Projs
    if (localProjDefinition[AGSLocal.projection]) {
      return localProjDefinition[AGSLocal.projection].code
    }
    return undefined
  }

  private parseLOCA() {
    if (this.LOCADataArray && this.LOCADataArray.length > 0) {
      this.LOCA_MAP = {}
      const dataArray = this.LOCADataArray.filter((ele) => ele[0] === 'DATA')
      const heading = this.LOCADataArray.filter((ele) => ele[0] === 'HEADING')[0]
      if (
        heading &&
        heading.indexOf('LOCA_ID') &&
        heading.indexOf('LOCA_NATE') &&
        heading.indexOf('LOCA_NATN') &&
        heading.indexOf('LOCA_GREF')
      ) {
        dataArray.map((ele) => {
          this.LOCA_MAP[ele[heading.indexOf('LOCA_ID')]] = {
            id: String(ele[heading.indexOf('LOCA_ID')]),
            easting: +ele[heading.indexOf('LOCA_NATE')],
            northing: +ele[heading.indexOf('LOCA_NATN')],
            projection: String(ele[heading.indexOf('LOCA_GREF')]),
            elevation: heading.indexOf('LOCA_GL') ? +ele[heading.indexOf('LOCA_GL')] : undefined,
          }
          if (
            ele[heading.indexOf('LOCA_GREF')] &&
            this.detectedProjections.indexOf(String(ele[heading.indexOf('LOCA_GREF')])) < 0
          ) {
            this.detectedProjections.push(String(ele[heading.indexOf('LOCA_GREF')]))
          }
        })
      }
    }
  }

  private parsePROJ() {
    if (this.PROJDataArray && this.PROJDataArray.length > 0) {
      const dataArray = this.PROJDataArray.filter((ele) => ele[0] === 'DATA')
      const heading = this.PROJDataArray.filter((ele) => ele[0] === 'HEADING')[0]
      if (heading && heading.indexOf('PROJ_NAME')) {
        dataArray.forEach((ele) => {
          if (
            ele[heading.indexOf('PROJ_NAME')] &&
            this.detectedJobsiteNames.indexOf(String(ele[heading.indexOf('PROJ_NAME')])) < 0
          ) {
            this.detectedJobsiteNames.push(String(ele[heading.indexOf('PROJ_NAME')]))
          }
        })
      }
    }
  }

  private groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  private getGroupDataArray(data: (string | number)[][]) {
    this.GEOLDataArray = []
    this.LOCADataArray = []
    this.PROJDataArray = []
    this.SCPPDataArray = []
    let isPROJ = false
    let isGEOL = false
    let isLOCA = false
    let isSCPP = false
    data.forEach((ele) => {
      if (ele[0] === 'GROUP' && ele[1] === 'PROJ') {
        isPROJ = true
      }
      if (isPROJ && ele[0] === 'GROUP' && ele[1] !== 'PROJ') {
        isPROJ = false
      }
      if (isPROJ) {
        this.PROJDataArray.push(ele)
      }
      if (ele[0] === 'GROUP' && ele[1] === 'GEOL') {
        isGEOL = true
      }
      if (isGEOL && ele[0] === 'GROUP' && ele[1] !== 'GEOL') {
        isGEOL = false
      }
      if (isGEOL) {
        this.GEOLDataArray.push(ele)
      }
      if (ele[0] === 'GROUP' && ele[1] === 'LOCA') {
        isLOCA = true
      }
      if (isLOCA && ele[0] === 'GROUP' && ele[1] !== 'LOCA') {
        isLOCA = false
      }
      if (isLOCA) {
        this.LOCADataArray.push(ele)
      }
      if (ele[0] === 'GROUP' && ele[1] === 'SCPP') {
        isSCPP = true
      }
      if (isSCPP && ele[0] === 'GROUP' && ele[1] !== 'SCPP') {
        isSCPP = false
      }
      if (isSCPP) {
        this.SCPPDataArray.push(ele)
      }
    })
  }
}
