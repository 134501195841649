import { Injectable } from '@angular/core'
import { map, catchError } from 'rxjs/operators'
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http'
import { Observable, throwError, BehaviorSubject, Subscription, EMPTY } from 'rxjs'
import * as FileSaver from 'file-saver'
import { UploadEventModel } from '../models/upload-event.model'
import { UploadFileModel } from '../models/upload-file.model'
import { generateUUID } from '../utils'
import { FileModel, MapFileModel } from '../models/file.model'

@Injectable()
export class FilesService {
  constructor(private http: HttpClient) {}

  get uploadFiles(): UploadFileModel[] | null {
    return this._uploadFiles.value
  }

  set uploadFiles(value: UploadFileModel[] | null) {
    this._uploadFiles.next(value)
  }

  private _uploadFiles: BehaviorSubject<UploadFileModel[] | null> = new BehaviorSubject(null)
  public uploadFiles$ = this._uploadFiles.asObservable()

  private subscriptionArray: { id: string; sub: Subscription }[] = []

  uploadFilesArray: UploadFileModel[] = []

  getMapFileList(jobsiteId: string): Observable<MapFileModel[]> {
    return this.http.get<MapFileModel[]>('api/files/map/' + jobsiteId)
  }

  getReportsInfo(jobsiteId: string): Observable<FileModel[]> {
    return this.http.get<FileModel[]>('api/files/list/' + jobsiteId)
  }

  downloadFile(jobsiteId: string, fileName: string) {
    return this.http.get(`api/files/${jobsiteId}/${fileName}`, { responseType: 'arraybuffer' }).subscribe((file) => {
      const blob = new Blob([file], { type: 'application/pdf' })
      FileSaver.saveAs(blob, `${fileName}`)
    })
  }

  deleteFile(jobsiteId: string, fileName: string) {
    return this.http.delete(`api/files/${jobsiteId}/${fileName}`)
  }

  uploadFile(file: File, jobsiteId: string, fileId: string): Observable<UploadEventModel> {
    // File size greater than 1 Go
    if (file.size > 1000000000) {
      return throwError(() => new Error('Can not upload a file bigger than 1Go'))
    }

    const formData = new FormData()
    formData.append('file', file)

    const uploadReq = new HttpRequest('POST', `api/files/${jobsiteId}`, formData, {
      reportProgress: true,
    })

    return this.http.request(uploadReq).pipe(
      map((event) => {
        const result = { loaded: false, progress: 0 }

        if (event.type === HttpEventType.UploadProgress) {
          result.progress = Math.round((100 * event.loaded) / (event.total || 0))
        } else if (event.type === HttpEventType.Response) {
          result.loaded = true
          result.progress = 100
        }
        this.updateFile(fileId, result)
        this.uploadFiles = this.uploadFilesArray
        return result
      }),
      catchError((error) => {
        this.updateFileWithError(fileId, error.json())
        this.uploadFiles = this.uploadFilesArray
        return EMPTY
      }),
    )
  }

  private updateFile(fileId: string, progressInfo: { loaded: boolean; progress: number }) {
    for (let i = 0; i < this.uploadFilesArray.length; i++) {
      if (fileId === this.uploadFilesArray[i].id) {
        this.uploadFilesArray[i] = {
          ...this.uploadFilesArray[i],
          loaded: progressInfo.loaded,
          progress: progressInfo.progress,
        }
        break
      }
    }
  }

  private updateFileWithError(fileId: string, errorMessage: string) {
    for (let i = 0; i < this.uploadFilesArray.length; i++) {
      if (fileId === this.uploadFilesArray[i].id) {
        this.uploadFilesArray[i] = { ...this.uploadFilesArray[i], inError: true, errorDesc: errorMessage }
        break
      }
    }
  }

  private updateRetryUpload(fileId: string) {
    for (let i = 0; i < this.uploadFilesArray.length; i++) {
      if (fileId === this.uploadFilesArray[i].id) {
        this.uploadFilesArray[i] = { ...this.uploadFilesArray[i], inError: false, errorDesc: '' }
        break
      }
    }
  }

  handleFilesToUpload(files: File[], jobsiteId: string) {
    for (const file of files) {
      const uploadFile: UploadFileModel = {
        id: generateUUID(),
        jobsiteId,
        file,
        inError: false,
        errorDesc: '',
        loaded: false,
        progress: 0,
      }
      this.uploadFilesArray.push(uploadFile)
      this.subscriptionArray.push({
        id: uploadFile.id,
        sub: this.uploadFile(uploadFile.file, jobsiteId, uploadFile.id).subscribe(() => {}),
      })
    }
  }

  cancelUploadRequest(fileId: string) {
    this.updateFileWithError(fileId, 'Cancelled')
    this.uploadFiles = this.uploadFilesArray
    const cancelsub: { id: string; sub: Subscription } = this.subscriptionArray.filter((item) => item.id === fileId)[0]
    cancelsub.sub.unsubscribe()
  }

  retryUploadRequest(file: UploadFileModel) {
    this.updateRetryUpload(file.id)
    this.uploadFiles = this.uploadFilesArray
    const filesub = this.subscriptionArray.filter((item) => item.id === file.id)[0]
    filesub.sub = this.uploadFile(file.file, file.jobsiteId, file.id).subscribe(() => {})
  }
}
