<ng-container
  *ngIf="{
    bottomTabOpened: bottomTabOpened$ | async,
    isModifyingSurvey: isModifyingSurvey$ | async,
    isModifyingZone: isModifyingZone$ | async
  } as state"
>
  <div id="map" class="map"></div>
  <soillib-overlays
    #overlays
    [zonesLayer]="zonesLayer"
    (goToJobsiteEvent)="clickJobsiteOnMap($event)"
    (goToSurveyEvent)="goToSurvey($event)"
  ></soillib-overlays>

  <!--Draggable popups-->
  <div [@slideInOutFromTop]="true" class="mode-edit" *ngIf="state.isModifyingZone || state.isModifyingSurvey">
    <div id="drag-edit" soillibDraggable="true">
      <p>{{ 'MAINMAP.MODIFYING' | translate }}</p>
      <p *ngIf="state.isModifyingSurvey">{{ surveyCoordinateDms }}</p>
    </div>
  </div>

  <soillib-edit-jobsite-draggable
    *ngIf="(showJobsiteEditPopup$ | async) === true"
    (closeJobsiteEditEvent)="closeJobsiteEdit()"
    (handleJobsiteEditOptionChangeEvent)="handleJobsiteEditOptionChange($event)"
    (updateJobsiteExtentCoordEvent)="updateJobsiteExtentCoord($event)"
  ></soillib-edit-jobsite-draggable>

  <soillib-search-address-draggable
    *ngIf="(showSearchAddressPopup$ | async) === true"
    class="search-address-popup"
    (saveAddressEvent)="saveAddress($event)"
    (closePopupEvent)="closeSaveAddressPopup($event)"
    (handleSelectedAddressEvent)="handleSelectedAddressFromPopup($event)"
  ></soillib-search-address-draggable>

  <soillib-new-jobsite-draggable
    *ngIf="(showNewJobsitePopup$ | async) === true"
    class="new-jobsite-popup"
    (createNewJobsiteEvent)="createNewJobsite($event)"
    (closePopupEvent)="closeNewJobsitePopup()"
    (handleSelectedAddressEvent)="handleSelectedAddressFromPopup($event)"
  ></soillib-new-jobsite-draggable>

  <!--Draggable popups-->

  <ng-container *ngIf="!state.bottomTabOpened">
    <!--Search bar-->
    <div id="search-box">
      <soillib-search-box
        (selectedJobsite)="handleSearchSelection($event)"
        (fitToSelectedPlaceEvent)="fitToSelectedPlace($event)"
      ></soillib-search-box>
    </div>
    <!--Search bar-->

    <!--Create jobsite button-->
    <div class="create-button">
      <button mat-raised-button color="warn" (click)="createAJobsite()">
        {{ 'MAINMAP.CREATE_JOBSITE' | translate }}
      </button>
    </div>

    <!--Jobsite infos box on top middle-->
    <soillib-jobsite-box
      (fitToVisibleMapEvent)="fitToVisibleMap($event)"
      (toggleJobsiteEditPopupEvent)="toggleJobsiteEditPopup($event)"
      (resetMapViewEvent)="resetMapView()"
      (goToNewJobsiteEvent)="gotoJobsite($event)"
    >
    </soillib-jobsite-box>
    <!--Jobsite infos box on top middle-->

    <div id="filter-container">
      <soillib-filter (filterSurveys)="drawSurveys()" (filterSurveysByType)="onFilterOptionsChange($event)">
      </soillib-filter>
    </div>

    <div *ngIf="selectedJobsite$ | async as selectedJobsite" id="statistics-container">
      <soillib-jobsite-statistics [selectedJobsite]="selectedJobsite"></soillib-jobsite-statistics>
    </div>
  </ng-container>

  <div
    id="surveys-edition-container"
    *ngIf="state.bottomTabOpened !== 'soil-cutting-edition' && state.bottomTabOpened !== 'borehole-comparison'"
  >
    <soillib-surveys-edition
      (addSurveyEvent)="addSurvey($event)"
      (updateSurveysEvent)="updateSurveys($event)"
      (deleteSurveyEvent)="deleteSurvey($event)"
      (markLocationEvent)="markSurveyLocation($event)"
      (updateSurveyEvent)="updateSurvey($event)"
      (setMoveSurveyModeEvent)="setMoveSurveyMode($event)"
      (saveMovedSurveyEvent)="saveMovedSurvey($event)"
      (setSurveyTableChangeStatusEvent)="setSurveyTableChangeStatus($event)"
    >
    </soillib-surveys-edition>
  </div>

  <div
    id="soil-cuttings-edition-container"
    *ngIf="state.bottomTabOpened !== 'borehole-edition' && state.bottomTabOpened !== 'borehole-comparison'"
  >
    <soillib-soil-cuttings-edition
      [originalBoreholes]="originalBoreholes"
      [projectedBoreholes]="projectedBoreholes"
      (addZoneEvent)="addZone($event)"
      (deleteZoneEvent)="deleteZone($event)"
      (setZoneModifyModeEvent)="setZoneModifyMode($event)"
      (saveModifiedPolygonEvent)="saveModifiedPolygon($event)"
      (setEditModeEvent)="setEditMode($event)"
      (resizeSelectBoxEvent)="resizeSelectBox($event)"
      (setBoreholeHoverStyleEvent)="setBoreholeHoverStyle($event)"
      (setCuttingTableChangeStatusEvent)="setCuttingTableChangeStatus($event)"
      (initCrossSectionSourceOnMapEvent)="initCrossSectionSourceOnMap()"
      (setSelectedCrossSectionOnMapEvent)="setSelectedCrossSectionOnMap($event)"
    >
    </soillib-soil-cuttings-edition>
  </div>

  <div
    id="surveys-comparison-container"
    *ngIf="state.bottomTabOpened !== 'borehole-edition' && state.bottomTabOpened !== 'soil-cutting-edition'"
  >
    <soillib-surveys-comparison [jobsite]="selectedJobsite$ | async"> </soillib-surveys-comparison>
  </div>

  <soillib-visible-info-box></soillib-visible-info-box>
</ng-container>
