<ng-container
  *ngIf="{
    filteringOpened: filteringOpened$ | async,
    location: selectedLocation$ | async,
    radiusSearch: radiusSearch$ | async
  } as state"
>
  <div
    [@slideInOut]="state.filteringOpened"
    [ngClass]="{ container: true, opened: state.filteringOpened }"
    fxLayout="column"
    fxLayoutAlign="center center"
    (click)="handleFilterToggle()"
  >
    <div fxLayout="row">
      <img id="img-filter" src="assets/ico-filter.png" fxFlexAlign="end" />
      <img *ngIf="!state.filteringOpened" fxFlexAlign="end" id="img-right-arrow" src="assets/ico-arrow-right.png" />
    </div>
    <span>{{ 'FILTER.BUTTON' | translate }}</span>
  </div>

  <div
    [@slideInOut]="state.filteringOpened"
    id="filter-sidenav"
    class="sidenav"
    *ngIf="state.filteringOpened"
    fxLayout="column"
  >
    <div class="title-text">
      <h3>{{ 'FILTER.TITLE' | translate }}</h3>
      <p>{{ 'FILTER.SUBTITLE' | translate }}</p>
    </div>
    <div class="text-filtering" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
      <p>{{ 'FILTER.SITEEXTENT' | translate }} :</p>
      <mat-slide-toggle
        color="primary"
        [checked]="(preventJobsiteExtentsDrawing$ | async) !== true"
        #jobsiteExtents
        (change)="onDrawJobsites(jobsiteExtents.checked)"
      ></mat-slide-toggle>
    </div>
    <hr />
    <div class="text-filtering" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
      <p>{{ 'FILTER.ZONE' | translate }} :</p>
      <mat-slide-toggle
        color="primary"
        [checked]="(preventCuttingsDrawing$ | async) !== true"
        #cuttings
        (change)="onDrawCuttings(cuttings.checked)"
      ></mat-slide-toggle>
    </div>
    <hr />
    <form
      [formGroup]="selectedSurveyTypeGroup"
      fxFlex="1 1"
      class="text-filtering"
      fxLayout="column"
      fxLayoutGap="20px"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <p>{{ 'FILTER.SURVEY' | translate }} :</p>
        <mat-slide-toggle color="primary" formControlName="displaySurveys"></mat-slide-toggle>
      </div>
      <div fxLayout="row wrap" formArrayName="filters" *ngIf="availableSurveyTypes$ | async as availableSurveyTypes">
        <mat-checkbox
          fxFlex="50"
          color="primary"
          *ngFor="let type of selectedSurveyTypeGroup.controls.filters.controls; let i = index"
          formControlName="{{ i }}"
        >
          <ng-container *ngIf="(availableSurveyTypes?.[i] ?? null) as type"
            ><img class="survey-legend" [src]="boreholeTypeSymbol(type)" /><span class="survey-type">{{
              showBoreholeTypeName(type)
            }}</span></ng-container
          >
        </mat-checkbox>
      </div>
    </form>
    <hr />
    <div class="text-filtering" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
      <p>{{ 'FILTER.SHOW_LABELS' | translate }} :</p>
      <mat-slide-toggle
        color="primary"
        [checked]="(showMapLabels$ | async) === true"
        (change)="toggleBingMapType($event.checked)"
      >
      </mat-slide-toggle>
    </div>
    <hr />
    <div class="text-filtering" fxLayout="column" fxLayoutGap="50px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <p>{{ 'FILTER.SITESEARCH' | translate }} :</p>
        <mat-slide-toggle
          [disabled]="!state.location"
          color="primary"
          #radiusToggle
          [checked]="state.radiusSearch.active"
          (change)="handleRadius({ active: radiusToggle.checked, radius: slider.value.toFixed(2) })"
        ></mat-slide-toggle>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-slider
          [disabled]="!state.location"
          fxFlex="1 1"
          color="primary"
          class="slider"
          min="0"
          max="5"
          step="0.05"
          [value]="+state.radiusSearch.radius"
          tickInterval="10"
          thumbLabel="true"
          #slider
          (input)="handleRadiusChange({ active: radiusToggle.checked, radius: slider.value.toFixed(2) })"
        ></mat-slider>
        <span>{{ slider.value.toFixed(2) }}</span> <span>km</span>
      </div>
    </div>
    <hr />
  </div>
</ng-container>
