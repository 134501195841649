import { ChangeDetectionStrategy, Component } from '@angular/core'
import { VisibleEntities } from '../../shared/models'
import { Select } from '@ngxs/store'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { Observable } from 'rxjs'
import { BottomTab } from '../../store/app/app.state'
import { MapStateSelectors } from '../../store/map/map.selectors'

@Component({
  selector: 'soillib-visible-info-box',
  templateUrl: './visible-info-box.component.html',
  styleUrls: ['./visible-info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisibleInfoBoxComponent {
  @Select(MapStateSelectors.slices.visibleEntities) visibleEntities$: Observable<VisibleEntities>
  @Select(AppStateSelectors.isLoading) isLoading$: Observable<boolean>
  @Select(AppStateSelectors.slices.bottomTabOpened) bottomTabOpened$: Observable<BottomTab | null>
}
