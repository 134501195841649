import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core'
import { BottomNavHeight } from '@sde-ild/ssd-soillib-lib'

@Component({
  selector: 'soillib-map-table-resize-button',
  templateUrl: './map-table-resize-button.component.html',
  styleUrls: ['./map-table-resize-button.component.scss'],
})
export class MapTableResizeButtonComponent implements OnInit {
  @Output()
  reRenderEvent: EventEmitter<void> = new EventEmitter()

  showUpButton: boolean

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // if (event.keyCode === 38) {//ArrowUp
    //   this.up();
    // } else if (event.keyCode === 40) {//ArrowDown
    //   this.down();
    // }
  }

  ngOnInit() {
    document.documentElement.style.setProperty('--bottom-nav-height', BottomNavHeight.MIDDLE)
    this.showUpButton = true
  }

  up() {
    const root = document.documentElement
    if (getComputedStyle(root).getPropertyValue('--bottom-nav-height') === BottomNavHeight.BOTTOM) {
      root.style.setProperty('--bottom-nav-height', BottomNavHeight.MIDDLE)
      this.showUpButton = true
    } else if (getComputedStyle(root).getPropertyValue('--bottom-nav-height') === BottomNavHeight.MIDDLE) {
      root.style.setProperty('--bottom-nav-height', BottomNavHeight.TOP)
      this.showUpButton = false
      this.reRenderEvent.emit()
    }
  }

  down() {
    const root = document.documentElement
    if (getComputedStyle(root).getPropertyValue('--bottom-nav-height') === BottomNavHeight.MIDDLE) {
      root.style.setProperty('--bottom-nav-height', BottomNavHeight.BOTTOM)
      this.showUpButton = true
    } else if (getComputedStyle(root).getPropertyValue('--bottom-nav-height') === BottomNavHeight.TOP) {
      root.style.setProperty('--bottom-nav-height', BottomNavHeight.MIDDLE)
      this.showUpButton = true
      this.reRenderEvent.emit()
    }
  }
}
