import { Injectable } from '@angular/core'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { CrossSectionDto } from '@sde-ild/ssd-soillib-lib'

@Injectable()
export class CrossSectionGraphService {
  private url = 'api/cross_section'
  private pageSize = 10

  constructor(private keycloakService: KeycloakService, private http: HttpClient) {}

  getCrossSectionList(
    jobsiteId: string,
    search: string,
    pageState: string | null,
  ): Observable<{ entities: CrossSectionDto[]; pageState: string }> {
    let params: Record<string, string> = { pageSize: this.pageSize.toString(), search }
    if (pageState) {
      params = {
        ...params,
        pageState,
      }
    }
    return this.http.get<{ entities: CrossSectionDto[]; pageState: string }>(`${this.url}/${jobsiteId}`, { params })
  }

  getCrossSectionById(jobsiteId: string, crId: string): Observable<CrossSectionDto> {
    return this.http.get<CrossSectionDto>(`${this.url}/${jobsiteId}/${crId}`)
  }

  saveCrossSection(jobsiteId: string, cr: CrossSectionDto): Observable<string> {
    cr.user_id = this.keycloakService.getCurrentUser().id
    cr.jobsite_id = jobsiteId
    return this.http.post<string>(this.url, cr)
  }

  updateCrossSection(jobsiteId: string, cr: CrossSectionDto): Observable<unknown> {
    cr.user_id = this.keycloakService.getCurrentUser().id
    return this.http.put(`${this.url}/${jobsiteId}/${cr.cr_id}`, cr)
  }

  deleteCrossSection(jobsiteId: string, crId: string): Observable<unknown> {
    return this.http.delete(`${this.url}/${jobsiteId}/${crId}`)
  }
}
