import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { SbtnZoneDesc, SbtnZoneColor } from '@sde-ild/ssd-soillib-lib'
import { KeyValue } from '@angular/common'

@Component({
  selector: 'soillib-sbtn-legend-draggable',
  templateUrl: './sbtn-legend-draggable.component.html',
  styleUrls: ['./sbtn-legend-draggable.component.scss'],
})
export class SbtnLegendDraggableComponent implements OnInit {
  @Output()
  closeEvent = new EventEmitter<void>()

  sbtnZoneDesc = SbtnZoneDesc
  sbtnZoneColor = SbtnZoneColor

  ngOnInit(): void {}

  close() {
    this.closeEvent.emit()
  }

  zoneDescription({ key, value }: KeyValue<string, SbtnZoneDesc[number]>): string {
    return `${this.formatKey(key)}: ${value}`
  }

  private formatKey = (key: string) =>
    key
      // Add a space before any digit in the string
      .replace(/(\d+)/g, ' $1')
      // Capitalize the first character and lower case the rest
      .replace(/^(.)(.*)$/, (_, p1, p2) => p1.toUpperCase() + p2.toLowerCase())
}
