<ng-container
  *ngIf="{
    isLoading: isLoading$ | async
  } as loadingWrapper"
>
  <div [class.loader-hidden]="!loadingWrapper.isLoading">
    <div class="loader-overlay">
      <div>
        <mat-progress-bar color="warn" mode="indeterminate" *ngIf="loadingWrapper.isLoading"></mat-progress-bar>
      </div>
    </div>
  </div>
</ng-container>
