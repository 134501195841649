import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import Handsontable from 'handsontable'
import { isString } from '../../utils'

export interface SelectColumnsDialogData {
  selectedCols: Handsontable.ColumnSettings[]
  selectableCols: Handsontable.ColumnSettings[]
}

@Component({
  selector: 'soillib-select-columns-dialog',
  templateUrl: 'select-columns-dialog.html',
})
export class SelectColumnsDialog {
  public selectedColumnsGroup: FormGroup<{
    [Col: string]: FormControl<boolean | null>
  }>
  public availableParams: Handsontable.ColumnSettings[]

  constructor(
    public dialogRef: MatDialogRef<SelectColumnsDialog>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public sourceData: SelectColumnsDialogData,
  ) {
    const { selectedCols, selectableCols } = sourceData
    this.availableParams = selectableCols
    this.selectedColumnsGroup = this.fb.group({})
    this.availableParams.forEach(({ data, title }) => {
      if (isString(data)) {
        this.selectedColumnsGroup.addControl(data, this.fb.control(selectedCols.map((el) => el.title).includes(title)))
      }
    })
    if (this.selectedColumnsGroup.controls.n && this.selectedColumnsGroup.controls.n_1_60) {
      this.selectedColumnsGroup.controls.n.valueChanges.subscribe((value) => {
        if (value) {
          this.selectedColumnsGroup.controls.n_1_60?.setValue(false)
        }
      })
      this.selectedColumnsGroup.controls.n_1_60.valueChanges.subscribe((value) => {
        if (value) {
          this.selectedColumnsGroup.controls.n?.setValue(false)
        }
      })
    }
    if (this.selectedColumnsGroup.controls.pl && this.selectedColumnsGroup.controls.pl_star) {
      this.selectedColumnsGroup.controls.pl.valueChanges.subscribe((value) => {
        if (value) {
          this.selectedColumnsGroup.controls.pl_star?.setValue(false)
        }
      })
      this.selectedColumnsGroup.controls.pl_star.valueChanges.subscribe((value) => {
        if (value) {
          this.selectedColumnsGroup.controls.pl?.setValue(false)
        }
      })
    }
  }

  close(): void {
    this.dialogRef.close()
  }
}
