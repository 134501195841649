/*
 * Generated type guards for "surveys-comparison.model.ts".
 * WARNING: Do not manually change this file.
 */
import { CorrelationParameterType } from './surveys-comparison.model'

export function isCorrelationParameterType(obj: unknown): obj is CorrelationParameterType {
  const typedObj = obj as CorrelationParameterType
  return (
    typedObj === 'correlation_pressiom_phi_prime' ||
    typedObj === 'correlation_pressiom_su' ||
    typedObj === 'correlation_cpt_pressiom_qc_clays' ||
    typedObj === 'correlation_cpt_pressiom_qc_silts' ||
    typedObj === 'correlation_cpt_pressiom_qc_sands' ||
    typedObj === 'correlation_cpt_gamma' ||
    typedObj === 'correlation_cpt_su' ||
    typedObj === 'correlation_cpt_ocr' ||
    typedObj === 'correlation_cpt_dr' ||
    typedObj === 'correlation_cpt_phi_prime' ||
    typedObj === 'correlation_cpt_k' ||
    typedObj === 'correlation_cpt_spt_n60' ||
    typedObj === 'correlation_spt_su' ||
    typedObj === 'correlation_spt_phi_prime_gravel' ||
    typedObj === 'correlation_spt_phi_prime_coarse_sand' ||
    typedObj === 'correlation_spt_phi_prime_medium_sand' ||
    typedObj === 'correlation_spt_phi_prime_fine_sand' ||
    typedObj === 'correlation_spt_phi_prime_silty_sand'
  )
}
