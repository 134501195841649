<h1 mat-dialog-title>{{ 'CUTTING.STATISTICS' | translate }}</h1>
<div style="height: 50vh; overflow-y: auto">
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
    <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span [innerHtml]="getTechniqueColumnTitle(column)"></span>
      </th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">OK</button>
</div>
