import { SimpleChanges } from '@angular/core'
import { isEqual } from 'lodash'

export const safeParseFloat = (v: string | number): number => parseFloat(typeof v === 'string' ? v : String(v))

export const isString = (obj: unknown): obj is string => typeof obj === 'string'

export const isNumber = (obj: unknown): obj is number => typeof obj === 'number'

export const nonNullable = <TValue>(value: TValue): value is NonNullable<TValue> =>
  value !== null && value !== undefined

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

export const withNonNullableFields = <TValue>(value: TValue): value is NonNullableFields<TValue> => {
  for (const k in value) {
    if (!nonNullable(value[k])) {
      return false
    }
  }
  return true
}

export const propertyHasChanged = (changes: SimpleChanges, propName: string) =>
  propName in changes && !isEqual(changes[propName].previousValue, changes[propName].currentValue)

export const scale = (n: number | null | undefined, precision = 1000) =>
  n != null ? Math.round((n + Number.EPSILON) * precision) / precision : n
