import { Injectable } from '@angular/core'
import {
  bhTypeToParams,
  BoreholeType,
  getLabColumnTitle,
  getLabParameters,
  getTechniqueAvailableParameters,
  getTechniqueColumnTitle,
  SurveyData,
} from '@sde-ild/ssd-soillib-lib'
import { TranslateService } from '@ngx-translate/core'
import {
  ComplexChartItem,
  CorrelationDataModel,
  CorrelationParameterType,
  CorrelationParameterTypeGuard,
  LabTestDataModel,
} from '../../shared/models'
import { nonNullable } from '../../shared/utils'
import { isEqual } from 'lodash'
import { ComplexChartParameter } from '../store/surveys-comparison.state'

@Injectable()
export class SurveysComparisonService {
  constructor(private translateService: TranslateService) {}

  private bhTypeToCorrelationParameters: Record<
    BoreholeType,
    Partial<Record<CorrelationParameterType, Omit<ComplexChartParameter, 'parameter'>>>
  > = {
    PRESSIO: {
      correlation_pressiom_phi_prime: {
        title: 'SURVEY.CORRELATION_DATA.PRESSIOM.PHI_PRIME.AXIS',
        accessor: 'pressiomPhiPrime',
      },
      correlation_pressiom_su: { title: 'SURVEY.CORRELATION_DATA.PRESSIOM.SU.AXIS', accessor: 'pressiomSu' },
      correlation_cpt_pressiom_qc_clays: {
        title: 'SURVEY.CORRELATION_DATA.TEST_CORRELATION.QC_CLAYS.AXIS',
        accessor: 'cptPressiomQcClays',
      },
      correlation_cpt_pressiom_qc_silts: {
        title: 'SURVEY.CORRELATION_DATA.TEST_CORRELATION.QC_SILTS.AXIS',
        accessor: 'cptPressiomQcSilts',
      },
      correlation_cpt_pressiom_qc_sands: {
        title: 'SURVEY.CORRELATION_DATA.TEST_CORRELATION.QC_SANDS.AXIS',
        accessor: 'cptPressiomQcSands',
      },
    },
    CPT: {
      correlation_cpt_gamma: { title: 'SURVEY.CORRELATION_DATA.CPT.GAMMA.AXIS', accessor: 'cptGamma' },
      correlation_cpt_su: { title: 'SURVEY.CORRELATION_DATA.CPT.SU.AXIS', accessor: 'cptSu' },
      correlation_cpt_ocr: { title: 'SURVEY.CORRELATION_DATA.CPT.OCR.AXIS', accessor: 'cptOcr' },
      correlation_cpt_dr: { title: 'SURVEY.CORRELATION_DATA.CPT.DR.AXIS', accessor: 'cptDr' },
      correlation_cpt_phi_prime: { title: 'SURVEY.CORRELATION_DATA.CPT.PHI_PRIME.AXIS', accessor: 'cptPhiPrime' },
      correlation_cpt_k: { title: 'SURVEY.CORRELATION_DATA.CPT.K.AXIS', accessor: 'cptK' },
      correlation_cpt_spt_n60: {
        title: 'SURVEY.CORRELATION_DATA.TEST_CORRELATION.N60.AXIS',
        accessor: 'cptSptN60',
      },
    },
    'CPT-HK': {
      correlation_cpt_gamma: { title: 'SURVEY.CORRELATION_DATA.CPT.GAMMA.AXIS', accessor: 'cptGamma' },
      correlation_cpt_su: { title: 'SURVEY.CORRELATION_DATA.CPT.SU.AXIS', accessor: 'cptSu' },
      correlation_cpt_ocr: { title: 'SURVEY.CORRELATION_DATA.CPT.OCR.AXIS', accessor: 'cptOcr' },
      correlation_cpt_dr: { title: 'SURVEY.CORRELATION_DATA.CPT.DR.AXIS', accessor: 'cptDr' },
      correlation_cpt_phi_prime: { title: 'SURVEY.CORRELATION_DATA.CPT.PHI_PRIME.AXIS', accessor: 'cptPhiPrime' },
      correlation_cpt_k: { title: 'SURVEY.CORRELATION_DATA.CPT.K.AXIS', accessor: 'cptK' },
      correlation_cpt_spt_n60: {
        title: 'SURVEY.CORRELATION_DATA.TEST_CORRELATION.N60.AXIS',
        accessor: 'cptSptN60',
      },
    },
    DESTRUCTIF: {
      correlation_spt_su: { title: 'SURVEY.CORRELATION_DATA.SPT.SU.AXIS', accessor: 'sptSu' },
      correlation_spt_phi_prime_gravel: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_GRAVEL.AXIS',
        accessor: 'sptPhiPrimeGravel',
      },
      correlation_spt_phi_prime_coarse_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_COARSE_SAND.AXIS',
        accessor: 'sptPhiPrimeCoarseSand',
      },
      correlation_spt_phi_prime_medium_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_MEDIUM_SAND.AXIS',
        accessor: 'sptPhiPrimeMediumSand',
      },
      correlation_spt_phi_prime_fine_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_FINE_SAND.AXIS',
        accessor: 'sptPhiPrimeFineSand',
      },
      correlation_spt_phi_prime_silty_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_SILTY_SAND.AXIS',
        accessor: 'sptPhiPrimeSiltySand',
      },
    },
    CAROTTE: {
      correlation_spt_su: { title: 'SURVEY.CORRELATION_DATA.SPT.SU.AXIS', accessor: 'sptSu' },
      correlation_spt_phi_prime_gravel: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_GRAVEL.AXIS',
        accessor: 'sptPhiPrimeGravel',
      },
      correlation_spt_phi_prime_coarse_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_COARSE_SAND.AXIS',
        accessor: 'sptPhiPrimeCoarseSand',
      },
      correlation_spt_phi_prime_medium_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_MEDIUM_SAND.AXIS',
        accessor: 'sptPhiPrimeMediumSand',
      },
      correlation_spt_phi_prime_fine_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_FINE_SAND.AXIS',
        accessor: 'sptPhiPrimeFineSand',
      },
      correlation_spt_phi_prime_silty_sand: {
        title: 'SURVEY.CORRELATION_DATA.SPT.PHI_PRIME_SILTY_SAND.AXIS',
        accessor: 'sptPhiPrimeSiltySand',
      },
    },
  }

  getParamName = (param: string): string => {
    const techniqueColumnTitle = getTechniqueColumnTitle(param)
    if (techniqueColumnTitle) {
      return techniqueColumnTitle
    }
    const labColumnTitle = getLabColumnTitle(param)
    if (labColumnTitle) {
      return `${labColumnTitle} (lab)`
    }
    if (CorrelationParameterTypeGuard.isCorrelationParameterType(param)) {
      const correlationColumnTitle = this.getCorrelationColumnTitle(param)
      if (correlationColumnTitle) {
        return `${correlationColumnTitle} (correlation)`
      }
    }
    return param
  }

  getParamData(
    selectedParam: string,
    bhType: string | null,
    labTestData: LabTestDataModel[] | undefined,
    correlationData: CorrelationDataModel[] | undefined,
    surveyData: SurveyData[] | undefined,
  ): { depths: number[] | undefined; paramData: (string | number)[] | undefined } {
    const labTestParam = this.getLabTestParameters().find((labTestData) => labTestData.parameter === selectedParam)
    const correlationParam =
      !!bhType && this.getCorrelationParameters(bhType).find((p) => p.parameter === selectedParam)
    let depths: number[] | undefined
    let paramData: string[] | undefined
    if (labTestParam) {
      depths = labTestData?.map((row) => row.depth)?.filter(nonNullable)
      paramData = selectedParam ? labTestData?.map((row) => row[labTestParam.accessor]) : []
    } else if (correlationParam) {
      depths = correlationData?.map((row) => row.depth)?.filter(nonNullable)
      paramData = selectedParam ? correlationData?.map((row) => row[correlationParam.accessor]) : []
    } else {
      depths = surveyData?.map((row) => row.depth)?.filter(nonNullable)
      paramData = selectedParam ? surveyData?.map((row) => row[selectedParam]) : []
    }
    return { depths, paramData }
  }

  parseAvailableParamByChart = (item: ComplexChartItem): ComplexChartParameter[] =>
    this.parseAvailableParam(item.rawData, item.surveyType, item.labRawData, item.correlationData)

  parseAvailableParam = (
    surveyData: SurveyData[] | null,
    surveyType: string | null,
    labTestData: LabTestDataModel[] | null,
    correlationData: CorrelationDataModel[] | null,
  ): ComplexChartParameter[] => [
    ...(surveyType ? this.filterParams(surveyData, this.getTechniqueAvailableParameters(surveyType)) : []),
    ...this.filterParams(labTestData, this.getLabTestParameters()),
    ...(surveyType ? this.filterParams(correlationData, this.getCorrelationParameters(surveyType)) : []),
  ]

  getChartCommonBhType(chartParams: ComplexChartItem[]): BoreholeType | null {
    const bhSimple = chartParams.find((para) => !para.surveyType.includes(','))
    if (bhSimple) {
      return bhSimple.surveyType as BoreholeType
    }
    const chartItem = chartParams.find(() => true)
    if (chartItem) {
      const typeArray = chartItem.surveyType.split(',')
      const typeFromTechnique = Object.keys(bhTypeToParams).find((key) => bhTypeToParams[key].includes(chartItem.param))
      const typeFromCorrelations = Object.keys(this.bhTypeToCorrelationParameters).find((key) =>
        Object.keys(this.bhTypeToCorrelationParameters[key]).includes(chartItem.param),
      )
      const type = typeFromTechnique || typeFromCorrelations

      if (type && typeArray.includes(type)) {
        return type as BoreholeType
      }
      return (typeArray.find(() => true) as BoreholeType) || null
    }
    return null
  }

  getCommonParameters = (type: BoreholeType | null, labParams: string[]): string[] => [
    ...(type ? getTechniqueAvailableParameters(type, false, false) : []),
    ...labParams,
    ...(type ? this.getCorrelationParameters(type) : []).map((p) => p.parameter),
  ]

  // Visible for testing
  getCorrelationParameters = (bhType: string): ComplexChartParameter[] =>
    this.distinct(
      bhType
        .split(',')
        .map((t) => this.bhTypeToCorrelationParameters[t])
        .filter(nonNullable)
        .flatMap((parameters) =>
          Object.keys(parameters).map((p) => ({
            parameter: p,
            ...parameters[p],
          })),
        ),
      isEqual,
    )

  // Visible for testing
  getLabTestParameters = (): ComplexChartParameter[] =>
    getLabParameters().map((key) => ({
      parameter: key,
      title: getLabColumnTitle(key) || '',
      accessor: key.substring(key.indexOf('lab_') + 'lab_'.length),
    }))

  private getCorrelationColumnTitle(param: CorrelationParameterType): string | null {
    for (const bhType in this.bhTypeToCorrelationParameters) {
      if (param in this.bhTypeToCorrelationParameters[bhType]) {
        return this.translateService.instant(this.bhTypeToCorrelationParameters[bhType][param].title)
      }
    }
    return null
  }

  private getTechniqueAvailableParameters = (surveyType: string | null): ComplexChartParameter[] =>
    (surveyType ? getTechniqueAvailableParameters(surveyType) : [])
      .filter((param) => param !== 'geo_soil' && param !== 'geotechnical_engineer_description')
      .map((p) => ({
        parameter: p,
        accessor: p,
        title: p,
      }))

  private filterParams<T>(data: T[] | null, possibleParams: ComplexChartParameter[]): ComplexChartParameter[] {
    const datum = data?.find(() => true)
    return datum
      ? Object.keys(datum)
          .filter((key) => key !== 'depth' && data?.some((val) => val[key] !== null))
          .map((key) => possibleParams.find((value1) => value1.accessor === key))
          .filter(nonNullable)
      : []
  }

  private distinct = <T>(array: T[], equal: (t1: T, t2: T) => boolean): T[] =>
    array.reduce((p: T[], c: T) => {
      p.findIndex((element) => equal(element, c)) > -1 || p.push(c)
      return p
    }, [])
}
