import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'
import { Observable, Subscription } from 'rxjs'
import { MessagesService, ZoneService } from '../shared/remote-services'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import Swal from 'sweetalert2'
import { switchMap, tap } from 'rxjs/operators'
import { BoreholeInfo, CrossSectionInfos, Jobsite, Zone } from '../shared/models'
import { TranslateService } from '@ngx-translate/core'
import { GoogleAnalyticsService } from '../shared/google-analytics/google-analytics.service'
import { CrossSectionDto, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import {
  AppClearResource,
  AppCloseBottomTab,
  AppLeaveZone,
  AppOpenBottomTab,
  AppSetSelectedResource,
  AppSetSelectedZone,
} from '../store/app/app.actions'
import { Select, Store } from '@ngxs/store'
import { CrossSectionClearSelectedCrossSection } from './store/cross-section.actions'
import { CrossSectionStateSelectors } from './store/cross-section.selectors'
import { AppStateSelectors } from '../store/app/app.selectors'
import { UserConfigStateSelectors } from '../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-soil-cuttings-edition',
  templateUrl: './soil-cuttings-edition.component.html',
  styleUrls: ['./soil-cuttings-edition.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-bottom': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-bottom': 'var(--bottom-nav-height)' })),
      ]),
      transition('true => false', [
        style({ 'margin-bottom': 'var(--bottom-nav-height)' }),
        animate('0.5s ease-in-out', style({ 'margin-bottom': '0' })),
      ]),
    ]),
    trigger('slideInOutFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-200%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-200%)' })),
      ]),
    ]),
  ],
})
export class SoilCuttingsEditionComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.soilCuttingEditionOpened) soilCuttingEditionOpened$: Observable<boolean>
  @Select(AppStateSelectors.slices.isModifyingZone) isModifyingZone$: Observable<boolean>
  @Select(AppStateSelectors.slices.validModifZone) validModifZone$: Observable<boolean>
  @Select(AppStateSelectors.slices.selectedJobsite) selectedJobsite$: Observable<Jobsite | null>
  @Select(AppStateSelectors.slices.selectedZone) selectedZone$: Observable<Zone | null>
  @Select(CrossSectionStateSelectors.slices.selectedCrossSection) selectedCrossSection$: Observable<{
    dto: CrossSectionDto
    infos: CrossSectionInfos
  } | null>

  @Output() addZoneEvent: EventEmitter<Zone> = new EventEmitter()
  @Output() deleteZoneEvent: EventEmitter<string> = new EventEmitter()
  @Output() setZoneModifyModeEvent: EventEmitter<{ active: boolean; selectedZone: Zone }> = new EventEmitter()
  @Output() saveModifiedPolygonEvent: EventEmitter<Zone> = new EventEmitter()
  @Output() setEditModeEvent: EventEmitter<'addzone' | 'cross-section'> = new EventEmitter()
  @Output() resizeSelectBoxEvent: EventEmitter<{
    vertical: number
    parallel: number
  }> = new EventEmitter()
  @Output() setBoreholeHoverStyleEvent: EventEmitter<string> = new EventEmitter()
  @Output() setCuttingTableChangeStatusEvent: EventEmitter<boolean> = new EventEmitter()
  @Output() initCrossSectionSourceOnMapEvent: EventEmitter<void> = new EventEmitter()
  @Output() setSelectedCrossSectionOnMapEvent: EventEmitter<{
    originalBhs: SoilSurvey[]
    projectedBhs: SoilSurvey[]
  }> = new EventEmitter()

  @Input() originalBoreholes: SoilSurvey[]
  @Input() projectedBoreholes: SoilSurvey[]

  crossSectionMode: boolean

  zoneForm: FormGroup<{
    name: FormControl<string | null>
  }>

  tableDataChanged: boolean

  private subscription = new Subscription()

  constructor(
    private zoneService: ZoneService,
    private messagesService: MessagesService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store,
  ) {
    this.setTableChangeStatus(false)
  }

  ngOnInit() {
    this.zoneForm = this.fb.group({
      name: this.fb.control('', Validators.required),
    })
    this.subscription.add(
      this.selectedCrossSection$.subscribe((crossSection) => {
        if (crossSection) {
          this.setSelectedCrossSection(crossSection)
        } else {
          this.initCrossSectionSourceOnMapEvent.emit()
        }
      }),
    )
    this.subscription.add(this.selectedZone$.subscribe(() => this.setTableChangeStatus(false)))
  }

  setTableChangeStatus(changed: boolean) {
    this.tableDataChanged = changed
    this.setCuttingTableChangeStatusEvent.emit(changed)
  }

  tabChanged(index: number) {
    if (index === 0) {
      this.setEditModeEvent.emit('addzone')
      this.crossSectionMode = false
    } else if (index === 1) {
      this.setEditModeEvent.emit('cross-section')
      this.crossSectionMode = true
      this.googleAnalyticsService.event('click_cross_section', {})
    }
  }

  resizeSelectBox(scale: { vertical: number; parallel: number }) {
    this.resizeSelectBoxEvent.emit({ ...scale })
  }

  setBoreholeHoverStyle(boreholeId: string) {
    this.setBoreholeHoverStyleEvent.emit(boreholeId)
  }

  handleSoilCuttingsEditionToggle(
    openSoilCuttingEdition: boolean,
    selectedJobsite: Jobsite | null,
    selectedZone: Zone | null,
  ) {
    if (this.tableDataChanged && selectedZone) {
      Swal.fire({
        title: 'You have made changes in data table',
        text: 'Do you want to quit without saving changes ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Stay to save',
        confirmButtonText: 'Quit without saving',
      }).then((result) => {
        if (result.value) {
          this.toggleSoilCuttingEdition(openSoilCuttingEdition, selectedJobsite)
          this.setTableChangeStatus(false)
        }
      })
    } else {
      this.toggleSoilCuttingEdition(openSoilCuttingEdition, selectedJobsite)
    }
  }

  private toggleSoilCuttingEdition(openSoilCuttingEdition: boolean, selectedJobsite: Jobsite | null) {
    const actions: (AppLeaveZone | AppClearResource | AppOpenBottomTab | AppCloseBottomTab)[] = [
      AppLeaveZone,
      AppClearResource,
    ]
    if (openSoilCuttingEdition) {
      actions.push(new AppOpenBottomTab('soil-cutting-edition'))
    } else {
      actions.push(AppCloseBottomTab)
    }
    if (selectedJobsite?.extent || !openSoilCuttingEdition) {
      this.store.dispatch(actions)
    }
    if (!openSoilCuttingEdition) {
      this.crossSectionMode = false
      this.cancelSelection()
    }
  }

  saveZone(zoneToSave: Zone, selectedJobsite: Jobsite | null) {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    this.zoneService
      .createZone({
        ...zoneToSave,
        name: this.zoneForm.value.name ?? undefined,
      })
      .subscribe({
        next: (res) => {
          return Swal.fire({
            title: this.translateService.instant('ALERT.ZONE_SAVED'),
            text: this.translateService.instant('ALERT.ZONE_SAVED_SUCCESS'),
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.zoneService
              .getZone(res)
              .pipe(
                tap((zone: Zone) =>
                  this.store.dispatch([
                    new AppSetSelectedZone(zone),
                    new AppSetSelectedResource({
                      type: 'section',
                      id: zone.id,
                    }),
                  ]),
                ),
                tap((zone: Zone) => this.addZoneEvent.emit(zone)),
                switchMap((zone: Zone) =>
                  this.messagesService.saveResourceAndJobsiteMessage$(
                    selectedJobsite?.id,
                    zone.name,
                    'section',
                    'created the',
                  ),
                ),
              )
              .subscribe()
          })
        },
        error: () => {
          return Swal.fire({
            title: this.translateService.instant('ALERT.FAILED'),
            text: this.translateService.instant('ALERT.SAVE_FAILED'),
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          })
        },
      })
  }

  modifyZone(selectedZone: Zone) {
    this.setZoneModifyModeEvent.emit({ active: true, selectedZone })
  }

  saveModifiedZone(selectedZone: Zone) {
    this.saveModifiedPolygonEvent.emit(selectedZone)
  }

  cancelModifiedZone(selectedZone: Zone) {
    this.setZoneModifyModeEvent.emit({ active: false, selectedZone })
  }

  deleteZone(zoneId: string, zoneName: string | undefined, selectedJobsite: Jobsite | null) {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    Swal.fire({
      title: this.translateService.instant('GENERAL.SURE'),
      text: this.translateService.instant('GENERAL.NO_REVERT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
      confirmButtonText: this.translateService.instant('GENERAL.DELETE'),
    }).then((result) => {
      if (result.value) {
        this.zoneService
          .deleteZone(zoneId)
          .pipe(
            switchMap(() =>
              this.messagesService.saveResourceAndJobsiteMessage$(
                selectedJobsite?.id,
                zoneName,
                'section',
                'deleted the',
              ),
            ),
            tap(() => this.deleteZoneEvent.emit(zoneId)),
            tap(() => this.store.dispatch([AppLeaveZone, AppClearResource])),
          )
          .subscribe({
            next: () => {},
            error: () => {
              return Swal.fire({
                title: this.translateService.instant('ALERT.FAILED'),
                text: this.translateService.instant('ALERT.DELETE_FAILED'),
                icon: 'error',
                showConfirmButton: false,
                timer: 1000,
              })
            },
          })
      }
    })
  }

  setSelectedCrossSection({
    dto: { boreholes },
    infos: { originalBhs, projectedBhs },
  }: {
    dto: CrossSectionDto
    infos: CrossSectionInfos
  }) {
    this.initCrossSectionSourceOnMapEvent.emit()
    const crOriginalBhs: BoreholeInfo[] = boreholes?.filter((item: BoreholeInfo) => item.bh_original) || []
    if (originalBhs?.length === 2) {
      this.setSelectedCrossSectionOnMapEvent.emit({ originalBhs, projectedBhs })
    } else if (originalBhs?.length === 1) {
      const deletedBh = crOriginalBhs.find((item: BoreholeInfo) => item.survey_id !== originalBhs[0].id)
      if (deletedBh) {
        Swal.fire({
          title: this.translateService.instant('CROSS_SECTION.ONE_BH_DELETED'),
          text: `${this.translateService.instant('CROSS_SECTION.BHS_DELETED') + deletedBh.survey_name}(${
            deletedBh.survey_type
          })`,
          icon: 'error',
          showConfirmButton: true,
        })
      }
    } else {
      const deletedBhs = crOriginalBhs
      Swal.fire({
        title: this.translateService.instant('CROSS_SECTION.TWO_BH_DELETED'),
        text: `${this.translateService.instant('CROSS_SECTION.BHS_DELETED') + deletedBhs?.[0].survey_name}(${
          deletedBhs?.[0].survey_type
        }); ${deletedBhs?.[1].survey_name}(${deletedBhs?.[1].survey_type})`,
        icon: 'error',
        showConfirmButton: true,
      })
    }
  }

  cancelSelection() {
    this.store.dispatch(CrossSectionClearSelectedCrossSection)
  }

  isSBT(): boolean {
    return this.originalBoreholes?.some((borehole: SoilSurvey) => borehole?.type?.includes('CPT'))
  }

  ngOnDestroy() {
    this.zoneForm.reset()
    this.subscription.unsubscribe()
  }
}
