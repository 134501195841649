<form [formGroup]="sbtnParamsFormGroup">
  <h2 mat-dialog-title>{{ 'SBTN.SBT_PARAS' | translate }}</h2>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>a ({{ 'SBTN.A' | translate }})</mat-label>
      <input type="number" autocomplete="off" matInput formControlName="a" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'SBTN.DIC' | translate }}</mat-label>
      <input type="number" autocomplete="off" matInput formControlName="dic" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'SBTN.CALCUL_TIMES' | translate }}</mat-label>
      <input type="number" autocomplete="off" matInput formControlName="calculationTimes" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'SBTN.WATER_LEVEL' | translate }}</mat-label>
      <input type="number" autocomplete="off" matInput formControlName="waterLevel" />
      <span matSuffix>m</span>
    </mat-form-field>
    <mat-form-field>
      <mat-label>&#947; </mat-label>
      <input type="number" autocomplete="off" matInput formControlName="gamma" />
      <span matSuffix>(kN/m3)</span>
    </mat-form-field>
  </div>
  <h2 mat-dialog-title>{{ 'SBTN.SMOOTHING' | translate }}</h2>
  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start center">
    <mat-form-field>
      <mat-label>{{ 'SBTN.INTERVAL' | translate }}</mat-label>
      <input type="number" autocomplete="off" matInput formControlName="smoothingInterval" step="0.02" />
      <span matSuffix>m</span>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'SBTN.OPERATOR' | translate }}</mat-label>
      <mat-select formControlName="smoothingOperator">
        <mat-option *ngFor="let item of operatorList" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>

<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">{{ 'GENERAL.CANCEL' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="sbtnParamsFormGroup.value"
    [disabled]="sbtnParamsFormGroup.invalid"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
