import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UploadFileModel } from '../../models/upload-file.model'
import { FilesService } from '../../remote-services/files.services'

@Component({
  selector: 'soillib-report-upload-progress',
  templateUrl: './report-upload-progress.component.html',
  styleUrls: ['./report-upload-progress.component.scss'],
})
export class ReportUploadProgressComponent implements OnInit {
  constructor(private filesService: FilesService) {}

  @Input()
  files: UploadFileModel[]

  @Input()
  isVisible: boolean

  @Output()
  hideUploadWidgetEvent: EventEmitter<boolean> = new EventEmitter()

  @Input()
  nbWaitingFiles: number

  isExpanded = true

  ngOnInit() {}

  close() {
    this.hideUploadWidgetEvent.emit(true)
  }

  trackByFile(index, file: UploadFileModel) {
    return file.id
  }

  retryFile(file: UploadFileModel) {
    this.filesService.retryUploadRequest(file)
  }

  cancelFile(file: UploadFileModel) {
    this.filesService.cancelUploadRequest(file.id)
  }
}
