<form [formGroup]="correlationParamsFormGroup" class="form-correlation-parameters">
  <ng-container *ngIf="correlationParametersDialogInput.hasCPT">
    <h2 mat-dialog-title>{{ 'SURVEY.CORRELATION_DATA.PARAMETERS.CPT.TITLE' | translate }}</h2>
    <div>
      <mat-form-field>
        <mat-label [innerHtml]="'SURVEY.CORRELATION_DATA.PARAMETERS.CPT.BX' | translate"></mat-label>
        <input type="number" step=".01" autocomplete="off" matInput formControlName="cptBx" />
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngIf="correlationParametersDialogInput.hasPressiom">
    <h2 mat-dialog-title>{{ 'SURVEY.CORRELATION_DATA.PARAMETERS.PRESSIOM.TITLE' | translate }}</h2>
    <div>
      <mat-form-field class="large">
        <mat-label
          [innerHtml]="'SURVEY.CORRELATION_DATA.PARAMETERS.PRESSIOM.PHI_PRIME_CORRECTING_ANGLE' | translate"
        ></mat-label>
        <mat-select formControlName="pressiomPhiPrimeCorrectingAngle">
          <mat-option [value]="-2">{{
            'SURVEY.CORRELATION_DATA.PARAMETERS.PRESSIOM.PHI_PRIME_CORRECTING_ANGLE_MINUS_2' | translate
          }}</mat-option>
          <mat-option [value]="0">{{
            'SURVEY.CORRELATION_DATA.PARAMETERS.PRESSIOM.PHI_PRIME_CORRECTING_ANGLE_0' | translate
          }}</mat-option>
          <mat-option [value]="2">{{
            'SURVEY.CORRELATION_DATA.PARAMETERS.PRESSIOM.PHI_PRIME_CORRECTING_ANGLE_PLUS_2' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</form>

<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">{{ 'GENERAL.CANCEL' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="correlationParamsFormGroup.value"
    [disabled]="correlationParamsFormGroup.invalid"
    cdkFocusInitial
  >
    {{ 'GENERAL.OK' | translate }}
  </button>
</div>
