/*
 * Generated type guards for "Google-search.model.ts".
 * WARNING: Do not manually change this file.
 */
import { GoogleSearchResult } from './Google-search.model'

export function isGoogleSearchResult(obj: unknown): obj is GoogleSearchResult {
  const typedObj = obj as GoogleSearchResult
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typeof typedObj['displayedName'] === 'undefined' ||
      typedObj['displayedName'] === null ||
      typeof typedObj['displayedName'] === 'string') &&
    Array.isArray(typedObj['address_components']) &&
    typedObj['address_components'].every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        typeof e['long_name'] === 'string' &&
        typeof e['short_name'] === 'string',
    ) &&
    typeof typedObj['formatted_address'] === 'string' &&
    (typeof typedObj['geometry'] === 'undefined' ||
      (((typedObj['geometry'] !== null && typeof typedObj['geometry'] === 'object') ||
        typeof typedObj['geometry'] === 'function') &&
        (typeof typedObj['geometry']['bounds'] === 'undefined' ||
          (((typedObj['geometry']['bounds'] !== null && typeof typedObj['geometry']['bounds'] === 'object') ||
            typeof typedObj['geometry']['bounds'] === 'function') &&
            ((typedObj['geometry']['bounds']['northeast'] !== null &&
              typeof typedObj['geometry']['bounds']['northeast'] === 'object') ||
              typeof typedObj['geometry']['bounds']['northeast'] === 'function') &&
            typeof typedObj['geometry']['bounds']['northeast']['lat'] === 'number' &&
            typeof typedObj['geometry']['bounds']['northeast']['lng'] === 'number' &&
            ((typedObj['geometry']['bounds']['southwest'] !== null &&
              typeof typedObj['geometry']['bounds']['southwest'] === 'object') ||
              typeof typedObj['geometry']['bounds']['southwest'] === 'function') &&
            typeof typedObj['geometry']['bounds']['southwest']['lat'] === 'number' &&
            typeof typedObj['geometry']['bounds']['southwest']['lng'] === 'number')) &&
        ((typedObj['geometry']['location'] !== null && typeof typedObj['geometry']['location'] === 'object') ||
          typeof typedObj['geometry']['location'] === 'function') &&
        typeof typedObj['geometry']['location']['lat'] === 'number' &&
        typeof typedObj['geometry']['location']['lng'] === 'number' &&
        typeof typedObj['geometry']['location_type'] === 'string' &&
        (typeof typedObj['geometry']['viewport'] === 'undefined' ||
          (((typedObj['geometry']['viewport'] !== null && typeof typedObj['geometry']['viewport'] === 'object') ||
            typeof typedObj['geometry']['viewport'] === 'function') &&
            ((typedObj['geometry']['viewport']['northeast'] !== null &&
              typeof typedObj['geometry']['viewport']['northeast'] === 'object') ||
              typeof typedObj['geometry']['viewport']['northeast'] === 'function') &&
            typeof typedObj['geometry']['viewport']['northeast']['lat'] === 'number' &&
            typeof typedObj['geometry']['viewport']['northeast']['lng'] === 'number' &&
            ((typedObj['geometry']['viewport']['southwest'] !== null &&
              typeof typedObj['geometry']['viewport']['southwest'] === 'object') ||
              typeof typedObj['geometry']['viewport']['southwest'] === 'function') &&
            typeof typedObj['geometry']['viewport']['southwest']['lat'] === 'number' &&
            typeof typedObj['geometry']['viewport']['southwest']['lng'] === 'number')))) &&
    typeof typedObj['place_id'] === 'string' &&
    Array.isArray(typedObj['types']) &&
    typedObj['types'].every((e: any) => typeof e === 'string')
  )
}
