<div class="select-container" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="15px">
  <div style="font-size: 17px; font-weight: bold">{{ 'FILE_IMPORT.IMPORT_SELECT' | translate }}:</div>
  <mat-radio-group [(ngModel)]="fileSelectOption" color="warn">
    <mat-radio-button value="files">{{ 'FILE_IMPORT.FILES' | translate }}</mat-radio-button>
    <mat-radio-button value="directory">{{ 'FILE_IMPORT.FOLDER' | translate }}</mat-radio-button>
  </mat-radio-group>
  <mat-form-field appearance="outline" *ngIf="!isAGS">
    <mat-label>{{ 'FILE_IMPORT.DELIMITER' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedDelimiter" (selectionChange)="onDelimiterSelected($event)">
      <mat-option *ngFor="let item of delimiters" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <label *ngIf="fileSelectOption === 'files'">
    {{ 'FILE_IMPORT.BROWSE' | translate }}
    <input type="file" multiple [accept]="getFileType()" class="hidden" (change)="handleFilesSelected($event)" />
  </label>
  <label *ngIf="fileSelectOption === 'directory'">
    {{ 'FILE_IMPORT.BROWSE' | translate }}
    <input
      type="file"
      multiple
      webkitdirectory
      [accept]="getFileType()"
      class="hidden"
      (change)="handleFilesSelected($event)"
    />
  </label>

  <ul *ngIf="selectedFileNames" style="font-size: 13px; margin-left: -30px">
    <li *ngFor="let filename of selectedFileNames">{{ filename }}</li>
  </ul>
</div>
