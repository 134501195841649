import TileLayer from 'ol/layer/Tile'
import olMap from 'ol/Map'
import BingMaps from 'ol/source/BingMaps'

export class BackgroundLayer {
  private bingMap: TileLayer

  private bingMapLabel: TileLayer

  constructor(private map: olMap) {
    this.bingMap = new TileLayer({
      visible: true,
      preload: Infinity,
      source: new BingMaps({
        key: 'AqjCouc3ZBYjkn4_fQAw0jJIDTOrr_NPdtFu9akE7RfYnv2Gy28cYKs5i-TcVz9Q',
        imagerySet: 'Aerial',
      }),
      zIndex: -1,
    })
    this.bingMapLabel = new TileLayer({
      visible: false,
      preload: Infinity,
      source: new BingMaps({
        key: 'AqjCouc3ZBYjkn4_fQAw0jJIDTOrr_NPdtFu9akE7RfYnv2Gy28cYKs5i-TcVz9Q',
        imagerySet: 'AerialWithLabels',
      }),
      zIndex: -1,
    })

    this.map.addLayer(this.bingMap)
    this.map.addLayer(this.bingMapLabel)
  }

  showMapLabels(show: boolean) {
    this.bingMap.setVisible(!show)
    this.bingMapLabel.setVisible(show)
  }
}
