import { Jobsite, LatLonLocation, Zone } from '../../shared/models'
import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import produce from 'immer'
import {
  AppClearResource,
  AppCloseBottomTab,
  AppFetchLabTestParameters,
  AppFetchSoilSurveyAvailableParameters,
  AppIsModifyingSurvey,
  AppIsModifyingZone,
  AppLeaveJobsite,
  AppLeaveSurvey,
  AppLeaveZone,
  AppOpenBottomTab,
  AppSetLoading,
  AppSetSelectedJobsite,
  AppSetSelectedJobsiteCoord,
  AppSetSelectedResource,
  AppSetSelectedSurvey,
  AppSetSelectedZone,
  AppSetShowJobsiteEditPopup,
  AppSetShowNewJobsitePopup,
  AppSetShowSearchAddressPopup,
  AppSetValidModifSurvey,
  AppSetValidModifZone,
  AppSetZoneEditMode,
  AppToggleJobsiteEditPopup,
  AppUpdateSelectedJobsite,
} from './app.actions'
import { SoilSurvey, SoilSurveyService } from '@sde-ild/ssd-soillib-lib'
import { filter, map, tap } from 'rxjs/operators'
import { JobsiteService, MessagesService } from '../../shared/remote-services'
import { iif, of } from 'rxjs'
import { withNonNullableFields } from '../../shared/utils'
import { switchTap } from '../../shared/utils/observables.utils'

export type BottomTab = 'borehole-edition' | 'soil-cutting-edition' | 'borehole-comparison'

export interface AppStateModel {
  pendingRequests: number
  selectedJobsite: Jobsite | null
  selectedSurvey: SoilSurvey | null
  selectedZone: Zone | null
  selectedResource: Resource | null
  soilSurveyAvailableParameters: string[] | null
  labTestParameters: string[] | null
  bottomTabOpened: BottomTab | null
  zoneEditMode: 'addzone' | 'cross-section' | null
  showNewJobsitePopup: boolean
  showJobsiteEditPopup: boolean
  showSearchAddressPopup: boolean
  isModifyingSurvey: boolean
  isModifyingZone: boolean
  selectedJobsiteCoord: LatLonLocation | null
  validModifZone: boolean
  validModifSurvey: boolean
}

export interface Resource {
  type: 'section' | 'borehole' | 'chart'
  id?: string
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    pendingRequests: 0,
    selectedJobsite: null,
    selectedSurvey: null,
    selectedZone: null,
    selectedResource: null,
    soilSurveyAvailableParameters: null,
    labTestParameters: null,
    bottomTabOpened: null,
    zoneEditMode: null,
    showNewJobsitePopup: false,
    showJobsiteEditPopup: false,
    showSearchAddressPopup: false,
    isModifyingSurvey: false,
    isModifyingZone: false,
    selectedJobsiteCoord: null,
    validModifZone: false,
    validModifSurvey: false,
  },
})
@Injectable()
export class AppState {
  // TODO [joris 03/08/2023] - jobsiteService should be injected here only
  constructor(
    private soilSurveyService: SoilSurveyService,
    private jobsiteService: JobsiteService,
    private messagesService: MessagesService,
  ) {}

  @Action(AppSetLoading)
  public setLoading(ctx: StateContext<AppStateModel>, { loading }: AppSetLoading) {
    const pendingRequests = ctx.getState().pendingRequests
    const newPendingRequests = loading ? pendingRequests + 1 : pendingRequests - 1
    ctx.setState(
      produce((draft) => {
        draft.pendingRequests = newPendingRequests
      }),
    )
  }

  @Action(AppSetSelectedJobsite)
  public setSelectedJobsite(ctx: StateContext<AppStateModel>, { selectedJobsite }: AppSetSelectedJobsite) {
    ctx.setState(
      produce((draft) => {
        draft.selectedJobsite = selectedJobsite
      }),
    )
  }

  @Action(AppUpdateSelectedJobsite)
  public updateSelectedJobsite(ctx: StateContext<AppStateModel>, { updatedJobsite, action }: AppUpdateSelectedJobsite) {
    return of(ctx.getState()).pipe(
      map(({ selectedJobsite }) => ({
        id: selectedJobsite?.id || updatedJobsite.id,
        jobsite: { ...selectedJobsite, ...updatedJobsite },
      })),
      filter(withNonNullableFields),
      switchTap(({ id, jobsite }) => this.jobsiteService.updateJobsite(id, jobsite)),
      switchTap(({ jobsite }) =>
        iif(
          () => !!action,
          this.messagesService.saveJobsiteMessage$(jobsite.id, jobsite.nickname, <string>action),
          of(null),
        ),
      ),
      tap(({ jobsite }) =>
        ctx.setState(
          produce((draft) => {
            draft.selectedJobsite = jobsite
          }),
        ),
      ),
    )
  }

  @Action(AppLeaveJobsite)
  public leaveJobsite(ctx: StateContext<AppStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.selectedJobsite = null
      }),
    )
  }

  @Action(AppSetSelectedSurvey)
  public setSelectedSurvey(ctx: StateContext<AppStateModel>, { selectedSurvey }: AppSetSelectedSurvey) {
    ctx.setState(
      produce((draft) => {
        draft.selectedSurvey = selectedSurvey
      }),
    )
  }

  @Action(AppLeaveSurvey)
  public leaveSurvey(ctx: StateContext<AppStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.selectedSurvey = null
      }),
    )
  }

  @Action(AppSetSelectedZone)
  public setSelectedZone(ctx: StateContext<AppStateModel>, { selectedZone }: AppSetSelectedZone) {
    ctx.setState(
      produce((draft) => {
        draft.selectedZone = selectedZone
      }),
    )
  }

  @Action(AppLeaveZone)
  public leaveZone(ctx: StateContext<AppStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.selectedZone = null
      }),
    )
  }

  @Action(AppSetSelectedResource)
  public setSelectedResource(ctx: StateContext<AppStateModel>, { selectedResource }: AppSetSelectedResource) {
    ctx.setState(
      produce((draft) => {
        draft.selectedResource = selectedResource
      }),
    )
  }

  @Action(AppClearResource)
  public clearSelectedResource(ctx: StateContext<AppStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.selectedResource = null
      }),
    )
  }

  @Action(AppFetchSoilSurveyAvailableParameters)
  public fetchSoilSurveyAvailableParameters(ctx: StateContext<AppStateModel>) {
    if (!ctx.getState().soilSurveyAvailableParameters) {
      return this.soilSurveyService.getSoilSurveyAvailableParameters().pipe(
        tap((data: string[]) =>
          ctx.setState(
            produce((draft) => {
              draft.soilSurveyAvailableParameters = data || null
            }),
          ),
        ),
      )
    }
  }

  @Action(AppFetchLabTestParameters)
  public fetchLabTestParameters(ctx: StateContext<AppStateModel>) {
    if (!ctx.getState().labTestParameters) {
      return this.soilSurveyService.getLabTestParameters().pipe(
        tap((data: string[]) =>
          ctx.setState(
            produce((draft) => {
              draft.labTestParameters = data || null
            }),
          ),
        ),
      )
    }
  }

  @Action(AppOpenBottomTab)
  public openBottomTab(ctx: StateContext<AppStateModel>, { bottomTabOpened }: AppOpenBottomTab) {
    ctx.setState(
      produce((draft) => {
        draft.bottomTabOpened = bottomTabOpened
      }),
    )
  }

  @Action(AppCloseBottomTab)
  public closeBottomTab(ctx: StateContext<AppStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.bottomTabOpened = null
      }),
    )
  }

  @Action(AppSetZoneEditMode)
  public setZoneEditMode(ctx: StateContext<AppStateModel>, { zoneEditMode }: AppSetZoneEditMode) {
    ctx.setState(
      produce((draft) => {
        draft.zoneEditMode = zoneEditMode
      }),
    )
  }

  @Action(AppSetShowNewJobsitePopup)
  public setShowNewJobsitePopup(ctx: StateContext<AppStateModel>, { showNewJobsitePopup }: AppSetShowNewJobsitePopup) {
    ctx.setState(
      produce((draft) => {
        draft.showNewJobsitePopup = showNewJobsitePopup
      }),
    )
  }

  @Action(AppSetShowJobsiteEditPopup)
  public setShowJobsiteEditPopup(
    ctx: StateContext<AppStateModel>,
    { showJobsiteEditPopup }: AppSetShowJobsiteEditPopup,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.showJobsiteEditPopup = showJobsiteEditPopup
      }),
    )
  }

  @Action(AppToggleJobsiteEditPopup)
  public toggleShowJobsiteEditPopup(ctx: StateContext<AppStateModel>) {
    const showJobsiteEditPopup = !ctx.getState().showNewJobsitePopup
    ctx.setState(
      produce((draft) => {
        draft.showJobsiteEditPopup = showJobsiteEditPopup
      }),
    )
  }

  @Action(AppSetShowSearchAddressPopup)
  public setShowSearchAddressPopup(
    ctx: StateContext<AppStateModel>,
    { showSearchAddressPopup }: AppSetShowSearchAddressPopup,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.showSearchAddressPopup = showSearchAddressPopup
      }),
    )
  }

  @Action(AppIsModifyingSurvey)
  public isModifyingSurvey(ctx: StateContext<AppStateModel>, { isModifyingSurvey }: AppIsModifyingSurvey) {
    ctx.setState(
      produce((draft) => {
        draft.isModifyingSurvey = isModifyingSurvey
      }),
    )
  }

  @Action(AppIsModifyingZone)
  public isModifyingZone(ctx: StateContext<AppStateModel>, { isModifyingZone }: AppIsModifyingZone) {
    ctx.setState(
      produce((draft) => {
        draft.isModifyingZone = isModifyingZone
      }),
    )
  }

  @Action(AppSetSelectedJobsiteCoord)
  public setSelectedJobsiteCoord(
    ctx: StateContext<AppStateModel>,
    { selectedJobsiteCoord }: AppSetSelectedJobsiteCoord,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.selectedJobsiteCoord = selectedJobsiteCoord
      }),
    )
  }

  @Action(AppSetValidModifZone)
  public setValidModifZone(ctx: StateContext<AppStateModel>, { validModifZone }: AppSetValidModifZone) {
    ctx.setState(
      produce((draft) => {
        draft.validModifZone = validModifZone
      }),
    )
  }

  @Action(AppSetValidModifSurvey)
  public setValidModifSurvey(ctx: StateContext<AppStateModel>, { validModifSurvey }: AppSetValidModifSurvey) {
    ctx.setState(
      produce((draft) => {
        draft.validModifSurvey = validModifSurvey
      }),
    )
  }
}
