import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MetaHeadersModel, ParaHeadersModel } from '../../models/Imprt-csv-config.model'
import { getCSVGeoKeyWord } from '../../utils'
import { metaKeys, metaKeysToTitle } from '../../utils/import-csv'
import { getTechniqueColumnTitle } from '@sde-ild/ssd-soillib-lib'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'soillib-import-csv-config',
  templateUrl: './import-csv-config.component.html',
  styleUrls: ['./import-csv-config.component.scss'],
})
export class ImportCsvConfigComponent implements OnInit, OnChanges {
  @Input()
  parameters_dispo: string[]

  @Input()
  csvMetaHeaders: string[]

  @Input()
  csvParamHeaders: string[]

  @Input()
  level: 'jobsite' | 'borehole'

  @Output()
  sendConfigHeadersEvent = new EventEmitter<{ meta: MetaHeadersModel; para: ParaHeadersModel }>()

  metaForm: FormGroup<{
    [K in keyof MetaHeadersModel]: FormControl<string | null>
  }>
  paraForm: FormGroup<{
    [K in keyof ParaHeadersModel]: FormControl<string | null>
  }>

  metaKeys: string[]

  initState = true
  paraFormKeys: string[]

  metaHelpText = `Put meta data between <META_START> and <META_END>`
  paraHelpText = `Put parameter data between <DATA_START> and <DATA_END>`

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.csvParamHeaders?.currentValue?.length || changes.parameters_dispo?.currentValue) &&
      this.initState &&
      this.paraForm
    ) {
      this.paraFormKeys = this.parameters_dispo?.filter(
        (para) => para !== 'colors' && para !== 'calculated_soil' && para !== 'calculated_compactness',
      )
      this.paraFormKeys.forEach((para) => {
        this.paraForm?.get(para)?.setValue(this.findValue(para, this.csvParamHeaders))
      })
    }
    if (changes.csvMetaHeaders?.currentValue?.length && this.initState && this.metaForm) {
      this.metaKeys.forEach((key) => {
        this.metaForm?.get(key)?.setValue(this.findValue(key, this.csvMetaHeaders))
      })
    }
  }

  ngOnInit(): void {
    this.metaKeys = [...metaKeys]
    if (this.level === 'borehole') {
      this.metaKeys.splice(this.metaKeys.indexOf('jobsite_name'), 1)
    }
    this.metaForm = this.fb.group({})
    this.metaKeys.forEach((key) => {
      this.metaForm.addControl(key, this.fb.control(key))
    })
    this.paraForm = this.fb.group({})
    this.paraFormKeys = this.parameters_dispo?.filter(
      (para) => para !== 'colors' && para !== 'calculated_soil' && para !== 'calculated_compactness',
    )
    this.paraFormKeys?.forEach((para) => {
      this.paraForm.addControl(para, this.fb.control(para))
    })
  }

  paraName(para: string) {
    return getTechniqueColumnTitle(para, true)
  }

  metaName(key: string): string {
    return metaKeysToTitle[key]
  }

  finishConfig() {
    this.initState = false
    const meta = this.metaForm.value
    const para = this.paraForm.value
    let effectivePara: ParaHeadersModel = {
      depth: para.depth ?? undefined,
      geo_soil: para.geo_soil ?? undefined,
      em: para.em ?? undefined,
      f: para.f ?? undefined,
      pf: para.pf ?? undefined,
      pl: para.pl ?? undefined,
      pl_star: para.pl_star ?? undefined,
      n: para.n ?? undefined,
      n_1_60: para.n_1_60 ?? undefined,
      qc: para.qc ?? undefined,
      rf: para.rf ?? undefined,
      fs: para.fs ?? undefined,
      i: para.i ?? undefined,
      uo: para.uo ?? undefined,
      qt: para.qt ?? undefined,
      qe: para.qe ?? undefined,
      qn: para.qn ?? undefined,
      soilfr: para.soilfr ?? undefined,
      soilbq: para.soilbq ?? undefined,
      cu_insitu: para.cu_insitu ?? undefined,
    }
    if (environment.features.soilCompactness) {
      effectivePara = {
        ...effectivePara,
        geotechnical_engineer_description: para.geotechnical_engineer_description ?? undefined,
      }
    }
    this.sendConfigHeadersEvent.emit({
      meta: {
        jobsite_name: meta.jobsite_name ?? undefined,
        name: meta.name ?? undefined,
        type: meta.type ?? undefined,
        projection: meta.projection ?? undefined,
        local_x: meta.local_x ?? undefined,
        local_y: meta.local_y ?? undefined,
        elevation: meta.elevation ?? undefined,
        water_level: meta.water_level ?? undefined,
      },
      para: effectivePara,
    })
  }

  private findValue(searchString: string, strings: string[]): string | undefined {
    const reformat = (s) => s.toLowerCase().replace(/_/g, '')
    const search = searchString.toLowerCase() // local_x
    return strings.filter((x) => !!x).find((ele) => search === getCSVGeoKeyWord(reformat(ele)))
  }
}
