import { ComplexChart, ComplexChartDTO } from '../../shared/models'
import { BoreholeType, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { ComplexChartSurvey } from './surveys-comparison.state'

export class SurveysComparisonFetchCharts {
  public static readonly type = '[SurveysComparison] FetchCharts'
}

export class SurveysComparisonSetComplexChartsDTO {
  public static readonly type = '[SurveysComparison] SetComplexChartsDTO'

  constructor(readonly chartsDTO: ComplexChartDTO[] | null) {}
}

export class SurveysComparisonOpenComparison {
  public static readonly type = '[SurveysComparison] OpenComparison'
}

export class SurveysComparisonCloseComparison {
  public static readonly type = '[SurveysComparison] CloseComparison'
}

export class SurveysComparisonAddSelectedSurvey {
  public static readonly type = '[SurveysComparison] AddSelectedSurvey'

  constructor(readonly selectedSurvey: SoilSurvey) {}
}

export class SurveysComparisonSetComplexChartSurvey {
  public static readonly type = '[SurveysComparison] SetComplexChartSurvey'

  constructor(readonly complexChartSurvey: SoilSurvey) {}
}

export class SurveysComparisonClearComplexChartSurvey {
  public static readonly type = '[SurveysComparison] ClearComplexChartSurvey'
}

export class SurveysComparisonSetComplexCharts {
  public static readonly type = '[SurveysComparison] SetComplexCharts'

  constructor(readonly complexCharts: ComplexChart[]) {}
}

export class SurveysComparisonDrawComplexChart {
  public static readonly type = '[SurveysComparison] DrawComplexChart'

  constructor(readonly chart: ComplexChart) {}
}

export class SurveysComparisonSaveComplexCharts {
  public static readonly type = '[SurveysComparison] SaveComplexCharts'
}

export class SurveysComparisonDeleteComplexChart {
  public static readonly type = '[SurveysComparison] DeleteComplexChart'
}

export class SurveysComparisonSetChartsMarginTop {
  public static readonly type = '[SurveysComparison] SetChartsMarginTop'

  constructor(readonly chartsMarginTop: number) {}
}

export class SurveysComparisonOpenComplexChart {
  public static readonly type = '[SurveysComparison] OpenComplexChart'

  constructor(readonly editedComplexChart: ComplexChart | null) {}
}

export class SurveysComparisonCloseComplexChart {
  public static readonly type = '[SurveysComparison] CloseComplexChart'
}

export class SurveysComparisonSetSelectedCommonBhType {
  public static readonly type = '[SurveysComparison] SetSelectedCommonBhType'

  constructor(readonly boreholeType: BoreholeType | null) {}
}

export class SurveysComparisonSetSelectedCommonParameter {
  public static readonly type = '[SurveysComparison] SetSelectedCommonParameter'

  constructor(readonly param: string | null) {}
}

export class SurveysComparisonFetchSurveyParams {
  public static readonly type = '[SurveysComparison] FetchSurveyParams'

  constructor(readonly survey: ComplexChartSurvey) {}
}

export class SurveysComparisonRemoveSurvey {
  public static readonly type = '[SurveysComparison] RemoveSurvey'

  constructor(readonly index: number) {}
}
