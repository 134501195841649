import { createPropertySelectors, Selector } from '@ngxs/store'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Jobsite, Zone } from '../../shared/models'
import { AppState, AppStateModel, BottomTab, Resource } from './app.state'
import { environment } from '../../../environments/environment'

export class AppStateSelectors {
  static slices = createPropertySelectors<AppStateModel>(AppState)

  @Selector([AppStateSelectors.slices.pendingRequests])
  static isLoading(pendingRequests: number): boolean {
    return pendingRequests > 0
  }

  @Selector([AppStateSelectors.slices.selectedJobsite])
  static selectedJobsiteId(selectedJobsite: Jobsite | null): string | null | undefined {
    return selectedJobsite?.id
  }

  @Selector([AppStateSelectors.slices.selectedJobsite])
  static datumDisplayName(selectedJobsite: Jobsite | null): string {
    return selectedJobsite?.datumDisplayName ?? 'NGF'
  }

  @Selector([AppStateSelectors.slices.selectedSurvey])
  static selectedSurveyId(selectedSurvey: SoilSurvey | null): string | null | undefined {
    return selectedSurvey?.id
  }

  @Selector([AppStateSelectors.slices.selectedZone])
  static selectedZoneId(selectedZone: Zone | null): string | null | undefined {
    return selectedZone?.id
  }

  @Selector([AppStateSelectors.slices.selectedResource])
  static selectedResourceId(selectedResource: Resource | null): string | null | undefined {
    return selectedResource?.id
  }

  @Selector([AppStateSelectors.slices.soilSurveyAvailableParameters])
  static soilSurveyAvailableParameters(soilSurveyAvailableParameters: string[] | null): string[] | null {
    return (
      soilSurveyAvailableParameters?.filter(
        (param) =>
          environment.features.soilCompactness ||
          !['geotechnical_engineer_description', 'calculated_compactness'].includes(param),
      ) || null
    )
  }

  @Selector([AppStateSelectors.slices.bottomTabOpened])
  static boreholeEditionOpened(bottomTabOpened: BottomTab | null): boolean {
    return bottomTabOpened === 'borehole-edition'
  }

  @Selector([AppStateSelectors.slices.bottomTabOpened])
  static soilCuttingEditionOpened(bottomTabOpened: BottomTab | null): boolean {
    return bottomTabOpened === 'soil-cutting-edition'
  }

  @Selector([AppStateSelectors.slices.bottomTabOpened])
  static surveysComparisonOpened(bottomTabOpened: BottomTab | null): boolean {
    return bottomTabOpened === 'borehole-comparison'
  }
}
