import Handsontable from 'handsontable'
import { CellChange, CellValue, RowObject } from 'handsontable/common'
import { isNumber, isString } from './type.utils'
import { CellsToColorize } from '../models'
import { Param } from '../models/CellsToColorize.model'

type ChangedInfos = { rowNumber: number | null; newValue: string | number | null }[]

export function isColumnEdit(
  cellChanges: CellChange[] | null,
  param: string,
): { changed: boolean; infos: ChangedInfos } {
  const infos = cellChanges
    ?.filter(([, column, , _]) => column === param)
    ?.map(([rowNumber, , , newValue]) => ({
      rowNumber,
      newValue: isNumber(newValue) || isString(newValue) ? newValue : null,
    }))
  return {
    changed: !!infos && infos.length > 0,
    infos: infos || [],
  }
}

export function integerValidator(value: CellValue, callback: (valid: boolean) => void): void {
  if (value === '' || value === null) {
    callback(true)
  } else if (/^\s*\d+?\s*$/.test(value)) {
    callback(true)
  } else {
    callback(false)
  }
}

export function depthValidator(value: number, callback: (res: boolean) => void) {
  const depthArray: number[] = this.instance.getDataAtProp('depth')
  depthArray?.splice(this.row, 1)
  if (!value) {
    callback(true)
  } else if (value >= 0) {
    if (depthArray?.includes(value)) {
      callback(false)
    } else {
      callback(true)
    }
  } else {
    callback(false)
  }
}

export function intervalValidator(
  min: number,
  max: number,
): (value: CellValue, callback: (valid: boolean) => void) => void {
  return (value: CellValue, callback: (valid: boolean) => void): void => {
    if (!value || (value >= min && value <= max)) {
      callback(true)
    } else {
      callback(false)
    }
  }
}
/**
 * Safely returns the source data of a living HandsonTable, undefined otherwise
 * @param tableRef the HandsonTable
 */
export const getSourceData: (tableRef: Handsontable | null | undefined) => CellValue[][] | RowObject[] | undefined = (
  tableRef: Handsontable | null | undefined,
) => (tableRef?.isDestroyed ? undefined : tableRef?.getSourceData())

export function colorizeCellsByKey(table: Handsontable | null | undefined, color: string) {
  if (table && !table?.isDestroyed && ['red', 'orange', 'yellow', 'none'].includes(color)) {
    const selection = table.getSelected()
    if (selection) {
      for (const [startRow, startCol, endRow, endCol] of selection) {
        for (let row = Math.max(0, startRow); row <= endRow; row++) {
          for (let col = Math.max(0, startCol); col <= endCol; col++) {
            if (table.colToProp(col) !== 'calculated_soil' && table.colToProp(col) !== 'calculated_compactness') {
              appendToClassName(table, row, col, color)
            }
          }
        }
      }
      table.render()
    }
  }
}

export function colorizeCells(table: Handsontable | null | undefined, cells: CellsToColorize) {
  if (table && !table?.isDestroyed) {
    Object.keys(cells)
      .filter((color) => color !== 'none')
      .forEach((color: string) => {
        cells[color].forEach(([row, param]: [number, Param]) => {
          const col = table.propToCol(param)
          if (!isNaN(col)) {
            appendToClassName(table, row, col, color)
          }
        })
      })
    table.render()
  }
}

function appendToClassName(table: Handsontable, row: number, col: number, color: string) {
  const cellMeta = table.getCellMeta(row, col)
  if (!cellMeta) {
    table.setCellMeta(row, col, 'className', color)
  } else {
    const prop = {
      ...cellMeta,
      className: cellMeta.className ? cellMeta.className + ' ' + color : color,
    }
    table.setCellMetaObject(row, col, prop)
  }
}
