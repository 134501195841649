import { Jobsite, LatLonLocation, Zone } from '../../shared/models'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { BottomTab, Resource } from './app.state'

export class AppSetLoading {
  public static readonly type = '[App] SetLoading'

  constructor(readonly loading: boolean) {}
}

export class AppSetSelectedJobsite {
  public static readonly type = '[App] set selected jobsite'

  constructor(readonly selectedJobsite: Jobsite) {}
}

export class AppUpdateSelectedJobsite {
  public static readonly type = '[App] UpdateSelectedJobsite'

  constructor(readonly updatedJobsite: Jobsite, readonly action?: string) {}
}

export class AppLeaveJobsite {
  public static readonly type = '[App] clear selected jobsite'
}

export class AppSetSelectedSurvey {
  public static readonly type = '[App] set selected survey'

  constructor(readonly selectedSurvey: SoilSurvey) {}
}

export class AppLeaveSurvey {
  public static readonly type = '[App] clear selected survey'
}

export class AppSetSelectedZone {
  public static readonly type = '[App] set selected zone'

  constructor(readonly selectedZone: Zone) {}
}

export class AppLeaveZone {
  public static readonly type = '[App] clear selected zone'
}

export class AppSetSelectedResource {
  public static readonly type = '[App] set selected resource'

  constructor(readonly selectedResource: Resource) {}
}

export class AppClearResource {
  public static readonly type = '[App] clear selected resource'
}

export class AppFetchSoilSurveyAvailableParameters {
  public static readonly type = '[App] fetch soil survey available parameters if not in state'
}

export class AppFetchLabTestParameters {
  public static readonly type = '[App] fetch lab test parameters if not in state'
}

export class AppOpenBottomTab {
  public static readonly type = '[App] OpenBottomTab'

  constructor(readonly bottomTabOpened: BottomTab) {}
}

export class AppCloseBottomTab {
  public static readonly type = '[App] CloseBottomTab'
}

export class AppSetZoneEditMode {
  public static readonly type = '[App] SetZoneEditMode'

  constructor(readonly zoneEditMode: 'addzone' | 'cross-section' | null) {}
}

export class AppSetShowNewJobsitePopup {
  public static readonly type = '[App] SetShowNewJobsitePopup'

  constructor(readonly showNewJobsitePopup: boolean) {}
}

export class AppSetShowJobsiteEditPopup {
  public static readonly type = '[App] SetShowJobsiteEditPopup'

  constructor(readonly showJobsiteEditPopup: boolean) {}
}

export class AppToggleJobsiteEditPopup {
  public static readonly type = '[App] ToggleJobsiteEditPopup'
}

export class AppSetShowSearchAddressPopup {
  public static readonly type = '[App] SetShowSearchAddressPopup'

  constructor(readonly showSearchAddressPopup: boolean) {}
}

export class AppIsModifyingSurvey {
  public static readonly type = '[App] IsModifyingSurvey'

  constructor(readonly isModifyingSurvey: boolean) {}
}

export class AppIsModifyingZone {
  public static readonly type = '[App] IsModifyingZone'

  constructor(readonly isModifyingZone: boolean) {}
}

export class AppSetSelectedJobsiteCoord {
  public static readonly type = '[App] SetSelectedJobsiteCoord'

  constructor(readonly selectedJobsiteCoord: LatLonLocation | null) {}
}

export class AppSetValidModifZone {
  public static readonly type = '[App] SetValidModifZone'

  constructor(readonly validModifZone: boolean) {}
}

export class AppSetValidModifSurvey {
  public static readonly type = '[App] SetValidModifSurvey'

  constructor(readonly validModifSurvey: boolean) {}
}
