import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { HotTableRegisterer } from '@handsontable/angular'
import Handsontable from 'handsontable'
import { AddRowsDialog } from '../../shared/components/add-rows-dialog/add-rows-dialog.component'
import { LabTestDataModel, LabTestDataModelTypeGuards as TypeGuards } from '../../shared/models'
import { MessagesService, SoilSurveyService } from '../../shared/remote-services'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import { switchMap } from 'rxjs/operators'
import { BottomNavHeight, labEntitiesColumnsToTitle as colToTitle, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { depthValidator, getSourceData } from '../../shared/utils'
import { CellChange, ChangeSource } from 'handsontable/common'
import { cloneDeep } from 'lodash'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../store/app/app.selectors'

@Component({
  selector: 'soillib-surveys-labtest-data-table',
  templateUrl: './surveys-labtest-data-table.component.html',
  styleUrls: ['./surveys-labtest-data-table.component.scss'],
})
export class SurveysLabtestDataTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selectedSurvey: SoilSurvey

  tableHotId = 'labtestHotInstance'
  tableRef: Handsontable

  tableSettings: Handsontable.GridSettings
  isTableValid: boolean
  isValidating: boolean

  observer: MutationObserver

  tableSourceData: LabTestDataModel[]
  labTestDataFromDB: LabTestDataModel[]

  constructor(
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private hotTableRegisterer: HotTableRegisterer,
    private soilSurveyService: SoilSurveyService,
    private translateService: TranslateService,
    private messagesService: MessagesService,
    private store: Store,
  ) {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event) {
    this.resizeTable()
  }

  private resizeTable() {
    this.tableRef = this.hotTableRegisterer.getInstance(this.tableHotId)
    if (this.tableRef) {
      this.tableRef.render()
    }
  }

  private adjustTable() {
    const targetNode = document.documentElement
    const config = { attributes: true }
    this.observer = new MutationObserver(() => {
      if (
        getComputedStyle(targetNode).getPropertyValue('--bottom-nav-height') === BottomNavHeight.MIDDLE ||
        getComputedStyle(targetNode).getPropertyValue('--bottom-nav-height') === BottomNavHeight.TOP
      ) {
        this.resizeTable()
      }
    })
    this.observer.observe(targetNode, config)
  }

  ngOnInit(): void {
    this.adjustTable()
  }

  ngAfterViewInit() {
    this.initHandsonTable()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedSurvey && this.selectedSurvey) {
      this.initHandsonTable()
      this.loadData()
    }
  }

  private loadData() {
    const surveyId = this.selectedSurvey.id
    if (surveyId) {
      const sub = this.soilSurveyService.getLabTestDataBySurveyId(surveyId).subscribe((res: LabTestDataModel[]) => {
        this.labTestDataFromDB = res
        this.tableRef = this.hotTableRegisterer.getInstance(this.tableHotId)
        this.tableRef.updateSettings({ ...this.tableSettings, data: this.labTestDataFromDB })
        sub.unsubscribe()
      })
    }
  }

  private initHandsonTable() {
    this.tableSettings = {
      stretchH: 'all',
      className: 'htLeft smallFont',
      minRows: 10,
      rowHeaders: true,
      minCols: undefined,
      columns: [],
      afterValidate: (valid: boolean) => {
        this.isTableValid = valid
      },
      columnSorting: true,
      contextMenu: true,
      manualRowMove: true,
      manualColumnMove: false,
      manualColumnResize: true,
      fillHandle: 'vertical',
      height: '100%',
      autoColumnSize: { useHeaders: true },
      nestedHeaders: [
        [
          { label: '', colspan: 2 },
          { label: 'Bulk density', colspan: 1 },
          { label: 'Dry density', colspan: 1 },
          { label: '', colspan: 3 },
          { label: 'Atterberg limits', colspan: 3 },
          { label: 'Particule size distribution', colspan: 4 },
          { label: 'Consolidation', colspan: 5 },
          { label: 'Permeability', colspan: 2 },
          { label: 'Rock Properties', colspan: 3 },
          { label: 'Chemical Properties', colspan: 3 },
        ],
      ],
    }
    const cols = Object.keys(colToTitle)
    this.tableSettings.nestedHeaders?.push(cols.map((item) => colToTitle[item].replace(' ', '<br>')))
    this.tableSettings.columns = cols.map((item: string) => {
      if (item === 'depth') {
        return {
          data: item,
          title: colToTitle[item].replace(' ', '<br>'),
          validator: depthValidator,
          allowInvalid: true,
          invalidCellClassName: 'depth--error',
          type: 'numeric',
        } as Handsontable.ColumnSettings
      } else {
        return {
          data: item,
          title: colToTitle[item].replace(' ', '<br>'),
          type: 'numeric',
          allowInvalid: true,
          invalidCellClassName: 'highlight--error',
        }
      }
    })
    try {
      this.tableRef = this.hotTableRegisterer.getInstance(this.tableHotId)
    } catch (e) {
      console.warn('Hot table not registered') // FIXME find a way to remove this ugly try catch
    }
    this.tableSettings.minCols = this.tableSettings.columns.length
    this.tableRef?.updateSettings({ ...this.tableSettings, data: [] })
  }

  handleCellChanges = (changes: CellChange[] | null, source: ChangeSource) => {
    if (this.hotTableRegisterer.getInstance(this.tableHotId)) {
      this.tableRef = this.hotTableRegisterer.getInstance(this.tableHotId)

      this.tableSourceData = this.getFormattedData() || []
      if (source === 'loadData' && this.tableSourceData && this.tableSourceData.length > 0) {
        this.checkTableValidation()
      }
      if (source !== 'loadData') {
        this.autoSaveTableData()
      }
    }
  }

  private getFormattedData(): LabTestDataModel[] | null {
    if (!this.tableRef) {
      this.tableRef = this.hotTableRegisterer.getInstance(this.tableHotId)
    }
    const sourceData = getSourceData(this.tableRef)

    if (sourceData) {
      const surveyId = this.selectedSurvey.id
      return cloneDeep(sourceData)
        .filter((item) => TypeGuards.hasDepth(item))
        .sort((a, b) => a.depth - b.depth)
        .map((item) => {
          return { ...item, soilsurvey_id: surveyId || undefined }
        })
    } else {
      return null
    }
  }

  addRow() {
    if (!this.tableSourceData) {
      this.tableSourceData = this.getFormattedData() || []
    }
    this.tableRef.alter('insert_row', this.tableSourceData.length, 1)
  }

  addRows() {
    if (!this.tableSourceData) {
      this.tableSourceData = this.getFormattedData() || []
    }
    const dialogRef = this.dialog.open<AddRowsDialog, never, { start: number; step: number; rows: number }>(
      AddRowsDialog,
      {
        width: '500px',
      },
    )
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const { start, step, rows } = data
        const initialLength = this.tableSourceData.length
        this.tableRef.alter('insert_row', initialLength, rows)
        for (let i = 0; i < rows; i++) {
          const depth = start + i * step
          this.tableSourceData.push({
            soilsurvey_id: this.selectedSurvey.id ?? undefined,
            depth,
          })
        }
        this.tableRef.updateSettings({ data: this.tableSourceData })
      }
    })
  }

  private checkTableValidation() {
    this.isValidating = true
    this.cdRef.detectChanges()
    setTimeout(() => {
      this.tableRef.validateCells((valid) => {
        this.isTableValid = valid
        this.isValidating = false
        this.cdRef.detectChanges()
      })
    }, 600)
  }

  private autoSaveTableData() {
    if (this.isTableValid && this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      const formattedData = this.getFormattedData()
      const surveyId = this.selectedSurvey.id
      if (surveyId && formattedData) {
        this.soilSurveyService.saveLabTestDataToSurvey(surveyId, formattedData).subscribe()
      }
    }
  }

  saveTableData() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    if (this.isTableValid) {
      const formattedData = this.getFormattedData()
      const { id: surveyId, name: surveyName } = this.selectedSurvey
      if (surveyId && surveyName && formattedData) {
        this.soilSurveyService
          .saveLabTestDataToSurvey(surveyId, formattedData)
          .pipe(
            switchMap(() =>
              this.messagesService.saveResourceAndJobsiteMessage$(
                this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId),
                surveyName,
                'borehole',
                'added lab test data to the',
              ),
            ),
          )
          .subscribe(() => {
            return Swal.fire({
              title: this.translateService.instant('ALERT.SAVED'),
              text: this.translateService.instant('ALERT.SAVE_SUCCESS'),
              icon: 'success',
              confirmButtonText: 'Ok',
            })
          })
      }
    } else {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.INVALID_MESS'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
  }

  ngOnDestroy() {
    this.observer.disconnect()
  }
}
