import { Injectable } from '@angular/core'
import { DisplayedSurveys, Jobsite, LatLonLocation, Zone } from '../models'
import { parseExtentString, statusColor } from '../utils'
import Map from 'ol/Map'
import Style, { StyleLike } from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Polygon from 'ol/geom/Polygon'
import Fill from 'ol/style/Fill'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { fromLonLat as FROMLONLAT } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import { StyleService } from '../../main-map/services/style.service'
import { Geometry } from 'ol/geom'
import { SelectStatus, SoilSurvey } from '@sde-ild/ssd-soillib-lib'

@Injectable()
export class MapService {
  boreholeFeatureStyleCache = {}

  constructor(private styleService: StyleService) {}

  markLocation(location: LatLonLocation | null, sourceSelectionVecteur: VectorSource) {
    if (location) {
      const features = sourceSelectionVecteur.getFeatures()
      const markerFeature = features.length === 1 ? features[0] : null
      if (!markerFeature) {
        const markF = new Feature<Geometry>({
          geometry: new Point(FROMLONLAT([location.longitude, location.latitude])),
        })
        markF.set('jobsitecenter', 1)
        sourceSelectionVecteur.addFeature(markF)
      } else {
        const point: Point = markerFeature.getGeometry() as Point
        point.setCoordinates(FROMLONLAT([location.longitude, location.latitude]))
        markerFeature.setGeometry(point)
        markerFeature.setStyle(this.styleService.getMarkLocationStyle())
      }
    } else {
      sourceSelectionVecteur.clear()
    }
  }

  fitToVisibleMap(map: Map, coordinate: number[] | null, selectedJobsite: Jobsite | null) {
    const center = map.getView().getCenter()
    if (coordinate && center) {
      map.getView().setCenter([coordinate[0], center[1]])
    } else if (selectedJobsite && selectedJobsite.extent) {
      const fitPolygon = parseExtentString(selectedJobsite.extent)
      const geoPolygon = fitPolygon ? new Polygon([fitPolygon]) : undefined
      if (geoPolygon) {
        map.getView().fit(geoPolygon, { size: map.getSize(), padding: [0, 0, 0, 0] })
      }
    }
  }

  drawZones(
    zonesSource: VectorSource,
    zones: Zone[],
    preventCuttingsDrawing: boolean,
    selectedJobsite: Jobsite | null,
    selectedZone: Zone | null,
  ) {
    zonesSource.clear()
    if (!preventCuttingsDrawing) {
      zonesSource.addFeatures(
        zones.map((zone: Zone, index) => {
          let color
          let fillColor
          if (selectedZone && selectedZone.id === zone.id) {
            color = [255, 215, 0]
            fillColor = [255, 215, 0, 0.1]
          } else if (selectedJobsite && zone.jobsite_id === selectedJobsite.id) {
            color = [127, 255, 0]
            fillColor = [127, 255, 0, 0.1]
          } else {
            color = [255, 0, 0]
            fillColor = [255, 0, 0, 0.1]
          }
          const style = new Style({
            stroke: new Stroke({
              color,
              width: 3,
            }),
            fill: new Fill({ color: fillColor }),
          })
          const polygon = parseExtentString(zone.extent)
          const feature = new Feature<Geometry>({
            geometry: polygon ? new Polygon([polygon]) : undefined,
          })
          feature.set('zoneId', index)
          feature.setStyle(style)
          feature.setId(zone.id)
          return feature
        }),
      )
    }
  }

  private getBHFeatureStyle(type: string, selected: SelectStatus) {
    if (this.boreholeFeatureStyleCache[type] && this.boreholeFeatureStyleCache[type][selected]) {
      return this.boreholeFeatureStyleCache[type][selected]
    } else {
      const style = this.styleService.getStyle(type, statusColor(selected), 'black', 1)
      if (!this.boreholeFeatureStyleCache[type]) {
        this.boreholeFeatureStyleCache[type] = {}
      }
      this.boreholeFeatureStyleCache[type][selected] = style
      return style
    }
  }

  toggleSurveySelectStyle(surveysSource: VectorSource, surveysToToggle: SoilSurvey[]) {
    if (surveysToToggle && surveysToToggle.length > 0) {
      surveysToToggle.forEach((survey) => {
        const surveyId = survey.id
        const surveyType = survey.type
        const feature = surveyId ? surveysSource.getFeatureById(surveyId) : undefined
        if (surveyType && feature) {
          if (feature.get('selectStatus') === SelectStatus.SELECTED) {
            feature.setStyle(this.getBHFeatureStyle(surveyType, SelectStatus.UNSELECTED))
            feature.set('selectStatus', SelectStatus.UNSELECTED)
          } else if (feature.get('selectStatus') === SelectStatus.UNSELECTED) {
            feature.setStyle(this.getBHFeatureStyle(surveyType, SelectStatus.SELECTED))
            feature.set('selectStatus', SelectStatus.SELECTED)
          }
        }
      })
    }
  }

  setSurveyFeatureStyle(surveysSource: VectorSource, surveysToToggle: SoilSurvey[], status: SelectStatus) {
    if (surveysToToggle && surveysToToggle.length > 0) {
      surveysToToggle.forEach((survey) => {
        const surveyId = survey.id
        const feature = surveyId ? surveysSource.getFeatureById(surveyId) : undefined
        if (feature) {
          const surveyType = survey.type
          if (surveyType) {
            feature.setStyle(this.getBHFeatureStyle(surveyType, status))
          }
          feature.set('selectStatus', status)
        }
      })
    }
  }

  deleteSurveys(surveysSource: VectorSource, surveyIdsToDelete: string[]) {
    if (surveyIdsToDelete && surveyIdsToDelete.length > 0) {
      surveyIdsToDelete.forEach((id) => {
        const feature = surveysSource.getFeatureById(id)
        if (feature) {
          surveysSource.removeFeature(feature)
        }
      })
    }
  }

  addSurveys(
    selectedSurveyId: string | null | undefined,
    surveysSource: VectorSource,
    displayedSurveys: DisplayedSurveys,
    surveysToAdd: SoilSurvey[],
    selectedJobsite: Jobsite | null,
  ) {
    if (surveysToAdd && surveysToAdd.length > 0) {
      if (displayedSurveys?.displaySurveys) {
        surveysSource.addFeatures(
          surveysToAdd
            .filter(
              ({ id: surveyId, type }) =>
                surveyId &&
                type &&
                !surveysSource.getFeatureById(surveyId) &&
                this.isTypeVisible(displayedSurveys, type),
            )
            .map((survey) => this.toFeature(selectedSurveyId, survey, selectedJobsite)),
        )
      }
    }
  }

  drawSurveysOnMap(
    selectedSurveyId: string | null | undefined,
    surveysSource: VectorSource,
    displaySurveys: DisplayedSurveys,
    selectedJobsite: Jobsite | null,
    surveysToDraw: SoilSurvey[] | null,
  ) {
    if (surveysToDraw) {
      this.addSurveys(selectedSurveyId, surveysSource, displaySurveys, surveysToDraw, selectedJobsite)
    }
  }

  drawSurveys(
    surveysSource: VectorSource,
    displayedSurveys: DisplayedSurveys,
    surveys: SoilSurvey[],
    selectedJobsite: Jobsite | null,
    selectedSurvey: SoilSurvey | null,
  ) {
    surveysSource.clear()
    if (displayedSurveys.displaySurveys) {
      surveysSource.addFeatures(
        surveys
          .filter(({ type }) => type && this.isTypeVisible(displayedSurveys, type))
          .map((survey) => this.toFeature(selectedSurvey?.id, survey, selectedJobsite)),
      )
    }
  }

  private toFeature(
    selectedSurveyId: string | null | undefined,
    survey: SoilSurvey,
    selectedJobsite: Jobsite | null,
  ): Feature<Geometry> {
    let selectedStatus: SelectStatus
    if (selectedSurveyId === survey.id) {
      selectedStatus = SelectStatus.SELECTED
    } else if (selectedJobsite && survey.jobsite_id === selectedJobsite.id) {
      selectedStatus = SelectStatus.UNSELECTED
    } else {
      selectedStatus = SelectStatus.DEACTIVATED
    }
    const style: StyleLike | undefined = survey.type ? this.getBHFeatureStyle(survey.type, selectedStatus) : undefined
    const feature = new Feature<Geometry>({
      geometry: new Point(FROMLONLAT([Number(survey.lon_coord), Number(survey.lat_coord)])),
    })
    feature.setStyle(style)
    feature.set('surveyId', survey.id)
    feature.set('selectStatus', selectedStatus)
    feature.setId(survey.id ?? undefined)
    return feature
  }

  private isTypeVisible = (displayedSurveys: DisplayedSurveys, type: string | null | undefined) =>
    type ? type.split(',').some((t) => displayedSurveys.filters[t]) : true
}
