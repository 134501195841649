<div
  [@slideInOutFromLeft]="crossSectionListOpened"
  [ngStyle]="getComplexChartsButtonStyle()"
  class="container"
  fxLayout="column"
  fxLayoutAlign="center center"
  (click)="handleCrossSectionListToggle()"
>
  <div fxLayout="row">
    <span>{{ 'CROSS_SECTION.LIST' | translate }}</span>
    <img *ngIf="!crossSectionListOpened" fxFlexAlign="end" id="img-right-arrow" src="assets/ico-arrow-right.png" />
  </div>
</div>

<div [@slideInOutFromLeft]="crossSectionListOpened" *ngIf="crossSectionListOpened" class="side-container">
  <div class="options-container">
    <mat-form-field>
      <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
      <mat-icon style="margin-right: 10px" matPrefix>search</mat-icon>
      <input matInput type="text" autocomplete="off" [formControl]="searchFormControl" />
      <button mat-raised-button matSuffix mat-icon-button aria-label="Clear" (click)="searchFormControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="list-container" (scroll)="onScroll($event.target)">
    <mat-selection-list [multiple]="false" (selectionChange)="onSelectCrossSectionGraph($event)">
      <mat-list-option *ngFor="let item of crossSections$ | async" [value]="item">
        <mat-icon mat-list-icon>insert_chart_outlined</mat-icon>
        <div fxLayout="row" fxLayoutAlign="space-between center" matTooltip="{{ getPopInfo(item) }}">
          <div>{{ item?.cr_name }}</div>
          <div *ngIf="user.id === item?.created_by" (click)="deleteCrossSection(item, $event)">
            <mat-icon>delete</mat-icon>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
