<h1 mat-dialog-title>{{ 'TABLE.ADDROWS' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'TABLE.HOWMANYROWS' | translate }}</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.rows" />
  </mat-form-field>
  <p>{{ 'TABLE.STARTDEPTH' | translate }}</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.start" />
  </mat-form-field>
  <p>{{ 'TABLE.STEP' | translate }}</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.step" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">{{ 'GENERAL.CANCEL' | translate }}</button>
  <button mat-raised-button [mat-dialog-close]="data" cdkFocusInitial>{{ 'GENERAL.ADD' | translate }}</button>
</div>
