<h1 mat-dialog-title>{{ 'GENERAL.SELECT_COLUMNS_SHOW' | translate }}</h1>
<div>
  <form *ngIf="availableParams" [formGroup]="selectedColumnsGroup" fxLayout="column">
    <span *ngFor="let param of availableParams; let i = index">
      <mat-checkbox color="primary" [formControlName]="param.data">
        <span [innerHTML]="param.title"></span>
      </mat-checkbox>
    </span>
  </form>
  <div *ngIf="!availableParams || availableParams.length === 0">{{ 'GENERAL.NO_PARAS' | translate }}</div>
</div>
<div mat-dialog-actions *ngIf="selectedColumnsGroup">
  <button mat-raised-button (click)="close()">{{ 'GENERAL.CANCEL' | translate }}</button>
  <button mat-raised-button [mat-dialog-close]="selectedColumnsGroup.value" cdkFocusInitial>Ok</button>
</div>
