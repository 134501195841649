import { Action, State, StateContext, Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
  CrossSectionClearSelectedCrossSection,
  CrossSectionDeleteCrossSection,
  CrossSectionFetchCrossSections,
  CrossSectionFetchSelectedCrossSection,
  CrossSectionSetProjectedBhInfos,
  CrossSectionSetSearch,
} from './cross-section.actions'
import produce from 'immer'
import { CrossSectionDto, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { map, of, OperatorFunction, pipe } from 'rxjs'
import { filter, switchMap, tap } from 'rxjs/operators'
import { CrossSectionGraphService } from '../../shared/remote-services'
import { unionBy } from 'lodash'
import { switchTap } from '../../shared/utils/observables.utils'
import { BoreholeInfo, CrossSectionInfos, ParamInfo } from '../../shared/models'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { MapStateSelectors } from '../../store/map/map.selectors'

export interface CrossSectionStateModel {
  search: string
  pageState: string | null
  crossSections: (CrossSectionDto & { selected?: boolean })[]
  selectedCrossSection: { dto: CrossSectionDto; infos: CrossSectionInfos } | null
  projectedBhInfos: Record<string, { projectedPointCoord: number[]; distance: number }> | null
}

@State<CrossSectionStateModel>({
  name: 'crossSection',
  defaults: {
    search: '',
    pageState: null,
    crossSections: [],
    selectedCrossSection: null,
    projectedBhInfos: null,
  },
})
@Injectable()
export class CrossSectionState {
  constructor(private store: Store, private crossSectionGraphService: CrossSectionGraphService) {}

  @Action(CrossSectionSetSearch)
  public setSearch(ctx: StateContext<CrossSectionStateModel>, { search }: CrossSectionSetSearch) {
    ctx.setState(
      produce((draft) => {
        draft.search = search
        draft.pageState = null
        draft.crossSections = []
      }),
    )
  }

  @Action(CrossSectionFetchCrossSections)
  public fetchCrossSections(ctx: StateContext<CrossSectionStateModel>) {
    return of(ctx.getState()).pipe(
      this.appendJobsiteId(),
      filter(({ pageState, crossSections }) => pageState != null || crossSections.length === 0),
      switchMap(({ jobsiteId, search, pageState }) =>
        this.crossSectionGraphService.getCrossSectionList(jobsiteId, search, pageState),
      ),
      tap(({ entities, pageState }) => {
        ctx.setState(
          produce((draft) => {
            draft.crossSections = unionBy(draft.crossSections, entities, 'cr_id')
            draft.pageState = pageState
          }),
        )
      }),
    )
  }

  @Action(CrossSectionFetchSelectedCrossSection)
  public fetchSelectedCrossSection(
    ctx: StateContext<CrossSectionStateModel>,
    payload: CrossSectionFetchSelectedCrossSection,
  ) {
    return of(payload).pipe(
      this.appendJobsiteId(),
      switchMap(({ jobsiteId, crId }) => this.crossSectionGraphService.getCrossSectionById(jobsiteId, crId)),
      tap((crossSection) => {
        ctx.setState(
          produce((draft) => {
            draft.selectedCrossSection = {
              dto: crossSection,
              infos: this.toCrossSectionInfos(crossSection),
            }
          }),
        )
      }),
    )
  }

  @Action(CrossSectionClearSelectedCrossSection)
  public clearSelectedCrossSection(ctx: StateContext<CrossSectionStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.crossSections = draft.crossSections.map((item) => ({ ...item, selected: undefined }))
        draft.selectedCrossSection = null
      }),
    )
  }

  @Action(CrossSectionDeleteCrossSection)
  public deleteCrossSection(ctx: StateContext<CrossSectionStateModel>, payload: CrossSectionDeleteCrossSection) {
    return of(payload).pipe(
      this.appendJobsiteId(),
      switchTap(({ jobsiteId, crId }) => this.crossSectionGraphService.deleteCrossSection(jobsiteId, crId)),
      tap(({ crId }) => {
        ctx.setState(
          produce((draft) => {
            draft.crossSections = draft.crossSections.filter((item) => item.cr_id !== crId)
            if (draft.selectedCrossSection?.dto.cr_id === crId) {
              draft.selectedCrossSection = null
            }
          }),
        )
      }),
    )
  }

  @Action(CrossSectionSetProjectedBhInfos)
  public setSetProjectedBhInfos(
    ctx: StateContext<CrossSectionStateModel>,
    { projectedBhInfos }: CrossSectionSetProjectedBhInfos,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.projectedBhInfos = projectedBhInfos
      }),
    )
  }

  private toCrossSectionInfos(crossSection: CrossSectionDto) {
    const surveysEntities = this.store.selectSnapshot(MapStateSelectors.slices.surveysEntities)
    const originalBhs: SoilSurvey[] = []
    const projectedBhs: SoilSurvey[] = []
    const paramInfos: Record<string, ParamInfo[]> = {}
    crossSection.boreholes?.forEach((item: BoreholeInfo) => {
      if (item.bh_original) {
        if (surveysEntities && surveysEntities[item.survey_id]) {
          originalBhs.push(surveysEntities[item.survey_id])
        }
      }
      if (!item.bh_original) {
        if (surveysEntities && surveysEntities[item.survey_id]) {
          projectedBhs.push(surveysEntities[item.survey_id])
        }
      }
      paramInfos[item.survey_id] = item.params
    })

    return {
      boreholeInfo: crossSection.boreholes || [],
      originalBhs,
      projectedBhs,
      paramInfos,
    }
  }

  private appendJobsiteId = <T>(): OperatorFunction<T, T & { jobsiteId: string }> =>
    pipe(
      map((payload) => ({ ...payload, jobsiteId: this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId) })),
      filter(this.withJobsiteId),
    )

  private withJobsiteId = <TValue>(
    value: TValue & { jobsiteId: string | null | undefined },
  ): value is TValue & { jobsiteId: string } => value.jobsiteId !== null && value.jobsiteId !== undefined
}
