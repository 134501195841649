import { CorrelationDataModel, CorrelationParametersModel, SBTnParametersModel } from '../../shared/models'

export class CorrelationDataFetchCorrelationData {
  public static readonly type = '[CorrelationData] FetchCorrelationData'

  constructor(readonly soilSurveyId: string) {}
}

export class CorrelationDataUpdateCorrelationData {
  public static readonly type = '[CorrelationData] UpdateCorrelationData'

  constructor(readonly soilSurveyId: string, readonly sbtNParametersModel?: SBTnParametersModel) {}
}

export class CorrelationDataSetCorrelationData {
  public static readonly type = '[CorrelationData] SetCorrelationData'

  constructor(readonly correlationData: CorrelationDataModel[] | null) {}
}

export class CorrelationDataClearCorrelationData {
  public static readonly type = '[CorrelationData] ClearCorrelationData'
}

export class CorrelationDataFetchCorrelationParameters {
  public static readonly type = '[CorrelationData] FetchCorrelationParameters'

  constructor(readonly soilSurveyId: string) {}
}

export class CorrelationDataSaveCorrelationParameters {
  public static readonly type = '[CorrelationData] SaveCorrelationParameters'

  constructor(readonly soilSurveyId: string, readonly correlationParameters: CorrelationParametersModel) {}
}

export class CorrelationDataDownloadExportFile {
  public static readonly type = '[CorrelationData] DownloadExportFile'

  constructor(readonly soilSurveyId: string, readonly sbtNParametersModel?: SBTnParametersModel) {}
}
