import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { Observable, Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { LatLonLocation } from '../../models'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { getDD } from '../../utils/ddToDms'
import { dmsLatValidator, dmsLonValidator, nonNullable } from '../../utils'
import { GoogleSearchModel, GoogleSearchResult } from '../../models/Google-search.model'
import { Select } from '@ngxs/store'
import { AppStateSelectors } from '../../../store/app/app.selectors'

@Component({
  selector: 'soillib-search-address-draggable',
  templateUrl: './search-address-draggable.component.html',
  styleUrls: ['./search-address-draggable.component.scss'],
})
export class SearchAddressDraggableComponent implements OnInit, OnDestroy {
  @Output()
  handleSelectedAddressEvent: EventEmitter<LatLonLocation> = new EventEmitter()
  @Output()
  saveAddressEvent: EventEmitter<LatLonLocation> = new EventEmitter()
  @Output()
  closePopupEvent: EventEmitter<boolean> = new EventEmitter()

  @Select(AppStateSelectors.slices.selectedJobsiteCoord) selectedJobsiteCoord$: Observable<LatLonLocation | null>

  private subscription: Subscription = new Subscription()
  search$: Subject<string> = new Subject<string>()
  searchResults: GoogleSearchResult[]
  coordinateForm: FormGroup<{
    lon: FormControl<string | null>
    lat: FormControl<string | null>
  }>
  selectedTabIndex = 0

  private data: LatLonLocation

  constructor(private http: HttpClient, private fb: FormBuilder) {}

  ngOnInit() {
    this.subscription.add(
      this.selectedJobsiteCoord$.pipe(filter(nonNullable)).subscribe((userSelectedCoord) => {
        this.selectedTabIndex = 1
        this.data = userSelectedCoord
        this.initForm(
          userSelectedCoord.longitude.toFixed(7).toString(),
          userSelectedCoord.latitude.toFixed(7).toString(),
        )
      }),
    )

    const searchInput$ = this.search$.pipe(
      debounceTime(600),
      filter((search) => search.length > 1),
      distinctUntilChanged(),
    )

    this.subscription.add(
      searchInput$.pipe(switchMap((searchInput) => this.geocodageQuery(searchInput))).subscribe({
        next: (res: GoogleSearchModel) => this.handleSearchResults(res),
        error: (err) => console.error(err),
      }),
    )

    this.initForm('', '')
  }

  private initForm(lon: string, lat: string) {
    this.coordinateForm = this.fb.group({
      lon: this.fb.control(lon, [Validators.required, dmsLonValidator.bind(this)]),
      lat: this.fb.control(lat, [Validators.required, dmsLatValidator.bind(this)]),
    })
    ;[
      this.coordinateForm
        .get('lon')
        ?.valueChanges?.pipe(debounceTime(600))
        ?.subscribe(() => {
          this.setLocationByCoordinate()
        }),
      this.coordinateForm
        .get('lat')
        ?.valueChanges?.pipe(debounceTime(600))
        ?.subscribe(() => {
          this.setLocationByCoordinate()
        }),
    ]
      .filter((s) => !!s)
      .forEach((s) => this.subscription.add(s))
  }

  private setLocationByCoordinate() {
    if (this.coordinateForm.get('lon')?.valid && this.coordinateForm.get('lat')?.valid) {
      const newCoordinates: LatLonLocation = {
        latitude: getDD(this.coordinateForm.value.lat || ''),
        longitude: getDD(this.coordinateForm.value.lon || ''),
      }
      this.data = newCoordinates
      this.handleSelectedAddressEvent.emit(newCoordinates)
    }
  }

  handleSearchResults(results: GoogleSearchModel) {
    if (results.results.length && results.status === 'OK') {
      results.results.forEach((result) => (result.displayedName = result.formatted_address))
      this.searchResults = results.results
    }
  }

  handleSearchSelection(selectedSearch: GoogleSearchResult) {
    if (selectedSearch.geometry) {
      const lat = selectedSearch.geometry.location.lat
      const lon = selectedSearch.geometry.location.lng
      const location: LatLonLocation = { latitude: lat, longitude: lon }
      this.data = location
      this.handleSelectedAddressEvent.emit(location)
      this.coordinateForm.setValue({
        lon: location.longitude.toString(),
        lat: location.latitude.toString(),
      })
    }
  }

  geocodageQuery(search: string): Observable<GoogleSearchModel> {
    const apiKey = 'AIzaSyCiGbSZZ8hZW12O-TrngTRUfrZT1WTmdAU'
    return this.http.get<GoogleSearchModel>(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${search}&key=${apiKey}`,
    )
  }

  displaySearch(searchResult: GoogleSearchResult) {
    return searchResult.displayedName
  }

  saveAddress() {
    if (this.data) {
      this.saveAddressEvent.emit(this.data)
    }
  }
  close() {
    this.closePopupEvent.emit(true)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
