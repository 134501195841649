/*
 * Generated type guards for "lab_test_data.model.ts".
 * WARNING: Do not manually change this file.
 */
import { LabTestDataModel, WithDepth } from './lab_test_data.model'

export function isLabTestDataModel(obj: unknown): obj is LabTestDataModel {
  const typedObj = obj as LabTestDataModel
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typeof typedObj['soilsurvey_id'] === 'undefined' || typeof typedObj['soilsurvey_id'] === 'string') &&
    (typeof typedObj['depth'] === 'undefined' || typeof typedObj['depth'] === 'number') &&
    (typeof typedObj['w'] === 'undefined' || typeof typedObj['w'] === 'number') &&
    (typeof typedObj['gamma'] === 'undefined' || typeof typedObj['gamma'] === 'number') &&
    (typeof typedObj['gammad'] === 'undefined' || typeof typedObj['gammad'] === 'number') &&
    (typeof typedObj['phi_prime'] === 'undefined' || typeof typedObj['phi_prime'] === 'number') &&
    (typeof typedObj['c_prime'] === 'undefined' || typeof typedObj['c_prime'] === 'number') &&
    (typeof typedObj['cu'] === 'undefined' || typeof typedObj['cu'] === 'number') &&
    (typeof typedObj['ll'] === 'undefined' || typeof typedObj['ll'] === 'number') &&
    (typeof typedObj['pl_maju'] === 'undefined' || typeof typedObj['pl_maju'] === 'number') &&
    (typeof typedObj['pl_minu'] === 'undefined' || typeof typedObj['pl_minu'] === 'number') &&
    (typeof typedObj['gravel'] === 'undefined' || typeof typedObj['gravel'] === 'number') &&
    (typeof typedObj['sand'] === 'undefined' || typeof typedObj['sand'] === 'number') &&
    (typeof typedObj['silt'] === 'undefined' || typeof typedObj['silt'] === 'number') &&
    (typeof typedObj['clay'] === 'undefined' || typeof typedObj['clay'] === 'number') &&
    (typeof typedObj['eo'] === 'undefined' || typeof typedObj['eo'] === 'number') &&
    (typeof typedObj['cc'] === 'undefined' || typeof typedObj['cc'] === 'number') &&
    (typeof typedObj['cs'] === 'undefined' || typeof typedObj['cs'] === 'number') &&
    (typeof typedObj['cv'] === 'undefined' || typeof typedObj['cv'] === 'number') &&
    (typeof typedObj['pc'] === 'undefined' || typeof typedObj['pc'] === 'number') &&
    (typeof typedObj['kv'] === 'undefined' || typeof typedObj['kv'] === 'number') &&
    (typeof typedObj['kh'] === 'undefined' || typeof typedObj['kh'] === 'number') &&
    (typeof typedObj['ucs'] === 'undefined' || typeof typedObj['ucs'] === 'number') &&
    (typeof typedObj['plil'] === 'undefined' || typeof typedObj['plil'] === 'number') &&
    (typeof typedObj['e'] === 'undefined' || typeof typedObj['e'] === 'number') &&
    (typeof typedObj['ph'] === 'undefined' || typeof typedObj['ph'] === 'number') &&
    (typeof typedObj['caco3'] === 'undefined' || typeof typedObj['caco3'] === 'number') &&
    (typeof typedObj['organic'] === 'undefined' || typeof typedObj['organic'] === 'number')
  )
}

export function hasDepth(obj: unknown): obj is WithDepth {
  const typedObj = obj as WithDepth
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    typeof typedObj['depth'] === 'number'
  )
}
