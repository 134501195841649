import { AfterViewChecked, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { chart } from 'highcharts'
import { dynamicTickIntervalsWhenZooming, propertyHasChanged } from '../../shared/utils'

@Component({
  selector: 'soillib-correlation-data-chart',
  template: `
    <mat-card>
      <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div [innerHtml]="title"></div>
          <div
            *ngIf="reference"
            class="info-ctnr"
            [matTooltip]="reference"
            matTooltipClass="correlation-data-reference-tooltip"
            matTooltipPosition="right"
          >
            <mat-icon>info</mat-icon>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div [id]="chartContainerId" class="chart-ctnr"></div>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      .mat-card {
        min-height: 442px;
      }

      .chart-ctnr {
        height: 100%;
      }

      .info-ctnr {
        width: 24px;
        height: 24px;
        margin-left: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrelationDataChartComponent implements AfterViewChecked, OnChanges {
  @Input()
  chartContainerId: string

  @Input()
  title: string

  @Input()
  yAxisTitle: string

  @Input()
  seriesData: Highcharts.SeriesOptionsType[]

  @Input()
  chartMarginTop = 50

  @Input()
  legend = false

  @Input()
  elevationParameters: {
    activeElevation: boolean
    label: string
  }

  @Input()
  reference: string

  @Input()
  warningText: string

  @Input()
  tooltip: Highcharts.TooltipOptions | undefined

  private chartRef: Highcharts.Chart | null = null

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.seriesData?.currentValue?.length >= 0 && propertyHasChanged(changes, 'seriesData')) ||
      (changes.elevationParameters && propertyHasChanged(changes, 'elevationParameters'))
    ) {
      this.drawChart()
    }
  }

  ngAfterViewChecked(): void {
    if (this.chartRef === null) {
      this.drawChart()
    }
  }

  private drawChart() {
    if (document.getElementById(this.chartContainerId)) {
      if (this.chartRef) {
        this.chartRef.destroy()
        this.chartRef = null
      }
      let options: Highcharts.Options = {
        chart: {
          zoomType: 'xy',
          inverted: true,
          marginTop: this.chartMarginTop,
          marginBottom: 70,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          title: {
            text: this.elevationParameters.label,
          },
          reversed: !this.elevationParameters.activeElevation,
          crosshair: true,
          endOnTick: true,
          startOnTick: true,
          gridLineWidth: 1,
          gridZIndex: 0,
          tickInterval: 5,
          events: {
            setExtremes: dynamicTickIntervalsWhenZooming(5),
          },
          min: this.elevationParameters.activeElevation ? undefined : 0,
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
          },
          endOnTick: true,
          startOnTick: true,
          lineWidth: 1,
          gridLineWidth: 1,
          gridZIndex: 0,
        },
        legend: {
          enabled: this.legend,
          verticalAlign: 'top',
        },
        tooltip: {
          formatter() {
            return `<div>${this.x}</div><br><span style="color: ${this.color}">${this.series.name}</span>: <b> ${this.y} </b>`
          },
        },
        credits: {
          enabled: false,
        },
        series: this.seriesData,
      }
      if (this.warningText) {
        options = {
          ...options,
          subtitle: {
            text: this.warningText,
            align: 'left',
          },
        }
      }
      if (this.tooltip) {
        options = {
          ...options,
          tooltip: this.tooltip,
        }
      }
      this.chartRef = chart(this.chartContainerId, options)
    }
  }
}
