import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { Select } from '@ngxs/store'
import { AppStateSelectors } from '../../../store/app/app.selectors'

@Component({
  selector: 'soillib-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Select(AppStateSelectors.isLoading) isLoading$: Observable<boolean>
}
