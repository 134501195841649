<form>
  <input
    type="text"
    placeholder="{{ 'SEARCHBOX.PLACEHOLDER' | translate }}"
    [formControl]="searchControl"
    [matAutocomplete]="searchBox"
  />
  <button type="button" (click)="fitToGoogleAddress()"><i class="material-icons md-light">search</i></button>
  <mat-autocomplete
    #searchBox
    [displayWith]="displaySearch"
    (optionSelected)="selectedJobsite.emit($event.option.value)"
    class="search-box-panel"
  >
    <div *ngIf="helpMess" class="search-helptips" fxLayout="row" fxLayoutAlign="space-between start">
      <mat-icon fxFlex="10">info</mat-icon>
      <div fxFlex [innerHTML]="helpMess"></div>
      <mat-icon fxFlex="5" class="close-button" (click)="closeHelpInfo($event)">clear</mat-icon>
    </div>
    <mat-option
      [ngStyle]="getDisplayedSearchStyle(searchResult)"
      *ngFor="let searchResult of searchResults"
      [value]="searchResult"
    >
      {{ searchResult.displayedName }}
    </mat-option>
  </mat-autocomplete>
</form>
