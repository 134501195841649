import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as Highcharts from 'highcharts'
import { SurveySBTnResultsModel } from '../../models'
import {
  chartTitle,
  SbtnZoneColor,
  SbtnZoneDesc,
  yAxisTitle,
  getPlotBands,
  getPolygonData,
  getY,
  getZone,
} from '@sde-ild/ssd-soillib-lib'
import { dynamicTickIntervalsWhenZooming } from '../../utils'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../../store/app/app.selectors'

@Component({
  selector: 'soillib-sbtn-chart-template',
  template: `<div id="{{ chartId }}" style="height: 100%"></div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbtnChartTemplateComponent implements AfterViewInit, OnChanges {
  @Input()
  elevation: number | null | undefined

  @Input()
  elevationMode: boolean

  @Input()
  chartId: string

  @Input()
  seriesData: SurveySBTnResultsModel[]

  @Input()
  reflowChartsSignal: boolean

  chartRef: Highcharts.Chart | null
  plotBands: Highcharts.YAxisPlotBandsOptions[]

  polygonSeriesData: Highcharts.SeriesOptionsType[]
  lineSeriesData: Highcharts.SeriesOptionsType[]

  constructor(private translateService: TranslateService, private store: Store) {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event) {
    this.chartRef?.reflow()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reflowChartsSignal) {
      this.chartRef?.reflow()
    }
    if (changes.seriesData?.currentValue?.length >= 0 || changes.elevationMode) {
      this.drawChart()
    }
  }

  ngAfterViewInit() {
    this.drawChart()
  }

  private getDepthOrElevation(depth: number) {
    if (this.activeElevation()) {
      return parseFloat(((this.elevation || 0) - depth).toFixed(2))
    } else {
      return parseFloat(depth.toFixed(2))
    }
  }

  private drawChart() {
    if (this.chartId === 'sbt') {
      this.generatePolygonSeries()
    } else {
      this.lineSeriesData = [
        {
          type: 'line',
          name: yAxisTitle[this.chartId],
          data: this.seriesData
            ?.map((res) => [this.getDepthOrElevation(res.depth), res[this.chartId]])
            .sort((a, b) => a[0] - b[0]),
        } as Highcharts.SeriesOptionsType,
      ]
    }

    this.initChart()
  }

  private initChart() {
    if (document.getElementById(this.chartId)) {
      if (this.chartRef) {
        this.chartRef.destroy()
        this.chartRef = null
      }
      const icChart = this.chartId === 'icRolling'
      if (icChart && !this.plotBands) {
        this.plotBands = getPlotBands()
      }

      const options: Highcharts.Options = {
        chart: {
          zoomType: 'xy',
          inverted: true,
          marginTop: 50,
          marginBottom: 70,
        },
        title: {
          text: chartTitle[this.chartId],
          style: {
            fontSize: '15px',
          },
        },
        boost: {
          useGPUTranslations: true,
        },
        xAxis: {
          title: {
            text: this.activeElevation()
              ? this.translateService.instant('SURVEY.ELEVATION', {
                  name: this.store.selectSnapshot(AppStateSelectors.datumDisplayName),
                })
              : this.translateService.instant('GENERAL.DEPTH'),
          },
          reversed: !this.activeElevation(),
          crosshair: true,
          endOnTick: true,
          startOnTick: true,
          gridLineWidth: 1,
          gridZIndex: 0,
          tickInterval: 5,
          events: {
            setExtremes: dynamicTickIntervalsWhenZooming(5),
          },
          min: this.activeElevation() ? undefined : 0,
        },
        yAxis: {
          title: {
            text: yAxisTitle[this.chartId],
          },
          endOnTick: !icChart,
          startOnTick: !icChart,
          plotBands: this.plotBands,
          lineWidth: 1,
          gridLineWidth: 1,
          gridZIndex: 0,
          min: icChart ? 1 : undefined,
          max: icChart ? 4 : undefined,
          tickInterval: icChart ? 1 : undefined,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 6,
        },
        credits: {
          enabled: false,
        },
        series: this.chartId === 'sbt' ? this.polygonSeriesData : this.lineSeriesData,
      }
      this.chartRef = Highcharts.chart(this.chartId, options)
    }
  }

  private activeElevation() {
    return this.elevationMode && this.elevation != null
  }

  private generatePolygonSeries() {
    this.polygonSeriesData = []
    const icData = this.seriesData
      ?.map((data) => [this.getDepthOrElevation(data.depth), data.icRolling])
      .sort((a, b) => a[0] - b[0])
    let SBTnZone: string | null = null
    if (icData) {
      let from = icData?.[0]?.[0]
      let to = icData?.[0]?.[0]
      for (const row of icData) {
        if (row[1] === null && SBTnZone === null) {
          from = row[0]
          continue
        }
        if (SBTnZone === null) {
          SBTnZone = getZone(row[1])
        }
        if (SBTnZone === getZone(row[1])) {
          to = row[0]
        } else {
          if (SBTnZone) {
            this.addPolygonSeriesData(from, to, SBTnZone)
          }
          from = to
          to = row[0]
          SBTnZone = getZone(row[1])
        }
      }
      if (SBTnZone) {
        this.addPolygonSeriesData(from, to, SBTnZone)
      }
    }
  }

  private addPolygonSeriesData(from: number, to: number, SBTnZone: string) {
    if (from < to && SBTnZone) {
      this.polygonSeriesData.push({
        type: 'polygon',
        name: from + 'm - ' + to + 'm: ' + SbtnZoneDesc[SBTnZone],
        data: getPolygonData(from, to, getY(SBTnZone)),
        color: SbtnZoneColor[SBTnZone],
      } as Highcharts.SeriesOptionsType)
    }
  }
}
