<div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start center">
  <h3>{{ 'SURVEY.ADD' | translate }}</h3>
  <mat-slide-toggle color="warn" [formControl]="useSNCoordFormControl">{{
    'SURVEY.USE_ENCOORDINATES' | translate
  }}</mat-slide-toggle>
</div>
<form [formGroup]="surveyForm">
  <table>
    <thead>
      <tr>
        <th>{{ 'SURVEY.NAME' | translate }} *</th>
        <th fxLayout="row">
          <span>Type *</span>
          <a
            href="https://support-ild.atlassian.net/wiki/spaces/ZHELP/pages/4063251/Borehole+type+parameters"
            target="_blank"
            title="help doc"
            style="text-decoration: none; color: red"
          >
            <i class="material-icons">help</i></a
          >
        </th>
        <th>{{ 'SURVEY.ELEVATION' | translate : { name: (datumDisplayName$ | async) || 'NGF' } }}</th>
        <th>{{ 'SURVEY.WATER_LEVEL' | translate : { name: (datumDisplayName$ | async) || 'NGF' } }}</th>
        <th>Longitude *</th>
        <th>Latitude *</th>
        <ng-container *ngIf="useSNCoordFormControl.value">
          <th class="black-background">{{ 'SURVEY.COORD_X' | translate }}</th>
          <th class="black-background">{{ 'SURVEY.COORD_Y' | translate }}</th>
          <th class="black-background">
            {{ 'SURVEY.PROJ_REF' | translate }}
            <span>
              <a href="http://epsg.io/" target="_blank" title="Coordinate System Worldwide" style="color: yellow">
                (EPSG)</a
              >
            </span>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <mat-form-field class="small-col">
            <input matInput formControlName="name" type="text" autocomplete="off" />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="small-col" style="margin-top: -4px">
            <mat-select formControlName="type" multiple>
              <mat-option *ngFor="let type of allBoreholeTypes" [value]="type">{{
                showBoreholeTypeName(type)
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="small-col">
            <input matInput formControlName="elevation" type="number" autocomplete="off" />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="medium-col">
            <input matInput formControlName="waterLvl" type="number" autocomplete="off" />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="medium-col">
            <input
              matInput
              formControlName="lon"
              type="text"
              autocomplete="off"
              [class.gray-color]="useSNCoordFormControl.value"
            />
            <mat-error *ngIf="surveyForm.controls['lon'].hasError('dmsLonFormat') && !useSNCoordFormControl.value">{{
              'SURVEY.LON_FORMAT_ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="medium-col">
            <input
              matInput
              formControlName="lat"
              type="text"
              autocomplete="off"
              [class.gray-color]="useSNCoordFormControl.value"
            />
            <mat-error *ngIf="surveyForm.controls['lat'].hasError('dmsLatFormat') && !useSNCoordFormControl.value">{{
              'SURVEY.LAT_FORMAT_ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </td>
        <ng-container *ngIf="useSNCoordFormControl.value">
          <td>
            <mat-form-field class="medium-col">
              <input matInput formControlName="localCoordX" type="number" autocomplete="off" />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="medium-col">
              <input matInput formControlName="localCoordY" type="number" autocomplete="off" />
            </mat-form-field>
          </td>
          <td>
            <div fxLayout="row">
              <mat-form-field class="medium-col" style="margin-top: -4px; margin-right: 10px" fxFlex="35">
                <mat-label>{{ 'FILE_IMPORT.KNOWN_PROJ' | translate }}</mat-label>
                <mat-select formControlName="projectionRef">
                  <mat-option *ngFor="let key of getKeys(known_projections)" value="{{ key }}"
                    >{{ known_projections[key]?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="65" fxLayout="row">
                <input
                  matInput
                  placeholder="{{ 'SURVEY.ADD_PROJECTION_VIA_EPSG' | translate }}"
                  matTooltip="{{ 'SURVEY.ADD_PROJECTION_VIA_EPSG' | translate }}"
                  [formControl]="customProjFormControl"
                  (keyup.enter)="applyProjection()"
                  autocomplete="off"
                />
                <button
                  mat-raised-button
                  *ngIf="customProjFormControl?.value"
                  [disabled]="customProjFormControl?.invalid"
                  matSuffix
                  mat-icon-button
                  (click)="applyProjection()"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
                <mat-error *ngIf="customProjFormControl?.dirty && customProjFormControl?.invalid">
                  {{ 'FILE_IMPORT.PROJ_FORMAT' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-error *ngIf="!proj_valid" style="float: left">{{ 'SURVEY.PROJ_NO_VALID' | translate }}</mat-error>
            <div *ngIf="notfounderror" style="color: red; float: right">{{ notfounderror }}</div>
            <div *ngIf="ENCoordApplied" style="color: green">{{ 'SURVEY.APPLY_ENCOORDINATES' | translate }}</div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <p class="form-error" *ngIf="surveyForm.hasError('dmsOutside')">
    <mat-error>{{ 'SURVEY.OUTSIDE_ERROR' | translate }}</mat-error>
  </p>
</form>

<button
  mat-raised-button
  color="primary"
  [ngClass]="{ disabled: !isSurveyFormValid() }"
  [disabled]="!isSurveyFormValid()"
  (click)="saveSurvey()"
>
  {{ 'SURVEY.SAVE' | translate }}
</button>
