import { createPropertySelectors, Selector } from '@ngxs/store'
import {
  ComplexChartSurveyParams,
  SurveysComparisonState,
  SurveysComparisonStateModel,
} from './surveys-comparison.state'

export class SurveysComparisonStateSelectors {
  static slices = createPropertySelectors<SurveysComparisonStateModel>(SurveysComparisonState)

  @Selector([SurveysComparisonStateSelectors.slices.surveyParams])
  static availableParams(surveyParams: ComplexChartSurveyParams[] | null): string[][] | null {
    return surveyParams?.map(({ availableParams }) => availableParams.map((p) => p.parameter)) || null
  }
}
