<form [formGroup]="configurationForm">
  <div *ngIf="level === 'jobsite'" class="jobsite-name-container">
    <div class="title">{{ 'MAINMAP.JOBSITE_NAME' | translate }}</div>
    <mat-form-field class="full-width selected-name">
      <textarea matInput formControlName="jobsiteName" autocomplete="off"> </textarea>
    </mat-form-field>
    <mat-form-field class="full-width projid-list" *ngIf="detectedJobsiteNames && detectedJobsiteNames.length > 0">
      <mat-label>{{ 'FILE_IMPORT.DETECTED_PRO_NAME' | translate }}</mat-label>
      <mat-select formControlName="selectedJobsiteName">
        <mat-option *ngFor="let name of detectedJobsiteNames" value="{{ name }}">{{ name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="local-projection-container">
    <div class="title">{{ 'SURVEY.PROJ_REF' | translate }}</div>
    <div *ngIf="detectedProjections && detectedProjections.length > 0" class="projection-list">
      <div class="sub-title">{{ 'FILE_IMPORT.DETECTED_LOCAL_PROJECTION' | translate }}</div>
      <ul>
        <li *ngFor="let proj of detectedProjections">
          {{ proj }} : {{ AGS_Proj[proj] ? AGS_Proj[proj].name : 'unknown' }}
        </li>
      </ul>
    </div>
    <div class="projection-options">
      <div class="sub-title">{{ 'FILE_IMPORT.CUSTOM_LOCAL_PROJECTION' | translate }}</div>
      <mat-form-field class="full-width" style="margin-top: 10px">
        <mat-label>{{ 'FILE_IMPORT.KNOWN_PROJ' | translate }}</mat-label>
        <mat-select formControlName="selectedProj">
          <mat-option *ngFor="let key of keys_proj" value="{{ key }}">{{ AGS_Proj[key].name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>{{ 'GENERAL.OR' | translate }}</div>
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="EPSG"
          formControlName="customProj"
          (keyup.enter)="applyProjection()"
          autocomplete="off"
        />
        <button
          mat-raised-button
          *ngIf="configurationForm.get('customProj')?.value"
          [disabled]="configurationForm.get('customProj').invalid"
          matSuffix
          mat-icon-button
          (click)="applyProjection()"
        >
          <mat-icon>done_outline</mat-icon>
        </button>
        <mat-error *ngIf="configurationForm.get('customProj')?.dirty && configurationForm.get('customProj')?.invalid">
          {{ 'FILE_IMPORT.PROJ_FORMAT' | translate }}
        </mat-error>
      </mat-form-field>
      <div style="margin-top: 20px; color: blue" *ngIf="customProjInfoText">{{ customProjInfoText }}</div>
      <div style="margin-top: 20px; color: red" *ngIf="customProjErrorText">{{ customProjErrorText }}</div>
    </div>
  </div>
  <div class="type-container">
    <div class="title">{{ 'FILE_IMPORT.BOREHOLE_TYPE' | translate }}</div>
    <mat-form-field class="full-width type-list">
      <mat-label>{{ 'FILE_IMPORT.BOREHOLE_TYPE' | translate }}</mat-label>
      <mat-select formControlName="selectedType">
        <mat-option *ngFor="let type of boreholeTypes" value="{{ type }}">{{ getBoreholeTypeName(type) }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
