import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Location } from '@angular/common'
import Swal from 'sweetalert2'
import { CrossSectionService, MapService, SbtService } from '../shared/services'
import { JobsiteService, MessagesService, SoilSurveyService, ZoneService } from '../shared/remote-services'
import { CorrelationDataModel, DisplayedSurveys, Jobsite, LatLonLocation, Zone } from '../shared/models'
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  iif,
  noop,
  Observable,
  of,
  pairwise,
  startWith,
  Subject,
  Subscription,
  withLatestFrom,
} from 'rxjs'
import {
  adjustWGS84Extent,
  isPointInsideJobsite,
  isPolygonOutsideJobsite,
  nonNullable,
  parseExtentString,
  statusColor,
} from '../shared/utils'
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import { KeycloakService } from '../keycloak/keycloak.service'
import { TranslateService } from '@ngx-translate/core'
import { animate, style, transition, trigger } from '@angular/animations'
import VectorSource from 'ol/source/Vector'
import Map from 'ol/Map'
import Modify, { ModifyEvent } from 'ol/interaction/Modify'
import { defaults as CONTROLDEFAULTS } from 'ol/control'
import { defaults as INTERACTION_DEFAULTS } from 'ol/interaction'
import View from 'ol/View'
import {
  fromLonLat as FROMLONLAT,
  toLonLat as TOLONLAT,
  transform as PROJTRANSFORM,
  transformExtent as PROJ_TRANSFORMEXTENT,
} from 'ol/proj'
import Point from 'ol/geom/Point'
import Collection from 'ol/Collection'
import Feature, { FeatureLike } from 'ol/Feature'
import Polygon, { fromExtent as POLYGON_FROMEXTENT } from 'ol/geom/Polygon'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import { Coordinate, toStringHDMS as COORDS_TOSTRINGHDMS } from 'ol/coordinate'
import { GoogleAnalyticsService } from '../shared/google-analytics/google-analytics.service'
import { BackgroundLayer } from './layers/background-layer'
import { JobsitesLayer } from './layers/jobsites-layer'
import { SurveysLayer } from './layers/surveys-layer'
import { ZonesLayer } from './layers/zones-layer'
import { DrawInteractionLayer } from './layers/draw-interaction-layer'
import { SelectionLayer } from './layers/selection-layer'
import { CircleDrawingLayer } from './layers/circle-drawing-layer'
import { CrossSectionLayer } from './layers/cross-section-layer'
import { OverlaysComponent } from './overlays/overlays.component'
import { ActivatedRoute, Router } from '@angular/router'
import { StyleService } from './services/style.service'
import { createEmpty, extend, Extent } from 'ol/extent'
import Geometry from 'ol/geom/Geometry'
import { cloneDeep } from 'lodash'
import { allBoreholeTypes, SelectStatus, SoilSurvey, Zoom } from '@sde-ild/ssd-soillib-lib'
import RenderFeature from 'ol/render/Feature'
import { StyleLike } from 'ol/style/Style'
import { SimpleGeometry } from 'ol/geom'
import { SearchResult } from '../search-box/search-box.component'
import { isJobsite, isJobsiteHarmony } from '../shared/models/Jobsite.model.type.guard'
import { isGoogleSearchResult } from '../shared/models/Google-search.model.type.guard'
import { Select, Store } from '@ngxs/store'
import {
  AppClearResource,
  AppCloseBottomTab,
  AppIsModifyingSurvey,
  AppIsModifyingZone,
  AppLeaveJobsite,
  AppLeaveSurvey,
  AppLeaveZone,
  AppOpenBottomTab,
  AppSetSelectedJobsite,
  AppSetSelectedJobsiteCoord,
  AppSetSelectedResource,
  AppSetSelectedSurvey,
  AppSetSelectedZone,
  AppSetShowJobsiteEditPopup,
  AppSetShowNewJobsitePopup,
  AppSetShowSearchAddressPopup,
  AppSetValidModifSurvey,
  AppSetValidModifZone,
  AppSetZoneEditMode,
  AppToggleJobsiteEditPopup,
  AppUpdateSelectedJobsite,
} from '../store/app/app.actions'
import {
  MapClearSelectedLocation,
  MapClearSurveysOnMap,
  MapIsAddingJobsiteExtent,
  MapIsAddingZone,
  MapIsModifyingJobsiteCoords,
  MapIsModifyingJobsiteExtent,
  MapSetSelectedLocation,
  MapSetSurveys,
  MapSetSurveysOnMap,
  MapSetVisibleEntities,
  MapSetZones,
  MapUpdateSurvey,
  MapUpdateSurveyLocation,
  MapUpdateZoneExtent,
} from '../store/map/map.actions'
import { UserConfigSetPermission, UserConfigSetZoomAndCenter } from '../store/user-config/user-config.actions'
import {
  CorrelationDataClearCorrelationData,
  CorrelationDataFetchCorrelationData,
  CorrelationDataUpdateCorrelationData,
} from '../surveys-correlation-data/store/correlation-data.actions'
import { AppStateSelectors } from '../store/app/app.selectors'
import { MapStateSelectors } from '../store/map/map.selectors'
import { CorrelationDataStateSelectors } from '../surveys-correlation-data/store/correlation-data.selectors'
import { UserConfigStateSelectors } from '../store/user-config/user-config.selectors'
import { DataTableClearSurveyData } from '../store/data-table/data-table.actions'
import { SurveysComparisonStateSelectors } from '../surveys-comparison/store/surveys-comparison.selectors'
import {
  SurveysComparisonAddSelectedSurvey,
  SurveysComparisonSetComplexChartSurvey,
} from '../surveys-comparison/store/surveys-comparison.actions'
import { BottomTab } from '../store/app/app.state'
import { switchTap } from '../shared/utils/observables.utils'
import { FilterStateSelectors } from '../store/filter/filter.selectors'
import { FilterSetDisplayedSurveys } from '../store/filter/filter.actions'

@Component({
  selector: 'soillib-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOutFromTop', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' })),
      ]),
      transition('true => false', [style({ opacity: 0 }), animate('0.5s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class MainMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(AppStateSelectors.slices.selectedJobsite) selectedJobsite$: Observable<Jobsite | null>
  @Select(AppStateSelectors.slices.selectedSurvey) selectedSurvey$: Observable<SoilSurvey | null>
  @Select(AppStateSelectors.slices.selectedZone) selectedZone$: Observable<Zone | null>
  @Select(AppStateSelectors.slices.bottomTabOpened) bottomTabOpened$: Observable<BottomTab | null>
  @Select(AppStateSelectors.slices.showNewJobsitePopup) showNewJobsitePopup$: Observable<boolean>
  @Select(AppStateSelectors.slices.showJobsiteEditPopup) showJobsiteEditPopup$: Observable<boolean>
  @Select(AppStateSelectors.slices.showSearchAddressPopup) showSearchAddressPopup$: Observable<boolean>
  @Select(AppStateSelectors.slices.isModifyingSurvey) isModifyingSurvey$: Observable<boolean>
  @Select(AppStateSelectors.slices.isModifyingZone) isModifyingZone$: Observable<boolean>

  @Select(MapStateSelectors.slices.jobsites) jobsites$: Observable<Jobsite[] | null>
  @Select(MapStateSelectors.slices.surveys) surveys$: Observable<SoilSurvey[] | null>
  @Select(MapStateSelectors.slices.zones) zones$: Observable<Zone[] | null>
  @Select(MapStateSelectors.slices.selectedLocation) selectedLocation$: Observable<LatLonLocation | null>
  @Select(SurveysComparisonStateSelectors.slices.selectedSurveys) selectedSurveys$: Observable<SoilSurvey[] | null>
  @Select(CorrelationDataStateSelectors.slices.correlationData) correlationData$: Observable<
    CorrelationDataModel[] | null
  >

  @Select(FilterStateSelectors.slices.preventJobsiteExtentsDrawing) preventJobsiteExtentsDrawing$: Observable<boolean>
  @Select(FilterStateSelectors.slices.preventCuttingsDrawing) preventCuttingsDrawing$: Observable<boolean>
  @Select(FilterStateSelectors.slices.showMapLabels) showMapLabels$: Observable<boolean>
  @Select(FilterStateSelectors.radiusSearchInMeter) radiusSearchInMeter$: Observable<number | null>

  map: Map

  private mapMoveEnd$ = new Subject<void>()

  private backgroundLayer: BackgroundLayer

  private selectionLayer: SelectionLayer | undefined

  private circleDrawingLayer: CircleDrawingLayer

  private surveysLayer: SurveysLayer

  zonesLayer: ZonesLayer

  private jobsitesLayer: JobsitesLayer

  drawInteractionLayer: DrawInteractionLayer

  private crossSectionLayer: CrossSectionLayer

  modifyInteraction: Modify

  originalBoreholes: SoilSurvey[] = [] // TODO [joris 07/09/2023] - move to dedicated state, see CrossSectionService
  projectedBoreholes: SoilSurvey[] = [] // TODO [joris 07/09/2023] - move to dedicated state see CrossSectionService

  surveyCoordinateDms = ''

  private isUserChangeCoord = false
  private jobsiteEditOption: 'extent' | 'coordinate' | null
  private canEditMapCrossSection = true
  private surveyTableDataChanged: boolean
  private zoneTableDataChanged: boolean
  private doNotSearchInVisible = false
  private drawPinsSubject = new BehaviorSubject<boolean>(true)

  private subscription: Subscription = new Subscription()

  @ViewChild('overlays') mapOverlays: OverlaysComponent

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.handleKeyboardEvents(event)
  }

  constructor(
    private kc: KeycloakService,
    private mapService: MapService,
    private styleService: StyleService,
    private jobsiteService: JobsiteService,
    private soilSurveyService: SoilSurveyService,
    private zoneService: ZoneService,
    private messagesService: MessagesService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private crossSectionService: CrossSectionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private injector: Injector,
    private store: Store,
    private sbtService: SbtService,
  ) {
    this.route.url.subscribe((params) => {
      const jobsiteId = params?.[0]?.path
      if (jobsiteId) {
        this.doNotSearchInVisible = true
        this.loadJobsite(jobsiteId)
      }
    })
  }

  private getInitialDisplayedSurveys(): DisplayedSurveys {
    const displayedSurveys: DisplayedSurveys = {
      displaySurveys: true,
      filters: {},
    }
    const bhTypes = allBoreholeTypes()
    bhTypes.forEach((type) => {
      displayedSurveys.filters[type] = true
    })
    return displayedSurveys
  }

  ngOnInit() {
    this.store.dispatch(new FilterSetDisplayedSurveys(this.getInitialDisplayedSurveys()))
    // check permission
    const userPermission = this.kc.getUserPermission()
    if (!userPermission.canConsult) {
      Swal.fire({
        title: 'Authentication failed',
        text: 'You have no permission, please contact support for assistance',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(({ value }) => {
        if (value) {
          this.kc.logout()
        }
      })
    }
    this.store.dispatch(new UserConfigSetPermission(userPermission))

    this.subscription.add(
      this.mapMoveEnd$
        .pipe(
          debounceTime(500),
          filter(
            () =>
              !this.store.selectSnapshot(FilterStateSelectors.slices.radiusSearch).active &&
              !this.store.selectSnapshot(MapStateSelectors.slices.isAddingZone) &&
              !this.store.selectSnapshot(AppStateSelectors.slices.isModifyingZone) &&
              !this.store.selectSnapshot(AppStateSelectors.slices.isModifyingSurvey) &&
              this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode) !== 'cross-section' &&
              !this.store.selectSnapshot(AppStateSelectors.slices.showJobsiteEditPopup) &&
              !this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened) &&
              !this.doNotSearchInVisible,
          ),
          tap(() => this.searchInVisibleArea(true)),
          withLatestFrom(this.selectedJobsite$),
          filter(([_, selectedJobsite]) => !selectedJobsite),
          tap(() => this.dispatchCurrentMapState()),
        )
        .subscribe(),
    )
    this.subscription.add(
      this.selectedSurvey$
        .pipe(
          startWith(null),
          pairwise<SoilSurvey | null>(),
          withLatestFrom(this.bottomTabOpened$, this.isModifyingSurvey$),
        )
        .subscribe(([[previousSelectedSurvey, currentSelectedSurvey], bottomTabOpened, isModifyingSurvey]) => {
          const surveysToToggle = [previousSelectedSurvey, currentSelectedSurvey].filter(nonNullable)
          if (surveysToToggle.length > 0) {
            this.surveysLayer.toggleSurveyStyle(surveysToToggle)
          }
          if (bottomTabOpened === 'borehole-edition' && isModifyingSurvey) {
            this.removeModifyInteraction()
            this.store.dispatch(new AppIsModifyingSurvey(false))
          }
        }),
    )

    this.subscription.add(
      this.selectedZone$
        .pipe(withLatestFrom(this.bottomTabOpened$, this.isModifyingZone$))
        .subscribe(([zone, bottomTabOpened, isModifyingZone]) => {
          if (zone?.id || !zone) {
            this.store.dispatch(new MapIsAddingZone(false))
          }
          if (bottomTabOpened === 'soil-cutting-edition' && isModifyingZone) {
            this.removeModifyInteraction()
            this.drawInteractionLayer.addDrawInteraction()
            this.store.dispatch(new AppIsModifyingZone(false))
          }
          this.drawZones()
        }),
    )

    this.subscription.add(
      this.selectedSurveys$
        .pipe(startWith(null), pairwise<SoilSurvey[] | null>())
        .subscribe(([previousSelectedSurveys, currentSelectedSurveys]) => {
          if (previousSelectedSurveys && previousSelectedSurveys.length > 0) {
            this.surveysLayer.setSurveyStyle(previousSelectedSurveys, SelectStatus.UNSELECTED)
          }
          if (currentSelectedSurveys && currentSelectedSurveys.length > 0) {
            this.surveysLayer.setSurveyStyle(currentSelectedSurveys, SelectStatus.SELECTED)
          }
        }),
    )

    this.subscription.add(
      this.selectedJobsite$.subscribe((selectedJobsite) => {
        if (selectedJobsite?.id) {
          const url = this.router.createUrlTree(['../' + selectedJobsite.id], { relativeTo: this.route }).toString()
          this.location.go(url)
          this.googleAnalyticsService.event('open_jobsite', {
            jobsite_id: selectedJobsite.id,
            jobsite_name: selectedJobsite.nickname,
          })
        } else {
          const url = this.router.createUrlTree(['../'], { relativeTo: this.route }).toString()
          this.location.go(url)
        }
      }),
    )

    this.subscription.add(
      this.crossSectionService.canCrossJobsite$.subscribe(() => {
        this.initCrossSectionSourceOnMap()
      }),
    )

    this.subscription.add(
      this.drawPinsSubject
        .asObservable()
        .pipe(
          debounceTime(1000),
          map((redraw) => ({ polygon: this.getVisibleMapPolygon(), redraw })),
          switchMap(({ polygon, redraw }) => this.searchInVisibleArea$(redraw, polygon)),
        )
        .subscribe(),
    )

    this.subscription.add(
      this.bottomTabOpened$
        .pipe(
          pairwise<BottomTab | null>(),
          map(([prevBottomTab, curBottomTab]) => ({
            bottomTabOpened: curBottomTab,
            bottomTabClosed: prevBottomTab !== null && curBottomTab !== prevBottomTab ? prevBottomTab : null,
          })),
          withLatestFrom(this.store.select(AppStateSelectors.slices.zoneEditMode)),
        )
        .subscribe(([{ bottomTabClosed, bottomTabOpened }, zoneEditMode]) => {
          switch (bottomTabOpened) {
            case 'borehole-edition':
              this.googleAnalyticsService.event('open_edit_borehole', {})
              break

            case 'soil-cutting-edition':
              this.googleAnalyticsService.event('open_edit_section', {})
              if (zoneEditMode === 'addzone') {
                this.drawInteractionLayer.addDrawInteraction()
              } else {
                this.removeMapInteraction()
                this.initZoneEdit()
              }
              this.markSurveyLocation(null)
              break

            case 'borehole-comparison':
              this.googleAnalyticsService.event('open_compare_boreholes', {})
              break
          }
          if (bottomTabOpened) {
            this.closeJobsiteEdit()
          }
          this.showMapLabels(bottomTabOpened !== null)

          switch (bottomTabClosed) {
            case 'borehole-edition':
              this.removeMapInteraction()
              this.markSurveyLocation(null)
              this.searchInVisibleArea()
              this.googleAnalyticsService.event('close_edit_borehole', {})
              break

            case 'soil-cutting-edition':
              this.removeMapInteraction()
              this.markSurveyLocation(null)
              this.initZoneEdit()
              this.googleAnalyticsService.event('close_edit_section', {})
              break

            case 'borehole-comparison':
              this.googleAnalyticsService.event('close_compare_boreholes', {})
              break
          }
        }),
    )

    this.subscription.add(this.zones$.subscribe(() => this.drawZones()))
    this.subscription.add(this.jobsites$.subscribe(() => this.drawJobsiteExtents()))
    this.subscription.add(this.surveys$.subscribe(() => this.drawSurveys(this.drawPinsSubject.getValue())))
    this.subscription.add(
      combineLatest([this.radiusSearchInMeter$, this.selectedLocation$]).subscribe(
        ([radiusSearchInMeter, selectedLocation]) => this.searchSurveysOrZones(radiusSearchInMeter, selectedLocation),
      ),
    )
    this.subscription.add(this.preventJobsiteExtentsDrawing$.subscribe(() => this.drawJobsiteExtents()))
    this.subscription.add(this.preventCuttingsDrawing$.subscribe(() => this.drawZones()))
    this.subscription.add(this.showMapLabels$.subscribe((showMapLabels) => this.showMapLabels(showMapLabels)))
  }

  private loadJobsite(jobsiteId: string) {
    if (jobsiteId) {
      this.jobsiteService.getJobsiteById(jobsiteId).subscribe((jobsite: Jobsite) => {
        this.gotoJobsite(jobsite)
      })
    }
  }

  ngAfterViewInit() {
    const { zoom, center } = this.store.selectSnapshot(UserConfigStateSelectors.zoomAndCenter)
    this.map = new Map({
      controls: CONTROLDEFAULTS({
        rotate: false,
        attribution: false,
        zoom: false,
      }),
      interactions: INTERACTION_DEFAULTS({ doubleClickZoom: false }),
      target: 'map',
      view: new View({
        center,
        extent: PROJ_TRANSFORMEXTENT([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857'),
        zoom,
        minZoom: Zoom.MIN,
      }),
    })
    // FOR NG0100: Expression has changed after it was checked
    setTimeout(() => {
      this.backgroundLayer = new BackgroundLayer(this.map)
      this.selectionLayer = new SelectionLayer(this.map, this.injector)
      this.jobsitesLayer = new JobsitesLayer(this.map, this.injector)
      this.zonesLayer = new ZonesLayer(this.map, this.injector)
      this.surveysLayer = new SurveysLayer(this.map, this.injector)
      this.circleDrawingLayer = new CircleDrawingLayer(this.map)
      this.crossSectionLayer = new CrossSectionLayer(this.map, this.injector)

      this.drawInteractionLayer = new DrawInteractionLayer(this.map, this.injector)
      this.drawInteractionLayer.initializeDrawInteraction((coordinates: number[][]) => {
        this.saveJobsiteExtent(coordinates)
      })

      this.mapOverlays.initOverlays(this.map)
      this.initializeSelection()
      this.handleZoomAndMoves()
    }, 0)
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  public clickJobsiteOnMap(jobsite: Jobsite) {
    const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
    if (selectedJobsiteId && selectedJobsiteId !== jobsite.id) {
      Swal.fire({
        title: this.translateService.instant('ALERT.QUIT_JOBSITE'),
        text: this.translateService.instant('ALERT.GOTO_JOBSITE') + jobsite.nickname,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
      }).then(({ value }) => {
        if (value) {
          this.gotoJobsite(jobsite)
        }
      })
    } else {
      this.gotoJobsite(jobsite)
    }
  }

  public gotoJobsite(jobsite: Jobsite) {
    if (jobsite.lat_coord != null && jobsite.lon_coord != null) {
      this.initJobsite()
      this.removeMapInteraction()
      const fitPolygon = jobsite.extent ? parseExtentString(jobsite.extent) : null
      this.store.dispatch(new AppSetSelectedJobsite(jobsite))
      this.doNotSearchInVisible = true
      this.selectLocation(
        {
          latitude: Number(jobsite.lat_coord),
          longitude: Number(jobsite.lon_coord),
        },
        fitPolygon,
        false,
      )
      this.searchInVisibleArea(true)
      if (!jobsite.extent) {
        return this.fireNoExtent()
      }
    } else {
      Swal.fire({
        title: 'The jobsite is not valid !',
        text: '',
        icon: 'error',
        confirmButtonText: 'Ok',
      }).then(noop)
    }
  }

  handleSearchSelection(selectedSearch: SearchResult) {
    this.initJobsite()
    this.removeMapInteraction()
    this.store.dispatch(AppLeaveJobsite)
    let fitPolygon: Coordinate[] | null = null
    this.doNotSearchInVisible = true
    const jobsite = isJobsite(selectedSearch)
    if (jobsite && selectedSearch.extent) {
      fitPolygon = parseExtentString(selectedSearch.extent)
    }
    if (jobsite && selectedSearch.nickname) {
      if (selectedSearch.lat_coord && selectedSearch.lon_coord) {
        this.store.dispatch(
          new AppSetSelectedJobsite({
            id: selectedSearch.id,
            lat_coord: selectedSearch.lat_coord,
            lon_coord: selectedSearch.lon_coord,
            extent: selectedSearch.extent,
            nickname: selectedSearch.nickname,
          }),
        )
      }
      if (selectedSearch.lat_coord != null) {
        this.selectLocation(
          {
            latitude: Number(selectedSearch.lat_coord),
            longitude: Number(selectedSearch.lon_coord),
          },
          fitPolygon,
        )
      } else {
        this.store.dispatch(new AppSetShowSearchAddressPopup(true))
      }
    } else if (isJobsiteHarmony(selectedSearch) && selectedSearch.jobname) {
      if (this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
        this.jobsiteService
          .createJobsite({
            harmony_id: selectedSearch.id,
            nickname: selectedSearch.jobname,
          })
          .pipe(
            tap((id) => this.handleSearchSelection({ nickname: selectedSearch.jobname, id })),
            switchMap((id) => this.messagesService.saveJobsiteMessage$(id, selectedSearch.jobname, 'imported the')),
          )
          .subscribe()
      } else {
        return Swal.fire({
          title: this.translateService.instant('ALERT.FORBIDDEN'),
          text: this.translateService.instant('ALERT.NO_PERMISSION'),
          icon: 'warning',
          showConfirmButton: false,
          timer: 1000,
        })
      }
    } else if (isGoogleSearchResult(selectedSearch) && selectedSearch.formatted_address) {
      const lat = selectedSearch.geometry?.location.lat
      const lon = selectedSearch.geometry?.location.lng
      const location: LatLonLocation | null =
        lat !== undefined && lon !== undefined
          ? {
              latitude: Number(lat),
              longitude: Number(lon),
            }
          : null
      if (location) {
        this.selectLocation(location, fitPolygon)
        if (this.store.selectSnapshot(UserConfigStateSelectors.canCreate)) {
          this.store.dispatch([new AppSetShowNewJobsitePopup(true), new AppSetSelectedJobsiteCoord(location)])
        }
      }
      // create a role in keycloak to allow certain users create a jobsite in our own dataBase
      // show a draggable popup to enter the jobsite name
      // add a column to indicate the type of jobsite: created by users or imported from harmony
    }
    // else {
    //   const parsedCoordinates = selectedSearch.search.match(/\d+\.\d+/g);
    //   if (parsedCoordinates) {
    //     const location: LatLonLocation = {
    //       latitude: Number(parsedCoordinates[0]),
    //       longitude: Number(parsedCoordinates[1]),
    //     };
    //     this.selectLocation(location, fitPolygon);
    //   }
    // }
    this.searchInVisibleArea(true)
  }

  handleSelectedAddressFromPopup(location: LatLonLocation) {
    const selectedJobsiteCoord = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsiteCoord)
    if (
      location &&
      (!selectedJobsiteCoord ||
        selectedJobsiteCoord.latitude !== location.latitude ||
        selectedJobsiteCoord.longitude !== location.longitude)
    ) {
      this.selectLocation(location, null)
    }
  }

  saveAddress(location: LatLonLocation) {
    if (location) {
      if (this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
        this.saveJobsiteCoordinates(location)
      } else {
        return Swal.fire({
          title: this.translateService.instant('ALERT.FORBIDDEN'),
          text: this.translateService.instant('ALERT.NO_PERMISSION'),
          icon: 'warning',
          showConfirmButton: false,
          timer: 1000,
        })
      }
      this.store.dispatch([new AppSetShowSearchAddressPopup(false), new AppSetSelectedJobsiteCoord(null)])
    }
  }

  createAJobsite() {
    this.store.dispatch(new AppSetShowNewJobsitePopup(true))
    const viewCenter = this.map.getView().getCenter()
    const center = viewCenter ? TOLONLAT(viewCenter) : null
    if (center) {
      this.store.dispatch(
        new AppSetSelectedJobsiteCoord({
          longitude: center[0],
          latitude: center[1],
        }),
      )
    }
  }

  createNewJobsite(jobsite: { name: string; coord: LatLonLocation }) {
    const newJobsite: Jobsite = {
      nickname: jobsite.name,
      lon_coord: jobsite.coord.longitude,
      lat_coord: jobsite.coord.latitude,
      extent: undefined,
    }
    this.jobsiteService
      .createJobsite(newJobsite)
      .pipe(
        map((id) => ({ ...newJobsite, id } as Jobsite)),
        tap((createdJobsite: Jobsite) => {
          this.store.dispatch([new AppSetSelectedJobsite(createdJobsite), new AppSetShowNewJobsitePopup(false)])
        }),
        switchMap((createdJobsite) =>
          this.messagesService.saveJobsiteMessage$(createdJobsite.id, createdJobsite.nickname, 'created a new jobsite'),
        ),
        switchMap(() => from(this.fireNoExtent())),
      )
      .subscribe()
  }

  closeSaveAddressPopup(close: boolean) {
    if (close) {
      this.store.dispatch([new AppSetShowSearchAddressPopup(false), new AppSetSelectedJobsiteCoord(null)])
      this.initJobsite()
    }
  }

  closeNewJobsitePopup() {
    this.store.dispatch([new AppSetShowNewJobsitePopup(false), new AppSetSelectedJobsiteCoord(null)])
    this.initJobsite()
  }

  saveJobsiteExtent(extent: number[][]) {
    const jobsite = cloneDeep(this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite))
    const polygon = extent.map(([first, second]) => `${first} ${second}`).reduce((acc, curr) => `${acc}, ${curr}`)
    if (jobsite && jobsite.id) {
      jobsite.extent = `POLYGON((${polygon}))`
      this.store
        .dispatch(new AppUpdateSelectedJobsite(jobsite, 'defined the extent of the'))
        .pipe(
          tap(() => {
            this.drawInteractionLayer.clearDrawingLayer()
            this.removeMapInteraction()
            this.markSurveyLocation(null)
          }),
          switchMap(() =>
            from(
              Swal.fire({
                title: this.translateService.instant('ALERT.EXTENT_SAVED'),
                text: this.translateService.instant('ALERT.EXTENT_COOR_SAVED'),
                icon: 'success',
                confirmButtonText: 'Ok',
              }),
            ),
          ),
          tap(() => this.gotoJobsite(jobsite)),
        )
        .subscribe()
    }
  }

  private saveJobsiteCoordinates(location: LatLonLocation) {
    const jobsite = cloneDeep(this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite))
    if (jobsite && jobsite.id) {
      jobsite.lon_coord = location.longitude
      jobsite.lat_coord = location.latitude
      this.store
        .dispatch(new AppUpdateSelectedJobsite(jobsite, 'defined the coordinates of the'))
        .pipe(
          switchMap(() =>
            from(
              Swal.fire({
                title: this.translateService.instant('ALERT.COOR_SAVE'),
                text: this.translateService.instant('ALERT.COOR_SAVE_TEXT'),
                icon: 'success',
                confirmButtonText: 'Ok',
              }),
            ),
          ),
          switchMap(() => from(this.fireNoExtent())),
        )
        .subscribe()
    }
  }

  private drawJobsiteExtents() {
    this.jobsitesLayer?.drawJobsiteExtents()
  }

  private drawZones() {
    this.zonesLayer?.drawZones()
  }

  private searchSurveysOrZones(radiusInMeter: number | null, selectedLocation: LatLonLocation | null) {
    if (radiusInMeter !== null && selectedLocation) {
      this.circleDrawingLayer?.setVisible(true)
      this.circleDrawingLayer?.drawCircle(radiusInMeter, selectedLocation)
      const { longitude, latitude } = selectedLocation
      this.soilSurveyService.searchSoilSurveys(latitude, longitude, radiusInMeter).subscribe((surveys) => {
        this.store.dispatch(new MapSetSurveys(surveys))
        this.drawSurveys()
      })
      this.zoneService.searchZones(latitude, longitude, radiusInMeter).subscribe((zones) => {
        this.store.dispatch(new MapSetZones(zones))
        this.drawZones()
      })
    } else {
      this.circleDrawingLayer?.setVisible(false)
      this.searchInVisibleArea(true)
    }
  }

  private consultSurvey(clickedSurvey: SoilSurvey) {
    if (this.surveyTableDataChanged) {
      Swal.fire({
        title: 'You have made changes in data table',
        text: 'Do you want to quit without saving changes ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Stay to save',
        confirmButtonText: 'Quit without saving',
      }).then(({ value }) => {
        if (value) {
          this.goToSurvey(clickedSurvey)
        }
      })
    } else {
      this.goToSurvey(clickedSurvey)
    }
  }

  private resetSelectedSurvey(e: MapBrowserEvent<UIEvent & { altKey: boolean; ctrlKey: boolean }>) {
    const features = this.map.getFeaturesAtPixel(e.pixel)
    let hasSurveyFeatures = false
    if (features) {
      for (const feature of features) {
        if (feature.get('surveyId')) {
          hasSurveyFeatures = true
          break
        }
      }
    }
    if (this.surveyTableDataChanged && !hasSurveyFeatures) {
      Swal.fire({
        title: 'You have made changes in data table',
        text: 'Do you want to quit without saving changes ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Stay to save',
        confirmButtonText: 'Quit without saving',
      }).then(({ value }) => {
        if (value) {
          this.leaveSurvey()
        }
      })
    } else if (!this.surveyTableDataChanged) {
      this.leaveSurvey()
    }
  }

  private consultZone(clickedZone: Zone) {
    if (this.zoneTableDataChanged) {
      Swal.fire({
        title: 'You have made changes in data table',
        text: 'Do you want to quit without saving changes ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Stay to save',
        confirmButtonText: 'Quit without saving',
      }).then(({ value }) => {
        if (value) {
          this.store.dispatch([
            new AppSetSelectedZone(clickedZone),
            new AppSetSelectedResource({
              type: 'section',
              id: clickedZone.id,
            }),
          ])
        }
      })
    } else {
      this.store.dispatch([
        new AppSetSelectedZone(clickedZone),
        new AppSetSelectedResource({
          type: 'section',
          id: clickedZone.id,
        }),
      ])
    }
  }

  private resetSelectedZone(e: MapBrowserEvent<UIEvent & { altKey: boolean; ctrlKey: boolean }>) {
    const features = this.map.getFeaturesAtPixel(e.pixel)
    let hasSurveyFeatures = false
    for (const feature of features) {
      if (feature.get('zoneId') !== undefined) {
        hasSurveyFeatures = true
        break
      }
    }
    if (this.zoneTableDataChanged && !hasSurveyFeatures) {
      Swal.fire({
        title: 'You have made changes in data table',
        text: 'Do you want to quit without saving changes ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Stay to save',
        confirmButtonText: 'Quit without saving',
      }).then(({ value }) => {
        if (value) {
          this.store.dispatch([AppLeaveZone, AppClearResource])
        }
      })
    } else if (!this.zoneTableDataChanged) {
      this.store.dispatch([AppLeaveZone, AppClearResource])
    }
  }

  private initializeSelection() {
    this.map.on('dblclick', (e: MapBrowserEvent<UIEvent & { altKey: boolean; ctrlKey: boolean }>) => {
      if (e.originalEvent.altKey || e.originalEvent.ctrlKey) {
        return
      }
      e.map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
        const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)

        const features = this.getFeatures(feature)
        const surveyFeature = features?.find((f) => f.get('surveyId'))
        if (!bottomTabOpened && surveyFeature) {
          const surveysEntities = this.store.selectSnapshot(MapStateSelectors.slices.surveysEntities)
          const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
          const featureId = surveyFeature.getId()
          const clickedSurvey = featureId && surveysEntities ? surveysEntities[featureId] : null
          if (clickedSurvey?.jobsite_id === selectedJobsiteId) {
            this.store.dispatch(new AppOpenBottomTab('borehole-edition'))
            if (clickedSurvey) {
              this.goToSurvey(clickedSurvey)
            } else {
              this.leaveSurvey()
            }
          }
        }
        if (features?.length > 1 && layer.get('name') === 'JOBSITE-CENTER') {
          const extent = createEmpty()
          features.forEach((f: FeatureLike) => {
            const featureExtent = f.getGeometry()?.getExtent()
            if (featureExtent) {
              extend(extent, featureExtent)
            }
          })
          e.map.getView().fit(extent, { size: e.map.getSize(), padding: [200, 150, 200, 150] })
          return
        }
      })
    })
    this.map.on('singleclick', (e: MapBrowserEvent<UIEvent & { altKey: boolean; ctrlKey: boolean }>) => {
      if (e.originalEvent.altKey || e.originalEvent.ctrlKey) {
        return
      }
      const zoneEditMode = this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode)
      const showNewJobsitePopup = this.store.selectSnapshot(AppStateSelectors.slices.showNewJobsitePopup)
      const showSearchAddressPopup = this.store.selectSnapshot(AppStateSelectors.slices.showSearchAddressPopup)
      const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
      const selectedJobsiteId = selectedJobsite?.id
      const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
      const isAddingZone = this.store.selectSnapshot(MapStateSelectors.slices.isAddingZone)
      const surveysEntities = this.store.selectSnapshot(MapStateSelectors.slices.surveysEntities)
      const jobsites = this.store.selectSnapshot(MapStateSelectors.slices.jobsites)
      const zones = this.store.selectSnapshot(MapStateSelectors.slices.zones)

      if (!isAddingZone && zoneEditMode !== 'cross-section') {
        const lonLat = TOLONLAT(e.coordinate)
        const location: LatLonLocation = {
          longitude: lonLat[0],
          latitude: lonLat[1],
        }
        if (bottomTabOpened === 'borehole-edition') {
          this.resetSelectedSurvey(e)
        }
        if (bottomTabOpened === 'soil-cutting-edition') {
          this.resetSelectedZone(e)
        }
        e.map.forEachFeatureAtPixel(e.pixel, (feature) => {
          const features = this.getFeatures(feature)
          if (features?.length === 1) {
            feature = features[0]
          }
          const featureId = feature.getId()
          if (bottomTabOpened === 'borehole-edition' && feature.get('surveyId')) {
            const clickedSurvey = featureId && surveysEntities ? surveysEntities[featureId] : null
            if (clickedSurvey && clickedSurvey.jobsite_id === selectedJobsiteId) {
              this.consultSurvey(clickedSurvey)
            }
          } else if (
            bottomTabOpened === 'soil-cutting-edition' &&
            zoneEditMode === 'addzone' &&
            feature.get('zoneId') !== undefined
          ) {
            const clickedZone = zones ? zones[feature.get('zoneId')] : null
            if (clickedZone && clickedZone.jobsite_id === selectedJobsiteId) {
              this.consultZone(clickedZone)
            }
          } else if (
            this.store.selectSnapshot(SurveysComparisonStateSelectors.slices.complexChartsOpened) &&
            feature.get('surveyId')
          ) {
            const clickedSurvey = featureId && surveysEntities ? surveysEntities[featureId] : null
            if (clickedSurvey && clickedSurvey.jobsite_id === selectedJobsiteId) {
              this.store.dispatch(new SurveysComparisonSetComplexChartSurvey(clickedSurvey))
            }
          } else if (bottomTabOpened === 'borehole-comparison' && feature.get('surveyId')) {
            const clickedSurvey = featureId && surveysEntities ? surveysEntities[featureId] : null
            if (clickedSurvey && clickedSurvey.jobsite_id === selectedJobsiteId) {
              this.store.dispatch(new SurveysComparisonAddSelectedSurvey(clickedSurvey))
            }
          }
          if (feature.get('centerId') && !bottomTabOpened) {
            const jobsite = jobsites?.find(({ id }) => id === featureId)
            if (jobsite && jobsite.id !== selectedJobsiteId) {
              this.clickJobsiteOnMap(jobsite)
            }
          }
        })
        if (bottomTabOpened === 'borehole-edition') {
          if (!isPointInsideJobsite(e.coordinate, selectedJobsite?.extent)) {
            return Swal.fire({
              title: this.translateService.instant('ALERT.FORBIDDEN'),
              text: this.translateService.instant('ALERT.SURVEY_INSIDE_SITE'),
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            })
          } else {
            this.selectLocation(location, null)
          }
        }

        if ((showSearchAddressPopup || showNewJobsitePopup) && !bottomTabOpened) {
          this.isUserChangeCoord = true
          this.selectLocation(location, null)
          this.store.dispatch(new AppSetSelectedJobsiteCoord(location))
        }
      }
      if (
        bottomTabOpened === 'soil-cutting-edition' &&
        zoneEditMode === 'cross-section' &&
        this.canEditMapCrossSection
      ) {
        e.map.forEachFeatureAtPixel(e.pixel, (feature) => {
          const features = this.getFeatures(feature)
          if (features?.length === 1) {
            feature = features[0]
          }
          feature = feature as Feature<Geometry>
          if (feature.get('surveyId')) {
            const surveysEntities = this.store.selectSnapshot(MapStateSelectors.slices.surveysEntities)
            const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
            const featureId = feature.getId()
            const survey = featureId && surveysEntities ? surveysEntities[featureId] : null
            if (this.crossSectionService.canCrossJobsite || survey?.jobsite_id === selectedJobsiteId) {
              if (feature.get('originalBorehole') === true) {
                feature.set('originalBorehole', false)
                this.originalBoreholes = this.originalBoreholes.filter((item) => item.id !== survey?.id)
                if (survey?.type) {
                  const oristyle = this.styleService.getStyle(
                    survey.type,
                    statusColor(feature.get('selectStatus')),
                    'black',
                    1,
                  )
                  feature.setStyle(oristyle)
                }
                this.crossSectionLayer.crossSectionSource.clear()
                this.surveysLayer.resetFeatureStyle(this.projectedBoreholes)
                this.projectedBoreholes = []
              } else if (this.originalBoreholes.length < 2) {
                feature.set('originalBorehole', true)
                if (survey) {
                  this.originalBoreholes.push(survey)
                  if (survey.type) {
                    const obStyle = this.styleService.getOriginalBoreholeStyle(survey.type)
                    feature.setStyle(obStyle)
                  }
                }
                if (this.originalBoreholes.length === 2) {
                  this.crossSectionService.showSelectBox(
                    this.originalBoreholes,
                    this.crossSectionLayer.crossSectionSource,
                  )
                  this.markProjectedBoreholes()
                }
              }
              if (feature.get('projectedBorehole') === true) {
                feature.set('projectedBorehole', false)
                this.projectedBoreholes = this.projectedBoreholes.filter((item) => item.id !== survey?.id)
                if (survey?.type) {
                  const projstyle = this.styleService.getStyle(
                    survey.type,
                    statusColor(feature.get('selectStatus')),
                    'black',
                    1,
                  )
                  feature.setStyle(projstyle)
                }
              }
            }
          }
        })
      }
    })
  }

  private showMapLabels(show: boolean) {
    this.backgroundLayer?.showMapLabels(show)
  }

  setMoveSurveyMode(active: boolean) {
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
    const survey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)
    if (this.map && survey && bottomTabOpened === 'borehole-edition') {
      this.surveyCoordinateDms = ''
      this.store.dispatch([new AppSetValidModifSurvey(false), new AppIsModifyingSurvey(active)])
      if (active) {
        this.addModifyInteraction()
      } else {
        this.removeModifyInteraction()
        this.surveysLayer.setSurveyStyle([survey], SelectStatus.SELECTED)
        this.surveysLayer.setSurveyPostion(survey)
      }
    }
  }

  setZoneModifyMode({ active }: { active: boolean }) {
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
    const zoneEditMode = this.store.selectSnapshot(AppStateSelectors.slices.zoneEditMode)
    if (this.map && bottomTabOpened === 'soil-cutting-edition' && zoneEditMode === 'addzone') {
      this.store.dispatch([new AppSetValidModifZone(false), new AppIsModifyingZone(active)])
      if (active) {
        this.drawInteractionLayer.removeDrawInteraction()
        this.addModifyInteraction()
      } else {
        this.removeModifyInteraction()
        this.drawInteractionLayer.addDrawInteraction()
        this.drawZones()
      }
    }
  }

  updateJobsiteExtentCoord(jobsiteEditOption: 'extent' | 'coordinate' | null | undefined) {
    const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
    if (jobsiteEditOption === 'extent' && selectedJobsiteId) {
      const coordinates = this.getCoordinates(selectedJobsiteId)
      if (coordinates) {
        const coord = coordinates.map((item: Coordinate) => {
          return PROJTRANSFORM(item, 'EPSG:3857', 'EPSG:4326')
        })
        this.updateJobsiteExtent(coord)
      }
    } else if (jobsiteEditOption === 'coordinate' && selectedJobsiteId) {
      const newFeature = this.jobsitesLayer.jobsiteCenterSource.getFeatureById(selectedJobsiteId)
      const geometry = newFeature ? (newFeature?.getGeometry() as SimpleGeometry) : null
      const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
      const jobsiteExtent = selectedJobsite?.extent
      const coordinates = geometry?.getCoordinates()
      if (coordinates && isPointInsideJobsite(coordinates, jobsiteExtent)) {
        const coordinate = TOLONLAT(coordinates)
        const coord: LatLonLocation = {
          longitude: coordinate[0],
          latitude: coordinate[1],
        }
        this.updateJobsiteCoordinate(coord)
      } else {
        this.cancelJobsiteEdit()
      }
    }
    this.resetJobsiteEdit()
  }

  private updateJobsiteCoordinate(coordinate: LatLonLocation) {
    const updatedJobsite = cloneDeep(this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite))
    if (updatedJobsite && updatedJobsite.id) {
      updatedJobsite.lon_coord = coordinate.longitude
      updatedJobsite.lat_coord = coordinate.latitude
      this.store
        .dispatch(new AppUpdateSelectedJobsite(updatedJobsite, 'modified the coordinate of the'))
        .subscribe(() => {
          this.searchInVisibleArea()
          this.removeMapInteraction()
          return Swal.fire({
            title: 'Jobsite address',
            text: 'Jobsite coordinate has been updated',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          })
        })
    }
  }

  private updateJobsiteExtent(extent: number[][]) {
    const updatedJobsite = cloneDeep(this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite))
    if (updatedJobsite && updatedJobsite.id) {
      const polygon = extent.map(([first, second]) => `${first} ${second}`).reduce((acc, curr) => `${acc}, ${curr}`)
      updatedJobsite.extent = `POLYGON((${polygon}))`
      this.store.dispatch(new AppUpdateSelectedJobsite(updatedJobsite, 'modified the extent of the')).subscribe(() => {
        this.searchInVisibleArea()
        this.removeMapInteraction()
        return Swal.fire({
          title: this.translateService.instant('ALERT.EXTENT_SAVED'),
          text: this.translateService.instant('ALERT.EXTENT_COOR_SAVED'),
          icon: 'success',
          showConfirmButton: false,
          timer: 1000,
        })
      })
    }
  }

  closeJobsiteEdit() {
    this.cancelJobsiteEdit()
    this.store.dispatch(new AppSetShowJobsiteEditPopup(false))
  }

  toggleJobsiteEditPopup(jobsite: Jobsite | null) {
    this.fitToVisibleMap(jobsite)
    this.store.dispatch(AppToggleJobsiteEditPopup)
    this.cancelJobsiteEdit()
  }

  handleJobsiteEditOptionChange(value: 'extent' | 'coordinate' | null | undefined) {
    if (this.jobsiteEditOption) {
      this.cancelJobsiteEdit()
    }
    this.jobsiteEditOption = value || null
    this.addModifyInteraction()
  }

  saveMovedSurvey(update: boolean) {
    if (update) {
      const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
      const selectedSurvey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)

      const surveyId = selectedSurvey?.id
      const selectedFeature = surveyId ? this.surveysLayer.surveysSource.getFeatureById(surveyId) : null
      const points = selectedFeature ? (selectedFeature.getGeometry() as Point) : null
      const coordinates = points?.getCoordinates()

      const jobsiteExtent = selectedJobsite?.extent
      const isInsideJobsite = coordinates && isPointInsideJobsite(coordinates, jobsiteExtent)
      if (isInsideJobsite) {
        const coordinate = TOLONLAT(coordinates)
        const newSurvey = {
          ...selectedSurvey,
          lon_coord: coordinate[0],
          lat_coord: coordinate[1],
        }
        delete newSurvey.id
        const jobsiteId = selectedSurvey?.jobsite_id
        if (jobsiteId && surveyId) {
          this.soilSurveyService
            .updateSoilSurvey(jobsiteId, surveyId, newSurvey)
            .pipe(
              switchMap(() =>
                this.messagesService.saveResourceAndJobsiteMessage$(
                  jobsiteId,
                  selectedSurvey?.name,
                  'borehole',
                  'modified coordinate of the',
                ),
              ),
            )
            .subscribe(() => {
              return Swal.fire({
                title: this.translateService.instant('ALERT.COOR_SAVE'),
                text: 'Coordinate of survey saved !',
                icon: 'success',
                confirmButtonText: 'Ok',
              }).then(() => {
                this.store.dispatch([
                  new MapUpdateSurveyLocation({
                    surveyId: selectedSurvey?.id || undefined,
                    location: coordinate,
                  }),
                  new AppSetValidModifSurvey(false),
                ])
                const movedSurvey = this.store
                  .selectSnapshot(MapStateSelectors.slices.surveys)
                  ?.find((survey) => survey.id === selectedSurvey?.id)
                if (movedSurvey) {
                  this.goToSurvey(movedSurvey)
                }
              })
            })
        }
      } else {
        return Swal.fire({
          title: this.translateService.instant('ALERT.FORBIDDEN'),
          text: this.translateService.instant('ALERT.SURVEY_INSIDE_SITE'),
          icon: 'error',
          showConfirmButton: false,
          timer: 1000,
        })
      }
    }
  }

  saveModifiedPolygon(selectedZone: Zone) {
    const selectedZoneId = selectedZone?.id
    const selectedFeature = selectedZoneId ? this.zonesLayer.zonesSource.getFeatureById(selectedZoneId) : null
    const geometry = selectedFeature ? (selectedFeature.getGeometry() as SimpleGeometry) : null
    const coordinates = geometry?.getCoordinates()
    const longitude = coordinates?.length ? coordinates[0] : null
    const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    const jobsiteExtent = selectedJobsite?.extent
    const isInsideJobsite = !isPolygonOutsideJobsite(longitude, jobsiteExtent)
    if (selectedZoneId && isInsideJobsite && selectedZone?.jobsite_id) {
      const coords = longitude.map((coord) => {
        return PROJTRANSFORM(coord, 'EPSG:3857', 'EPSG:4326')
      })
      const polygon = coords.map(([first, second]) => `${first} ${second}`).reduce((acc, curr) => `${acc}, ${curr}`)
      const newZone = {
        ...selectedZone,
        extent: `POLYGON((${polygon}))`,
      }
      this.zoneService
        .updateZone(selectedZoneId, newZone)
        .pipe(
          switchMap(() =>
            this.messagesService.saveResourceAndJobsiteMessage$(
              selectedJobsite?.id,
              selectedZone?.name,
              'section',
              'modify polygon of the',
            ),
          ),
        )
        .subscribe(() => {
          return Swal.fire({
            title: this.translateService.instant('ALERT.COOR_SAVE'),
            text: 'Coordinates of zone polygon saved !',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.store.dispatch([
              new MapUpdateZoneExtent({
                zoneId: selectedZoneId,
                extent: newZone.extent,
              }),
              new AppIsModifyingZone(false),
              new AppSetValidModifZone(false),
            ])
            this.removeModifyInteraction()
            this.drawInteractionLayer.addDrawInteraction()
            this.drawZones()
          })
        })
    } else {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.ZONE_INSIDE_SITE'), // To modify
        icon: 'error',
        showConfirmButton: false,
        timer: 1000,
      })
    }
  }

  private addModifyInteraction(): void {
    const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    const selectedSurvey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)
    const selectedZone = this.store.selectSnapshot(AppStateSelectors.slices.selectedZone)
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
    const showJobsiteEditPopup = this.store.selectSnapshot(AppStateSelectors.slices.showJobsiteEditPopup)

    let modifyStyle: StyleLike | undefined
    let selectedItemId: string | null | undefined
    let vectorSource: VectorSource | undefined
    if (selectedZone && selectedSurvey === null) {
      selectedItemId = selectedZone?.id
      vectorSource = this.zonesLayer.zonesSource
      modifyStyle = this.styleService.getModifyStyle()
    } else if (selectedSurvey && selectedZone === null) {
      selectedItemId = selectedSurvey?.id
      vectorSource = this.surveysLayer.surveysSource
      modifyStyle = this.styleService.getSurveyModifyStyle(selectedSurvey.type)
    } else {
      if (selectedJobsite && !bottomTabOpened && showJobsiteEditPopup) {
        if (this.jobsiteEditOption === 'extent') {
          this.store.dispatch(new MapIsModifyingJobsiteExtent(true))
          modifyStyle = this.styleService.getModifyStyle()
          vectorSource = this.jobsitesLayer.jobsiteExtentSource
          selectedItemId = selectedJobsite?.id
        } else if (this.jobsiteEditOption === 'coordinate') {
          this.store.dispatch(new MapIsModifyingJobsiteCoords(true))
          modifyStyle = this.styleService.getJobsiteCenterModifyStyle()
          vectorSource = this.jobsitesLayer.jobsiteCenterSource
          selectedItemId = selectedJobsite?.id
        }
      }
    }

    const feature = selectedItemId ? vectorSource?.getFeatureById(selectedItemId) : undefined
    if (feature) {
      this.modifyInteraction = new Modify({
        features: new Collection([feature]),
      })
      feature.setStyle(modifyStyle)
    }
    this.modifyInteraction.on('modifyend', (e: ModifyEvent) => this.onModifyEnd(e))
    this.map.addInteraction(this.modifyInteraction)
  }

  private onModifyEnd(e: ModifyEvent) {
    const selectedJobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    const jobsiteExtent = selectedJobsite?.extent
    const selectedSurvey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)
    const selectedZone = this.store.selectSnapshot(AppStateSelectors.slices.selectedZone)
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)
    const showJobsiteEditPopup = this.store.selectSnapshot(AppStateSelectors.slices.showJobsiteEditPopup)

    const modifiedFeature: Feature<Geometry> = e.features.getArray()[0]
    const geometry = modifiedFeature.getGeometry() as SimpleGeometry
    const coordinate = geometry.getCoordinates()

    if (selectedSurvey && selectedZone === null) {
      if (isPointInsideJobsite(coordinate, jobsiteExtent)) {
        this.surveyCoordinateDms = COORDS_TOSTRINGHDMS(TOLONLAT(coordinate), 3)
        this.store.dispatch(new AppSetValidModifSurvey(true))
      } else {
        this.store.dispatch(new AppSetValidModifSurvey(false))
        return Swal.fire({
          title: this.translateService.instant('ALERT.FORBIDDEN'),
          text: this.translateService.instant('ALERT.SURVEY_INSIDE_SITE'),
          icon: 'error',
          showConfirmButton: false,
          timer: 1000,
        })
      }
    } else if (selectedZone && selectedSurvey === null) {
      if (isPolygonOutsideJobsite(coordinate[0], jobsiteExtent)) {
        this.store.dispatch(new AppSetValidModifZone(false))
        return Swal.fire({
          title: this.translateService.instant('ALERT.FORBIDDEN'),
          text: this.translateService.instant('ALERT.ZONE_INSIDE_SITE'), // To modify
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      } else {
        this.store.dispatch(new AppSetValidModifZone(true))
      }
    } else {
      if (selectedJobsite && !bottomTabOpened && showJobsiteEditPopup && this.jobsiteEditOption === 'coordinate') {
        if (!isPointInsideJobsite(coordinate, jobsiteExtent)) {
          return Swal.fire({
            title: this.translateService.instant('ALERT.FORBIDDEN'),
            text: 'New coordinate of jobsite can not be outside of jobsite extent',
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        }
      }
    }
  }

  private removeModifyInteraction(): void {
    if (this.modifyInteraction) {
      this.map.removeInteraction(this.modifyInteraction)
    }
  }

  private removeMapInteraction(): void {
    this.drawInteractionLayer?.removeDrawInteraction()
    this.removeModifyInteraction()
  }

  private handleZoomAndMoves() {
    this.map.on('moveend', () => {
      this.mapMoveEnd$.next()
    })
  }

  private initJobsite() {
    this.drawInteractionLayer.clearDrawingLayer()
    if (this.selectionLayer) {
      this.selectionLayer.sourceSelectionVecteur.clear()
    }
    this.store.dispatch([
      MapClearSelectedLocation,
      AppLeaveZone,
      AppLeaveSurvey,
      AppClearResource,
      CorrelationDataClearCorrelationData,
      DataTableClearSurveyData,
    ])
  }

  private handleKeyboardEvents(event: KeyboardEvent) {
    const isAddingZone = this.store.selectSnapshot(MapStateSelectors.slices.isAddingZone)
    if (isAddingZone) {
      if (event.key === 'Escape' || event.key === 'Enter') {
        this.drawInteractionLayer.finishDrawing()
      }
    }
    if (event.key === 'Escape') {
      this.initJobsite()
    }
  }

  private selectLocation(location: LatLonLocation, polygon: Coordinate[] | null, mark: boolean = true) {
    const firstTime = !this.store.selectSnapshot(MapStateSelectors.slices.selectedLocation)
    const bottomTabOpened = this.store.selectSnapshot(AppStateSelectors.slices.bottomTabOpened)

    this.store.dispatch(new MapSetSelectedLocation(location))
    if (mark) {
      this.markSurveyLocation(location)
    }
    if (!bottomTabOpened) {
      if (!this.isUserChangeCoord || firstTime) {
        this.centerOnLocation(location)
      }
      if (polygon) {
        const geoPolygon = new Polygon([polygon])
        this.map.getView().fit(geoPolygon, { size: this.map.getSize() })
      } else {
        if (!this.isUserChangeCoord || firstTime) {
          this.map.getView().setZoom(16)
        }
      }
    }
    this.isUserChangeCoord = false
  }

  private getVisibleMapPolygon() {
    const mapExtentRawCoords = this.map.getView().calculateExtent(this.map.getSize())
    const mapExtentCoords = adjustWGS84Extent(PROJ_TRANSFORMEXTENT(mapExtentRawCoords, 'EPSG:3857', 'EPSG:4326'))
    const polygon = POLYGON_FROMEXTENT(mapExtentCoords).getCoordinates()[0]
    return 'POLYGON((' + polygon.map((a) => `${a[0]} ${a[1]}`).reduce((acc, curr) => `${acc}, ${curr}`) + '))'
  }

  private searchInVisibleArea(redraw: boolean = false) {
    this.drawPinsSubject.next(redraw)
  }

  private searchInVisibleArea$(redraw: boolean, polygon: string) {
    return combineLatest([
      this.selectedJobsite$.pipe(
        map((jobsite) => jobsite?.id),
        switchMap((jobsiteId) =>
          iif(
            () => !!jobsiteId,
            this.jobsiteService.getJobsiteSurveys(jobsiteId || ''),
            this.soilSurveyService.searchVisibleSoilSurveys(polygon),
          ),
        ),
      ),
      this.zoneService.searchVisibleZones(polygon),
      this.jobsiteService.searchVisibleJobsites(polygon),
    ]).pipe(
      switchTap(([visibleSurveys, visibleZones, visibleJobsites]) =>
        this.store.dispatch(
          new MapSetVisibleEntities({
            jobsites: visibleJobsites,
            surveys: visibleSurveys,
            zones: visibleZones,
          }),
        ),
      ),
      switchTap(([visibleSurveys]) => {
        const actions: unknown[] = [new MapSetSurveysOnMap(visibleSurveys)]
        if (redraw) {
          actions.unshift(MapClearSurveysOnMap)
          this.doNotSearchInVisible = false
        }
        return this.store.dispatch(actions)
      }),
    )
  }

  centerOnLocation(location: LatLonLocation) {
    this.map.getView().setCenter(FROMLONLAT([location.longitude, location.latitude]))
  }

  markSurveyLocation(location: LatLonLocation | null): void {
    if (this.selectionLayer) {
      const zone = this.store.selectSnapshot(AppStateSelectors.slices.selectedZone)
      const survey = this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)
      this.mapService.markLocation(survey || zone ? null : location, this.selectionLayer.sourceSelectionVecteur)
    }
  }

  resetMapView() {
    this.store.dispatch(AppLeaveJobsite)
    if (this.map) {
      this.doNotSearchInVisible = true
      const { zoom, center } = this.store.selectSnapshot(UserConfigStateSelectors.zoomAndCenter)
      if (zoom !== undefined) {
        this.map.getView().setZoom(zoom)
      }
      this.map.getView().setCenter(center)
      this.searchInVisibleArea(true)
      this.markSurveyLocation(null)
      this.store.dispatch(MapClearSelectedLocation)
      this.removeMapInteraction()
    }
  }

  fitToVisibleMap(jobsite: Jobsite | null, coordinate: number[] | null = null) {
    this.mapService.fitToVisibleMap(this.map, coordinate, jobsite)
  }

  initCrossSectionSourceOnMap() {
    this.canEditMapCrossSection = true
    this.surveysLayer?.resetFeatureStyle(this.originalBoreholes.concat(this.projectedBoreholes))
    this.originalBoreholes = []
    this.projectedBoreholes = []
    this.crossSectionLayer?.crossSectionSource?.clear()
  }

  setSelectedCrossSectionOnMap({
    originalBhs,
    projectedBhs,
  }: {
    originalBhs: SoilSurvey[]
    projectedBhs: SoilSurvey[]
  }) {
    this.canEditMapCrossSection = false
    this.originalBoreholes = originalBhs
    this.projectedBoreholes = projectedBhs
    this.crossSectionService.showSelectBox(this.originalBoreholes, this.crossSectionLayer.crossSectionSource, false)
    this.markOriginalBoreholes()
    this.markProjectedBoreholes(false)
  }

  setBoreholeHoverStyle(boreholeId: string) {
    if (
      this.crossSectionLayer.crossSectionSource.getFeatures() &&
      this.crossSectionLayer.crossSectionSource.getFeatures().length > 0
    ) {
      const surveys = this.store.selectSnapshot(MapStateSelectors.slices.surveys)
      if (surveys) {
        this.surveysLayer.setCrossSectionBoreholeHoverStyle(boreholeId, surveys)
      }
    }
  }

  fitToSelectedPlace(extent: Extent) {
    this.map.getView().fit(extent, { size: this.map.getSize(), padding: [100, 100, 100, 100] })
  }

  private initZoneEdit() {
    this.store.dispatch(new AppSetZoneEditMode('addzone'))
    this.initCrossSectionSourceOnMap()
  }

  addSurvey(survey: SoilSurvey) {
    const currentSurveys = this.store.selectSnapshot(MapStateSelectors.slices.surveys)
    const newSurveys = [...(currentSurveys || []), survey]

    this.store.dispatch([new MapSetSurveys(newSurveys), new MapSetSurveysOnMap(newSurveys)])

    this.surveysLayer.addSurveys(survey.id, [survey])
    this.markSurveyLocation(null)
  }

  updateSurveys({ deleteIds, newSurveys }: { deleteIds: string[]; newSurveys: SoilSurvey[] }) {
    const currentSurveys = this.store.selectSnapshot(MapStateSelectors.slices.surveys)
    const updatedSurveys = [
      ...(currentSurveys?.filter((item) => item.id && !deleteIds.includes(item.id)) || []),
      ...newSurveys,
    ]

    this.store.dispatch([
      new MapSetSurveys(updatedSurveys),
      MapClearSurveysOnMap,
      new MapSetSurveysOnMap(updatedSurveys),
    ])

    this.drawSurveys()
  }

  deleteSurvey(surveyId: string) {
    const currentSurveys = this.store.selectSnapshot(MapStateSelectors.slices.surveys)
    const updatedSurveys = currentSurveys?.filter((item) => item.id !== surveyId)

    this.store.dispatch([
      new MapSetSurveys(updatedSurveys || null),
      MapClearSurveysOnMap,
      new MapSetSurveysOnMap(updatedSurveys || []),
      AppLeaveSurvey,
      AppClearResource,
      CorrelationDataClearCorrelationData,
      DataTableClearSurveyData,
      AppCloseBottomTab,
    ])

    this.surveysLayer.deleteSurveys([surveyId])
  }

  addZone(zone: Zone) {
    const newZones = [
      ...(this.store.selectSnapshot(MapStateSelectors.slices.zones)?.filter((z) => !z.temp) || []),
      zone,
    ]
    this.store.dispatch(new MapSetZones(newZones))

    this.drawInteractionLayer.drawingSource.clear()
    this.drawZones()
  }

  deleteZone(zoneId: string) {
    const newZones =
      this.store.selectSnapshot(MapStateSelectors.slices.zones)?.filter((zone) => zone.id !== zoneId) || null
    this.store.dispatch(new MapSetZones(newZones))

    this.drawZones()
  }

  onFilterOptionsChange({ type, checked }: { type: string; checked: boolean }) {
    const selectedSurveyId = this.store.selectSnapshot(AppStateSelectors.selectedSurveyId)
    const surveys = this.store.selectSnapshot(MapStateSelectors.slices.surveys)
    const displayedSurveys = this.store.selectSnapshot(FilterStateSelectors.slices.displayedSurveys).filters
    if (surveys) {
      if (checked) {
        this.surveysLayer.addSurveys(selectedSurveyId, surveys)
      } else {
        const surveysOfType = surveys
          .filter(({ type: surveyType }) => {
            if (surveyType && surveyType.indexOf(',') > -1) {
              return surveyType.split(',').every((value) => !displayedSurveys[value])
            } else {
              return surveyType === type
            }
          })
          .filter(({ id }) => !!id)
          .map(({ id }) => id as string)
        this.surveysLayer.deleteSurveys(surveysOfType)
      }
    }
  }

  updateSurvey(formattedSurvey: SoilSurvey) {
    this.store.dispatch(new MapUpdateSurvey(formattedSurvey))
    if (formattedSurvey.id) {
      this.surveysLayer.deleteSurveys([formattedSurvey.id])
    }
    this.surveysLayer.addSurveys(formattedSurvey.id, [formattedSurvey])
  }

  setEditMode(mode: 'addzone' | 'cross-section') {
    this.store.dispatch(new AppSetZoneEditMode(mode))
    if (mode === 'addzone') {
      this.drawInteractionLayer.addDrawInteraction()
      this.initZoneEdit()
    } else {
      this.removeMapInteraction()
    }
  }

  private markOriginalBoreholes() {
    this.originalBoreholes.forEach((bh: SoilSurvey) => {
      const feature = bh.id ? this.surveysLayer.surveysSource.getFeatureById(bh.id) : null
      if (feature) {
        const crossStyle = this.styleService.getOriginalBoreholeStyle(bh.type)
        feature.set('originalBorehole', true)
        feature.setStyle(crossStyle)
      }
    })
  }

  private markProjectedBoreholes(searchProjectedBhs = true) {
    const surveys = this.store.selectSnapshot(MapStateSelectors.slices.surveys)
    const selectedJobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)

    this.surveysLayer.resetFeatureStyle(this.projectedBoreholes)
    if (surveys && searchProjectedBhs && surveys.length > 2 && this.originalBoreholes?.length === 2) {
      const boreholes = surveys.filter(
        (item) => item.id !== this.originalBoreholes[0].id && item.id !== this.originalBoreholes[1].id,
      )
      this.projectedBoreholes = this.crossSectionService.getBoreholesInside(selectedJobsiteId, boreholes)
    }
    this.projectedBoreholes = this.projectedBoreholes.filter((borehole: SoilSurvey) => {
      const feature = borehole.id ? this.surveysLayer.surveysSource.getFeatureById(borehole.id) : null
      if (feature) {
        const crossStyle = this.styleService.getProjectedBoreholeStyle(borehole.type)
        feature.set('projectedBorehole', true)
        feature.setStyle(crossStyle)
        return true
      } else {
        return false
      }
    })
    this.crossSectionService.showVerticalLines(this.projectedBoreholes, this.crossSectionLayer.crossSectionSource)
  }

  resizeSelectBox({ parallel, vertical }: { vertical: number; parallel: number }) {
    this.crossSectionService.resizeSelectBox(parallel, vertical, this.crossSectionLayer.crossSectionSource)
    // mark the projected boreholes
    this.markProjectedBoreholes()
  }

  setSurveyTableChangeStatus(changed: boolean) {
    this.surveyTableDataChanged = changed
  }

  setCuttingTableChangeStatus(changed: boolean) {
    this.zoneTableDataChanged = changed
  }

  drawSurveys(redraw: boolean = true) {
    if (redraw) {
      this.surveysLayer?.drawSurveys()
    } else {
      this.surveysLayer?.drawSurveysAdded()
    }
  }

  goToSurvey(survey: SoilSurvey) {
    const actions: (AppSetSelectedSurvey | AppSetSelectedResource | CorrelationDataFetchCorrelationData)[] = [
      new AppSetSelectedSurvey(survey),
      new AppSetSelectedResource({
        type: 'borehole',
        id: survey.id ?? undefined,
      }),
    ]
    if (survey.id) {
      actions.push(new CorrelationDataFetchCorrelationData(survey.id))
    }
    this.store
      .dispatch(actions)
      .pipe(
        withLatestFrom(this.correlationData$),
        switchMap(([_, correlationData]) =>
          // if the survey has currently no correlation data, try to refresh it
          iif(() => !correlationData && !!survey.id, this.updateCorrelationData$(survey), EMPTY),
        ),
      )
      .subscribe()
  }

  private updateCorrelationData$(survey) {
    return of(
      new CorrelationDataUpdateCorrelationData(survey.id, this.sbtService.getLocalSurveySbtParameters(survey)),
    ).pipe(switchMap((action) => this.store.dispatch(action)))
  }

  private cancelJobsiteEdit() {
    this.drawJobsiteExtents()
    this.resetJobsiteEdit()
  }

  private resetJobsiteEdit() {
    this.jobsiteEditOption = null
    this.store.dispatch([new MapIsModifyingJobsiteExtent(false), new MapIsModifyingJobsiteCoords(false)])
    this.removeModifyInteraction()
  }

  private leaveSurvey() {
    this.store.dispatch([
      AppLeaveSurvey,
      AppClearResource,
      CorrelationDataClearCorrelationData,
      DataTableClearSurveyData,
    ])
  }

  private dispatchCurrentMapState() {
    this.store.dispatch(
      new UserConfigSetZoomAndCenter({
        zoom: this.map.getView().getZoom(),
        center: this.map.getView().getCenter(),
      }),
    )
  }

  private getFeatures = (feature: Feature<Geometry> | RenderFeature): FeatureLike[] => feature.get('features')

  private fireNoExtent(): Promise<void> {
    return Swal.fire({
      title: this.translateService.instant('ALERT.SITE_EXTENT'),
      text: this.translateService.instant('ALERT.DEFINE_EXTENT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
    }).then(({ value }) => {
      if (value) {
        this.store.dispatch(new MapIsAddingJobsiteExtent(true))
        this.drawInteractionLayer.addDrawInteraction()
      }
    })
  }

  private getCoordinates(selectedJobsiteId: string): Coordinate[] | undefined {
    const newFeature = this.jobsitesLayer.jobsiteExtentSource.getFeatureById(selectedJobsiteId)
    const simpleGeometry = newFeature ? (newFeature.getGeometry() as SimpleGeometry) : undefined
    return simpleGeometry?.getCoordinates()?.find(() => true)
  }
}
