/*
 * Generated type guards for "keycloakToken.model.ts".
 * WARNING: Do not manually change this file.
 */
import { KeycloakToken } from './keycloakToken.model'

export function isKeycloakToken(obj: unknown): obj is KeycloakToken {
  const typedObj = obj as KeycloakToken
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typeof typedObj['iss'] === 'undefined' || typeof typedObj['iss'] === 'string') &&
    (typeof typedObj['sub'] === 'undefined' || typeof typedObj['sub'] === 'string') &&
    (typeof typedObj['aud'] === 'undefined' || typeof typedObj['aud'] === 'string') &&
    (typeof typedObj['exp'] === 'undefined' || typeof typedObj['exp'] === 'number') &&
    (typeof typedObj['iat'] === 'undefined' || typeof typedObj['iat'] === 'number') &&
    (typeof typedObj['auth_time'] === 'undefined' || typeof typedObj['auth_time'] === 'number') &&
    (typeof typedObj['nonce'] === 'undefined' || typeof typedObj['nonce'] === 'string') &&
    (typeof typedObj['acr'] === 'undefined' || typeof typedObj['acr'] === 'string') &&
    (typeof typedObj['amr'] === 'undefined' || typeof typedObj['amr'] === 'string') &&
    (typeof typedObj['azp'] === 'undefined' || typeof typedObj['azp'] === 'string') &&
    (typeof typedObj['session_state'] === 'undefined' || typeof typedObj['session_state'] === 'string') &&
    (typeof typedObj['realm_access'] === 'undefined' ||
      (((typedObj['realm_access'] !== null && typeof typedObj['realm_access'] === 'object') ||
        typeof typedObj['realm_access'] === 'function') &&
        Array.isArray(typedObj['realm_access']['roles']) &&
        typedObj['realm_access']['roles'].every((e: any) => typeof e === 'string'))) &&
    (typeof typedObj['resource_access'] === 'undefined' ||
      (((typedObj['resource_access'] !== null && typeof typedObj['resource_access'] === 'object') ||
        typeof typedObj['resource_access'] === 'function') &&
        Object.entries<any>(typedObj['resource_access']).every(
          ([key, value]) =>
            ((value !== null && typeof value === 'object') || typeof value === 'function') &&
            Array.isArray(value['roles']) &&
            value['roles'].every((e: any) => typeof e === 'string') &&
            typeof key === 'string',
        ))) &&
    Object.entries<any>(typedObj)
      .filter(
        ([key]) =>
          ![
            'iss',
            'sub',
            'aud',
            'exp',
            'iat',
            'auth_time',
            'nonce',
            'acr',
            'amr',
            'azp',
            'session_state',
            'realm_access',
            'resource_access',
          ].includes(key),
      )
      .every(([key, _value]) => typeof key === 'string') &&
    (typeof typedObj['name'] === 'undefined' || typeof typedObj['name'] === 'string') &&
    (typeof typedObj['company'] === 'undefined' || typeof typedObj['company'] === 'string') &&
    (typeof typedObj['preferred_username'] === 'undefined' || typeof typedObj['preferred_username'] === 'string') &&
    (typeof typedObj['lang'] === 'undefined' || typeof typedObj['lang'] === 'string') &&
    (typeof typedObj['timeZone'] === 'undefined' || typeof typedObj['timeZone'] === 'string') &&
    (typeof typedObj['iss'] === 'undefined' || typeof typedObj['iss'] === 'string') &&
    (typeof typedObj['apps'] === 'undefined' ||
      (Array.isArray(typedObj['apps']) && typedObj['apps'].every((e: any) => typeof e === 'string')))
  )
}
