<div class="message-item" *ngIf="message.author !== 'logger'">
  <b>{{ timestampToDatestring(message.date) }} - {{ message.author }}</b>
  <br />
  {{ message.content }}
</div>

<div class="message-item" [ngClass]="message.type" *ngIf="message.author === 'logger'">
  <b>{{ timestampToDatestring(message.date) }}</b>
  <br />
  {{ message.content }}
</div>
