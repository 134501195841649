import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { dmsLatValidator, dmsLonValidator, nonNullable } from '../../utils'
import { LatLonLocation } from '../../models'
import { Observable, Subscription } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { getDD } from '../../utils/ddToDms'
import { Select } from '@ngxs/store'
import { AppStateSelectors } from '../../../store/app/app.selectors'

@Component({
  selector: 'soillib-new-jobsite-draggable',
  templateUrl: './new-jobsite-draggable.component.html',
  styleUrls: ['./new-jobsite-draggable.component.scss'],
})
export class NewJobsiteDraggableComponent implements OnInit, OnDestroy {
  @Output()
  closePopupEvent: EventEmitter<boolean> = new EventEmitter()
  @Output()
  handleSelectedAddressEvent: EventEmitter<LatLonLocation> = new EventEmitter()
  @Output()
  createNewJobsiteEvent: EventEmitter<{ name: string; coord: LatLonLocation }> = new EventEmitter()

  @Select(AppStateSelectors.slices.selectedJobsiteCoord) selectedJobsiteCoord$: Observable<LatLonLocation | null>

  constructor(public fb: FormBuilder) {}

  newJobsiteForm: FormGroup<{
    jobsiteName: FormControl<string | null>
    lon: FormControl<string | null>
    lat: FormControl<string | null>
  }>
  data: LatLonLocation
  jobsiteName = ''
  private subscription: Subscription = new Subscription()

  ngOnInit() {
    this.subscription.add(
      this.selectedJobsiteCoord$.pipe(filter(nonNullable)).subscribe((userSelectedCoord) => {
        this.data = userSelectedCoord
        this.initForm(
          userSelectedCoord.longitude.toFixed(7).toString(),
          userSelectedCoord.latitude.toFixed(7).toString(),
        )
      }),
    )
    this.setLocationByCoordinate()
  }

  private setLocationByCoordinate() {
    if (this.newJobsiteForm.get('lon')?.valid && this.newJobsiteForm.get('lat')?.valid) {
      const newCoordinates: LatLonLocation = {
        latitude: getDD(this.newJobsiteForm.value.lat ?? ''),
        longitude: getDD(this.newJobsiteForm.value.lon ?? ''),
      }
      this.data = newCoordinates
      this.handleSelectedAddressEvent.emit(newCoordinates)
    }
  }

  private initForm(lon: string, lat: string) {
    this.newJobsiteForm = this.fb.group({
      jobsiteName: this.fb.control(this.jobsiteName, Validators.required),
      lon: this.fb.control(lon, [Validators.required, dmsLonValidator.bind(this)]),
      lat: this.fb.control(lat, [Validators.required, dmsLatValidator.bind(this)]),
    })
    ;[
      this.newJobsiteForm
        .get('jobsiteName')
        ?.valueChanges?.pipe(debounceTime(600))
        ?.subscribe((value) => {
          if (value) {
            this.jobsiteName = value
          }
        }),
      this.newJobsiteForm
        .get('lon')
        ?.valueChanges?.pipe(debounceTime(600))
        ?.subscribe(() => {
          this.setLocationByCoordinate()
        }),
      this.newJobsiteForm
        .get('lat')
        ?.valueChanges?.pipe(debounceTime(600))
        ?.subscribe(() => {
          this.setLocationByCoordinate()
        }),
    ]
      .filter((s) => !!s)
      .forEach((s) => this.subscription.add(s))
  }

  createJobsite() {
    this.createNewJobsiteEvent.emit({ name: this.jobsiteName, coord: this.data })
  }

  close() {
    this.closePopupEvent.emit(true)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
