import { animate, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatSelectionListChange } from '@angular/material/list'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { CrossSectionInfos, User } from '../../shared/models'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import { FormControl } from '@angular/forms'
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators'
import { CrossSectionDto } from '@sde-ild/ssd-soillib-lib'
import { Observable, Subscription } from 'rxjs'
import { nonNullable } from '../../shared/utils'
import { Select, Store } from '@ngxs/store'
import {
  CrossSectionDeleteCrossSection,
  CrossSectionFetchSelectedCrossSection,
  CrossSectionFetchCrossSections,
  CrossSectionSetSearch,
} from '../store/cross-section.actions'
import { CrossSectionStateSelectors } from '../store/cross-section.selectors'

@Component({
  selector: 'soillib-cross-section-list',
  templateUrl: './cross-section-list.component.html',
  styleUrls: ['./cross-section-list.component.scss'],
  animations: [
    trigger('slideInOutFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-left': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-left': '42vw' })),
      ]),
      transition('true => false', [
        style({ 'margin-left': '42vw' }),
        animate('0.5s ease-in-out', style({ 'margin-left': '0' })),
      ]),
    ]),
  ],
})
export class CrossSectionListComponent implements OnInit, OnDestroy {
  @Select(CrossSectionStateSelectors.slices.crossSections)
  crossSections$: Observable<CrossSectionDto[]>
  @Select(CrossSectionStateSelectors.slices.selectedCrossSection)
  selectedCrossSection$: Observable<{ dto: CrossSectionDto; infos: CrossSectionInfos } | null>

  crossSectionListOpened = false // FIXME [joris 08/09/2023] - move to state

  user: User

  searchFormControl: FormControl<string | null>

  private subscription: Subscription = new Subscription()
  private currentPosition = 0

  constructor(
    private keycloakService: KeycloakService,
    private translateService: TranslateService,
    private store: Store,
  ) {
    this.user = this.keycloakService.getCurrentUser()
  }

  ngOnInit(): void {
    this.searchFormControl = new FormControl('')

    this.subscription.add(
      this.searchFormControl.valueChanges
        .pipe(
          filter(nonNullable),
          debounceTime(500),
          map((search) => search.trim()),
          distinctUntilChanged(),
          tap((search) => {
            this.currentPosition = 0
            this.store.dispatch([new CrossSectionSetSearch(search), CrossSectionFetchCrossSections])
          }),
        )
        .subscribe(),
    )

    this.subscription.add(
      this.selectedCrossSection$.pipe(map((crossSection) => crossSection?.dto)).subscribe((dto) => {
        if (!dto) {
          this.crossSectionListOpened = false
        }
      }),
    )
  }

  handleCrossSectionListToggle() {
    this.crossSectionListOpened = !this.crossSectionListOpened
    if (this.crossSectionListOpened) {
      this.store.dispatch([
        new CrossSectionSetSearch(this.searchFormControl.value || ''),
        CrossSectionFetchCrossSections,
      ])
    }
  }

  onScroll(element: Element | null) {
    if (element) {
      const { clientHeight, scrollTop, scrollHeight } = element
      if (scrollTop > this.currentPosition && scrollHeight - scrollTop === clientHeight) {
        this.store.dispatch(CrossSectionFetchCrossSections)
        this.currentPosition = scrollTop
      }
    }
  }

  onSelectCrossSectionGraph(selection: MatSelectionListChange) {
    const crId = selection.options[0].value?.cr_id
    if (crId) {
      this.store.dispatch(new CrossSectionFetchSelectedCrossSection(crId))
    }
  }

  getComplexChartsButtonStyle() {
    return this.crossSectionListOpened ? { 'margin-left': '42vw' } : ''
  }

  getPopInfo(cr: CrossSectionDto) {
    const created_at_string = cr.created_at
      ? new Date(cr.created_at).toLocaleString(
          this.translateService.currentLang ? this.translateService.currentLang : 'en',
        )
      : ''
    let info = `${this.translateService.instant(
      'GENERAL.CREATED_BY',
    )} ${cr.name_created_by?.toLocaleUpperCase()} ${created_at_string}`
    if (cr.name_modified_by && cr.modified_at) {
      const modified_at_string = new Date(cr.modified_at).toLocaleString(
        this.translateService.currentLang ? this.translateService.currentLang : 'en',
      )
      info = `${info};${this.translateService.instant(
        'GENERAL.MODIFIED_BY',
      )} ${cr.name_modified_by.toLocaleUpperCase()} ${modified_at_string}`
    }
    return info
  }

  deleteCrossSection(cr: CrossSectionDto, event: MouseEvent) {
    event.stopPropagation()
    Swal.fire({
      title: this.translateService.instant('GENERAL.SURE'),
      text: this.translateService.instant('GENERAL.NO_REVERT'),
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
      confirmButtonText: this.translateService.instant('GENERAL.DELETE') + ': ' + cr.cr_name,
      confirmButtonColor: '#e20025',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed && cr.cr_id) {
        this.store.dispatch(new CrossSectionDeleteCrossSection(cr.cr_id))
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
