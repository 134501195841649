import { Injectable } from '@angular/core'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { OperatorType, SBTnParametersModel } from '../models/SurveySBTnResults.model'
import { getLSItem, safeSetLSItem } from '../utils/local-storage.utils'

@Injectable()
export class SbtService {
  getLocalSurveySbtParameters({ elevation, id, water_level, type }: SoilSurvey): SBTnParametersModel | undefined {
    if (type?.includes('CPT') === true) {
      let model = this.getDefaultSbtParameters(elevation, water_level)
      const item = id ? this.getLocalSbtItem(id) : null
      if (item) {
        model = {
          ...model,
          ...item,
        }
      }
      return model
    } else {
      return undefined
    }
  }

  getLocalSbtItem(surveyId: string): SBTnParametersModel | null {
    const localId = this.getLocalSbtParamsId(surveyId)
    if (getLSItem(localId)) {
      const item = getLSItem(localId)
      if (item) {
        return JSON.parse(item)
      }
    }
    return null
  }

  setLocalSbtItem(surveyId: string, params: SBTnParametersModel) {
    safeSetLSItem(this.getLocalSbtParamsId(surveyId), JSON.stringify(params))
  }

  getDefaultSbtParameters = (
    elevation: number | null | undefined,
    waterLevel: number | null | undefined,
  ): SBTnParametersModel => ({
    a: 0.8,
    dic: 0.001,
    calculationTimes: 100,
    gamma: 19,
    smoothingInterval: 0.01,
    smoothingOperator: OperatorType.AVG,
    waterLevel: elevation != null && waterLevel != null ? elevation - waterLevel : 0,
  })

  private getLocalSbtParamsId = (surveyId: string) => 'sbtn-' + surveyId
}
