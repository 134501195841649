import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnInit } from '@angular/core'
import { SoilStats, SoilStatsService } from '@sde-ild/ssd-soillib-lib'
import { Jobsite } from '../shared/models'

@Component({
  selector: 'soillib-jobsite-statistics',
  templateUrl: './jobsite-statistics.component.html',
  styleUrls: ['./jobsite-statistics.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-right': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-right': '350px' })),
      ]),
      transition('true => false', [
        style({ 'margin-right': '350px' }),
        animate('0.5s ease-in-out', style({ 'margin-right': '0' })),
      ]),
    ]),
  ],
})
export class JobsiteStatisticsComponent implements OnInit {
  @Input() selectedJobsite: Jobsite | null

  statisticsOpened = false
  globalStats: SoilStats

  constructor(private soilStatsService: SoilStatsService) {}

  ngOnInit(): void {}

  handlestatisticsToggle() {
    this.statisticsOpened = !this.statisticsOpened
    if (this.statisticsOpened) {
      const jobsiteId = this.selectedJobsite?.id
      if (jobsiteId) {
        this.soilStatsService.getJobsiteSoilStats(jobsiteId).subscribe((res: SoilStats) => {
          this.globalStats = res
        })
      }
    }
  }

  getstatisticsButtonStyle() {
    return this.statisticsOpened ? { 'margin-right': '350px' } : ''
  }
}
