import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CorrelationDataModel, CorrelationParametersModel, SBTnParametersModel } from '../models'
import { map } from 'rxjs/operators'
import { scale } from '../utils'

@Injectable()
export class CorrelationDataService {
  constructor(private http: HttpClient) {}

  getCorrelationData(surveyId: string): Observable<CorrelationDataModel[]> {
    return this.http
      .get<CorrelationDataModel[]>(`api/correlation-data/${surveyId}`)
      .pipe(map((data) => data.map((model) => this.scale(model))))
  }

  updateCorrelationData(surveyId: string, params?: SBTnParametersModel): Observable<CorrelationDataModel[]> {
    return this.http
      .post<CorrelationDataModel[]>(`api/correlation-data/${surveyId}`, params)
      .pipe(map((data) => data.map((model) => this.scale(model))))
  }

  saveCorrelationParameters(
    surveyId: string,
    parameters: CorrelationParametersModel,
  ): Observable<CorrelationParametersModel> {
    return this.http.post<CorrelationParametersModel>(`api/correlation-data/parameters/${surveyId}`, parameters)
  }

  updateCorrelationParameters(
    surveyId: string,
    parameters: CorrelationParametersModel,
  ): Observable<CorrelationParametersModel> {
    return this.http.put<CorrelationParametersModel>(`api/correlation-data/parameters/${surveyId}`, parameters)
  }

  getCorrelationParameters(surveyId: string): Observable<CorrelationParametersModel> {
    return this.http.get<CorrelationParametersModel>(`api/correlation-data/parameters/${surveyId}`)
  }

  exportData(surveyId: string, sbtNParameters?: SBTnParametersModel, lang?: string): Observable<HttpResponse<Blob>> {
    const options = lang ? { params: new HttpParams().set('lang', lang) } : {}
    return this.http.post(`api/correlation-data/export/${surveyId}`, sbtNParameters, {
      observe: 'response',
      responseType: 'blob',
      ...options,
    })
  }

  scale = (data: CorrelationDataModel): CorrelationDataModel => ({
    ...data,
    cptGamma: scale(data.cptGamma),
    cptSu: scale(data.cptSu),
    cptDr: scale(data.cptDr),
    cptOcr: scale(data.cptOcr),
    cptPhiPrime: scale(data.cptPhiPrime),
    cptSptN60: scale(data.cptSptN60, 10),
    pressiomSu: scale(data.pressiomSu),
    pressiomPhiPrime: scale(data.pressiomPhiPrime),
    sptSu: scale(data.sptSu),
    sptPhiPrimeGravel: scale(data.sptPhiPrimeGravel),
    sptPhiPrimeCoarseSand: scale(data.sptPhiPrimeCoarseSand),
    sptPhiPrimeMediumSand: scale(data.sptPhiPrimeMediumSand),
    sptPhiPrimeFineSand: scale(data.sptPhiPrimeFineSand),
    sptPhiPrimeSiltySand: scale(data.sptPhiPrimeSiltySand),
    cptK: scale(data.cptK != null ? data.cptK * 1000000 : data.cptK),
    cptPressiomQcClays: scale(data.cptPressiomQcClays),
    cptPressiomQcSilts: scale(data.cptPressiomQcSilts),
    cptPressiomQcSands: scale(data.cptPressiomQcSands),
  })
}
