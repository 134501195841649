import { DisplayedSurveys, RadiusSearch } from '../../shared/models'

export class FilterToggleFilteringOpened {
  public static readonly type = '[Filter] ToggleFilteringOpened'
}

export class FilterSetPreventJobsiteExtentsDrawing {
  public static readonly type = '[Filter] SetPreventJobsiteExtentsDrawing'

  constructor(readonly preventJobsiteExtentsDrawing: boolean) {}
}

export class FilterSetPreventCuttingsDrawing {
  public static readonly type = '[Filter] SetPreventCuttingsDrawing'

  constructor(readonly preventCuttingsDrawing: boolean) {}
}

export class FilterSetDisplayedSurveys {
  public static readonly type = '[Filter] SetDisplayedSurveys'

  constructor(readonly displayedSurveys: DisplayedSurveys) {}
}

export class FilterSetShowMapLabels {
  public static readonly type = '[Filter] SetShowMapLabels'

  constructor(readonly showMapLabels: boolean) {}
}

export class FilterSetRadiusSearch {
  public static readonly type = '[Filter] SetRadiusSearch'

  constructor(readonly radiusSearch: RadiusSearch) {}
}
