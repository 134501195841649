import { FormControl } from '@angular/forms'

export function dmsLonValidator(input: FormControl<string | null>) {
  const str = input.value?.trim() || ''
  const patt = new RegExp(/^\d{1,3}[\u00b0]\d{1,2}[']([0-9]*[.])?[0-9]+["][E|W]$/i)
  if (patt.test(str)) {
    const matchPatt = new RegExp(/^(\d{1,3})[\u00b0](\d{1,2})[']([0-9]*[.]?[0-9]+)["][E|W]$/i)
    const matches = str.match(matchPatt)
    if (matches && matches.length > 3) {
      // ["E2°25'56.017"", "2", "25", "56.017"]
      if (parseInt(matches[1], 10) < 180 && parseInt(matches[2], 10) < 60 && parseFloat(matches[3]) < 60) {
        return null
      } else {
        return { longitudeError: true }
      }
    }
  } else if (/^[+-]?([0-9]*[.])?[0-9]+$/.test(str)) {
    const ddmatch = str.match(/^[+-]?([0-9]*[.]?[0-9]+)$/)
    if (ddmatch && ddmatch[0] && parseInt(ddmatch[0], 10) < 180) {
      return null
    } else {
      return { longitudeError: true }
    }
  }
  return { longitudeError: true }
}

export function dmsLatValidator(input: FormControl<string | null>) {
  const str = input.value?.trim() || ''
  const patt = new RegExp(/^\d{1,2}[\u00b0]\d{1,2}[']([0-9]*[.])?[0-9]+["][N|S]$/i)
  if (patt.test(str)) {
    const matchPatt = new RegExp(/^(\d{1,2})[\u00b0](\d{1,2})[']([0-9]*[.]?[0-9]+)["][N|S]$/i)
    const matches = str.match(matchPatt)
    if (matches && matches.length > 3) {
      // ["E2°25'56.017"", "2", "25", "56.017"]
      if (parseInt(matches[1], 10) < 90 && parseInt(matches[2], 10) < 60 && parseFloat(matches[3]) < 60) {
        return null
      } else {
        return { latitudeError: true }
      }
    }
  } else if (/^[+-]?([0-9]*[.])?[0-9]+$/.test(str)) {
    const ddmatch = str.match(/^[+-]?([0-9]*[.]?[0-9]+)$/)
    if (ddmatch && ddmatch[0] && parseInt(ddmatch[0], 10) < 90) {
      return null
    } else {
      return { latitudeError: true }
    }
  }
  return { latitudeError: true }
}
