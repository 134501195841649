import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { EpsgProjectionService, FileParseService } from '../../shared/services'
import { EpsgProjModel, ImportedBorehole, Jobsite } from '../../shared/models'
import { forkJoin, noop, Observable } from 'rxjs'
import { MetaHeadersModel, ParaHeadersModel } from '../../shared/models/Imprt-csv-config.model'
import { cloneDeep } from 'lodash'
import { TranslateService } from '@ngx-translate/core'
import Swal from 'sweetalert2'
import { SoilSurvey, SoilTypeUtilsService, SurveyData } from '@sde-ild/ssd-soillib-lib'
import { safeParseFloat } from '../../shared/utils'
import { Select, Store } from '@ngxs/store'
import { AppFetchSoilSurveyAvailableParameters, AppSetLoading } from '../../store/app/app.actions'
import { AppStateSelectors } from '../../store/app/app.selectors'

@Component({
  selector: 'soillib-surveys-import-csv',
  templateUrl: './surveys-import-csv.component.html',
  styleUrls: ['./surveys-import-csv.component.scss'],
})
export class SurveysImportCsvComponent implements OnInit {
  @Input()
  level: 'jobsite' | 'borehole'

  @Input()
  datumDisplayName: string

  @Input()
  isSBT = false

  @Select(AppStateSelectors.soilSurveyAvailableParameters) availableParameters$: Observable<string[] | null>

  @Output()
  updateSurveysToMapEvent: EventEmitter<{ deleteIds: string[]; newSurveys: SoilSurvey[] }> = new EventEmitter()

  @Output()
  finishAgsImportEvent: EventEmitter<Jobsite> = new EventEmitter()

  detectedProjections: string[]
  detectedJobsiteNames: string[]
  detectedType: string | null
  selectedJobsiteName: string | null

  allCSVBoreholes: ImportedBorehole[]

  allImportedBoreholes: ImportedBorehole[]

  loadingText: string
  customProjInfoText: string | null
  customProjErrorText: string | null

  AGS_Proj: object

  csvDataArrays: (string | number)[][][]
  paraHeaders: string[] = []
  metaHeaders: string[] = []
  metaConfigHeaders: MetaHeadersModel | null
  paraConfigHeaders: ParaHeadersModel | null

  selectedFiles: File[]

  shortTemplateExample = `<BH_START>
                      <META_START>
                      Jobsite name; test A
                      Test number; 05-CPT2
                      Ground level; 0.00
                      Water level; 0.00
                      Type;CPT
                      Projection; HongKong
                      E Coordinate; 807161.545
                      N Coordinate; 818729.841
                      <META_END>
                      <DATA_START>
                      Depth;qc;fs;u2;I;Rf;qt;qe;soilfr;soilbq
                      0.00;0.00;0.000;0.000;1.7;0.00;0.000;0.000;0.0;0.0
                      0.02;0.02;0.001;0.029;1.8;9.82;0.026;-0.008;9.0;0.0
                      0.04;0.02;0.001;0.029;1.8;7.92;0.030;-0.005;9.0;0.0
                      0.06;0.02;0.001;0.030;1.7;6.63;0.031;-0.005;9.0;0.0
                      <DATA_END>
                      <BH_END>

                      <BH_START>
                      <META_START>
                      Jobsite name; test Aa
                      Test number; 05-CPT2_Aa
                      ...
                      <DATA_START>
                      ...
                      <DATA_END>
                      <BH_END>`

  templateExample = `<BH_START>
<META_START>
Jobsite name;Jobsite Example;;;;;;;;;
Borehole name;Borehole Example #1;;;;;;;;;
Ground level;1.02;;;;;;;;;
Water level;-0.60;;;;;;;;;
Type;CPT;;;;;;;;;
Projection;OSGB;;;;;;;;;
Date;22/04/2022 121700;;;;;;;;;
Prehole Mode;;;;;;;;;;
Prehole Depth;0;;;;;;;;;
Hydrostatic Line;0;;;;;;;;;
Probe Code;Mkj572;;;;;;;;;
N Coordinate;175330.38;;;;;;;;;
E Coordinate;565093.59;;;;;;;;;
Comments;;;;;;;;;;
Operator;;;;;;;;;;
SeisON;9600;;;;;;;;;
TempON;0;;;;;;;;;
P. Qc;191120;;;;;;;;;
P. Fs;31084;;;;;;;;;
P. U2;10973;;;;;;;;;
P. Tilt;280278;;;;;;;;;
P. Temp;400800;;;;;;;;;
Z. Qc;2354;;;;;;;;;
Z. Fs;3824;;;;;;;;;
Z. U2;15688;;;;;;;;;
Z. Tilt;1019;;;;;;;;;
<META_END>
<DATA_START>
Depth;Qc;Fs;U2;Rf;Temp;Tilt;Dist;Speed;Qt;U2-U0
1;0.08;0;0.73;0;0;1.39;0.02;0;0.08;0.63
2;0.16;0;2.46;0;0;1.38;0.05;2;0.16;2.26
3;0.25;0;12.39;0;0;1.38;0.07;1.7;0.25;12.1
4;0.45;0;31.35;0;0;1.39;0.1;1.4;0.46;30.96
5;0.62;0;50.58;0;0;1.39;0.12;1.4;0.63;50.09
6;0.69;0;53.59;0;0;1.39;0.15;1.4;0.7;53
7;0.75;2.28;55.04;0.31;0;1.47;0.17;1.4;0.76;54.36
8;0.72;6.43;54.68;0.9;0;1.48;0.2;1.4;0.73;53.89
9;0.72;5.86;54.77;0.82;0;1.48;0.22;1.4;0.73;53.89
10;0.72;6.79;56.78;0.95;0;1.47;0.25;1.4;0.73;55.79
11;0.73;6.56;57.5;0.9;0;1.47;0.27;1.4;0.74;56.43
12;0.72;7.24;59.33;1;0;1.55;0.3;1.4;0.73;58.15
13;0.72;7.43;59.78;1.03;0;1.55;0.33;1.4;0.73;58.51
14;0.72;7.53;58.14;1.05;0;1.54;0.35;1.4;0.73;56.77
15;0.71;7.56;57.32;1.06;0;1.55;0.38;1.5;0.72;55.85
16;0.7;7.91;54.5;1.13;0;1.55;0.41;1.5;0.71;52.93
17;0.7;7.91;53.31;1.13;0;1.55;0.44;1.5;0.71;51.64
18;0.7;8.24;52.31;1.18;0;1.54;0.46;1.4;0.71;50.54
19;0.71;8.56;50.85;1.2;0;1.55;0.49;1.4;0.72;48.99
20;0.72;9.07;50.21;1.27;0;1.54;0.52;1.4;0.73;48.25
<DATA_END>
<BH_END>
`

  constructor(
    private fileParseService: FileParseService,
    private epsgProjectionService: EpsgProjectionService,
    private translateService: TranslateService,
    private soilTypeUtilsService: SoilTypeUtilsService,
    private store: Store,
  ) {
    this.AGS_Proj = this.epsgProjectionService.Known_Projs
  }

  ngOnInit() {
    this.initData()
    this.store.dispatch(AppFetchSoilSurveyAvailableParameters)
  }

  private initData() {
    this.allCSVBoreholes = []
    this.allImportedBoreholes = []
    this.loadingText = 'LOADING...'
    this.customProjInfoText = null
    this.customProjErrorText = null
    this.selectedJobsiteName = null
    this.detectedProjections = []
    this.detectedJobsiteNames = []
    this.detectedType = null
    this.metaHeaders = []
    this.paraHeaders = []
    this.metaConfigHeaders = null
    this.paraConfigHeaders = null
  }

  handleFilesSelected(files: File[]) {
    this.selectedFiles = files
    this.handleFiles()
  }

  private handleFiles(delimiter = ';') {
    this.initData()
    const observableParsedData: Observable<(string | number)[][]>[] = []
    this.selectedFiles.forEach((file) => {
      if (file) {
        observableParsedData.push(this.fileParseService.parseFile(file, delimiter))
      }
    })
    this.store.dispatch(new AppSetLoading(true))
    forkJoin(observableParsedData).subscribe({
      next: (csvDataArr: (string | number)[][][]) => {
        this.csvDataArrays = csvDataArr
        this.parseAllCsvs()
      },
      error: (error) => {
        console.error('AGS parse errors: ' + error)
      },
      complete: () => {
        this.store.dispatch(new AppSetLoading(false))
        if (this.allCSVBoreholes.length === 0) {
          this.loadingText = 'No boreholes found'
        }
      },
    })
  }

  delimiterSelected(delimiter: string) {
    this.handleFiles(delimiter)
  }

  sendConfigHeaders(headers: { meta: MetaHeadersModel; para: ParaHeadersModel }) {
    this.metaConfigHeaders = headers.meta
    this.paraConfigHeaders = headers.para
    this.parseAllCsvs()
  }

  private parseAllCsvs() {
    this.allCSVBoreholes = []
    if (this.csvDataArrays && this.csvDataArrays.length > 0) {
      this.csvDataArrays.forEach((data) => {
        this.parseSingleCSV(data)
      })
      this.selectedJobsiteName = this.detectedJobsiteNames[0] ? this.detectedJobsiteNames[0] : ''
      if (this.allCSVBoreholes.length > 0) {
        this.allImportedBoreholes = cloneDeep(this.allCSVBoreholes)
      }
    }
  }

  private parseSingleCSV(csvData: (string | number)[][]) {
    let boreholeGeo: SoilSurvey = {}
    let boreholeData: SurveyData[] = []
    let bhStart = false
    let metaStart = false
    let dataHeaderStart = false
    let dataStart = false
    let dataHeaders: string[] = []
    for (const element of csvData) {
      const row = element[0]?.toString().trim()
      if (row?.startsWith('<BH_START>')) {
        boreholeGeo = {
          jobsite_id: this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId),
          lon_coord: undefined,
          lat_coord: undefined,
          type: undefined,
        }
        boreholeData = []
        bhStart = true
        continue
      }
      if (row?.startsWith('<META_START>')) {
        metaStart = true
        continue
      }
      if (row?.startsWith('<META_END>')) {
        metaStart = false
        continue
      }
      if (row?.startsWith('<DATA_START>')) {
        dataHeaderStart = true
        continue
      }
      if (row?.startsWith('<DATA_END>')) {
        dataStart = false
        continue
      }
      if (row?.startsWith('<BH_END>')) {
        bhStart = false
        this.allCSVBoreholes.push({
          survey_basic: boreholeGeo,
          survey_data: boreholeData,
        })
        continue
      }
      if (dataHeaderStart) {
        dataStart = true
        dataHeaderStart = false
        dataHeaders = element.filter((el) => el).map((ele) => String(ele).trim())
        this.paraHeaders = Array.from(new Set([...this.paraHeaders, ...dataHeaders]))
        continue
      }
      if (dataStart) {
        if (isNaN(safeParseFloat(element[0]))) {
          continue
        }

        const eleData: SurveyData = {}
        dataHeaders.forEach((ele) => {
          const headers = this.paraConfigHeaders
          if (headers && ele) {
            const para = Object.keys(headers).find((key) => headers[key] === ele)
            if (para) {
              const rawData = element[dataHeaders.indexOf(ele)]
              if (para === 'geo_soil' || para === 'geotechnical_engineer_description') {
                eleData[para] = rawData?.toString()
              } else {
                const floatData = safeParseFloat(rawData)
                eleData[para] = isNaN(floatData) ? undefined : floatData
              }
            }
          }
        })
        if (eleData) {
          if (eleData.geo_soil) {
            eleData.calculated_soil = this.soilTypeUtilsService.parseSoilType(eleData.geo_soil, this.isSBT)
          }
          if (eleData.geotechnical_engineer_description) {
            eleData.calculated_compactness = this.soilTypeUtilsService.parseSoilCompactness(
              eleData.geotechnical_engineer_description,
            )
          }
          boreholeData.push(eleData)
        }
      }
      if (metaStart) {
        const arr = element
        if (arr[0]) {
          this.metaHeaders = Array.from(new Set([...this.metaHeaders, String(arr[0]).trim()]))
          const metaHeaders = this.metaConfigHeaders
          if (metaHeaders) {
            const key = Object.keys(metaHeaders).find((k) => metaHeaders[k] === arr[0])
            if (key === 'jobsite_name') {
              this.detectedJobsiteNames = Array.from(new Set([...this.detectedJobsiteNames, arr[1]?.toString().trim()]))
            } else if (key === 'projection') {
              this.detectedProjections = Array.from(new Set([...this.detectedProjections, arr[1]?.toString().trim()]))
            } else if (key) {
              boreholeGeo[key] = key === 'name' || key === 'type' ? arr[1]?.toString().trim() : safeParseFloat(arr[1])
            }
          }
          this.detectedType = boreholeGeo.type ?? null
        }
      }
    }
  }

  setJobsiteName(name: string) {
    this.selectedJobsiteName = name
  }

  applyProjection(value: string) {
    if (this.allCSVBoreholes.length > 0) {
      this.customProjInfoText = null
      this.customProjErrorText = null
      this.epsgProjectionService.searchEpsg(value).subscribe((res: EpsgProjModel) => {
        if (res) {
          this.allCSVBoreholes.forEach((hole) => {
            if (hole.survey_basic) {
              const coords = this.epsgProjectionService.toLonLat(res, [
                Number(hole.survey_basic.local_x),
                Number(hole.survey_basic.local_y),
              ])
              hole.survey_basic.local_proj = res.code
              hole.survey_basic.projection_ref = res.code
              hole.survey_basic.lon_coord = coords ? coords[0] : undefined
              hole.survey_basic.lat_coord = coords ? coords[1] : undefined
            }
          })

          this.allImportedBoreholes = cloneDeep(this.allCSVBoreholes)
          this.customProjInfoText = res.name
        } else {
          this.customProjErrorText = 'Projection not valid!'
        }
      })
    }
  }

  onBoreholeTypeChange(value: string | undefined) {
    this.allCSVBoreholes.forEach((hole) => {
      if (hole.survey_basic) {
        hole.survey_basic.type = value
      }
    })
    this.allImportedBoreholes = cloneDeep(this.allCSVBoreholes)
  }

  onProjSelectionChange(value: string) {
    const local_proj: EpsgProjModel = this.AGS_Proj[value]
    this.allCSVBoreholes.forEach((hole) => {
      if (hole.survey_basic) {
        const coords = this.epsgProjectionService.toLonLat(local_proj, [
          Number(hole.survey_basic.local_x),
          Number(hole.survey_basic.local_y),
        ])
        hole.survey_basic.local_proj = value
        hole.survey_basic.projection_ref = local_proj?.code
        hole.survey_basic.lon_coord = coords ? coords[0] : undefined
        hole.survey_basic.lat_coord = coords ? coords[1] : undefined
      }
    })

    this.allImportedBoreholes = cloneDeep(this.allCSVBoreholes)
    this.customProjErrorText = null
    this.customProjInfoText = null
  }

  updateSurveysToMap(value: { deleteIds: string[]; newSurveys: SoilSurvey[] }) {
    this.updateSurveysToMapEvent.emit(value)
  }

  finishAgsImport(value: Jobsite) {
    this.finishAgsImportEvent.emit(value)
  }

  showCopySuccess() {
    Swal.fire({
      title: this.translateService.instant('FILE_IMPORT.CSV_CONFIG_COPY.SUCCESS_TITLE'),
      text: this.translateService.instant('FILE_IMPORT.CSV_CONFIG_COPY.SUCCESS_MESSAGE'),
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(noop)
  }
}
