import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols'
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols'
import { NgxsConfig } from '@ngxs/store/src/symbols'
import { AppState } from '../store/app/app.state'
import { environment } from '../../environments/environment'
import { MapState } from '../store/map/map.state'
import { UserConfigState } from '../store/user-config/user-config.state'
import { DataTableState } from '../store/data-table/data-table.state'
import { CrossSectionState } from '../soil-cuttings-edition/store/cross-section.state'
import { SurveysComparisonState } from '../surveys-comparison/store/surveys-comparison.state'
import { FilterState } from '../store/filter/filter.state'

export const ROOT_STATES = [
  AppState,
  UserConfigState,
  MapState,
  FilterState,
  DataTableState,
  CrossSectionState,
  SurveysComparisonState,
]

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  /**
   * This will add additional debugging features (object.freeze on the state
   * and actions to guarantee immutability).
   */
  developmentMode: !environment.production,
  selectorOptions: {
    /**
     * These selector settings are recommended in preparation for NGXS v4
     */
    injectContainerState: false,
    suppressErrors: false,
  },
}

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
  /**
   * Whether the dev tools is enabled or not.
   * Useful for setting during production.
   */
  disabled: environment.production,
}

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
  /**
   * Disable the logger. Useful for prod mode.
   */
  disabled: environment.disableLogging,
}
