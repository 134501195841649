import { Injectable } from '@angular/core'
import { Action, State, StateContext, Store } from '@ngxs/store'
import {
  SurveysComparisonAddSelectedSurvey,
  SurveysComparisonClearComplexChartSurvey,
  SurveysComparisonCloseComparison,
  SurveysComparisonCloseComplexChart,
  SurveysComparisonDeleteComplexChart,
  SurveysComparisonDrawComplexChart,
  SurveysComparisonFetchCharts,
  SurveysComparisonFetchSurveyParams,
  SurveysComparisonOpenComplexChart,
  SurveysComparisonRemoveSurvey,
  SurveysComparisonSaveComplexCharts,
  SurveysComparisonSetChartsMarginTop,
  SurveysComparisonSetComplexCharts,
  SurveysComparisonSetComplexChartsDTO,
  SurveysComparisonSetComplexChartSurvey,
  SurveysComparisonSetSelectedCommonBhType,
  SurveysComparisonSetSelectedCommonParameter,
} from './surveys-comparison.actions'
import { CorrelationDataService, SoilSurveyService, UserChartsService } from '../../shared/remote-services'
import {
  ComplexChart,
  ComplexChartDTO,
  ComplexChartItem,
  CorrelationDataModel,
  LabTestDataModel,
} from '../../shared/models'
import produce from 'immer'
import { catchError, filter, switchMap, tap } from 'rxjs/operators'
import { combineLatest, EMPTY, forkJoin, map, Observable, of } from 'rxjs'
import { switchTap } from '../../shared/utils/observables.utils'
import { allBoreholeTypes, getLabParameters, SoilSurvey, SurveyData, BoreholeType } from '@sde-ild/ssd-soillib-lib'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { nonNullable, withNonNullableFields } from '../../shared/utils'
import * as Sentry from '@sentry/angular'
import { ComplexChartDTOData } from '../../shared/models/ComplexChartDTO.model'
import { SurveysComparisonService } from '../service/surveys-comparison.service'

export interface ComplexChartSurvey {
  id: string
  name: string | null
  type: string | null
  elevation: number | null
  color: string | null
}

export interface ComplexChartParameter {
  parameter: string
  title: string
  accessor: string
}

export type ComplexChartSurveyParams = ComplexChartSurvey & {
  availableParams: ComplexChartParameter[]
  surveysData: SurveyData[]
  labTestsData: LabTestDataModel[]
  correlationData: CorrelationDataModel[]
}

export interface SurveysComparisonStateModel {
  selectedSurveys: SoilSurvey[] | null
  complexCharts: ComplexChart[] | null
  chartsMarginTop: number
  complexChartsOpened: boolean
  editedComplexChart: ComplexChart | null
  commonParameters: string[] | null
  selectedCommonBhType: BoreholeType | null
  selectedCommonParameter: string | null
  saveError: { jobsiteId: string; error: boolean } | null
  allBoreholeTypes: string[]
  labParams: string[]
  complexChartSurvey: ComplexChartSurvey | null
  surveyParams: ComplexChartSurveyParams[] | null
}

@State<SurveysComparisonStateModel>({
  name: 'surveysComparison',
  defaults: {
    selectedSurveys: null,
    complexCharts: null,
    chartsMarginTop: 0,
    complexChartsOpened: false,
    editedComplexChart: null,
    commonParameters: null,
    selectedCommonBhType: null,
    selectedCommonParameter: null,
    saveError: null,
    labParams: getLabParameters(),
    allBoreholeTypes: allBoreholeTypes(),
    complexChartSurvey: null,
    surveyParams: null,
  },
})
@Injectable()
export class SurveysComparisonState {
  constructor(
    private userChartsService: UserChartsService,
    private soilSurveyService: SoilSurveyService,
    private surveysComparisonService: SurveysComparisonService,
    private correlationDataService: CorrelationDataService,
    private store: Store,
  ) {}

  @Action(SurveysComparisonFetchCharts)
  public fetchCharts(ctx: StateContext<SurveysComparisonStateModel>) {
    const jobsiteId = this.getJobsiteId()
    if (jobsiteId) {
      return this.userChartsService
        .getUserCharts(jobsiteId)
        .pipe(switchMap((charts: ComplexChartDTO[]) => ctx.dispatch(new SurveysComparisonSetComplexChartsDTO(charts))))
    } else {
      return EMPTY
    }
  }

  @Action(SurveysComparisonSetComplexChartsDTO)
  public setComplexChartsDTO(
    ctx: StateContext<SurveysComparisonStateModel>,
    { chartsDTO }: SurveysComparisonSetComplexChartsDTO,
  ) {
    const jobsiteId = this.getJobsiteId()
    return chartsDTO?.length && jobsiteId
      ? of(chartsDTO).pipe(
          switchMap((charts) => this.loadChartsData$(charts)),
          switchMap((complexCharts) => ctx.dispatch(new SurveysComparisonSetComplexCharts(complexCharts))),
        )
      : EMPTY
  }

  @Action(SurveysComparisonCloseComparison)
  public closeComparison(ctx: StateContext<SurveysComparisonStateModel>) {
    return of(null).pipe(
      switchTap(() => ctx.dispatch(SurveysComparisonCloseComplexChart)),
      tap(() =>
        ctx.setState(
          produce((draft) => {
            draft.selectedSurveys = null
            draft.complexCharts = null
            draft.chartsMarginTop = 0
          }),
        ),
      ),
    )
  }

  @Action(SurveysComparisonAddSelectedSurvey)
  public addSelectedSurvey(
    ctx: StateContext<SurveysComparisonStateModel>,
    { selectedSurvey }: SurveysComparisonAddSelectedSurvey,
  ) {
    return of(ctx.getState()).pipe(
      tap(({ selectedSurveys }) => {
        let newSelectedSurveys: SoilSurvey[]
        if (selectedSurveys) {
          if (selectedSurveys.map((survey) => survey.id).includes(selectedSurvey.id)) {
            newSelectedSurveys = selectedSurveys.filter((s) => s.id !== selectedSurvey.id)
          } else {
            newSelectedSurveys = [...selectedSurveys, selectedSurvey]
          }
        } else {
          newSelectedSurveys = [selectedSurvey]
        }
        ctx.setState(
          produce((draft) => {
            draft.selectedSurveys = newSelectedSurveys
          }),
        )
      }),
    )
  }

  @Action(SurveysComparisonSetComplexChartSurvey)
  public setComplexChartSurvey(
    ctx: StateContext<SurveysComparisonStateModel>,
    { complexChartSurvey }: SurveysComparisonSetComplexChartSurvey,
  ) {
    const surveyId = complexChartSurvey.id
    if (surveyId) {
      ctx.setState(
        produce((draft) => {
          draft.complexChartSurvey = {
            id: surveyId,
            name: complexChartSurvey.name || null,
            type: complexChartSurvey.type || null,
            elevation: complexChartSurvey.elevation || null,
            color: null,
          }
        }),
      )
    }
  }

  @Action(SurveysComparisonClearComplexChartSurvey)
  public clearComplexChartSurvey(ctx: StateContext<SurveysComparisonStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.complexChartSurvey = null
      }),
    )
  }

  @Action(SurveysComparisonSetComplexCharts)
  public setComplexCharts(
    ctx: StateContext<SurveysComparisonStateModel>,
    { complexCharts }: SurveysComparisonSetComplexCharts,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.complexCharts = complexCharts
      }),
    )
  }

  @Action(SurveysComparisonSaveComplexCharts)
  public saveComplexCharts(ctx: StateContext<SurveysComparisonStateModel>) {
    const jobsiteId = this.getJobsiteId()
    if (jobsiteId) {
      return of(ctx.getState()).pipe(
        switchMap(({ complexCharts }) => this.userChartsService.saveUserCharts(jobsiteId, complexCharts)),
        switchMap((charts: ComplexChartDTO[]) => ctx.dispatch(new SurveysComparisonSetComplexChartsDTO(charts))),
        catchError((err) => {
          console.error(err)
          return of(ctx.getState()).pipe(
            tap(({ complexCharts }) => {
              ctx.setState(
                produce((draft) => {
                  draft.saveError = { jobsiteId, error: !!complexCharts?.length }
                }),
              )
            }),
          )
        }),
      )
    } else {
      return EMPTY
    }
  }

  @Action(SurveysComparisonDrawComplexChart)
  public drawComplexCharts(
    ctx: StateContext<SurveysComparisonStateModel>,
    { chart }: SurveysComparisonDrawComplexChart,
  ) {
    return of(ctx.getState()).pipe(
      switchMap(({ complexCharts }) => {
        let newComplexCharts = [...(complexCharts || [])]
        if (chart.index != null) {
          newComplexCharts.splice(chart.index, 0, chart)
        } else {
          newComplexCharts.unshift(chart)
        }
        newComplexCharts = newComplexCharts.map((chart, index) => ({ ...chart, index }))
        return ctx.dispatch(new SurveysComparisonSetComplexCharts(newComplexCharts))
      }),
    )
  }

  @Action(SurveysComparisonDeleteComplexChart)
  public deleteComplexChart(ctx: StateContext<SurveysComparisonStateModel>) {
    const jobsiteId = this.getJobsiteId()
    if (jobsiteId) {
      return of(ctx.getState()).pipe(
        map(({ complexCharts, editedComplexChart }) => ({
          chartId: editedComplexChart?.chartId,
          chartIndex: editedComplexChart?.index,
          complexCharts,
        })),
        filter(withNonNullableFields),
        switchTap(({ chartId }) => this.userChartsService.deleteChart(jobsiteId, chartId)),
        switchMap(({ complexCharts, chartIndex }) => {
          let newComplexCharts = [...complexCharts]
          newComplexCharts.splice(chartIndex, 1)
          newComplexCharts = newComplexCharts.map((chart, index) => ({ ...chart, index }))
          return ctx.dispatch(new SurveysComparisonSetComplexCharts(newComplexCharts))
        }),
      )
    } else {
      return EMPTY
    }
  }

  @Action(SurveysComparisonSetChartsMarginTop)
  public setChartsMarginTop(
    ctx: StateContext<SurveysComparisonStateModel>,
    { chartsMarginTop }: SurveysComparisonSetChartsMarginTop,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.chartsMarginTop = chartsMarginTop
      }),
    )
  }

  @Action(SurveysComparisonOpenComplexChart)
  public openComplexChart(
    ctx: StateContext<SurveysComparisonStateModel>,
    { editedComplexChart }: SurveysComparisonOpenComplexChart,
  ) {
    let selectedCommonBhType: BoreholeType | null = null
    let selectedCommonParameter: string | null = null
    let commonParameters: string[] = []
    const items = editedComplexChart?.items
    if (items) {
      selectedCommonParameter = items.find(() => true)?.param || null
      selectedCommonBhType = this.surveysComparisonService.getChartCommonBhType(items)
      commonParameters = this.surveysComparisonService.getCommonParameters(
        selectedCommonBhType,
        ctx.getState().labParams,
      )
    }
    ctx.setState(
      produce((draft) => {
        draft.editedComplexChart = editedComplexChart
        draft.commonParameters = commonParameters
        draft.selectedCommonBhType = selectedCommonBhType
        draft.selectedCommonParameter = selectedCommonParameter
        draft.complexChartsOpened = true
        draft.surveyParams =
          editedComplexChart?.items?.map((item) => ({
            id: item.surveyId,
            name: item.surveyName,
            type: item.surveyType,
            availableParams: this.surveysComparisonService.parseAvailableParamByChart(item),
            surveysData: item.rawData,
            labTestsData: item.labRawData,
            correlationData: item.correlationData,
            elevation: item.elevation,
            color: item.color,
          })) || []
      }),
    )
  }

  @Action(SurveysComparisonSetSelectedCommonBhType)
  public setSelectedCommonBhType(
    ctx: StateContext<SurveysComparisonStateModel>,
    { boreholeType }: SurveysComparisonSetSelectedCommonBhType,
  ) {
    return of(ctx.getState()).pipe(
      map(({ labParams }) => this.surveysComparisonService.getCommonParameters(boreholeType, labParams)),
      tap((commonParameters) => {
        ctx.setState(
          produce((draft) => {
            draft.selectedCommonBhType = boreholeType
            draft.commonParameters = commonParameters
          }),
        )
      }),
    )
  }

  @Action(SurveysComparisonSetSelectedCommonParameter)
  public setSelectedCommonParameter(
    ctx: StateContext<SurveysComparisonStateModel>,
    { param }: SurveysComparisonSetSelectedCommonParameter,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.selectedCommonParameter = param
      }),
    )
  }

  @Action(SurveysComparisonCloseComplexChart)
  public closeComplexChart(ctx: StateContext<SurveysComparisonStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.editedComplexChart = null
        draft.commonParameters = null
        draft.selectedCommonBhType = null
        draft.selectedCommonParameter = null
        draft.complexChartsOpened = false
        draft.complexChartSurvey = null
        draft.surveyParams = null
      }),
    )
  }

  @Action(SurveysComparisonFetchSurveyParams)
  public fetchSurveyParams(
    ctx: StateContext<SurveysComparisonStateModel>,
    { survey }: SurveysComparisonFetchSurveyParams,
  ) {
    const surveyId = survey?.id
    if (surveyId) {
      return combineLatest([
        this.soilSurveyService.getSurveyData(surveyId),
        this.soilSurveyService.getLabTestDataBySurveyId(surveyId),
        this.correlationDataService.getCorrelationData(surveyId),
      ]).pipe(
        map(([surveysData, labTestsData, correlationData]) => [
          ...(ctx.getState().surveyParams || []),
          {
            ...survey,
            availableParams: this.surveysComparisonService.parseAvailableParam(
              surveysData,
              survey.type,
              labTestsData,
              correlationData,
            ),
            surveysData,
            labTestsData,
            correlationData,
          },
        ]),
        tap((newSurveyParams) =>
          ctx.setState(
            produce((draft) => {
              draft.surveyParams = newSurveyParams
            }),
          ),
        ),
      )
    } else {
      return EMPTY
    }
  }

  @Action(SurveysComparisonRemoveSurvey)
  public removeSurvey(ctx: StateContext<SurveysComparisonStateModel>, { index }: SurveysComparisonRemoveSurvey) {
    return of(ctx.getState()).pipe(
      tap(({ surveyParams }) => {
        let newSurveyParams: ComplexChartSurveyParams[] | null
        if (surveyParams && index < surveyParams.length) {
          newSurveyParams = [...surveyParams]
          newSurveyParams.splice(index, 1)
        } else {
          newSurveyParams = null
        }

        ctx.setState(
          produce((draft) => {
            draft.surveyParams = newSurveyParams
          }),
        )
      }),
    )
  }

  private getJobsiteId(): string | null {
    return this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId) || null
  }

  private loadChartsData$(charts: ComplexChartDTO[]): Observable<ComplexChart[] | never> {
    if (charts.length) {
      charts.sort((a, b) => (a.chart_index || 0) - (b.chart_index || 0))
      return this.loadUserCharts$(charts)
    } else {
      return EMPTY
    }
  }

  private loadUserCharts$(charts: ComplexChartDTO[]): Observable<ComplexChart[]> {
    const surveyIds: string[] = [
      ...new Set(
        charts
          .map(({ data }) => data)
          .filter(nonNullable)
          .map((data) => data.map(({ survey_id }) => survey_id))
          .reduce((acc, curr) => acc.concat(curr), []),
      ),
    ]
    const surveysData$: Observable<SurveyData[][]> = forkJoin(
      surveyIds.map((id) => this.soilSurveyService.getSurveyData(id)),
    )
    const labTestData$: Observable<LabTestDataModel[][]> = forkJoin(
      surveyIds.map((id) => this.soilSurveyService.getLabTestDataBySurveyId(id)),
    )
    const correlationData$: Observable<CorrelationDataModel[][]> = forkJoin(
      surveyIds.map((id) => this.correlationDataService.getCorrelationData(id)),
    )
    const soilSurveys$: Observable<SoilSurvey[]> = forkJoin(
      surveyIds.map((id) =>
        this.soilSurveyService.getSoilSurvey(id).pipe(
          catchError((error) => {
            if (error && error.message) {
              Sentry.captureException(error.message)
            } else if (error instanceof Error) {
              Sentry.captureException(error)
            }
            return of(null)
          }),
          filter(nonNullable),
        ),
      ),
    )

    return combineLatest([surveysData$, labTestData$, correlationData$, soilSurveys$]).pipe(
      map(([surveysData, labTestsData, correlationData, surveys]) =>
        this.toChartParamsIndexed(charts, surveysData, labTestsData, correlationData, surveys),
      ),
    )
  }

  private toChartParamsIndexed = (
    charts: ComplexChartDTO[],
    surveysData: SurveyData[][],
    labTestsData: LabTestDataModel[][],
    correlationsData: CorrelationDataModel[][],
    surveys: (SoilSurvey | null)[],
  ): ComplexChart[] =>
    charts.map((chart: ComplexChartDTO, index: number) => ({
      chartId: chart.chart_id,
      chartName: chart.chart_name || '',
      index,
      items:
        chart.data
          ?.map((data) => this.toChartParams(data, surveysData, labTestsData, correlationsData, surveys))
          ?.filter(nonNullable) || [],
    }))

  private toChartParams(
    data: ComplexChartDTOData,
    surveysData: SurveyData[][],
    labTestsData: LabTestDataModel[][],
    correlationsData: CorrelationDataModel[][],
    surveys: (SoilSurvey | null)[],
  ): ComplexChartItem | null {
    const surveyData = surveysData.find((el) => el[0]?.soilsurvey_id === data.survey_id)
    const labTestData = labTestsData?.find((el) => el[0]?.soilsurvey_id === data.survey_id)
    const correlationData = correlationsData?.find((el) => el[0]?.soilSurveyId === data.survey_id)
    const survey = surveys.find((el) => el?.id === data.survey_id)

    if (survey) {
      const { depths, paramData } = this.surveysComparisonService.getParamData(
        data.param,
        survey.type || null,
        labTestData,
        correlationData,
        surveyData,
      )

      return {
        surveyId: survey.id || '',
        surveyName: survey.name || '',
        surveyType: survey.type || '',
        rawData: surveyData || [],
        labRawData: labTestData || [],
        correlationData: correlationData || [],
        elevation: survey.elevation || 0,
        depths: depths || [],
        paramData: paramData || [],
        param: data.param,
        color: data.color,
      }
    } else {
      return null
    }
  }
}
