import { MapService } from '../../shared/services'
import olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { Zone } from '../../shared/models'
import Style from 'ol/style/Style'
import { Fill } from 'ol/style'
import { Feature } from 'ol'
import Geometry from 'ol/geom/Geometry'
import { Injector } from '@angular/core'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { FilterStateSelectors } from '../../store/filter/filter.selectors'
import { MapStateSelectors } from '../../store/map/map.selectors'

export class ZonesLayer {
  zonesSource: VectorSource
  zonesLayer: VectorLayer

  private mapService: MapService
  private store: Store

  constructor(private map: olMap, private injector: Injector) {
    this.mapService = this.injector.get(MapService)
    this.store = this.injector.get(Store)
    this.zonesSource = new VectorSource({})
    this.zonesLayer = new VectorLayer({ source: this.zonesSource })
    this.zonesLayer.set('name', 'ZONE')
    this.map.addLayer(this.zonesLayer)
  }

  drawZones() {
    this.mapService.drawZones(
      this.zonesSource,
      this.store.selectSnapshot(MapStateSelectors.slices.zones) || [],
      this.store.selectSnapshot(FilterStateSelectors.slices.preventCuttingsDrawing),
      this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite),
      this.store.selectSnapshot(AppStateSelectors.slices.selectedZone),
    )
  }

  onHovering(zone: Zone) {
    if (zone.id) {
      const feature = this.zonesSource.getFeatureById(zone.id)
      const styles = feature?.getStyle()
      let hoverStyles: Style[]
      if (styles && (styles as Style[]).length) {
        ;(styles as Style[]).push(
          new Style({
            fill: new Fill({
              color: [255, 255, 255, 0.5],
            }),
          }),
        )
        feature.setStyle(styles)
      } else if (styles) {
        hoverStyles = [
          styles as Style,
          new Style({
            fill: new Fill({
              color: [255, 255, 255, 0.5],
            }),
          }),
        ]
        feature.setStyle(hoverStyles)
      }
    }
  }

  onUnhovering(zone: Zone) {
    if (zone.id) {
      const feature: Feature<Geometry> = this.zonesSource.getFeatureById(zone.id)
      const styles = feature?.getStyle()
      if (styles && (styles as Style[]).length) {
        ;(styles as Style[]).pop()
        feature.setStyle(styles)
      }
    }
  }
}
