import { from as fromPromise, Observable, of, throwError as observableThrowError, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { catchError, concatMap, filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import * as Sentry from '@sentry/angular'
import { KeycloakToken } from '../models'

@Injectable()
export class KeycloakInterceptor implements HttpInterceptor {
  private isRefreshingToken = false

  private addToken<T>(req: HttpRequest<T>, token: string): HttpRequest<T> {
    return req.url.includes('google') ? req : req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
  }

  private handle401Error<T>(req: HttpRequest<T>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true
      return fromPromise(this.keycloakService.getToken()).pipe(
        switchMap((newToken) => {
          if (newToken?.raw) {
            return next.handle(this.addToken(req, newToken.raw))
          } else {
            this.keycloakService.logout()
            return observableThrowError(null)
          }
        }),
        finalize(() => (this.isRefreshingToken = false)),
      )
    } else {
      console.warn(
        '%c%s',
        'background: orange; color: #FFFFFF; font-weight: bold;',
        'LOST REQ : ' + JSON.stringify({ req }, null, 2),
      )
      return of(null)
    }
  }

  constructor(private keycloakService: KeycloakService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return fromPromise(this.keycloakService.getToken()).pipe(
      filter(({ parsed }) => !!parsed),
      map(({ parsed, raw }) => ({ parsed: parsed as KeycloakToken, raw })),
      tap(({ parsed }) => this.setSentryUser(parsed)),
      map(({ raw: token }: { parsed: KeycloakToken; raw: string }) => this.addToken(req, token)),
      concatMap((request) => next.handle(request)),
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          return this.handle401Error(req, next)
        } else {
          return throwError(() => err)
        }
      }),
    )
  }

  private setSentryUser(token: KeycloakToken) {
    if (token) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: token.sub,
          username: `${token.name || token.preferred_username}`,
          companyName: token.company,
          lang: token.lang,
          timeZone: token.timeZone,
          env: token.iss?.split('/').pop(),
          applications: token?.apps,
        })
      })
    }
  }
}
