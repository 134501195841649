<div fxLayout="row" class="table-container">
  <div class="table" fxFlex="50" [class.hidden]="hideTable">
    <hot-table
      licenseKey="non-commercial-and-evaluation"
      [afterChange]="handleCellChanges"
      [hotId]="table"
      [settings]="tableSettings$ | async"
    ></hot-table>
  </div>
  <div fxFlex="45px" fxLayout="column" fxLayoutGap="50px">
    <button mat-icon-button color="warn" (click)="toggleHideTable()">
      <mat-icon *ngIf="!hideTable" [matTooltip]="'GENERAL.HIDE_TABLE' | translate">arrow_left</mat-icon>
      <mat-icon *ngIf="hideTable" [matTooltip]="'GENERAL.SHOW_TABLE' | translate">arrow_right</mat-icon>
    </button>
    <button
      *ngIf="selectedSurvey?.type?.includes('CPT')"
      mat-mini-fab
      [matTooltip]="'SURVEY.SBT_RESULTS' | translate"
      [color]="showSBT ? 'warn' : 'gray'"
      (click)="toggleShowSBT()"
    >
      <mat-icon> show_chart</mat-icon>
    </button>
    <button
      mat-icon-button
      color="warn"
      *ngIf="showSBT"
      [matTooltip]="'SURVEY.SBT_PARAMS' | translate"
      matTooltipClass="line-break-tooltip"
      (click)="openSBTnParams()"
    >
      <mat-icon>settings</mat-icon>
    </button>
    <button
      mat-icon-button
      color="warn"
      *ngIf="showSBT"
      [matTooltip]="'SURVEY.SBT_LEGEND' | translate"
      (click)="showLegend = !showLegend"
    >
      <mat-icon>view_list</mat-icon>
    </button>
    <soillib-sbtn-legend-draggable *ngIf="showLegend" (closeEvent)="showLegend = false"></soillib-sbtn-legend-draggable>
  </div>

  <div fxFlex="calc(50% - 45px)" fxLayout="row" *ngIf="!showSBT">
    <ssd-soillib-lib-stratigraphy-rendering-chart
      fxFlex="30"
      [stratigraphyData]="stratigraphyData"
      [chartFrom]="chartFrom"
      [depthMax]="depthMax"
      [tickInterval]="5"
      [unifiedMarginTop]="unifiedMarginTop"
      [elevationMode]="elevationMode"
      [datumDisplayName]="(datumDisplayName$ | async) || 'NGF'"
      [chartIndexStr]="'StratigraphySurvey'"
    ></ssd-soillib-lib-stratigraphy-rendering-chart>
    <soillib-surveys-edition-chart
      fxFlex
      [selectedColumns]="(selectedColumns$ | async) || []"
      [tableSourceData]="tableSourceData"
      [depthMax]="depthMax"
      [elevation]="selectedSurvey?.elevation"
      [elevationMode]="elevationMode"
      (marginTopChanged)="onChartMarginTopChanged($event)"
    ></soillib-surveys-edition-chart>
  </div>
  <soillib-sbtn-results-charts
    fxFlex="calc(50% - 45px)"
    *ngIf="showSBT"
    [sbtNResults]="sbtNResults"
    [reflowChartsSignal]="reflowChartsSignal"
    [elevation]="selectedSurvey?.elevation"
    [elevationMode]="elevationMode"
  ></soillib-sbtn-results-charts>
</div>

<div class="button-container" fxLayout="row" fxLayoutAlign="start center" [class.hidden]="hideTable">
  <button mat-raised-button color="primary" (click)="addRow()">{{ 'GENERAL.ADD_ROW' | translate }}</button>
  <button mat-raised-button color="primary" (click)="addRows()">{{ 'GENERAL.ADD_ROWS' | translate }}</button>
  <button mat-raised-button color="primary" (click)="selectColumns()">
    {{ 'GENERAL.SELECT_COLUMNS' | translate }}
  </button>
  <button mat-mini-fab color="warn" [disabled]="isValidating" (click)="saveTableData()">
    <mat-icon>save</mat-icon>
  </button>
  <div style="cursor: pointer" [matTooltip]="'SURVEY.AVAILABLE_SOIL_TYPES' | translate" (click)="openHelpDoc()">
    <mat-icon>help_center</mat-icon>
  </div>
  <div class="alert-message" *ngIf="isValidating">
    {{ 'TABLE.VALIDATE_TABLE_CELLS' | translate }}
  </div>
</div>
