import { Action, State, StateContext } from '@ngxs/store'
import { Injectable } from '@angular/core'
import produce from 'immer'
import {
  MapClearSelectedLocation,
  MapClearSurveysOnMap,
  MapIsAddingJobsiteExtent,
  MapIsAddingZone,
  MapIsDrawingPolygon,
  MapIsModifyingJobsiteCoords,
  MapIsModifyingJobsiteExtent,
  MapSetSelectedLocation,
  MapSetSurveys,
  MapSetSurveysOnMap,
  MapSetVisibleEntities,
  MapSetZones,
  MapUpdateSurvey,
  MapUpdateSurveyLocation,
  MapUpdateZoneExtent,
} from './map.actions'
import { SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Jobsite, LatLonLocation, VisibleEntities, Zone } from '../../shared/models'
import { of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { cloneDeep } from 'lodash'

export interface MapStateModel {
  selectedLocation: LatLonLocation | null
  visibleEntities: VisibleEntities
  jobsites: Jobsite[] | null
  surveys: SoilSurvey[] | null
  surveysEntities: Record<string, SoilSurvey> | null
  surveysToDraw: SoilSurvey[] | null
  zones: Zone[] | null
  isAddingZone: boolean
  isAddingJobsiteExtent: boolean
  isJobsiteExtentModifying: boolean
  isJobsiteCoordsModifying: boolean
  isDrawingPolygon: boolean
}

@State<MapStateModel>({
  name: 'map',
  defaults: {
    selectedLocation: null,
    visibleEntities: {
      surveys: 0,
      zones: 0,
      jobsites: 0,
    },
    jobsites: null,
    surveys: null,
    surveysEntities: null,
    surveysToDraw: null,
    zones: null,
    isAddingZone: false,
    isAddingJobsiteExtent: false,
    isJobsiteExtentModifying: false,
    isJobsiteCoordsModifying: false,
    isDrawingPolygon: false,
  },
})
@Injectable()
export class MapState {
  @Action(MapSetSelectedLocation)
  public setMapSelectedLocation(ctx: StateContext<MapStateModel>, { location }: MapSetSelectedLocation) {
    ctx.setState(
      produce((draft) => {
        draft.selectedLocation = location
      }),
    )
  }

  @Action(MapClearSelectedLocation)
  public clearMapSelectedLocation(ctx: StateContext<MapStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.selectedLocation = null
      }),
    )
  }

  @Action(MapSetVisibleEntities)
  public setVisibleEntites(
    ctx: StateContext<MapStateModel>,
    { payload: { jobsites, surveys, zones } }: MapSetVisibleEntities,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.visibleEntities = {
          jobsites: jobsites?.length || 0,
          surveys: surveys?.length || 0,
          zones: zones?.length || 0,
        }
        draft.jobsites = jobsites
        draft.surveys = surveys
        draft.zones = zones
      }),
    )
  }

  @Action(MapSetSurveys)
  public setSurveys(ctx: StateContext<MapStateModel>, { surveys }: MapSetSurveys) {
    ctx.setState(
      produce((draft) => {
        draft.surveys = surveys
      }),
    )
  }

  @Action(MapUpdateSurveyLocation)
  public updateSurveyLocation(
    ctx: StateContext<MapStateModel>,
    { payload: { surveyId, location } }: MapUpdateSurveyLocation,
  ) {
    return of(ctx.getState()).pipe(
      map(({ surveys }) =>
        surveys?.map((survey) => {
          if (survey.id === surveyId) {
            const copy = cloneDeep(survey)
            copy.lon_coord = location[0]
            copy.lat_coord = location[1]
            return copy
          } else {
            return survey
          }
        }),
      ),
      tap((surveys) => {
        ctx.setState(
          produce((draft) => {
            draft.surveys = surveys || null
          }),
        )
      }),
    )
  }

  @Action(MapUpdateSurvey)
  public updateSurvey(ctx: StateContext<MapStateModel>, { updatedSurvey }: MapUpdateSurvey) {
    function cloneSoilSurvey(target: SoilSurvey, source: SoilSurvey) {
      const updatedItem = cloneDeep(target)
      Object.keys(source).forEach((key) => {
        if (key !== 'id' && key !== 'jobsite_id' && updatedItem[key] !== undefined && source[key] !== undefined) {
          updatedItem[key] = source[key]
        }
      })
      return updatedItem
    }

    return of(ctx.getState()).pipe(
      map(({ surveys, surveysEntities }) => {
        const updatedSurveyId = updatedSurvey.id
        const updatedSurveys = surveys?.map((item) => {
          if (item.id === updatedSurveyId) {
            return cloneSoilSurvey(item, updatedSurvey)
          } else {
            return item
          }
        })

        const updatedSurveysEntities = surveysEntities ? cloneDeep(surveysEntities) : {}
        if (updatedSurveyId) {
          if (updatedSurveysEntities[updatedSurveyId]) {
            updatedSurveysEntities[updatedSurveyId] = cloneSoilSurvey(
              updatedSurveysEntities[updatedSurveyId],
              updatedSurvey,
            )
          } else {
            updatedSurveysEntities[updatedSurveyId] = updatedSurvey
          }
        }

        return {
          surveys: updatedSurveys,
          surveysEntities: updatedSurveysEntities,
        }
      }),
      tap(({ surveys, surveysEntities }) => {
        ctx.setState(
          produce((draft) => {
            draft.surveys = surveys || null
            draft.surveysEntities = surveysEntities || null
          }),
        )
      }),
    )
  }

  @Action(MapSetSurveysOnMap)
  public setSurveysEntities(ctx: StateContext<MapStateModel>, { surveysArray }: MapSetSurveysOnMap) {
    const currentSurveysEntities = ctx.getState().surveysEntities
    let surveysEntities: Record<string, SoilSurvey>
    let surveysToDraw: SoilSurvey[]
    if (!currentSurveysEntities) {
      surveysToDraw = surveysArray
      surveysEntities = this.arrayToObject(surveysArray, 'id')
    } else {
      surveysToDraw = []
      surveysEntities = cloneDeep(currentSurveysEntities)
      surveysArray.forEach((survey) => {
        if (survey.id && !surveysEntities[survey.id]) {
          surveysEntities[survey.id] = survey
          if (!surveysToDraw) {
            surveysToDraw = []
          }
          surveysToDraw.push(survey)
        }
      })
    }
    ctx.setState(
      produce((draft) => {
        draft.surveysEntities = surveysEntities
        draft.surveysToDraw = surveysToDraw
      }),
    )
  }

  @Action(MapClearSurveysOnMap)
  public clearSurveysEntities(ctx: StateContext<MapStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.surveysEntities = null
        draft.surveysToDraw = null
      }),
    )
  }

  @Action(MapSetZones)
  public setZones(ctx: StateContext<MapStateModel>, { zones }: MapSetZones) {
    ctx.setState(
      produce((draft) => {
        draft.zones = zones
      }),
    )
  }

  @Action(MapUpdateZoneExtent)
  public updateZoneExtent(ctx: StateContext<MapStateModel>, { payload: { zoneId, extent } }: MapUpdateZoneExtent) {
    return of(ctx.getState()).pipe(
      map(({ zones }) =>
        zones?.map((zone) => {
          if (zone.id === zoneId) {
            const copy = cloneDeep(zone)
            copy.extent = extent
            return copy
          } else {
            return zone
          }
        }),
      ),
      tap((zones) => {
        ctx.setState(
          produce((draft) => {
            draft.zones = zones || null
          }),
        )
      }),
    )
  }

  @Action(MapIsAddingZone)
  public isAddingZone(ctx: StateContext<MapStateModel>, { isAddingZone }: MapIsAddingZone) {
    ctx.setState(
      produce((draft) => {
        draft.isAddingZone = isAddingZone
      }),
    )
  }

  @Action(MapIsAddingJobsiteExtent)
  public isAddingJobsiteExtent(ctx: StateContext<MapStateModel>, { isAddingJobsiteExtent }: MapIsAddingJobsiteExtent) {
    ctx.setState(
      produce((draft) => {
        draft.isAddingJobsiteExtent = isAddingJobsiteExtent
      }),
    )
  }

  @Action(MapIsModifyingJobsiteExtent)
  public isModifyingJobsiteExtent(
    ctx: StateContext<MapStateModel>,
    { isJobsiteExtentModifying }: MapIsModifyingJobsiteExtent,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.isJobsiteExtentModifying = isJobsiteExtentModifying
      }),
    )
  }

  @Action(MapIsModifyingJobsiteCoords)
  public isModifyingJobsiteCoords(
    ctx: StateContext<MapStateModel>,
    { isJobsiteCoordsModifying }: MapIsModifyingJobsiteCoords,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.isJobsiteCoordsModifying = isJobsiteCoordsModifying
      }),
    )
  }

  @Action(MapIsDrawingPolygon)
  public isDrawingPolygon(ctx: StateContext<MapStateModel>, { isDrawingPolygon }: MapIsDrawingPolygon) {
    ctx.setState(
      produce((draft) => {
        draft.isDrawingPolygon = isDrawingPolygon
      }),
    )
  }

  private arrayToObject(array: SoilSurvey[], keyField: string): Record<string, SoilSurvey> {
    const acc: Record<string, SoilSurvey> = {}
    return array.reduce((obj, item) => {
      obj[item[keyField]] = item
      return obj
    }, acc)
  }
}
