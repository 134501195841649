import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MessagesService } from '../../shared/remote-services'
import { Jobsite, JobsiteMessage } from '../../shared/models'
import { Observable, Subscription } from 'rxjs'
import { debounceTime, switchMap, take, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import Swal from 'sweetalert2'
import { Select, Store } from '@ngxs/store'
import { Resource } from '../../store/app/app.state'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-messages',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss'],
})
export class MessagesListComponent implements OnInit, OnDestroy {
  @Select(AppStateSelectors.slices.selectedResource) selectedResource$: Observable<Resource | null>

  @Input() selectedJobsite: Jobsite | null

  public messages: JobsiteMessage[] = []
  public commentsForm: FormGroup<{
    content: FormControl<string | null>
  }>
  private subscription: Subscription = new Subscription()
  private pageState: string | null

  constructor(
    private fb: FormBuilder,
    private messagesService: MessagesService,
    private translateService: TranslateService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.commentsForm = this.fb.group({
      content: [{ value: '', disabled: !this.selectedJobsite }, Validators.required],
    })

    this.subscription.add(
      this.selectedResource$
        .pipe(
          tap(() => (this.pageState = null)),
          switchMap((selectedResource) =>
            this.messagesService.getLastMessages(this.selectedJobsite?.id ?? null, null, selectedResource),
          ),
        )
        .subscribe((data) => {
          this.messages = data?.entities ?? []
          this.pageState = data?.pageState ?? null
        }),
    )
  }

  saveComments() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    const { content } = this.commentsForm.value
    if (content != null) {
      this.selectedResource$
        .pipe(
          take(1),
          switchMap((selectedResource) =>
            this.messagesService.saveComment(
              this.selectedJobsite?.id ?? null,
              {
                content,
              },
              selectedResource,
            ),
          ),
        )
        .subscribe((savedMessage) => {
          if (savedMessage) {
            this.messages.unshift(savedMessage)
          }
        })
    }
    this.commentsForm.reset()
  }

  onScroll(element) {
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if (this.pageState !== null) {
        this.selectedResource$
          .pipe(
            take(1),
            switchMap((selectedResource) =>
              this.messagesService.getLastMessages(this.selectedJobsite?.id ?? null, this.pageState, selectedResource),
            ),
            debounceTime(500),
          )
          .subscribe((data) => {
            if (data?.entities) {
              this.messages = this.messages.concat(data.entities)
            }
            this.pageState = data?.pageState ?? null
          })
      }
    }
  }

  ngOnDestroy() {
    this.commentsForm.reset()
    this.subscription.unsubscribe()
  }
}
