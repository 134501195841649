import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  combineLatest,
  EMPTY,
  from,
  iif,
  map,
  noop,
  Observable,
  pairwise,
  startWith,
  Subscription,
  withLatestFrom,
} from 'rxjs'
import { switchMap, tap, take, filter, distinctUntilChanged } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core'
import { ComplexChart, ComplexChartDTO, ComplexChartItem } from '../../shared/models'
import { TableAutoSaveService } from '../../shared/services'
import { KeycloakService } from '../../keycloak/keycloak.service'
import { BoreholeType, getBoreholeTypeName } from '@sde-ild/ssd-soillib-lib'
import { Select, Store } from '@ngxs/store'
import { SurveysComparisonStateSelectors } from '../store/surveys-comparison.selectors'
import {
  SurveysComparisonOpenComplexChart,
  SurveysComparisonCloseComplexChart,
  SurveysComparisonDeleteComplexChart,
  SurveysComparisonDrawComplexChart,
  SurveysComparisonSaveComplexCharts,
  SurveysComparisonFetchCharts,
  SurveysComparisonSetComplexChartsDTO,
  SurveysComparisonSetSelectedCommonBhType,
  SurveysComparisonSetSelectedCommonParameter,
  SurveysComparisonRemoveSurvey,
  SurveysComparisonClearComplexChartSurvey,
  SurveysComparisonFetchSurveyParams,
} from '../store/surveys-comparison.actions'
import { switchTap } from '../../shared/utils/observables.utils'
import { nonNullable, withNonNullableFields } from '../../shared/utils'
import { ComplexChartSurvey, ComplexChartSurveyParams } from '../store/surveys-comparison.state'
import { SurveysComparisonService } from '../service/surveys-comparison.service'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-surveys-comparison-complex-charts',
  templateUrl: './surveys-comparison-complex-charts.component.html',
  styleUrls: ['./surveys-comparison-complex-charts.component.scss'],
  animations: [
    trigger('slideInOutFromLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-left': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-left': '45vw' })),
      ]),
      transition('true => false', [
        style({ 'margin-left': '45vw' }),
        animate('0.5s ease-in-out', style({ 'margin-left': '0' })),
      ]),
    ]),
  ],
})
export class SurveysComparisonComplexChartsComponent implements OnInit, OnDestroy {
  @Input() jobsiteId: string | null | undefined

  @Select(SurveysComparisonStateSelectors.slices.complexChartsOpened) complexChartOpened$: Observable<boolean>
  @Select(SurveysComparisonStateSelectors.slices.editedComplexChart)
  editedComplexChart$: Observable<ComplexChart | null>
  @Select(SurveysComparisonStateSelectors.slices.commonParameters)
  commonParameters$: Observable<string[] | null>
  @Select(SurveysComparisonStateSelectors.slices.selectedCommonBhType)
  selectedCommonBhType$: Observable<string | null>
  @Select(SurveysComparisonStateSelectors.slices.selectedCommonParameter)
  selectedCommonParameter$: Observable<string | null>
  @Select(SurveysComparisonStateSelectors.slices.allBoreholeTypes)
  allBoreholeTypes$: Observable<string[]>
  @Select(SurveysComparisonStateSelectors.slices.complexChartSurvey)
  complexChartSurvey$: Observable<ComplexChartSurvey | null>
  @Select(SurveysComparisonStateSelectors.availableParams)
  availableParams$: Observable<string[][] | null>
  @Select(SurveysComparisonStateSelectors.slices.surveyParams)
  surveyParams$: Observable<ComplexChartSurveyParams[] | null>
  private subscription: Subscription = new Subscription()
  private formSubscriptions: Subscription[] = []

  complexChartForm: FormGroup<{
    title: FormControl<string | null>
    surveyNames: FormArray<FormControl<string | null>>
    surveyTypes: FormArray<FormControl<string | null>>
    selectedParams: FormArray<FormControl<string | null>>
    selectedColors: FormArray<FormControl<string | null>>
  }>

  commonParametersFormControl: FormControl<string | null>
  commonBhTypeFormControl: FormControl<string | null>

  getParamName = this.surveysComparisonService.getParamName

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private tableAutoSaveService: TableAutoSaveService,
    private keycloakService: KeycloakService,
    private surveysComparisonService: SurveysComparisonService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.createForms()
    this.store
      .dispatch(SurveysComparisonFetchCharts)
      .pipe(
        withLatestFrom(this.store.select(SurveysComparisonStateSelectors.slices.complexCharts)),
        switchMap(([_, charts]) =>
          iif(
            () => !charts?.length && !!this.jobsiteId,
            this.tableAutoSaveService
              .getAutoSaveTableData$<ComplexChartDTO[]>(this.jobsiteId || '', this.keycloakService.getCurrentUser().id)
              .pipe(
                switchMap((localCharts: ComplexChartDTO[] | null) =>
                  this.store.dispatch(new SurveysComparisonSetComplexChartsDTO(localCharts)),
                ),
              ),
            EMPTY,
          ),
        ),
      )
      .subscribe()

    this.subscription.add(
      this.complexChartSurvey$
        .pipe(
          filter(nonNullable),
          withLatestFrom(this.selectedCommonBhType$),
          switchMap(([complexChartSurvey, selectedCommonBhType]) => {
            if (
              selectedCommonBhType &&
              (!complexChartSurvey.type || !complexChartSurvey.type.includes(selectedCommonBhType))
            ) {
              return from(
                Swal.fire({
                  icon: 'warning',
                  text: this.translateService.instant('ALERT.BH_TYPE_NOT_AUTHORIZED', {
                    type: this.showBoreholeTypeName(selectedCommonBhType),
                  }),
                }),
              )
            } else {
              return this.store.dispatch(new SurveysComparisonFetchSurveyParams(complexChartSurvey))
            }
          }),
          switchMap(() => this.store.dispatch(SurveysComparisonClearComplexChartSurvey)),
        )
        .subscribe(),
    )

    this.subscription.add(
      this.editedComplexChart$
        .pipe(withLatestFrom(this.selectedCommonBhType$, this.selectedCommonParameter$))
        .subscribe(([complexChart, selectedCommonBhType, selectedCommonParameter]) => {
          this.createForms()
          this.commonParametersFormControl.setValue(selectedCommonParameter)
          this.commonBhTypeFormControl.setValue(selectedCommonBhType)
          this.complexChartForm.patchValue({
            title: complexChart?.chartName || '',
          })
        }),
    )

    this.subscription.add(
      this.selectedCommonParameter$
        .pipe(
          withLatestFrom(this.availableParams$),
          filter(withNonNullableFields),
          tap(([para, availableParams]) => {
            this.complexChartForm.controls.selectedParams?.controls?.forEach((control, index) => {
              const value = availableParams[index].includes(para) ? para : ''
              control.setValue(value)
            })
            this.complexChartForm.markAllAsTouched()
          }),
        )
        .subscribe(),
    )

    this.subscription.add(
      this.surveyParams$
        .pipe(
          startWith(null),
          pairwise<ComplexChartSurveyParams[] | null>(),
          withLatestFrom(this.selectedCommonBhType$, this.selectedCommonParameter$),
          tap(([[oldSurveyParams, surveyParams], selectedCommonBhType, selectedCommonParameter]) => {
            const controls = this.complexChartForm.controls
            const colors = oldSurveyParams ? this.readColors(oldSurveyParams, controls.selectedColors) : null
            controls.surveyNames.clear()
            controls.surveyTypes.clear()
            controls.selectedParams.clear()
            controls.selectedColors.clear()

            if (surveyParams) {
              for (let i = 0; i < surveyParams.length; i++) {
                const surveyParam: ComplexChartSurveyParams = surveyParams[i]
                if (
                  surveyParam.surveysData.length > 0 ||
                  surveyParam.labTestsData.length > 0 ||
                  surveyParam.correlationData.length > 0
                ) {
                  if (!selectedCommonBhType) {
                    this.commonBhTypeFormControl.setValue(surveyParam.type?.split(',')?.find(() => true) ?? null)
                  }
                  controls.surveyNames.push(this.fb.control(surveyParam.name, Validators.required))
                  controls.surveyTypes.push(this.fb.control(surveyParam.type, Validators.required))
                  controls.selectedParams.push(
                    this.fb.control(
                      {
                        value:
                          selectedCommonParameter &&
                          surveyParam.availableParams.map((p) => p.parameter).includes(selectedCommonParameter)
                            ? selectedCommonParameter
                            : null,
                        disabled: true,
                      },
                      { validators: Validators.required },
                    ),
                  )
                  controls.selectedColors.push(
                    this.fb.control(
                      colors?.get(surveyParam.id) || surveyParam.color || this.getRandomColor(),
                      Validators.required,
                    ),
                  )
                } else {
                  Swal.fire({ icon: 'warning', text: this.translateService.instant('ALERT.NODATA') }).then(noop)
                  this.store.dispatch(new SurveysComparisonRemoveSurvey(i))
                }
              }
            }
          }),
        )
        .subscribe(),
    )
  }

  disableDrawOrEditGraph$ = (): Observable<boolean> =>
    combineLatest([this.selectedCommonBhType$, this.selectedCommonParameter$]).pipe(
      map(([selectedCommonBhType, selectedCommonParameter]) => ({
        selectedCommonBhType,
        selectedCommonParameter,
        formTouched: this.complexChartForm?.touched,
        formInvalid: this.complexChartForm?.invalid,
        selectedParams: this.complexChartForm?.getRawValue()?.selectedParams,
        surveyTypes: this.complexChartForm?.getRawValue()?.surveyTypes,
      })),
      map(
        ({ selectedCommonBhType, selectedCommonParameter, formTouched, formInvalid, selectedParams, surveyTypes }) =>
          !formTouched ||
          formInvalid ||
          !selectedCommonBhType ||
          !selectedParams?.every((item) => item && item === selectedCommonParameter) ||
          !surveyTypes?.every((item) => item?.includes(selectedCommonBhType || '')),
      ),
    )

  handleComplexChartsToggle(complexChartsOpened: boolean) {
    this.store.dispatch(
      complexChartsOpened ? new SurveysComparisonOpenComplexChart(null) : SurveysComparisonCloseComplexChart,
    )
  }

  showBoreholeTypeName = (type: string | string[] | null) =>
    type !== null ? getBoreholeTypeName(type, this.translateService) : null

  deleteSurvey(i: number) {
    this.store.dispatch(new SurveysComparisonRemoveSurvey(i))
    this.complexChartForm.markAllAsTouched()
  }

  drawComplexGraph(index?: number) {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    const formValue = this.complexChartForm.getRawValue()
    const surveyParams = this.store.selectSnapshot(SurveysComparisonStateSelectors.slices.surveyParams)

    const chartParams: ComplexChartItem[] = formValue.surveyNames.map((surveyName: string, i: number) => {
      const selectedParam = formValue.selectedParams[i] || ''
      const surveyType = formValue.surveyTypes[i] || ''

      const params = surveyParams?.[i]
      const { depths, paramData } = this.surveysComparisonService.getParamData(
        selectedParam,
        surveyType,
        params?.labTestsData,
        params?.correlationData,
        params?.surveysData,
      )
      return {
        surveyId: params?.id || '',
        surveyName,
        surveyType,
        rawData: params?.surveysData || [],
        labRawData: params?.labTestsData || [],
        correlationData: params?.correlationData || [],
        elevation: params?.elevation ?? 0,
        depths: depths || [],
        paramData: paramData || [],
        param: selectedParam || '',
        color: formValue.selectedColors[i] || '',
      }
    })
    this.store
      .dispatch([
        new SurveysComparisonDrawComplexChart({
          chartName: formValue.title || '',
          index,
          items: chartParams,
        }),
        SurveysComparisonCloseComplexChart,
        SurveysComparisonSaveComplexCharts,
      ])
      .pipe(
        tap(() => {
          this.complexChartForm.reset()
          this.commonBhTypeFormControl.reset()
          this.commonParametersFormControl.reset()
        }),
      )
      .subscribe()
  }

  copyComplexGraph() {
    const complexChartInEdit = this.store.selectSnapshot(SurveysComparisonStateSelectors.slices.editedComplexChart)
    if (complexChartInEdit) {
      this.store
        .dispatch([
          new SurveysComparisonDrawComplexChart({
            index: undefined,
            chartName: `${complexChartInEdit.chartName} (copied)`,
            items: complexChartInEdit.items.map((item: ComplexChartItem) => ({
              ...item,
            })),
          }),
          SurveysComparisonCloseComplexChart,
          SurveysComparisonSaveComplexCharts,
        ])
        .subscribe()
    }
  }

  editComplexGraph() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    this.editedComplexChart$
      .pipe(
        take(1),
        switchTap(() => this.store.dispatch(SurveysComparisonDeleteComplexChart)),
        tap((editedComplexChart: ComplexChart | null) => this.drawComplexGraph(editedComplexChart?.index)),
      )
      .subscribe()
  }

  deleteComplexGraph() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
    Swal.fire({
      title: this.translateService.instant('GENERAL.SURE'),
      text: this.translateService.instant('GENERAL.NO_REVERT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translateService.instant('GENERAL.CANCEL'),
      confirmButtonText: this.translateService.instant('GENERAL.DELETE'),
    }).then((result) => {
      if (result.value) {
        this.store
          .dispatch(SurveysComparisonDeleteComplexChart)
          .pipe(switchMap(() => this.store.dispatch(SurveysComparisonCloseComplexChart)))
          .subscribe()
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.formSubscriptions.forEach((s) => s.unsubscribe())
  }

  private createForms() {
    this.complexChartForm = this.fb.group({
      title: this.fb.control('', Validators.required),
      surveyNames: this.fb.array<FormControl<string | null>>([]),
      surveyTypes: this.fb.array<FormControl<string | null>>([]),
      selectedParams: this.fb.array<FormControl<string | null>>([]),
      selectedColors: this.fb.array<FormControl<string | null>>([]),
    })

    this.formSubscriptions.forEach((s) => s.unsubscribe())
    this.commonParametersFormControl = new FormControl<string | null>(null, Validators.required)
    this.commonBhTypeFormControl = new FormControl<string | null>(null, Validators.required)
    this.formSubscriptions = [
      this.commonParametersFormControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          switchMap((para: string | null) =>
            this.store.dispatch(new SurveysComparisonSetSelectedCommonParameter(para)),
          ),
        )
        .subscribe(),
      this.commonBhTypeFormControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          switchMap((type: string | null) =>
            this.store.dispatch(new SurveysComparisonSetSelectedCommonBhType(type as BoreholeType)),
          ),
        )
        .subscribe(),
    ]
  }

  private readColors(
    oldSurveyParams: ComplexChartSurveyParams[],
    selectedColors: FormArray<FormControl<string | null>>,
  ): Map<string, string> {
    const colors = new Map<string, string>()
    for (let i = 0; i < oldSurveyParams.length; i++) {
      const oldSurveyParam = oldSurveyParams[i]
      const color = selectedColors.at(i)?.value
      if (color) {
        colors.set(oldSurveyParam.id, color)
      }
    }
    return colors
  }

  private getRandomColor = (): string => `#${(((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0')}`
}
