import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { SoilStats } from '@sde-ild/ssd-soillib-lib'

@Injectable()
export class SoilStatsService {
  constructor(private http: HttpClient) {}

  getJobsiteSoilStats(jobsiteId: string): Observable<SoilStats> {
    return this.http.get<SoilStats>(`api/soilstats/jobsite/stats/${jobsiteId}`)
  }
}
