import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { addSeriesAndYaxis, SurveyData } from '@sde-ild/ssd-soillib-lib'
import Handsontable from 'handsontable'
import { getUnifiedMarginTop } from '../../shared/utils/charts.utils'
import {
  dynamicTickIntervalsWhenZooming,
  isNumber,
  isString,
  nonNullable,
  propertyHasChanged,
} from '../../shared/utils'
import { Store } from '@ngxs/store'
import { DataTableAddSurveyData } from '../../store/data-table/data-table.actions'
import { Chart, chart, Options } from 'highcharts'
import { AppStateSelectors } from '../../store/app/app.selectors'

@Component({
  selector: 'soillib-surveys-edition-chart',
  template: `
    <div [id]="chartContainerId"></div>
    <mat-slide-toggle
      color="primary"
      class="smallFont"
      [disabled]="disabled"
      matTooltip="{{ disabled ? disableMessage : '' }}"
      (change)="toggleLogYaxis($event)"
      >logarithmic
    </mat-slide-toggle>
  `,
  styleUrls: ['./surveys-edition-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveysEditionChartComponent implements AfterViewInit, OnChanges {
  chartContainerId = 'chartContainer'

  @Input() selectedColumns: Handsontable.ColumnSettings[]

  @Input() tableSourceData: SurveyData[]

  @Input() depthMax: number

  @Input() elevation: number | null | undefined

  @Input() elevationMode: boolean

  @Output() marginTopChanged = new EventEmitter<number>()

  disabled: boolean
  disableMessage: string

  private chartRef: Chart | null = null

  constructor(private translateService: TranslateService, private store: Store) {
    this.disableMessage = 'Logarithmic does not support series containing zero or negative values'
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      propertyHasChanged(changes, 'tableSourceData') ||
      propertyHasChanged(changes, 'selectedColumns') ||
      propertyHasChanged(changes, 'elevationMode')
    ) {
      this.drawChart()
    }
  }

  ngAfterViewInit(): void {
    if (this.chartRef === null) {
      this.drawChart()
    }
  }

  private drawChart() {
    const ele = document.getElementById(this.chartContainerId)
    if (ele) {
      if (this.chartRef) {
        this.chartRef.destroy()
        this.chartRef = null
      }

      const chartSeriesData: Record<string, [number, number][]> = this.readChartSeriesData()
      this.store.dispatch(new DataTableAddSurveyData(chartSeriesData))
      const reversed: boolean =
        !!this.depthMax && Object.keys(chartSeriesData).length > 0 && !this.activeElevationMode()

      const options: Options = {
        chart: {
          zoomType: 'xy',
          inverted: true,
          marginBottom: 70,
        },
        title: {
          text: this.translateService.instant('GENERAL.CHART_TITLE'),
        },
        boost: {
          useGPUTranslations: true,
        },
        xAxis: {
          title: {
            text:
              reversed || !this.activeElevationMode()
                ? this.translateService.instant('GENERAL.DEPTH')
                : this.translateService.instant('SURVEY.ELEVATION', {
                    name: this.store.selectSnapshot(AppStateSelectors.datumDisplayName),
                  }),
          },
          reversed: reversed || !this.activeElevationMode(),
          tickInterval: 5,
          events: {
            setExtremes: dynamicTickIntervalsWhenZooming(5),
          },
          gridLineWidth: 1,
          showEmpty: false,
          crosshair: true,
          endOnTick: true,
          showLastLabel: true,
          startOnTick: true,
          min: reversed ? 0 : undefined,
          max: reversed ? this.depthMax : undefined,
        },
        yAxis: [],
        plotOptions: {
          series: {
            connectNulls: true,
          },
        },
        exporting: {
          sourceHeight: ele.clientHeight,
          sourceWidth: ele.clientWidth,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadPDF'],
            },
          },
        },
        tooltip: {
          formatter() {
            return `<div>${this.x}</div><br><span style="color: ${this.color}">${this.series.name}</span>: <b> ${this.y} </b>`
          },
        },
        legend: {
          enabled: true,
          verticalAlign: 'top',
          floating: false,
        },
        credits: {
          enabled: false,
        },
      }
      const chartRef = chart(this.chartContainerId, options)

      for (const para of Object.keys(chartSeriesData)) {
        addSeriesAndYaxis(chartRef, para, chartSeriesData[para], undefined, undefined)
      }

      this.disabled = this.isSeriesLogarithmic(chartRef)

      const marginTop = getUnifiedMarginTop(chartRef)
      this.marginTopChanged.emit(marginTop)
      chartRef.update(
        {
          chart: {
            marginTop,
          },
        },
        false,
      )
      this.chartRef = chartRef
    }
  }

  private readChartSeriesData(): Record<string, [number, number][]> {
    const chartSeriesData: Record<string, [number, number][]> = {}
    this.selectedColumns.forEach(({ data }) => {
      if (
        isString(data) &&
        ![
          'depth',
          'geo_soil',
          'calculated_soil',
          'geotechnical_engineer_description',
          'calculated_compactness',
        ].includes(data)
      ) {
        chartSeriesData[data] = this.tableSourceData
          .map((item) => {
            const itemValue = item[data]
            let out: [number, number] | null = null
            if (isNumber(itemValue)) {
              if (this.activeElevationMode()) {
                out = [(this.elevation || 0) - (item.depth || 0), +itemValue]
              } else if (item.depth != null) {
                out = [item.depth, +itemValue]
              }
            }
            return out
          })
          .filter(nonNullable)
      }
    })
    return chartSeriesData
  }

  private activeElevationMode() {
    return this.elevationMode && this.elevation != null
  }

  private isSeriesLogarithmic(chartRef: Chart) {
    let invalidSeries = false
    chartRef.series.forEach(({ yData }: any) => {
      const invalidValues = yData.filter((d) => d != null && d <= 0)
      if (invalidValues.length > 0) {
        invalidSeries = true
        return
      }
    })
    return invalidSeries
  }

  toggleLogYaxis(event) {
    if (this.chartRef) {
      if (event.checked) {
        this.chartRef.yAxis.forEach((y) => {
          y.update({
            type: 'logarithmic',
          })
        })
      } else {
        this.chartRef.yAxis.forEach((y) => {
          y.update({
            type: 'linear',
          })
        })
      }
    }
  }
}
