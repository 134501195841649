import { Coordinate } from 'ol/coordinate'
import { Extent } from 'ol/extent'
import Polygon from 'ol/geom/Polygon'
import { fromLonLat } from 'ol/proj'
import { parseExtentString } from './parseExtentString'

export function isPolygonOutsideJobsite(polygonCoords, jobsiteExtent: string | null | undefined) {
  if (jobsiteExtent) {
    const extentString = parseExtentString(jobsiteExtent)
    if (!extentString) {
      return true
    }
    const extentGeometry = new Polygon([extentString])
    for (const pointCoord of polygonCoords) {
      if (!extentGeometry.intersectsCoordinate(pointCoord)) {
        return true
      }
    }
  }
  return false
}

export function isPointInsideJobsite(
  coord: Coordinate,
  jobsiteExtent: string | null | undefined,
  isLonLatPoint = false,
) {
  if (jobsiteExtent) {
    const extentString = parseExtentString(jobsiteExtent)
    if (!extentString) {
      return true
    }
    const extentGeometry = new Polygon([extentString])
    coord = isLonLatPoint ? fromLonLat(coord) : coord
    return extentGeometry.intersectsCoordinate(coord)
  }
  return true
}

export function adjustWGS84Extent(input: Extent): Extent {
  // [minx, miny, maxx, maxy]
  return [
    limitValue(-180, 180, input[0]),
    limitValue(-90, 90, input[1]),
    limitValue(-180, 180, input[2]),
    limitValue(-90, 90, input[3]),
  ] as Extent
}

function limitValue(min: number, max: number, value: number) {
  if (value <= min) {
    return min + 1
  } else if (value >= max) {
    return max - 1
  } else {
    return value
  }
}
