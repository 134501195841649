import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'soillib-import-select-files',
  templateUrl: './import-select-files.component.html',
  styleUrls: ['./import-select-files.component.scss'],
})
export class ImportSelectFilesComponent implements OnInit {
  @Input()
  isAGS: boolean

  @Output()
  filesSelectedEvent = new EventEmitter<File[]>()

  @Output()
  delimiterSelectedEvent = new EventEmitter<string>()

  selectedFileNames: string[] | undefined

  fileSelectOption: 'files' | 'directory'

  delimiters = [';', ',']

  selectedDelimiter: string

  ngOnInit(): void {
    this.fileSelectOption = 'files'
    this.selectedDelimiter = ';'
  }

  getFileType() {
    return this.isAGS ? '.ags' : '.csv,.txt'
  }

  handleFilesSelected(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement
    const arrayLike = target.files
    let files = arrayLike ? Array.from(arrayLike) : undefined
    files = files?.filter((file: File) => {
      if (this.isAGS) {
        return !!file.name.match(/.ags$/i)
      } else {
        return !!file.name.match(/(.txt|.csv)$/i)
      }
    })
    this.selectedFileNames = files?.map((item) => item.name)
    this.filesSelectedEvent.emit(files)
  }

  onDelimiterSelected(event) {
    this.delimiterSelectedEvent.emit(event.value)
  }
}
