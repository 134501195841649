import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ComplexChart } from '../../../shared/models'
import { getChartTypeByPara, SoilTypeUtilsService, StratigraphyDataModel } from '@sde-ild/ssd-soillib-lib'
import { SeriesOptionsType, Chart, Options, chart } from 'highcharts'
import { getUnifiedMarginTop } from '../../../shared/utils/charts.utils'
import { Select, Store } from '@ngxs/store'
import { dynamicTickIntervalsWhenZooming } from '../../../shared/utils'
import {
  SurveysComparisonOpenComplexChart,
  SurveysComparisonSetChartsMarginTop,
} from '../../store/surveys-comparison.actions'
import { SurveysComparisonStateSelectors } from '../../store/surveys-comparison.selectors'
import { Observable, Subscription, takeWhile } from 'rxjs'
import { distinctUntilChanged, filter, tap } from 'rxjs/operators'
import { AppStateSelectors } from '../../../store/app/app.selectors'
import { SurveysComparisonService } from '../../service/surveys-comparison.service'

@Component({
  selector: 'soillib-surveys-comparison-complex-chart',
  templateUrl: './surveys-comparison-complex-chart.component.html',
  styleUrls: ['./surveys-comparison-complex-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveysComparisonComplexChartComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input() chartContainerId: string
  @Input() complexChart: ComplexChart
  @Input() dragDrop: string
  @Input() elevationMode: boolean

  @Select(AppStateSelectors.datumDisplayName) datumDisplayName$: Observable<string>
  @Select(SurveysComparisonStateSelectors.slices.chartsMarginTop) chartsMarginTop$: Observable<number>

  chartRef: Chart | null
  stratigraphyDataArray: StratigraphyDataModel[] = []
  depthMax: number
  hasParamChart = true
  hasStratigraphy = true
  chartNum = 0
  marginTop: number | null = null

  private subscription = new Subscription()

  constructor(
    private translateService: TranslateService,
    private soilTypeUtilsService: SoilTypeUtilsService,
    private surveysComparisonService: SurveysComparisonService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.initData()
    this.subscription.add(
      this.chartsMarginTop$
        .pipe(
          distinctUntilChanged(),
          takeWhile((chartsMarginTop) => chartsMarginTop !== this.marginTop),
          filter((chartsMarginTop) => chartsMarginTop > 0),
          tap((chartsMarginTop) => {
            this.loadChart(chartsMarginTop)
            this.marginTop = chartsMarginTop
          }),
        )
        .subscribe(),
    )
  }

  ngAfterViewInit() {
    this.loadChart()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.elevationMode && !changes.elevationMode.firstChange) {
      this.loadChart()
    }
  }

  editChart(complexChart: ComplexChart) {
    this.store.dispatch(new SurveysComparisonOpenComplexChart(complexChart))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private initData() {
    this.chartNum = 0
    const stratigraphys: StratigraphyDataModel[] = []
    let maxDepth = 0
    let stratiNum = 0
    this.complexChart.items.forEach(({ depths, elevation, param, rawData, surveyName, surveyType }) => {
      const isSBT = surveyType?.includes('CPT')

      maxDepth = Math.max(Math.max.apply(null, depths), maxDepth)
      // for stratigraphy graphs
      if (param === 'calculated_soil') {
        const calculatedSoilTypes = rawData.map(({ geo_soil }) =>
          geo_soil ? this.soilTypeUtilsService.parseSoilType(geo_soil, isSBT) ?? '' : '',
        )

        stratiNum++
        const stratiColData = depths
          .map((depth, index) => [depth ?? null, calculatedSoilTypes[index] ?? null])
          .filter((el) => !!el[0]) // depth can not be 0, '', null.

        stratigraphys.push({
          name: surveyName,
          data: stratiColData,
          elevation,
        })
      } else {
        this.chartNum++
      }
    })
    this.depthMax = maxDepth
    if (stratiNum > 0) {
      this.stratigraphyDataArray = stratigraphys
      this.hasStratigraphy = true
    } else {
      this.hasStratigraphy = false
    }

    this.hasParamChart = this.chartNum > 0
  }

  private loadChart(
    marginTop: number = this.store.selectSnapshot(SurveysComparisonStateSelectors.slices.chartsMarginTop),
  ) {
    const chartElement = document.getElementById(this.chartContainerId)
    if (this.chartNum > 0 && chartElement) {
      this.chartRef?.destroy()
      const options: Options = {
        chart: {
          zoomType: 'xy',
          inverted: true,
          marginTop,
          marginBottom: 70,
        },
        title: {
          text: this.complexChart.chartName,
        },
        boost: {
          useGPUTranslations: true,
        },
        xAxis: {
          title: {
            text: this.activeElevation()
              ? this.translateService.instant('SURVEY.ELEVATION', {
                  name: this.store.selectSnapshot(AppStateSelectors.datumDisplayName),
                })
              : this.translateService.instant('GENERAL.DEPTH'),
          },
          reversed: !this.activeElevation(),
          tickInterval: 5,
          events: {
            setExtremes: dynamicTickIntervalsWhenZooming(5),
          },
          gridLineWidth: 1,
          min: this.activeElevation() ? undefined : 0,
          max: this.activeElevation() ? undefined : this.hasStratigraphy ? this.depthMax : undefined,
          crosshair: true,
          endOnTick: true,
          showLastLabel: true,
          startOnTick: true,
        },
        yAxis: [],
        plotOptions: {
          series: {
            connectNulls: true,
          },
        },
        exporting: {
          filename: 'Borehole comparison chart',
          sourceHeight: chartElement.clientHeight,
          sourceWidth: chartElement.clientWidth,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadPDF'],
            },
          },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            return `<div>${this.x}</div><span style="color: ${this.color}">${this.series.name}</span>: <b> ${this.y} </b>`
          },
        },
        legend: {
          enabled: true,
          verticalAlign: 'top',
        },
        credits: {
          enabled: false,
        },
      }
      this.chartRef = chart(this.chartContainerId, options)
      this.drawParameterChart()

      const chartsMarginTop = getUnifiedMarginTop(this.chartRef)
      if (chartsMarginTop > marginTop) {
        this.store.dispatch(new SurveysComparisonSetChartsMarginTop(chartsMarginTop))
      }
    }
  }

  private drawParameterChart() {
    this.complexChart.items.forEach((params) => {
      const surveyName = params.surveyName
      const depthData = this.activeElevation() ? params.depths.map((depth) => params.elevation - depth) : params.depths
      const curveColor = params.color
      const colName = params.param
      const paramData = params.paramData
      if (colName !== 'calculated_soil' && colName !== 'calculated_compactness') {
        const colData = depthData
          .map((depth, index) => (depth !== null ? [depth, paramData[index] ? Number(paramData[index]) : null] : null))
          .filter((el) => el !== null && el.every((e) => e !== null))
          .map((el) => el as number[])
          .sort((a, b) => a[0] - b[0])
        if (this.chartRef) {
          this.addSeriesAndYaxis(this.chartRef, colName, colData, curveColor, surveyName)
        }
      }
    })
  }

  private activeElevation() {
    return (
      this.elevationMode &&
      this.complexChart.items.every((item) => item.elevation !== null && item.elevation !== undefined)
    )
  }

  private addSeriesAndYaxis(chart: Chart, para: string, data: number[][], color: string, curveName: string) {
    const yAxisName = this.surveysComparisonService.getParamName(para)
    if (!chart.get(yAxisName)) {
      chart.addAxis({
        id: yAxisName,
        margin: -10,
        title: {
          text: yAxisName,
        },
        labels: {
          format: '{value}',
        },
        opposite: true,
        showEmpty: false,
      })
    }
    const { lineWidth, showMarker, type } = getChartTypeByPara(para)
    chart.addSeries({
      id: para,
      yAxis: yAxisName,
      data,
      name: curveName,
      type,
      lineWidth,
      marker: { enabled: showMarker },
      color,
    } as SeriesOptionsType)
  }
}
