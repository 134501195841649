import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, map, merge, Observable, of, Subscription, withLatestFrom } from 'rxjs'
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators'
import { EpsgProjModel, KnowProjModel, LatLonLocation } from '../../shared/models'
import { MessagesService, SoilSurveyService } from '../../shared/remote-services'
import { EpsgProjectionService } from '../../shared/services'
import { ddToDmsLat, ddToDmsLng, isPointInsideJobsite } from '../../shared/utils'
import { getDD } from '../../shared/utils/ddToDms'
import Swal from 'sweetalert2'
import { allBoreholeTypes, getBoreholeTypeName, SoilSurvey } from '@sde-ild/ssd-soillib-lib'
import { Select, Store } from '@ngxs/store'
import { AppSetSelectedResource, AppSetSelectedSurvey, AppUpdateSelectedJobsite } from '../../store/app/app.actions'
import { MapClearSelectedLocation } from '../../store/map/map.actions'
import { deepDistinctUntilChanged, switchTap } from '../../shared/utils/observables.utils'
import { Coordinate } from 'ol/coordinate'
import { CorrelationDataFetchCorrelationData } from '../../surveys-correlation-data/store/correlation-data.actions'
import { MapStateSelectors } from '../../store/map/map.selectors'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { UserConfigStateSelectors } from '../../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-survey-create-form',
  templateUrl: './survey-create-form.component.html',
  styleUrls: ['./survey-create-form.component.scss'],
})
export class SurveyCreateFormComponent implements OnInit, OnDestroy {
  @Select(MapStateSelectors.slices.selectedLocation)
  selectedLocation$: Observable<LatLonLocation | null>

  @Select(AppStateSelectors.datumDisplayName) datumDisplayName$: Observable<string>

  @Output()
  markLocationEvent: EventEmitter<LatLonLocation | null> = new EventEmitter()

  @Output()
  addSurveyEvent: EventEmitter<SoilSurvey> = new EventEmitter()

  allBoreholeTypes: string[]

  known_projections: KnowProjModel = {}
  proj_valid = true
  ENCoordApplied = false
  notfounderror: string | null

  useSNCoordFormControl: FormControl<boolean | null>
  surveyForm: FormGroup<{
    name: FormControl<string | null>
    type: FormControl<string | null>
    elevation: FormControl<number | null>
    localCoordX: FormControl<number | null>
    localCoordY: FormControl<number | null>
    lon: FormControl<string | null>
    lat: FormControl<string | null>
    projectionRef: FormControl<string | null>
    waterLvl: FormControl<number | null>
  }>
  customProjFormControl: FormControl<string | null>

  private subscription: Subscription = new Subscription()
  constructor(
    private translateService: TranslateService,
    private epsgProjectionService: EpsgProjectionService,
    private fb: FormBuilder,
    private soilSurveyService: SoilSurveyService,
    private messagesService: MessagesService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.allBoreholeTypes = allBoreholeTypes()
    const epsgCode = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)?.epsg_code
    if (epsgCode) {
      this.epsgProjectionService.searchEpsg(epsgCode).subscribe((res) => {
        if (res) {
          this.known_projections[res.code] = res
          this.surveyForm.controls.projectionRef.setValue(res.code)
        }
      })
    }

    this.useSNCoordFormControl = this.fb.control(false)
    this.customProjFormControl = this.fb.control(
      { value: null, disabled: !this.store.selectSnapshot(UserConfigStateSelectors.canWrite) },
      { validators: [Validators.pattern('^([0-9]{4,5})$')] },
    )
    this.surveyForm = this.fb.group(
      {
        name: this.fb.control('', Validators.required),
        type: this.fb.control('', { validators: [Validators.required], updateOn: 'blur' }),
        elevation: this.fb.control(0, Validators.pattern(/^(-)?([0-9]+(\.[0-9]+)?)$/)),
        localCoordX: this.fb.control(0),
        localCoordY: this.fb.control(0),
        lon: this.fb.control('', [Validators.required, this.dmsLonValidator.bind(this)]),
        lat: this.fb.control('', [Validators.required, this.dmsLatValidator.bind(this)]),
        projectionRef: this.fb.control(''),
        waterLvl: this.fb.control(0, Validators.pattern(/^(-)?([0-9]+(\.[0-9]+)?)$/)),
      },
      {
        validators: this.dmsLatLonValidator,
      },
    )

    this.subscription.add(this.selectedLocationChanged$().subscribe())
    this.subscription.add(this.latLonChanged$().subscribe())
    this.subscription.add(this.projectionRefChanged$().subscribe())
    this.subscription.add(this.localCoordChanged$().subscribe())
    this.subscription.add(this.useSNCoordChanged$().subscribe())
  }

  private selectedLocationChanged$ = (): Observable<LatLonLocation | null> =>
    this.selectedLocation$.pipe(
      tap((selectedLocation) => {
        this.surveyForm.patchValue({
          localCoordX: 0,
          localCoordY: 0,
          lon: selectedLocation ? ddToDmsLng(selectedLocation.longitude) : '',
          lat: selectedLocation ? ddToDmsLat(selectedLocation.latitude) : '',
          projectionRef: '',
        })
        if (selectedLocation) {
          this.surveyForm.controls.lon.markAsTouched()
          this.surveyForm.controls.lat.markAsTouched()
        }

        this.markLocationEvent.emit(selectedLocation)
        this.ENCoordApplied = false
        this.proj_valid = true
      }),
    )

  private latLonChanged$ = (): Observable<LatLonLocation | null> =>
    combineLatest([this.surveyForm.controls.lat.valueChanges, this.surveyForm.controls.lon.valueChanges]).pipe(
      debounceTime(500),
      deepDistinctUntilChanged(),
      map(([latitude, longitude]) => {
        let newCoordinates: LatLonLocation | null = null
        if (this.surveyForm.controls.lat.valid && this.surveyForm.controls.lon.valid) {
          newCoordinates = {
            latitude: getDD(latitude || ''),
            longitude: getDD(longitude || ''),
          }
        }
        return newCoordinates
      }),
      deepDistinctUntilChanged(),
      tap((loc) => this.markLocationEvent.emit(loc)),
    )

  private projectionRefChanged$ = (): Observable<string | null> =>
    this.surveyForm.controls.projectionRef.valueChanges.pipe(
      debounceTime(500),
      switchTap(() => this.setLonLatFromEN$()),
      tap((proj) => {
        if (proj) {
          this.customProjFormControl.setValue(null)
          this.notfounderror = null
        }
      }),
    )

  private localCoordChanged$ = (): Observable<number | null> =>
    merge(this.surveyForm.controls.localCoordX.valueChanges, this.surveyForm.controls.localCoordY.valueChanges).pipe(
      debounceTime(500),
      switchTap(() => this.setLonLatFromEN$()),
    )

  private useSNCoordChanged$: () => Observable<[boolean | null, LatLonLocation | null]> = () =>
    this.useSNCoordFormControl.valueChanges.pipe(
      withLatestFrom(this.selectedLocation$),
      tap(([checked, selectedLocation]) => {
        if (checked) {
          this.surveyForm.reset({
            lon: '',
            lat: '',
          })
        } else if (selectedLocation) {
          this.surveyForm.controls.lon.setValue(ddToDmsLng(selectedLocation.longitude))
          this.surveyForm.controls.lon.markAsTouched()
          this.surveyForm.controls.lat.setValue(ddToDmsLat(selectedLocation.latitude))
          this.surveyForm.controls.lat.markAsTouched()
        }
      }),
      switchTap(() => this.setLonLatFromEN$()),
    )

  private setLonLatFromEN$ = (): Observable<{
    projectionRef: string
    coordinates: Coordinate | undefined
  }> =>
    of({
      ...this.surveyForm.value,
      useSNCoord: this.useSNCoordFormControl.value,
    }).pipe(
      filter(
        ({ useSNCoord, projectionRef, localCoordX, localCoordY }) =>
          !!useSNCoord && !!projectionRef && localCoordX != null && localCoordY != null,
      ),
      map(({ projectionRef, localCoordX, localCoordY }) => ({
        projectionRef: projectionRef as string,
        localCoordX: localCoordX as number,
        localCoordY: localCoordY as number,
      })),
      map(({ projectionRef, localCoordX, localCoordY }) => {
        const localProjection: EpsgProjModel = this.known_projections[projectionRef]
        const coordinates = this.epsgProjectionService.toLonLat(localProjection, [localCoordX, localCoordY])
        return {
          projectionRef,
          coordinates,
        }
      }),
      tap(({ projectionRef, coordinates }) => {
        if (coordinates) {
          this.ENCoordApplied = true
          this.surveyForm.controls.lon.setValue(ddToDmsLng(coordinates[0]))
          this.surveyForm.controls.lat.setValue(ddToDmsLat(coordinates[1]))
          if (this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)?.epsg_code !== projectionRef) {
            this.updateJobsiteEpsgCode()
          }
        } else {
          this.ENCoordApplied = false
        }
        this.proj_valid = !!projectionRef
      }),
    )

  private updateJobsiteEpsgCode() {
    const jobsite = this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)
    if (jobsite && jobsite.id) {
      const updatedJobsite = {
        ...jobsite,
        epsg_code: this.surveyForm.get('projectionRef')?.value ?? undefined,
      }
      this.store.dispatch(
        new AppUpdateSelectedJobsite(
          updatedJobsite,
          `saved the projection as EPSG:${updatedJobsite.epsg_code} for the`,
        ),
      )
    }
  }

  getKeys(obj: object) {
    return Object.keys(obj)
  }

  applyProjection() {
    const projectionRef = this.surveyForm.controls.projectionRef
    projectionRef.setValue(null)
    if (this.customProjFormControl.valid && this.customProjFormControl.value) {
      this.epsgProjectionService.searchEpsg(this.customProjFormControl.value).subscribe((res: EpsgProjModel) => {
        if (res) {
          this.known_projections[res.code] = res
          projectionRef.setValue(res.code)
          this.notfounderror = null
        } else {
          this.notfounderror = this.translateService.instant('SURVEY.PROJECTION_NOT_FOUND')
        }
      })
    }
  }

  private dmsLonValidator(input: FormControl<string>) {
    const str: string = input.value.trim()
    const patt = new RegExp(/^\d{1,3}[\u00b0]\d{1,2}[']([0-9]*[.])?[0-9]+["][E|W]$/i)
    if (patt.test(str)) {
      const matchPatt = new RegExp(/^(\d{1,3})[\u00b0](\d{1,2})[']([0-9]*[.]?[0-9]+)["][E|W]$/i)
      const matches = str.match(matchPatt)
      if (matches && matches.length > 3) {
        // ["E2°25'56.017"", "2", "25", "56.017"]
        if (parseInt(matches[1], 10) < 180 && parseInt(matches[2], 10) < 60 && parseFloat(matches[3]) < 60) {
          return null
        }
      }
    } else if (/^[+-]?([0-9]*[.])?[0-9]+$/.test(str)) {
      const ddmatch = str.match(/^[+-]?([0-9]*[.]?[0-9]+)$/)
      if (ddmatch && ddmatch[0] && parseInt(ddmatch[0], 10) < 180) {
        return null
      }
    }
    return { dmsLonFormat: true }
  }

  private dmsLatValidator(input: FormControl<string>) {
    const str: string = input.value.trim()
    const patt = new RegExp(/^\d{1,2}[\u00b0]\d{1,2}[']([0-9]*[.])?[0-9]+["][N|S]$/i)
    if (patt.test(str)) {
      const matchPatt = new RegExp(/^(\d{1,2})[\u00b0](\d{1,2})[']([0-9]*[.]?[0-9]+)["][N|S]$/i)
      const matches = str.match(matchPatt)
      if (matches && matches.length > 3) {
        // ["E2°25'56.017"", "2", "25", "56.017"]
        if (parseInt(matches[1], 10) < 90 && parseInt(matches[2], 10) < 60 && parseFloat(matches[3]) < 60) {
          return null
        }
      }
    } else if (/^[+-]?([0-9]*[.])?[0-9]+$/.test(str)) {
      const ddmatch = str.match(/^[+-]?([0-9]*[.]?[0-9]+)$/)
      if (ddmatch && ddmatch[0] && parseInt(ddmatch[0], 10) < 90) {
        return null
      }
    }
    return { dmsLatFormat: true }
  }

  showBoreholeTypeName(type) {
    return getBoreholeTypeName(type, this.translateService)
  }

  isSurveyFormValid() {
    const typeInput = this.surveyForm.get('type')
    return this.surveyForm.valid && typeInput && typeInput.value && typeInput.value.length > 0
  }

  saveSurvey() {
    if (!this.store.selectSnapshot(UserConfigStateSelectors.canWrite)) {
      return Swal.fire({
        title: this.translateService.instant('ALERT.FORBIDDEN'),
        text: this.translateService.instant('ALERT.NO_PERMISSION'),
        icon: 'warning',
        showConfirmButton: false,
        timer: 1000,
      })
    }
    const surveyValues = this.surveyForm.value
    const jobsiteId = this.store.selectSnapshot(AppStateSelectors.selectedJobsiteId)
    if (jobsiteId) {
      const useNSCoord = !!this.useSNCoordFormControl.value
      const projectionRef = useNSCoord
        ? this.epsgProjectionService.getCodeByKey(surveyValues.projectionRef) || surveyValues.projectionRef
        : undefined
      this.soilSurveyService
        .createSoilSurvey(jobsiteId, {
          jobsite_id: jobsiteId,
          name: surveyValues.name,
          type: surveyValues.type?.toString(),
          elevation: surveyValues.elevation,
          local_x: useNSCoord ? surveyValues.localCoordX : undefined,
          local_y: useNSCoord ? surveyValues.localCoordY : undefined,
          lon_coord: getDD(surveyValues.lon || ''), // this.selectedLocation.longitude, //save as decimal value in DB
          lat_coord: getDD(surveyValues.lat || ''), // this.selectedLocation.latitude, //save as decimal value in DB
          projection_ref: projectionRef,
          water_level: surveyValues.waterLvl,
        })
        .subscribe({
          next: (res) => {
            return Swal.fire({
              title: this.translateService.instant('ALERT.SAVED'),
              text: this.translateService.instant('ALERT.SAVE_SUCCESS'),
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then(() => {
              this.soilSurveyService
                .getSoilSurvey(res)
                .pipe(
                  tap((survey: SoilSurvey) => {
                    const actions: (
                      | MapClearSelectedLocation
                      | AppSetSelectedSurvey
                      | AppSetSelectedResource
                      | CorrelationDataFetchCorrelationData
                    )[] = [
                      MapClearSelectedLocation,
                      new AppSetSelectedSurvey(survey),
                      new AppSetSelectedResource({
                        type: 'borehole',
                        id: survey.id ?? undefined,
                      }),
                    ]
                    if (survey.id) {
                      actions.push(new CorrelationDataFetchCorrelationData(survey.id))
                    }
                    this.store.dispatch(actions)
                    this.addSurveyEvent.emit(survey)
                  }),
                  switchMap((survey: SoilSurvey) =>
                    this.messagesService.saveResourceAndJobsiteMessage$(
                      jobsiteId,
                      survey.name,
                      'borehole',
                      'created the',
                    ),
                  ),
                )
                .subscribe()
            })
          },
          error: () => {
            return Swal.fire({
              title: this.translateService.instant('ALERT.FAILED'),
              text: this.translateService.instant('ALERT.SAVE_FAILED'),
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          },
        })
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  private dmsLatLonValidator = (control: AbstractControl): ValidationErrors | null => {
    const lon = control.get('lon')?.valid ? control.get('lon')?.value : null
    const lat = control.get('lat')?.valid ? control.get('lat')?.value : null
    return lon &&
      lat &&
      !isPointInsideJobsite(
        [getDD(lon), getDD(lat)],
        this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite)?.extent,
        true,
      )
      ? { dmsOutside: true }
      : null
  }
}
