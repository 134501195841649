import { ErrorHandler, Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { HttpErrorResponse } from '@angular/common/http'
import { getBrowerVersion } from '../utils'
import * as Sentry from '@sentry/angular'
import Swal from 'sweetalert2'
import { environment } from '../../../environments/environment'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private translate: TranslateService) {}

  handleError(error: Error | HttpErrorResponse | unknown) {
    if (error instanceof HttpErrorResponse && error.status === 404) {
      console.error('404 NOT FOUND', error)
    } else if (error && getBrowerVersion().indexOf('IE') === 0) {
      this.showBrowserVersionErrorMessage()
    } else if (error) {
      const message = this.hasMessage(error) ? error.message : undefined
      if (message && message.includes('Session expired')) {
        this.sessionExpired()
      } else {
        if (typeof error === 'string' || error instanceof Error) {
          Sentry.captureException(error)
        }
        this.showGeneralErrorMessage(message ?? (typeof error === 'string' ? error : undefined))
        this.logError(error)
      }
    } else {
      this.showGeneralErrorMessage()
      this.logError(error)
    }
  }

  private showBrowserVersionErrorMessage() {
    Swal.fire({
      title: 'Sorry, SoilLib does not support IE11 and its earlier versions',
      html: '<h4>We recommend you to use Google Chrome 9+, Firefox 4+<h4><hr><img src="assets/sb.png" height="40" alt="Soletanche bachy">',
      icon: 'error',
      showCancelButton: false,
      customClass: { container: 'swal-wide-sb' },
      showConfirmButton: false,
      allowOutsideClick: false,
    })
  }

  private sessionExpired() {}

  private showGeneralErrorMessage(mess: string = this.translate.instant('ALERT.UNEXPECTED_ERROR.DESCRIPTION')) {
    const title: string = this.translate.instant('ALERT.UNEXPECTED_ERROR.TITLE')
    if (environment.platform !== 'PROD') {
      Swal.fire({
        title,
        text: mess,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }

  private hasMessage = (obj: unknown): obj is { message: string } => {
    const typedObj = obj as { message: string }
    return (
      ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
      typeof typedObj.message === 'string'
    )
  }

  private logError(error: unknown) {
    if (!environment.production) {
      console.error(error)
    }
  }
}
