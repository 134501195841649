<div class="jobsite-edit">
  <div id="drag-jobsite-edit" soillibDraggable>
    <div (click)="closeJobsiteEditEvent.emit()" class="ol-popup-closer"></div>
    <div>
      <mat-radio-group (change)="handleJobsiteEditOptionChangeEvent.emit($event.value)" [(ngModel)]="value">
        <mat-radio-button value="extent">{{ 'ALERT.SITE_EXTENT' | translate }}</mat-radio-button> <br />
        <mat-radio-button value="coordinate">{{ 'MAINMAP.JOBSITE_CENTER' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div style="margin-top: 30px">
      <button mat-raised-button style="margin-right: 20px" (click)="updateJobsiteExtentCoordEvent.emit(value)">
        OK
      </button>
      <button mat-raised-button (click)="closeJobsiteEditEvent.emit()">{{ 'GENERAL.CANCEL' | translate }}</button>
    </div>
  </div>
</div>
