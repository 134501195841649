import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewChild } from '@angular/core'
import { Subscription, forkJoin, Observable } from 'rxjs'
import { filter, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators'
import { JobsiteService } from '../shared/remote-services'
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'
import { GoogleSearchModel, GoogleSearchResult } from '../shared/models/Google-search.model'
import { boundingExtent, Extent } from 'ol/extent'
import { fromLonLat } from 'ol/proj'
import { JobsiteSearch } from '../shared/models'
import { isJobsite, isJobsiteHarmony } from '../shared/models/Jobsite.model.type.guard'
import { isString, nonNullable } from '../shared/utils'
import { environment } from '../../environments/environment'
import { FormControl } from '@angular/forms'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'

export type SearchResult = (JobsiteSearch | GoogleSearchResult | { search: string }) & {
  displayedName?: string | null
}

@Component({
  selector: 'soillib-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  searchResults: SearchResult[]
  helpMess = ''

  @Output() selectedJobsite: EventEmitter<SearchResult> = new EventEmitter()
  @Output() fitToSelectedPlaceEvent: EventEmitter<Extent> = new EventEmitter()

  searchControl: FormControl<string | SearchResult | null>
  @ViewChild(MatAutocompleteTrigger) searchTrigger: MatAutocompleteTrigger

  private googleResults: GoogleSearchModel
  private subscription: Subscription = new Subscription()

  constructor(
    private http: HttpClient,
    private jobsiteService: JobsiteService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.searchControl = new FormControl<string | SearchResult | null>('')

    this.subscription.add(
      this.searchControl.valueChanges
        .pipe(
          filter(isString),
          debounceTime(600),
          map((search) => search.trim()),
          filter((search) => search.length > 0),
          distinctUntilChanged(),
          switchMap((searchInput: string) =>
            forkJoin([this.jobsiteService.searchJobsites(searchInput), this.geocodageQuery(searchInput)]).pipe(
              map((searchResults: [JobsiteSearch[], GoogleSearchModel]) => [...searchResults, searchInput]),
            ),
          ),
        )
        .subscribe({
          next: (res: [JobsiteSearch[], GoogleSearchModel, string]) => this.handleSearchResults(res),
          error: (err) => console.error(err),
        }),
    )
  }

  geocodageQuery(search: string): Observable<GoogleSearchModel> {
    return this.http.get<GoogleSearchModel>(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${search}&key=${environment.googleSearchApiKey}`,
    )
  }

  handleSearchResults(results: [JobsiteSearch[], GoogleSearchModel, string]) {
    this.searchResults = []
    this.helpMess = ''
    const [jobsitesResults, googleResults, initialQuery] = results

    this.googleResults = googleResults
    if (jobsitesResults.length) {
      this.searchResults = jobsitesResults.map((result) => ({
        ...result,
        displayedName:
          (isJobsite(result) ? result.nickname : undefined) || (isJobsiteHarmony(result) ? result.jobname : undefined),
      }))
      this.helpMess = this.translateService.instant('SEARCHBOX.JOBSITE_COLOR_HELP')
    } else if (
      this.googleResults.results.length &&
      this.googleResults.status === 'OK' &&
      !initialQuery.match(/\d+\.\d+/g)
    ) {
      this.googleResults.results.forEach((result) => (result.displayedName = result.formatted_address))
      this.searchResults = this.googleResults.results
      this.helpMess = this.translateService.instant('SEARCHBOX.ADDRESS_HELP')
    } else if (initialQuery.length) {
      this.searchResults = [
        {
          search: initialQuery,
          displayedName: initialQuery, // `Se rendre aux coordonnées ${initialQuery}`,
        },
      ]
      this.helpMess = this.translateService.instant('SEARCHBOX.NO_RESULTS')
    }
    if (this.searchResults.length > 0) {
      this.searchTrigger.openPanel()
    }
  }

  fitToGoogleAddress() {
    const getCoordinate = (lng: number | undefined, lat: number | undefined) =>
      lng && lat ? fromLonLat([lng, lat]) : null

    if (this.googleResults?.results?.[0]) {
      const result = this.googleResults.results[0]
      const bounds = result.geometry?.bounds || result.geometry?.viewport
      const coordinates = [
        getCoordinate(bounds?.northeast?.lng, bounds?.northeast?.lat),
        getCoordinate(bounds?.southwest?.lng, bounds?.southwest?.lat),
      ].filter(nonNullable)
      const extent = boundingExtent(coordinates)
      this.fitToSelectedPlaceEvent.emit(extent)
    }
  }

  displaySearch(searchResult: SearchResult) {
    return searchResult.displayedName ? searchResult.displayedName : undefined
  }

  closeHelpInfo(e: MouseEvent) {
    e.stopPropagation()
    this.helpMess = ''
  }

  getDisplayedSearchStyle(searchResult: SearchResult) {
    const jobsite = isJobsite(searchResult)
    const jobsiteHarmony = isJobsiteHarmony(searchResult)
    if (jobsite && searchResult.extent) {
      return { 'background-color': 'green' }
    } else if (jobsite && searchResult.nickname) {
      return { 'background-color': 'orange' }
    } else if (jobsiteHarmony && searchResult.jobname) {
      return { 'background-color': 'red' }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
