<div class="lengend-container">
  <div id="sbtn-lengend" soillibDraggable>
    <div (click)="close()" class="ol-popup-closer"></div>
    <h3 style="text-align: center">SBTn Legend</h3>
    <div fxLayout="column" fxLayoutGap="10px">
      <div *ngFor="let item of sbtnZoneDesc | keyvalue" fxLayout="row" fxLayoutGap="10px">
        <div class="rectangle" [style.background-color]="sbtnZoneColor[item.key]"></div>
        <span>{{ zoneDescription(item) }}</span>
      </div>
    </div>
  </div>
</div>
