/*
 * Generated type guards for "Jobsite.model.ts".
 * WARNING: Do not manually change this file.
 */
import { Jobsite, JobsiteHarmony, JobsiteSearch } from './Jobsite.model'

export function isJobsite(obj: unknown): obj is Jobsite {
  const typedObj = obj as Jobsite
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' || typedObj['id'] === null || typeof typedObj['id'] === 'string') &&
    (typeof typedObj['lat_coord'] === 'undefined' ||
      typedObj['lat_coord'] === null ||
      typeof typedObj['lat_coord'] === 'number') &&
    (typeof typedObj['lon_coord'] === 'undefined' ||
      typedObj['lon_coord'] === null ||
      typeof typedObj['lon_coord'] === 'number') &&
    (typeof typedObj['extent'] === 'undefined' ||
      typedObj['extent'] === null ||
      typeof typedObj['extent'] === 'string') &&
    (typeof typedObj['nickname'] === 'undefined' ||
      typedObj['nickname'] === null ||
      typeof typedObj['nickname'] === 'string') &&
    (typeof typedObj['status'] === 'undefined' ||
      typedObj['status'] === null ||
      typeof typedObj['status'] === 'string') &&
    (typeof typedObj['ngf'] === 'undefined' || typedObj['ngf'] === null || typeof typedObj['ngf'] === 'number') &&
    (typeof typedObj['epsg_code'] === 'undefined' ||
      typedObj['epsg_code'] === null ||
      typeof typedObj['epsg_code'] === 'string') &&
    (typeof typedObj['datumDisplayName'] === 'undefined' ||
      typedObj['datumDisplayName'] === null ||
      typeof typedObj['datumDisplayName'] === 'string')
  )
}

export function isJobsiteHarmony(obj: unknown): obj is JobsiteHarmony {
  const typedObj = obj as JobsiteHarmony
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    (typeof typedObj['id'] === 'undefined' || typedObj['id'] === null || typeof typedObj['id'] === 'string') &&
    (typeof typedObj['agency'] === 'undefined' ||
      typedObj['agency'] === null ||
      typeof typedObj['agency'] === 'string') &&
    (typeof typedObj['buid'] === 'undefined' || typedObj['buid'] === null || typeof typedObj['buid'] === 'string') &&
    (typeof typedObj['buname'] === 'undefined' ||
      typedObj['buname'] === null ||
      typeof typedObj['buname'] === 'string') &&
    (typeof typedObj['dateCreation'] === 'undefined' ||
      typedObj['dateCreation'] === null ||
      typedObj['dateCreation'] instanceof Date) &&
    (typeof typedObj['dateModif'] === 'undefined' ||
      typedObj['dateModif'] === null ||
      typedObj['dateModif'] instanceof Date) &&
    (typeof typedObj['jobname'] === 'undefined' ||
      typedObj['jobname'] === null ||
      typeof typedObj['jobname'] === 'string') &&
    (typeof typedObj['jobid'] === 'undefined' || typedObj['jobid'] === null || typeof typedObj['jobid'] === 'string') &&
    (typeof typedObj['type'] === 'undefined' || typedObj['type'] === null || typeof typedObj['type'] === 'string')
  )
}

export function isJobsiteSearch(obj: unknown): obj is JobsiteSearch {
  const typedObj = obj as JobsiteSearch
  return (isJobsite(typedObj) as boolean) || (isJobsiteHarmony(typedObj) as boolean)
}
