/*
 * Generated type guards for "CellsToColorize.model.ts".
 * WARNING: Do not manually change this file.
 */
import { Param, CellsToColorize } from './CellsToColorize.model'

export function isParam(obj: unknown): obj is Param {
  const typedObj = obj as Param
  return (
    typedObj === 'depth' ||
    typedObj === 'em' ||
    typedObj === 'f' ||
    typedObj === 'pf' ||
    typedObj === 'pl' ||
    typedObj === 'qc' ||
    typedObj === 'rf' ||
    typedObj === 'geo_soil' ||
    typedObj === 'calculated_soil' ||
    typedObj === 'geotechnical_engineer_description' ||
    typedObj === 'calculated_compactness' ||
    typedObj === 'pl_star' ||
    typedObj === 'n' ||
    typedObj === 'n_1_60' ||
    typedObj === 'spt_su_coefficient' ||
    typedObj === 'rqd' ||
    typedObj === 'scr' ||
    typedObj === 'tcr' ||
    typedObj === 'u2' ||
    typedObj === 'uo' ||
    typedObj === 'i' ||
    typedObj === 'fs' ||
    typedObj === 'qt' ||
    typedObj === 'qn' ||
    typedObj === 'qe' ||
    typedObj === 'soilfr' ||
    typedObj === 'soilbq' ||
    typedObj === 'cu_insitu' ||
    typedObj === 'toit' ||
    typedObj === 'base' ||
    typedObj === 'ic' ||
    typedObj === 'icRolling' ||
    typedObj === 'gamma'
  )
}

export function isCellsToColorize(obj: unknown): obj is CellsToColorize {
  const typedObj = obj as CellsToColorize
  return (
    ((typedObj !== null && typeof typedObj === 'object') || typeof typedObj === 'function') &&
    Array.isArray(typedObj['none']) &&
    typedObj['none'].every((e: any) => Array.isArray(e) && typeof e[0] === 'number' && (isParam(e[1]) as boolean)) &&
    Array.isArray(typedObj['yellow']) &&
    typedObj['yellow'].every((e: any) => Array.isArray(e) && typeof e[0] === 'number' && (isParam(e[1]) as boolean)) &&
    Array.isArray(typedObj['red']) &&
    typedObj['red'].every((e: any) => Array.isArray(e) && typeof e[0] === 'number' && (isParam(e[1]) as boolean)) &&
    Array.isArray(typedObj['orange']) &&
    typedObj['orange'].every((e: any) => Array.isArray(e) && typeof e[0] === 'number' && (isParam(e[1]) as boolean))
  )
}
