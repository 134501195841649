import { Coordinate } from 'ol/coordinate'
import { Permission } from '../../shared/models'

export class UserConfigSetPermission {
  public static readonly type = '[UserConfig] SetPermission'

  constructor(readonly permission: Permission) {}
}

export class UserConfigSetLanguage {
  public static readonly type = '[UserConfig] set user language'

  constructor(readonly language: string) {}
}

export class UserConfigSetTutorial {
  public static readonly type = '[UserConfig] set user tutorial checked'

  constructor(readonly tutorial: boolean) {}
}
export class UserConfigSetZoomAndCenter {
  public static readonly type = '[UserConfig] set map zoom and center'

  constructor(
    readonly payload: {
      zoom: number | undefined
      center: Coordinate | undefined
    },
  ) {}
}
