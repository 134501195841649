import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { SurveySBTnResultsModel } from '../../shared/models/SurveySBTnResults.model'

@Component({
  selector: 'soillib-sbtn-results-charts',
  template: ` <div fxLayout="row" style="height: 100%">
    <soillib-sbtn-chart-template
      fxFlex="20"
      [chartId]="'qt'"
      [seriesData]="sbtNResults"
      [reflowChartsSignal]="reflowChartsSignal"
      [elevation]="elevation"
      [elevationMode]="elevationMode"
    ></soillib-sbtn-chart-template>
    <soillib-sbtn-chart-template
      fxFlex="20"
      [chartId]="'rf'"
      [seriesData]="sbtNResults"
      [reflowChartsSignal]="reflowChartsSignal"
      [elevation]="elevation"
      [elevationMode]="elevationMode"
    ></soillib-sbtn-chart-template>
    <soillib-sbtn-pore-pressure-chart
      fxFlex="20"
      [seriesData]="sbtNResults"
      [reflowChartsSignal]="reflowChartsSignal"
      [elevation]="elevation"
      [elevationMode]="elevationMode"
    ></soillib-sbtn-pore-pressure-chart>
    <soillib-sbtn-chart-template
      fxFlex="20"
      [chartId]="'icRolling'"
      [seriesData]="sbtNResults"
      [reflowChartsSignal]="reflowChartsSignal"
      [elevation]="elevation"
      [elevationMode]="elevationMode"
    ></soillib-sbtn-chart-template>
    <soillib-sbtn-chart-template
      fxFlex="20"
      [chartId]="'sbt'"
      [seriesData]="sbtNResults"
      [reflowChartsSignal]="reflowChartsSignal"
      [elevation]="elevation"
      [elevationMode]="elevationMode"
    ></soillib-sbtn-chart-template>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbtnResultsChartsComponent {
  @Input()
  sbtNResults: SurveySBTnResultsModel[]

  @Input()
  reflowChartsSignal: boolean

  @Input()
  elevationMode: boolean

  @Input()
  elevation: number | null | undefined
}
