import { Component, OnDestroy, OnInit } from '@angular/core'
import { KeycloakService } from '../keycloak/keycloak.service'
import { Jobsite, User } from '../shared/models'
import { TranslateService } from '@ngx-translate/core'
import { GoogleAnalyticsService } from '../shared/google-analytics/google-analytics.service'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { Subscription } from 'rxjs/internal/Subscription'
import { UserConfigSetLanguage, UserConfigSetTutorial } from '../store/user-config/user-config.actions'
import { StateClear } from 'ngxs-reset-plugin'
import { AppStateSelectors } from '../store/app/app.selectors'
import { UserConfigStateSelectors } from '../store/user-config/user-config.selectors'

@Component({
  selector: 'soillib-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit, OnDestroy {
  @Select(UserConfigStateSelectors.userLanguage) currentLanguage$: Observable<string | null>
  @Select(AppStateSelectors.slices.selectedJobsite) selectedJobsite$: Observable<Jobsite | null>

  constructor(
    private keycloak: KeycloakService,
    public translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store,
  ) {}

  public user: User
  public displayMessagesList = false

  hideOverlay: boolean

  private subscription = new Subscription()

  ngOnInit() {
    this.user = this.keycloak.getCurrentUser()
    this.googleAnalyticsService.setUserId(this.user.id)
    this.googleAnalyticsService.event('login', {
      method: 'keycloak',
      user_id: this.user.id,
      user_name: this.user.name,
    })
    this.translate.addLangs(['en', 'fr'])
    const lang = this.store.selectSnapshot(UserConfigStateSelectors.slices.language)
    if (lang) {
      this.translate.use(lang)
    } else {
      const defaultLang = this.translate.getBrowserLang()?.match(/en|fr/) ? this.translate.getBrowserLang() : 'en'
      this.translate.setDefaultLang(defaultLang || 'en')
      this.store.dispatch(new UserConfigSetLanguage(defaultLang || 'en'))
    }
    if (this.store.selectSnapshot(UserConfigStateSelectors.slices.tutorial)) {
      this.hideOverlay = true
    }
    this.subscription.add(
      this.selectedJobsite$.subscribe((jobsite) => {
        if (jobsite === null) {
          this.displayMessagesList = false
        }
      }),
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  logout() {
    this.store.dispatch(new StateClear())
    this.keycloak.logout()
    this.googleAnalyticsService.event('logout', {
      method: 'keycloak',
      user_id: this.user.id,
      user_name: this.user.name,
    })
  }

  openTuto() {
    window.open('https://support-ild.atlassian.net/wiki/spaces/ZHELP/pages/4063263/Tutorials')
  }

  openAbout() {
    window.open('https://support-ild.atlassian.net/wiki/spaces/ZHELP/pages/4063267/Release+note')
  }

  setCurrentLang(lang) {
    this.translate.use(lang)
    this.store.dispatch(new UserConfigSetLanguage(lang))
  }

  onCheck(checked: boolean) {
    this.store.dispatch(new UserConfigSetTutorial(checked))
  }

  hide() {
    this.hideOverlay = true
  }
}
