import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { RadiusSearch, DisplayedSurveys, LatLonLocation } from '../shared/models'
import { delay, first, Observable, Subject, withLatestFrom } from 'rxjs'
import { debounceTime, map, tap } from 'rxjs/operators'
import { Subscription } from 'rxjs/internal/Subscription'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { getBoreholeTypeName, getBoreholeTypeSymbol } from '@sde-ild/ssd-soillib-lib'
import { Select, Store } from '@ngxs/store'
import { MapStateSelectors } from '../store/map/map.selectors'
import { FilterStateSelectors } from '../store/filter/filter.selectors'
import {
  FilterSetDisplayedSurveys,
  FilterToggleFilteringOpened,
  FilterSetPreventCuttingsDrawing,
  FilterSetPreventJobsiteExtentsDrawing,
  FilterSetRadiusSearch,
  FilterSetShowMapLabels,
} from '../store/filter/filter.actions'

@Component({
  selector: 'soillib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
      transition('false => true', [
        style({ 'margin-left': '0' }),
        animate('0.5s ease-in-out', style({ 'margin-left': '350px' })),
      ]),
      transition('true => false', [
        style({ 'margin-left': '350px' }),
        animate('0.5s ease-in-out', style({ 'margin-left': '0' })),
      ]),
    ]),
  ],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Output() filterSurveys: EventEmitter<DisplayedSurveys> = new EventEmitter()
  @Output() filterSurveysByType: EventEmitter<{ type: string; checked: boolean }> = new EventEmitter()

  @Select(MapStateSelectors.slices.selectedLocation) selectedLocation$: Observable<LatLonLocation | null>
  @Select(FilterStateSelectors.slices.filteringOpened) filteringOpened$: Observable<boolean>
  @Select(FilterStateSelectors.slices.preventJobsiteExtentsDrawing) preventJobsiteExtentsDrawing$: Observable<boolean>
  @Select(FilterStateSelectors.slices.preventCuttingsDrawing) preventCuttingsDrawing$: Observable<boolean>
  @Select(FilterStateSelectors.slices.displayedSurveys) displayedSurveys$: Observable<DisplayedSurveys>
  @Select(FilterStateSelectors.availableSurveyTypes) availableSurveyTypes$: Observable<string[]>
  @Select(FilterStateSelectors.slices.showMapLabels) showMapLabels$: Observable<boolean>
  @Select(FilterStateSelectors.slices.radiusSearch) radiusSearch$: Observable<RadiusSearch>

  selectedSurveyTypeGroup: FormGroup<{
    displaySurveys: FormControl<boolean | null>
    filters: FormArray<FormControl<boolean | null>>
  }>

  private _debounceSubject = new Subject<RadiusSearch>()
  private subscription = new Subscription()

  constructor(private fb: FormBuilder, private translateService: TranslateService, private store: Store) {}

  ngOnInit() {
    this.selectedSurveyTypeGroup = this.fb.group({
      displaySurveys: this.fb.control({ value: true, disabled: false }),
      filters: this.fb.array<FormControl<boolean | null>>([]),
    })

    this.displayedSurveys$
      .pipe(
        withLatestFrom(this.availableSurveyTypes$),
        first(),
        tap(([selectedSurveys, availableSurveyTypes]) => {
          this.selectedSurveyTypeGroup.controls.displaySurveys.setValue(selectedSurveys.displaySurveys)
          this.selectedSurveyTypeGroup.controls.filters.clear()

          availableSurveyTypes
            .map((key) => this.fb.control({ value: selectedSurveys.filters[key], disabled: false }))
            .forEach((control, index) => {
              this.subscription.add(this.selectedSurveyTypesChanged$(control, availableSurveyTypes, index).subscribe())
              this.selectedSurveyTypeGroup.controls.filters.push(control)
            })

          this.subscription.add(this.selectedSurveyAvailableTypeChanged$(availableSurveyTypes).subscribe())
        }),
      )
      .subscribe()

    this.subscription.add(
      this._debounceSubject.pipe(debounceTime(1000)).subscribe((radiusSearch) => {
        this.handleRadius(radiusSearch)
      }),
    )
  }

  showBoreholeTypeName = (type: string) => getBoreholeTypeName(type.toUpperCase(), this.translateService)

  boreholeTypeSymbol = (type: string) => getBoreholeTypeSymbol(type.toUpperCase())

  handleFilterToggle() {
    this.store.dispatch(new FilterToggleFilteringOpened())
  }

  handleRadiusChange(radiusSearch: RadiusSearch) {
    this._debounceSubject.next(radiusSearch)
  }

  handleRadius(radiusSearch: RadiusSearch) {
    this.store.dispatch(new FilterSetRadiusSearch(radiusSearch))
  }

  toggleBingMapType(showMapLabels: boolean) {
    this.store.dispatch(new FilterSetShowMapLabels(showMapLabels))
  }

  onDrawJobsites(showJobsites: boolean) {
    this.store.dispatch(new FilterSetPreventJobsiteExtentsDrawing(!showJobsites))
  }

  onDrawCuttings(showZones: boolean) {
    this.store.dispatch(new FilterSetPreventCuttingsDrawing(!showZones))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  private selectedSurveyTypesChanged$ = (
    selectedSurveyType: FormControl<boolean | null>,
    availableSurveyTypes: string[],
    index: number,
  ): Observable<{ checked: boolean; type: string }> =>
    selectedSurveyType.valueChanges.pipe(
      map((newValue) => ({ type: availableSurveyTypes[index], checked: newValue || false })),
      delay(0), // we wait for the next angular tick, in order to have the form value up to date
      tap((typeChange) => {
        this.store.dispatch(
          new FilterSetDisplayedSurveys(this.getTypedValue(this.selectedSurveyTypeGroup.value, availableSurveyTypes)),
        )
        this.filterSurveysByType.emit(typeChange)
      }),
    )

  private selectedSurveyAvailableTypeChanged$ = (availableSurveyTypes: string[]): Observable<boolean | null> =>
    this.selectedSurveyTypeGroup.controls.displaySurveys.valueChanges.pipe(
      delay(0), // we wait for the next angular tick, in order to have the form value up to date
      tap((value) => {
        this.selectedSurveyTypeGroup.controls.filters.controls.forEach((control) => {
          if (value) {
            control.enable({ emitEvent: false })
          } else {
            control.disable({ emitEvent: false })
          }
        })
        const displayedSurveys = this.getTypedValue(this.selectedSurveyTypeGroup.value, availableSurveyTypes)
        this.store.dispatch(new FilterSetDisplayedSurveys(displayedSurveys))
        this.filterSurveys.emit(displayedSurveys)
      }),
    )

  private getTypedValue(
    formValue: Partial<{ displaySurveys: boolean | null; filters: (boolean | null)[] }>,
    availableSurveyTypes: string[],
  ): DisplayedSurveys {
    const filters: Record<string, boolean> = {}
    availableSurveyTypes.forEach((value, index) => {
      filters[value] = (formValue.filters?.[index] ?? false) || false
    })
    return {
      displaySurveys: formValue.displaySurveys || false,
      filters,
    }
  }
}
