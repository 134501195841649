import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DEVTOOLS_REDUX_CONFIG, LOGGER_CONFIG, OPTIONS_CONFIG, ROOT_STATES } from './ngxs-store.config'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import { UserConfigState } from '../store/user-config/user-config.state'
import { NgxsResetPluginModule } from 'ngxs-reset-plugin'

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(ROOT_STATES, OPTIONS_CONFIG),
    NgxsStoragePluginModule.forRoot({
      namespace: 'soillib',
      key: UserConfigState,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
    NgxsLoggerPluginModule.forRoot(LOGGER_CONFIG),
    NgxsResetPluginModule.forRoot(),
  ],
  exports: [NgxsModule],
})
export class NgxsStoreModule {}
