import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { parse, ParseConfig, ParseResult } from 'papaparse'

@Injectable()
export class FileParseService {
  parseFile(file: File, separator: string = ','): Observable<(string | number)[][]> {
    const subject = new Subject<(string | number)[][]>()
    const parsedResults: (string | number)[][] = []
    parse(file, {
      Worker: true,
      step: (results: ParseResult<string | number>) => {
        parsedResults.push(results.data)
      },
      dynamicTyping: true,
      delimiter: separator,
      skipEmptyLines: true,
      complete: parsingComplete,
    } as ParseConfig)
    return subject

    function parsingComplete(parseResult) {
      try {
        subject.next(parsedResults)
        subject.complete()
      } catch (error) {
        subject.error(error)
      }
    }
  }
}
