<ng-container
  *ngIf="{
    selectedJobsite: selectedJobsite$ | async,
    selectedSurvey: selectedSurvey$ | async,
    boreholeEditionOpened: boreholeEditionOpened$ | async,
    cData: correlationData$ | async,
    elevationParameters: getElevationParameters$() | async,
    isSBT: isSBT$ | async
  } as state"
>
  <div
    matTooltip="{{ 'GENERAL.POPOVER' | translate }}"
    matTooltipPosition="above"
    [matTooltipDisabled]="state.selectedJobsite !== null"
  >
    <div
      [@slideInOut]="state.boreholeEditionOpened"
      [ngClass]="{
        'edit-button-state0': !state.selectedJobsite?.extent,
        'edit-button-state1': !!state.selectedJobsite?.extent && !state.boreholeEditionOpened,
        'edit-button-state2': !!state.selectedJobsite?.extent && state.boreholeEditionOpened
      }"
      class="container"
      fxLayout="column"
      fxLayoutAlign="center center"
      (click)="toggleSurveyEdition(!state.boreholeEditionOpened)"
    >
      <img *ngIf="!state.boreholeEditionOpened" id="img-up-arrow" src="assets/ico-arrow-up.png" />
      <div fxLayout="row">
        <img fxFlex="20" id="img-survey-edition" src="assets/ico-sondageedit.png" />
        <div fxFlex fxLayout="column">
          <span id="text-edition">{{ 'GENERAL.EDIT' | translate }}</span>
          <span id="text-surveys">{{ 'SURVEY.SURVEY' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    [@slideInOutFromLeft]="state.boreholeEditionOpened"
    id="info-box"
    *ngIf="state.selectedSurvey && state.boreholeEditionOpened && showInfoBox"
  >
    <soillib-surveys-edition-info-box
      *ngIf="state.selectedSurvey"
      [parsedSurvey]="parsedSurvey"
      (setMoveSurveyModeEvent)="setMoveSurveyModeEvent.emit($event)"
      (saveMovedSurveyEvent)="saveMovedSurveyEvent.emit($event)"
      (deleteSurveyEvent)="deleteSurveyEvent.emit($event)"
      (updateSurveyEvent)="updateSurveyEvent.emit($event)"
    ></soillib-surveys-edition-info-box>
  </div>

  <div
    [@slideInOut]="state.boreholeEditionOpened"
    id="survey-edition-bottomNav"
    class="bottomNav"
    *ngIf="state.boreholeEditionOpened"
    fxLayout="column"
  >
    <soillib-map-table-resize-button
      *ngIf="state.boreholeEditionOpened"
      class="resize-button"
    ></soillib-map-table-resize-button>

    <div *ngIf="state.selectedSurvey !== null; else noSelectedSurvey" class="bottom-container">
      <div class="option-elevation">
        <mat-slide-toggle
          color="warn"
          [(ngModel)]="elevationMode"
          [disabled]="state.selectedSurvey.elevation === null || state.selectedSurvey.elevation === undefined"
          >{{ 'SURVEY.ELEVATION' | translate : { name: (datumDisplayName$ | async) || 'NGF' } }}</mat-slide-toggle
        >
      </div>
      <mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="tabChanged($event)" animationDuration="0ms">
        <mat-tab label="In-situ Test">
          <soillib-surveys-edition-data-table [selectedSurvey]="state.selectedSurvey" [elevationMode]="elevationMode">
          </soillib-surveys-edition-data-table>
        </mat-tab>
        <mat-tab label="Lab Test">
          <soillib-surveys-labtest-data-table
            *ngIf="tabIndex === 1"
            [selectedSurvey]="state.selectedSurvey"
          ></soillib-surveys-labtest-data-table>
        </mat-tab>
        <mat-tab
          [label]="'SURVEY.CORRELATION_DATA.TAB_TITLE' | translate"
          *ngIf="state.cData !== null && state.elevationParameters !== null"
        >
          <soillib-surveys-correlation-data
            *ngIf="tabIndex === 2"
            [selectedSurvey]="state.selectedSurvey"
            [correlationData]="state.cData"
            [insituData]="surveyData$ | async"
            [elevationParameters]="state.elevationParameters"
          ></soillib-surveys-correlation-data>
        </mat-tab>
      </mat-tab-group>
    </div>

    <ng-template #noSelectedSurvey>
      <div class="bottom-container">
        <soillib-survey-create-form
          (markLocationEvent)="markLocationEvent.emit($event)"
          (addSurveyEvent)="addSurveyEvent.emit($event)"
        ></soillib-survey-create-form>
        <soillib-surveys-import-ags
          [level]="'borehole'"
          [isSBT]="state.isSBT === true"
          (updateSurveysToMapEvent)="updateSurveysEvent.emit($event)"
        ></soillib-surveys-import-ags>
        <soillib-surveys-import-csv
          [level]="'borehole'"
          [isSBT]="state.isSBT === true"
          (updateSurveysToMapEvent)="updateSurveysEvent.emit($event)"
        ></soillib-surveys-import-csv>
      </div>
    </ng-template>
  </div>
</ng-container>
