import { Coordinate } from 'ol/coordinate'
import { Action, State, StateContext } from '@ngxs/store'
import { fromLonLat as FROMLONLAT } from 'ol/proj'
import { Injectable } from '@angular/core'
import produce from 'immer'
import {
  UserConfigSetLanguage,
  UserConfigSetPermission,
  UserConfigSetTutorial,
  UserConfigSetZoomAndCenter,
} from './user-config.actions'
import { Permission } from '../../shared/models'

export interface UserConfigStateModel {
  permission: Permission | null
  language: string | undefined
  tutorial: boolean
  zoom: number | undefined
  center: Coordinate | undefined
}

@State<UserConfigStateModel>({
  name: 'userConfig',
  defaults: {
    permission: null,
    language: undefined,
    tutorial: false,
    zoom: 6,
    center: FROMLONLAT([2.2944, 48.8582]),
  },
})
@Injectable()
export class UserConfigState {
  @Action(UserConfigSetPermission)
  public setPermission(ctx: StateContext<UserConfigStateModel>, { permission }: UserConfigSetPermission) {
    ctx.setState(
      produce((draft) => {
        draft.permission = permission
      }),
    )
  }
  @Action(UserConfigSetLanguage)
  public setLanguage(ctx: StateContext<UserConfigStateModel>, { language }: UserConfigSetLanguage) {
    ctx.setState(
      produce((draft) => {
        draft.language = language
      }),
    )
  }

  @Action(UserConfigSetTutorial)
  public setTutorial(ctx: StateContext<UserConfigStateModel>, { tutorial }: UserConfigSetTutorial) {
    ctx.setState(
      produce((draft) => {
        draft.tutorial = tutorial
      }),
    )
  }

  @Action(UserConfigSetZoomAndCenter)
  public setMapZoomAndCenter(
    ctx: StateContext<UserConfigStateModel>,
    { payload: { center, zoom } }: UserConfigSetZoomAndCenter,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.zoom = zoom
        draft.center = center
      }),
    )
  }
}
