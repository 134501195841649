import VectorLayer from 'ol/layer/Vector'
import olMap from 'ol/Map'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style, Text } from 'ol/style'
import { statusColor } from '../../shared/utils'
import ClusterSource from 'ol/source/Cluster'
import CircleStyle from 'ol/style/Circle'
import { MapService } from '../../shared/services'
import { StyleService } from '../services/style.service'
import { Injector } from '@angular/core'
import { fromLonLat } from 'ol/proj'
import Point from 'ol/geom/Point'
import { SelectStatus, SoilSurvey, Zoom } from '@sde-ild/ssd-soillib-lib'
import { Store } from '@ngxs/store'
import { AppStateSelectors } from '../../store/app/app.selectors'
import { MapStateSelectors } from '../../store/map/map.selectors'
import { FilterStateSelectors } from '../../store/filter/filter.selectors'

export class SurveysLayer {
  surveysSource: VectorSource
  surveysLayer: VectorLayer
  surveysClusterSource: ClusterSource
  private styleCache = {}

  private highlightedBoreholeId: string

  private mapService: MapService
  private styleService: StyleService
  private store: Store

  constructor(private map: olMap, private injector: Injector) {
    this.mapService = this.injector.get(MapService)
    this.styleService = this.injector.get(StyleService)
    this.store = this.injector.get(Store)
    this.surveysSource = new VectorSource({})
    this.surveysClusterSource = new ClusterSource({
      distance: 15,
      source: this.surveysSource,
    })
    this.surveysLayer = new VectorLayer({
      source: this.surveysClusterSource,
      style: (feature) => {
        const size = feature.get('features').length
        let featureStyle: Style
        const zoom = this.map.getView().getZoom()
        if (size > 1 && zoom && zoom < Zoom.MAX) {
          featureStyle = this.styleCache[size]
          if (!featureStyle) {
            featureStyle = new Style({
              image: new CircleStyle({
                radius: 12,
                fill: new Fill({
                  color: [255, 153, 0, 0.8],
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: '#fff',
                }),
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 0.6)',
                  width: 3,
                }),
              }),
            })
            this.styleCache[size] = featureStyle
          }
        } else {
          featureStyle = feature.get('features')[0].getStyle()
        }
        return featureStyle
      },
    })
    this.surveysLayer.set('name', 'SURVEY')
    this.map.addLayer(this.surveysLayer)
  }

  setSurveyPostion(survey: SoilSurvey) {
    if (survey.id) {
      const feature = this.surveysSource.getFeatureById(survey.id)
      feature?.setGeometry(new Point(fromLonLat([Number(survey.lon_coord), Number(survey.lat_coord)])))
    }
  }

  drawSurveysAdded() {
    this.mapService.drawSurveysOnMap(
      this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey)?.id,
      this.surveysSource,
      this.store.selectSnapshot(FilterStateSelectors.slices.displayedSurveys),
      this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite),
      this.store.selectSnapshot(MapStateSelectors.slices.surveysToDraw),
    )
  }

  drawSurveys() {
    this.mapService.drawSurveys(
      this.surveysSource,
      this.store.selectSnapshot(FilterStateSelectors.slices.displayedSurveys),
      this.store.selectSnapshot(MapStateSelectors.slices.surveys) || [],
      this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite),
      this.store.selectSnapshot(AppStateSelectors.slices.selectedSurvey),
    )
  }

  deleteSurveys(surveyIdsToDelete: string[]) {
    this.mapService.deleteSurveys(this.surveysSource, surveyIdsToDelete)
  }

  addSurveys(selectedSurveyId: string | null | undefined, surveysToAdd: SoilSurvey[]) {
    this.mapService.addSurveys(
      selectedSurveyId,
      this.surveysSource,
      this.store.selectSnapshot(FilterStateSelectors.slices.displayedSurveys),
      surveysToAdd,
      this.store.selectSnapshot(AppStateSelectors.slices.selectedJobsite),
    )
  }

  setSurveyStyle(surveys: SoilSurvey[], status: SelectStatus) {
    this.mapService.setSurveyFeatureStyle(this.surveysSource, surveys, status)
  }

  toggleSurveyStyle(surveys: SoilSurvey[]) {
    this.mapService.toggleSurveySelectStyle(this.surveysSource, surveys)
  }

  resetFeatureStyle(surveys: SoilSurvey[]) {
    surveys
      .map(({ id, type }) => ({
        feature: id ? this.surveysSource.getFeatureById(id) : null,
        type,
      }))
      .forEach(({ feature, type }) => {
        if (feature) {
          feature.set('projectedBorehole', false)
          feature.set('originalBorehole', false)
          if (type) {
            const oldStyle = this.styleService.getStyle(type, statusColor(feature.get('selectStatus')), 'black', 1)
            feature.setStyle(oldStyle)
          }
        }
      })
  }

  setCrossSectionBoreholeHoverStyle(boreholeId: string, surveys: SoilSurvey[]) {
    const highlightedFeature = this.highlightedBoreholeId
      ? this.surveysSource.getFeatureById(this.highlightedBoreholeId)
      : null
    if (highlightedFeature) {
      const survey = surveys.filter((item) => item.id === this.highlightedBoreholeId)[0]
      if (highlightedFeature.get('originalBorehole') === true) {
        const oldStyle = this.styleService.getOriginalBoreholeStyle(survey.type)
        highlightedFeature.setStyle(oldStyle)
      } else if (highlightedFeature.get('projectedBorehole') === true) {
        const oldStyle = this.styleService.getProjectedBoreholeStyle(survey.type)
        highlightedFeature.setStyle(oldStyle)
      } else if (survey.type) {
        const oldStyle = this.styleService.getStyle(
          survey.type,
          statusColor(highlightedFeature.get('selectStatus')),
          'black',
          1,
        )
        highlightedFeature.setStyle(oldStyle)
      }
    }
    if (boreholeId) {
      const feature = this.surveysSource.getFeatureById(boreholeId)
      const survey0 = surveys.filter((survey) => survey.id === boreholeId)[0]
      const hoverStyle = this.styleService.getBoreholeHoverStyle(survey0.type)
      feature?.setStyle(hoverStyle)
      this.highlightedBoreholeId = boreholeId
    }
  }
}
